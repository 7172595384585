import {createSlice} from '@reduxjs/toolkit';

export type FatalErrorsState = {
    accessDenied: boolean;
}

const initialState: FatalErrorsState = {
    accessDenied: false,
};

const fatalErrorsSlice = createSlice({
    name: 'fatalErrors',
    initialState,
    reducers: {
        setAccessDenied: (state) => {
            state.accessDenied = true;
        },
    },
});

export const fatalErrorsReducer = fatalErrorsSlice.reducer;
export const fatalErrorsReducerName = fatalErrorsSlice.name;
export const {setAccessDenied} = fatalErrorsSlice.actions;
