import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function UserAddIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)} {...props}>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 10 10'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M1.5165 8.5C1.2315 8.5 1 8.264 1 7.9725C1 7.92 1.0075 7.8685 1.022 7.8195C1.051 7.722 1.1075 7.6345 1.184 7.569C1.222 7.536 1.2655 7.5085 1.313 7.488L2.8595 6.5595C3.1935 6.76 3.5755 6.875 4 6.875C4.4245 6.875 4.8065 6.76 5.14 6.5595L5.316 6.665C5.467 6.362 5.6665 6.087 5.9065 5.853L5.9035 5.851C6.426 5.1555 6.7145 4.1955 6.7145 3.2495C6.7145 1.605 5.6235 0.5 4 0.5C2.3765 0.5 1.2855 1.605 1.2855 3.25C1.2855 4.1955 1.574 5.156 2.0965 5.8515L0.839 6.6065C0.3265 6.8635 0 7.3915 0 7.9725C0 8.815 0.6805 9.5 1.5165 9.5H5.4045C5.2285 9.196 5.105 8.859 5.045 8.5H1.5165ZM4 1.5C4.947 1.5 5.7145 2.042 5.7145 3.25C5.7145 3.991 5.472 4.8085 5.038 5.3315C4.764 5.6615 4.4145 5.875 4 5.875C3.866 5.875 3.739 5.853 3.6185 5.812C3.5585 5.7915 3.5 5.766 3.4435 5.7365C3.3305 5.677 3.225 5.6005 3.127 5.51C3.069 5.456 3.0145 5.3955 2.9615 5.332C2.9255 5.289 2.89 5.245 2.857 5.198C2.775 5.082 2.7015 4.955 2.636 4.82C2.539 4.617 2.4605 4.397 2.403 4.17C2.3645 4.0185 2.335 3.864 2.3155 3.7095C2.2955 3.5555 2.2855 3.401 2.2855 3.25C2.2855 2.042 3.053 1.5 4 1.5ZM7.5 6H8.5V7.5H10V8.5H8.5V10H7.5V8.5H6V7.5H7.5V6Z' />
            </svg>
        </span>
    );
}
