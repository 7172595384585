// This is a temporary store while we are transitioning from Flux to Redux. This file exports
// the configured Redux store for use by actions and selectors.

import {useDispatch, useSelector} from 'react-redux';

import React from 'react';

import {AnyAction, bindActionCreators, EnhancedStore} from '@reduxjs/toolkit';
import type {ThunkMiddleware} from 'redux-thunk';

import configureStore from 'store';
import type {GlobalState} from 'types/store';

const store = configureStore({});

type StoreType = EnhancedStore<GlobalState, AnyAction, [ThunkMiddleware<GlobalState, AnyAction, undefined>]>;

export type RootState = GlobalState;
export type AppDispatch = StoreType['dispatch'];

export type ThunkConfig = {state: GlobalState; dispatch: AppDispatch};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: <TSelected>(
    selector: (state: GlobalState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => TSelected = useSelector;

export function useAction<A extends AnyAction>(actions: A[], deps: any[]) {
    const dispatch = useDispatch();

    return React.useMemo(
        () => {
            if (Array.isArray(actions)) {
                return actions.map((a) => bindActionCreators(a, dispatch));
            }
            return bindActionCreators(actions, dispatch);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ? [dispatch, ...deps] : [dispatch],
    );
}

declare global {
    interface Window {
        store: typeof store;
    }
}

// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV !== 'production') {
    window.store = store;
}

export default store;
