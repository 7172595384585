import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import type {AppDispatch} from 'stores/redux_store';
import type {GlobalState} from 'types/store';

import {getHasUnreadThreadsByTeamId} from './get_has_unread_threads_by_team_id';

/**
 * Checks if user has unread threads in current team
 */
export const getHasUnreadThreadsInCurrentTeam = createAsyncThunk(
    'threads/actions/getHasUnreadThreadsInCurrentTeam',
    async (_: void, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as GlobalState;

        const currentUserId = getCurrentUserId(state);
        const currentTeamId = getCurrentTeamId(state);

        if (!currentUserId || !currentTeamId) {
            return thunkAPI.rejectWithValue('no user or team id');
        }

        const result = await dispatch(getHasUnreadThreadsByTeamId({teamId: currentTeamId})).unwrap();

        return result;
    },
);
