import React from 'react';
import './list_item.scss';
import classNames from 'classnames';

interface Props {
    image?: any;
    label?: any;
    extra?: any;
    compact?: boolean;
    className?: string;
    children?: any;
}

const ListItem = (props: Props) => {
    const {image, label, extra, compact, className, children} = props;
    return (
        <div className={classNames(className, 'time-list-item', {'time-list-item_compact': compact})}>
            {image ? <div className='time-list-item__image'>{image}</div> : null}
            <div className='time-list-item__content'>
                {label && !compact ? <div className='time-list-item__label'>{label}</div> : null}
                {children && <div>{children}</div>}
            </div>
            {extra}
        </div>
    );
};

export default ListItem;
