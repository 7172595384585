import * as Sentry from '@sentry/react';
import {Breadcrumb} from '@sentry/react';
import {parse} from '@tinkoff/url';

import type {User, CaptureContext, SeverityLevel} from '@sentry/types';

import type {TelemetryHandler} from 'packages/mattermost-redux/src/client/telemetry';

// eslint-disable-next-line no-process-env
const sentryEnabled = process.env.SENTRY_ENABLED;

let sentryInitialized = false;

export function initSentry() {
    if (sentryEnabled && !sentryInitialized) {
        Sentry.init({
            // eslint-disable-next-line no-process-env
            dsn: process.env.SENTRY_DSN,
            integrations: [],
            autoSessionTracking: false,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            enableAutoSessionTracking: false,
            enableAutoPerformanceTracking: false,
            // eslint-disable-next-line no-process-env
            environment: typeof window === 'undefined' ? 'development' : parse(window.location.href).hostname,
            // eslint-disable-next-line no-process-env
            release: process.env.SENTRY_RELEASE || 'dev',

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.001,

            // Add this ignore as it is ignored in webapp
            // @see line 50
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
            ],
            beforeBreadcrumb(breadcrumb) {
                if (Array.isArray(breadcrumb.data?.arguments)) {
                    const breadcrumbArgs = breadcrumb.data!.arguments.filter((arg) => typeof arg !== 'object' && typeof arg !== 'undefined');
                    return {
                        ...breadcrumb,
                        data: {
                            ...breadcrumb.data,
                            arguments: breadcrumbArgs,
                        },
                    };
                }
                return breadcrumb;
            },
        });
        sentryInitialized = true;
    }
}

export function setSentryUser(user: User) {
    if (sentryInitialized) {
        Sentry.setUser(user);
    }
}

export function setSentryServer(serverUrl: string) {
    if (sentryInitialized) {
        Sentry.setExtra('selectedServerUrl', serverUrl);
    }
}

export function reportErrorToSentry<Exception>(exception: Exception, captureContext?: CaptureContext) {
    if (sentryInitialized) {
        Sentry.captureException(exception, captureContext);
    }
}

export function reportMessageToSentry(exception: string, captureContext?: CaptureContext | SeverityLevel) {
    if (sentryInitialized) {
        Sentry.captureMessage(exception, captureContext);
    }
}

export function addBreadcrumbToSentry(breadcrumb: Breadcrumb) {
    if (sentryInitialized) {
        Sentry.addBreadcrumb(breadcrumb);
    }
}

export class SentryBreadcrumbsTelemetry implements TelemetryHandler {
    pageVisited = (userId: string, userRoles: string, category: string, name: string) => {
        addBreadcrumbToSentry({
            category,
            type: 'navigation',
            level: 'info',
            timestamp: Date.now(),
            message: name,
            data: {
                userRoles,
                userId,
            },
        });
    }
    trackEvent = (userId: string, userRoles: string, category: string, event: string, props = {}) => {
        addBreadcrumbToSentry({
            category,
            type: 'info',
            message: event,
            level: 'info',
            timestamp: Date.now(),
            data: {
                userRoles,
                userId,
                ...props,
            },
        });
    }
}
