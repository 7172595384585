import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import omit from '@tinkoff/utils/object/omit';

import {injectIntl} from 'react-intl';

import {intlShape} from 'utils/react_intl';

class ToggleModalButton extends React.PureComponent {
    static get propTypes() {
        return {
            ariaLabel: PropTypes.string,
            children: PropTypes.node.isRequired,
            modalId: PropTypes.string.isRequired,
            dialogType: PropTypes.elementType.isRequired,
            dialogProps: PropTypes.object,
            intl: intlShape.isRequired,
            onClick: PropTypes.func,
            className: PropTypes.string,
            showUnread: PropTypes.bool,
            noDefaultClass: PropTypes.bool,
            actions: PropTypes.shape({
                openModal: PropTypes.func.isRequired,
            }).isRequired,
        };
    }

    static defaultProps = {
        dialogProps: {},
        className: '',
    };

    show(event) {
        event?.preventDefault();

        const {modalId, dialogProps, dialogType} = this.props;

        const modalData = {
            modalId,
            dialogProps,
            dialogType,
        };

        this.props.actions.openModal(modalData);
    }

    handleToggleModalButtonClick = (event) => {
        this.props.onClick?.();
        this.show(event);
    }

    render() {
        const {
            children,
            intl: {formatMessage},
            ...props
        } = this.props;

        let ariaLabel;
        if (props.ariaLabel) {
            ariaLabel = formatMessage(
                {id: 'accessibility.button.dialog', defaultMessage: '{dialogName} dialog'},
                {dialogName: props.ariaLabel},
            );
        }

        let badge = null;
        if (this.props.showUnread) {
            badge = <span className={'unread-badge'} />;
        }

        return (
            <button
                {...omit(['modalId', 'dialogType', 'dialogProps', 'ariaLabel', 'actions', 'showUnread', 'noDefaultClass'], props)}
                className={classNames(!this.props.noDefaultClass && 'style--none', props.className)}
                aria-label={ariaLabel}
                onClick={this.handleToggleModalButtonClick}
            >
                {children}
                {badge}
            </button>
        );
    }
}

export default injectIntl(ToggleModalButton);
