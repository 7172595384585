import type {Channel} from '@mattermost/types/channels';

import {isDmChannel} from './isDmChannel';
import {isGmChannel} from './isGmChannel';

/**
 * Говорит что это личка или групповой чат
 */
export function isDirectLikeChannel(channel?: Channel) {
    return isDmChannel(channel) || isGmChannel(channel);
}
