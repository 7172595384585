import {ComponentProps, ReactNode} from 'react';

import {ListBox, ListBoxItem} from '../listbox';

import {DropdownListItem} from './dropdown-list-item';

type Props<Payload = unknown> = Omit<ComponentProps<typeof ListBox>, 'renderItem' | 'items'> & {
    items: Array<ListBoxItem<Payload>>;
    renderItem?: (item: ListBoxItem<Payload>, isFocused?: boolean, isSelected?: boolean) => ReactNode;
};

export const DropdownList = <Payload = unknown>({renderItem = renderItemDefault, ...props}: Props<Payload>) => (
    <ListBox
        {...props}
        renderItem={renderItem}
    />
);

const renderItemDefault = (item: ListBoxItem, isFocused?: boolean) => (
    <DropdownListItem
        label={item.label}
        focused={isFocused}
    />
);
