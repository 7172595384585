import {useIntl} from 'react-intl';

import {useSelector} from 'react-redux';

import {LinkVariantIcon} from '@time-webkit/all/icons/link-variant';
import {getCurrentTeam} from 'mattermost-redux/selectors/entities/teams';
import {getSiteURL} from '../../../utils/url';

import {ButtonCopyToClipboard} from '../../button-copy-to-clipboard';

type Props = {
    containerId?: string;
}

export const LinkInviteMember = ({containerId}: Props) => {
    const intl = useIntl();
    const currentTeam = useSelector(getCurrentTeam);
    const inviteUrl = `${getSiteURL()}/signup_user_complete/?id=${currentTeam!.invite_id}`;

    return (
        <ButtonCopyToClipboard
            containerId={containerId}
            icon={<LinkVariantIcon size={16} />}
            copyText={inviteUrl}
            messageSuccess={intl.formatMessage({
                id: 'invite.members.link_has_been_copied',
                defaultMessage: 'Link has been copied',
            })}
        >
            {
                intl.formatMessage({
                    id: 'invite.members.copy_invite_link',
                    defaultMessage: 'Copy invite link',
                })
            }
        </ButtonCopyToClipboard>
    );
};
