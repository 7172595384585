import {getChannel} from 'mattermost-redux/selectors/entities/channels';
import {createThunkAction} from 'stores/create_thunk_action';
import {selectThreadsByChannelId} from '../selectors/select_threads_by_channel_id';

import {handleThreadsReadChanged} from './handle_threads_read_changed';

type Payload = {
    channelId: string;
    lastViewedAt: number;
};

export const markAllThreadsInChannelRead = (payload: Payload) =>
    createThunkAction('threads/actions/markAllThreadsInChannelRead', (dispatch, getState) => {
        const {channelId, lastViewedAt} = payload;
        const state = getState();
        const threadsInChannel = selectThreadsByChannelId(state, channelId);
        const channel = getChannel(state, channelId);

        if (!channel?.id) {
            return;
        }

        const teamId = channel.team_id;

        for (const thread of threadsInChannel) {
            dispatch(
                handleThreadsReadChanged({
                    channelId,
                    lastViewedAt,
                    newUnreadMentions: 0,
                    newUnreadReplies: 0,
                    teamId,
                    threadId: thread.id,
                    thread,
                }),
            );
        }
    });
