import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {createThunkAction} from 'stores/create_thunk_action';
import {loadDmCategoryForTeam} from '../actions/load_dm_category_for_team';
import {loadSidebarForTeam} from '../actions/load_sidebar_for_team';
import {SidebarLogger} from '../logger';
import {getDMChannelShowPreference} from '../selectors/get_dm_channel_show_preference';
import {getGMChannelShowPreference} from '../selectors/get_gm_channel_show_preference';
import {isFilteredSidebarEnabled} from '../selectors/is_filtered_sidebar_enabled';
import type {PreferenceChangedEvent} from '../types/preference_changed';
import {isDirectChannelShowPreference} from '../utils/isDirectChannelShowPreference';
import {isGroupChannelShowPreference} from '../utils/isGroupChannelShowPreference';
import {isLimitVisibleDmsGmsPreference} from '../utils/isLimitVisibleDmsGmsPreference';
import {parsePreferenceChangedEvent} from '../utils/parsePreferenceChangedEvent';

export const handlePreferenceChangedEvent = (msg: PreferenceChangedEvent) =>
    createThunkAction('sidebar/wsEventsHandlers/handlePreferenceChanged', async (dispatch, getState) => {
        const state = getState();
        const parsedMessage = parsePreferenceChangedEvent(msg);
        const preference = parsedMessage.data.preference;
        const currentTeamId = getCurrentTeamId(state);

        /**
         * Два следующих условия выполняют следующую логику:
         * Так как появился канал который теперь нужно "показать"/"скрыть",
         * нам нужно перезапросить сайдбар
         *
         * @TODO: сделать более умное изменение
         */

        if (isDirectChannelShowPreference(preference)) {
            const existingPreferenceValue = getDMChannelShowPreference(state, preference.name, false);

            if (existingPreferenceValue === false && (!preference.value || preference.value === 'false')) {
                return false;
            }

            if (existingPreferenceValue === true && preference.value === 'true') {
                return false;
            }

            const currentTeamId = getCurrentTeamId(state);

            dispatch(
                loadSidebarForTeam({
                    teamId: currentTeamId,
                }),
            );
        }

        if (isGroupChannelShowPreference(preference)) {
            const existingPreferenceValue = getGMChannelShowPreference(state, preference.name, false);

            if (existingPreferenceValue === false && (!preference.value || preference.value === 'false')) {
                return false;
            }

            if (existingPreferenceValue === true && preference.value === 'true') {
                return false;
            }

            dispatch(
                loadSidebarForTeam({
                    teamId: currentTeamId,
                }),
            );
        }

        /**
         * При изменении лимита ограничения показа каналов в категории ЛС
         * следует перезапросить эту категорию ЛС
         * Но, только если бэкенд фильтрует ЛС сам, так как в ином случае фронтенд
         * и так обладает достаточно информацией и перезапрос категории не нужен
         */
        if (!isFilteredSidebarEnabled(state)) {
            return;
        }

        const limitVisibleDmsGmsPreference = isLimitVisibleDmsGmsPreference(preference) ? preference : undefined;

        if (limitVisibleDmsGmsPreference) {
            SidebarLogger.info({
                event: 'load-dm-category-for-team',
                reason: 'limit-changed',
                newValue: limitVisibleDmsGmsPreference.value,
            });
            dispatch(loadDmCategoryForTeam({teamId: currentTeamId}));
        }
    });
