import {useTooltipTriggerState} from '@react-stately/tooltip';

import {useTooltipTrigger} from '@react-aria/tooltip';

import {type HTMLAttributes, useRef, type ReactNode, type ComponentProps} from 'react';

import {mergeProps} from '@react-aria/utils';

import classNames from 'classnames';

import useCopyText from '../common/hooks/useCopyText';

import {Link} from '@time-webkit/all/atoms/link';

import {Tooltip} from '@time-webkit/all/atoms/tooltip';

import styles from './button-copy-to-clipboard.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    copyText: string;
    messageSuccess: string;

    /**
     * Обязательно передать этот параметр, если кнопка находится внутри модалки
    */
    containerId?: string;
    size?: ComponentProps<typeof Link>['size'];
    icon?: ReactNode;
    children?: ReactNode;
}

export const ButtonCopyToClipboard = ({className, copyText, messageSuccess, icon, containerId, children, size, ...props}: Props) => {
    const targetRef = useRef(null);
    const tooltipTriggerState = useTooltipTriggerState({delay: 200});
    const {copiedRecently, onClick} = useCopyText({
        text: copyText,
        appendId: containerId,
        successCopyTimeout: 2_000,
    });
    const {triggerProps, tooltipProps} = useTooltipTrigger({}, tooltipTriggerState, targetRef);

    return (
        <>
            <div
                {...mergeProps(triggerProps, props)}
                className={classNames(styles.root, className)}
                ref={targetRef}
            >
                <Link
                    size={size}
                    as='button'
                    type='button'
                    pseudo={true}
                    icon={icon}
                    onClick={onClick}
                >
                    {children}
                </Link>
            </div>
            {copiedRecently && (
                <Tooltip
                    {...tooltipProps}
                    state={tooltipTriggerState}
                    targetRef={targetRef}
                >
                    {messageSuccess}
                </Tooltip>
            )}
        </>
    );
};
