import type {RootState} from 'stores/redux_store';

export const getActivityItems = (state: RootState) => state.activity.items;

export const getActivityPaginationToken = (state: RootState) => state.activity.nextPageToken;

export const getIsActivityLoading = (state: RootState) => state.activity.isLoading;

export const getIsActivityLoaded = (state: RootState) => state.activity.isLoaded;

export const getActivityAbortController = (state: RootState) => state.activity.abortController;
