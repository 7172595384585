import {TypographySize} from '@time-webkit/all/hooks/typography';

export enum HeadingLevel {
    FIRST = 'h1',
    SECOND = 'h2',
    THIRD = 'h3',
    FOURTH = 'h4',
    FIFTH = 'h5',
    SIXTH = 'h6',
}

export const LEVEL_TO_HEADING_TYPOGRAPHY = {
    [HeadingLevel.FIRST]: TypographySize.Heading1,
    [HeadingLevel.SECOND]: TypographySize.Heading2,
    [HeadingLevel.THIRD]: TypographySize.Heading3,
    [HeadingLevel.FOURTH]: TypographySize.Heading4,
    [HeadingLevel.FIFTH]: TypographySize.Heading5,
    [HeadingLevel.SIXTH]: TypographySize.Heading6,
} as const;
