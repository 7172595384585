import {createSelector} from 'reselect';

import {getConfig} from 'mattermost-redux/selectors/entities/general';
import {getCurrentTeam} from 'mattermost-redux/selectors/entities/teams';

export const getAllowedGuestDomains = createSelector(
    getConfig,
    (config) => makeDomainsListFormString(config.GuestRestrictCreationToDomains ?? ''),
);

export const getAllowedTeamDomains = createSelector(
    getConfig,
    getCurrentTeam,
    (config, currentTeam) => {
        const currentTeamDomains = makeDomainsListFormString(currentTeam?.allowed_domains ?? '');
        const globalTeamDomains = makeDomainsListFormString(config.TeamRestrictCreationToDomains ?? '');
        if (currentTeamDomains.length > 0) {
            return currentTeamDomains;
        }
        return globalTeamDomains;
    },
);

function makeDomainsListFormString(input: string) {
    return input.split(',').map((domain) => domain.trim()).filter((domain) => domain !== '');
}
