import {useFocusRing} from '@react-aria/focus';
import {ComponentProps, RefObject} from 'react';

import {mergeProps} from '@react-aria/utils';

import {InputContainer} from './input-container';

type Props = {

    inputRef: RefObject<HTMLInputElement>;

    value: string;

    /**
     * id инпута, нужен для тега label
     */
    inputId: string;

    /**
     * указывает на то, что внутри компонента используется text input, по умолчанию true
     */
    isTextInput?: boolean;

    /**
     * если input неактивен, то он не будет получать фокус
     */
    isDisabled?: boolean;

    /**
     * если у компонента есть встроенный label, следует ли скрывать его при фокусе
     */
    hideLabelOnFocus?: boolean;
};

type Result = Partial<ComponentProps<typeof InputContainer>>;

export const useInput = ({inputRef, value, inputId, hideLabelOnFocus, isDisabled, isTextInput = true}: Props): Result => {
    const {isFocusVisible, isFocused, focusProps} = useFocusRing({within: true, isTextInput});
    const isDirty = Boolean(value);

    const handleMouseDown = (event: MouseEvent) => {
        if (isDisabled) {
            return;
        }

        const isInputFocused = inputRef.current === document.activeElement;

        if (isInputFocused) {
            const haveClickedOnInput = event.target === inputRef.current;
            if (!haveClickedOnInput) {
                event.preventDefault();
            }
            return;
        }

        inputRef.current?.focus();
        event.preventDefault();
    };

    const hideLabel = hideLabelOnFocus && isFocused;

    return mergeProps(focusProps, {
        inputId,
        focusVisible: isFocusVisible,
        showLabelReduced: (isFocused || isDirty) && !hideLabel,
        hideLabel,
        onMouseDown: handleMouseDown,
    });
};
