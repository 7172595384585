import {ComponentProps} from 'react';
import {ActionCreatorsMapObject, bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {isCustomStatusEnabled, isCustomStatusExpired, makeGetCustomStatus} from 'selectors/views/custom_status';
import {getCurrentUserTimezone} from 'selectors/general';
import {GlobalState} from 'types/store';
import {ModalData} from 'types/actions';

import {openModal} from 'actions/views/modals';
import {GenericAction} from 'mattermost-redux/types/actions';

import CustomStatus from './custom_status';

function makeMapStateToProps(): (
    state: GlobalState,
    props: Pick<ComponentProps<typeof CustomStatus>, 'userId' | 'editable'>,
) => Pick<
ComponentProps<typeof CustomStatus>,
'customStatus' | 'isCustomStatusEnabled' | 'isCustomStatusExpired' | 'currentUserTimezone'
> {
    const getCustomStatus = makeGetCustomStatus();

    return (state, {editable, userId}) => {
        const customStatus = getCustomStatus(state, userId);
        return {
            editable: editable && userId === getCurrentUserId(state),
            customStatus,
            isCustomStatusEnabled: isCustomStatusEnabled(state),
            isCustomStatusExpired: isCustomStatusExpired(state, customStatus),
            currentUserTimezone: getCurrentUserTimezone(state),
        };
    };
}

type Actions = {
    openModal: <P>(modalData: ModalData<P>) => void;
};

function mapDispatchToProps(dispatch: Dispatch<GenericAction>) {
    return {
        actions: bindActionCreators<ActionCreatorsMapObject<any>, Actions>(
            {
                openModal,
            },
            dispatch,
        ),
    };
}

export default connect(makeMapStateToProps, mapDispatchToProps)(CustomStatus);
