import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';
import type {Channel, ChannelModeration} from '@mattermost/types/channels';

type Payload = {

    /**
     * Channel GUID
     */
    channelId: Channel['id'];

    withCache?: {ttl: number};
};

/**
 * Get information about channel's moderation.
 *
 * Must have `manage_system` permission or to be a channel admin
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetChannelModerations
 * @example "/channels/{channel_id}/moderations"
 */
export const getChannelModerations = createAsyncThunk(
    'sidebar/api/getChannelModerations',
    async (payload: Payload, thunkAPI) => {
        const {channelId, withCache} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/channels/${channelId}/moderations`;

        try {
            const {data} = await requester.get<ChannelModeration[]>(url, {
                ...options,
                id: `getChannelModerations-${channelId}`,
                cache: withCache ? {...withCache, methods: ['get'], etag: false, interpretHeader: false} : false,
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
);
