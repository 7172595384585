import {batchActions} from 'utils/batch_actions';

import {ChannelCategoryTypes, ChannelTypes} from 'mattermost-redux/action_types';

import {Client4} from 'mattermost-redux/client';

import {logError} from 'mattermost-redux/actions/errors';
import {forceLogoutIfNecessary} from 'mattermost-redux/actions/helpers';

import {CategoryTypes} from 'mattermost-redux/constants/channel_categories';

import {
    getCategory,
    getCategoryIdsForTeam,
    getCategoryInTeamWithChannel,
} from 'mattermost-redux/selectors/entities/channel_categories';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';

import {ActionFunc, DispatchFunc, GetStateFunc} from 'mattermost-redux/types/actions';
import {savePreferences} from 'mattermost-redux/actions/preferences';
import {CategorySorting, OrderedChannelCategories, ChannelCategory} from 'mattermost-redux/types/channel_categories';
import {Channel} from 'mattermost-redux/types/channels';

import {insertMultipleWithoutDuplicates, insertWithoutDuplicates, removeItem} from 'mattermost-redux/utils/array_utils';
import {receivedChannelCategories, updateSidebarCategoriesForTeamForUser} from 'features/sidebar';
import Constants from 'utils/constants';
import {Preferences} from 'mattermost-redux/constants';
import {LimitVisibleDmsGmsValue} from 'features/sidebar/types';

export function expandCategory(categoryId: string) {
    return setCategoryCollapsed(categoryId, false);
}

export function collapseCategory(categoryId: string) {
    return setCategoryCollapsed(categoryId, true);
}

export function setCategoryCollapsed(categoryId: string, collapsed: boolean) {
    return patchCategory(categoryId, {
        collapsed,
    });
}

export function setCategorySorting(categoryId: string, sorting: CategorySorting) {
    return patchCategory(categoryId, {
        sorting,
    });
}

export function patchCategory(categoryId: string, patch: Partial<ChannelCategory>): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        const category = getCategory(state, categoryId);
        const patchedCategory = {
            ...category,
            ...patch,
        };

        dispatch(receivedChannelCategories([patchedCategory]));

        try {
            await Client4.updateChannelCategory(currentUserId, category.team_id, patchedCategory);
        } catch (error: any) {
            dispatch(receivedChannelCategories([category]));

            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch(logError(error));
            return {error};
        }

        return {data: patchedCategory};
    };
}

export function setCategoryMuted(categoryId: string, muted: boolean) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const category = getCategory(state, categoryId);

        const result = await dispatch(
            updateCategory({
                ...category,
                muted,
            }),
        );

        if ('error' in result) {
            return result;
        }

        const updated = result.data as ChannelCategory;

        return dispatch(
            batchActions([
                receivedChannelCategories([updated]),
                ...updated.channel_ids.map((channelId) => ({
                    type: ChannelTypes.SET_CHANNEL_MUTED,
                    data: {
                        channelId,
                        muted,
                    },
                })),
            ]),
        );
    };
}

function updateCategory(category: ChannelCategory) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let updatedCategory;
        try {
            updatedCategory = await Client4.updateChannelCategory(currentUserId, category.team_id, category);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch(logError(error));
            return {error};
        }

        // The updated category will be added to the state after receiving the corresponding websocket event.

        return {data: updatedCategory};
    };
}

export function fetchMyCategories(teamId: string) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const currentUserId = getCurrentUserId(getState());

        let data: OrderedChannelCategories;
        try {
            data = await Client4.getChannelCategories(currentUserId, teamId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch(logError(error));
            return {error};
        }

        return dispatch(
            batchActions([
                receivedChannelCategories(data.categories),
                {
                    type: ChannelCategoryTypes.RECEIVED_CATEGORY_ORDER,
                    data: {
                        teamId,
                        order: data.order,
                    },
                },
            ]),
        );
    };
}

/**
 * @TODO: перенести в фичу "сайдбар"
 */
export function moveChannelsToCategory(
    categoryId: string,
    channelIds: string[],
    newIndex: number,
    setManualSorting = true,
) {
    // eslint-disable-next-line consistent-return
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const targetCategory = getCategory(state, categoryId);
        const currentUserId = getCurrentUserId(state);

        // The default sorting needs to behave like alphabetical sorting until the point that the user rearranges their
        // channels at which point, it becomes manual. Other than that, we never change the sorting method automatically.
        let sorting = targetCategory.sorting;
        if (
            setManualSorting &&
            targetCategory.type !== CategoryTypes.DIRECT_MESSAGES &&
            targetCategory.sorting === CategorySorting.Default
        ) {
            sorting = CategorySorting.Manual;
        }

        // Add the channels to the new category
        let categories = {
            [targetCategory.id]: {
                ...targetCategory,
                sorting,
                channel_ids: insertMultipleWithoutDuplicates(targetCategory.channel_ids, channelIds, newIndex),
            },
        };

        // Needed if we have to revert categories and for checking for favourites
        let unmodifiedCategories = {[targetCategory.id]: targetCategory};
        let sourceCategories: Record<string, string> = {};

        // And remove it from the old categories
        channelIds.forEach((channelId) => {
            const sourceCategory = getCategoryInTeamWithChannel(getState(), targetCategory.team_id, channelId);
            if (sourceCategory && sourceCategory.id !== targetCategory.id) {
                unmodifiedCategories = {
                    ...unmodifiedCategories,
                    [sourceCategory.id]: sourceCategory,
                };
                sourceCategories = {...sourceCategories, [channelId]: sourceCategory.id};
                categories = {
                    ...categories,
                    [sourceCategory.id]: {
                        ...(categories[sourceCategory.id] || sourceCategory),
                        channel_ids: removeItem(
                            (categories[sourceCategory.id] || sourceCategory).channel_ids,
                            channelId,
                        ),
                    },
                };
            }
        });

        const categoriesArray = Object.values(categories).reduce((allCategories: ChannelCategory[], category) => {
            allCategories.push(category);
            return allCategories;
        }, []);

        try {
            const updatedCategories = await dispatch(
                updateSidebarCategoriesForTeamForUser({
                    categories: categoriesArray,
                    teamId: targetCategory.team_id,
                    userId: currentUserId,
                }),
            ).unwrap();

            dispatch(receivedChannelCategories(updatedCategories));
        } catch (error) {
            return {error};
        }
    };
}

export function moveCategory(teamId: string, categoryId: string, newIndex: number) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const order = getCategoryIdsForTeam(state, teamId)!;
        const currentUserId = getCurrentUserId(state);

        const newOrder = insertWithoutDuplicates(order, categoryId, newIndex);

        // Optimistically update the category order
        const result = dispatch({
            type: ChannelCategoryTypes.RECEIVED_CATEGORY_ORDER,
            data: {
                teamId,
                order: newOrder,
            },
        });

        try {
            await Client4.updateChannelCategoryOrder(currentUserId, teamId, newOrder);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch(logError(error));

            // Restore original order
            dispatch({
                type: ChannelCategoryTypes.RECEIVED_CATEGORY_ORDER,
                data: {
                    teamId,
                    order,
                },
            });

            return {error};
        }

        return result;
    };
}

export function receivedCategoryOrder(teamId: string, order: string[]) {
    return {
        type: ChannelCategoryTypes.RECEIVED_CATEGORY_ORDER,
        data: {
            teamId,
            order,
        },
    };
}

export function createCategory(teamId: string, displayName: string, channelIds: Array<Channel['id']> = []): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const currentUserId = getCurrentUserId(getState());

        let newCategory;
        try {
            newCategory = await Client4.createChannelCategory(currentUserId, teamId, {
                team_id: teamId,
                user_id: currentUserId,
                display_name: displayName,
                channel_ids: channelIds,
            });
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch(logError(error));
            return {error};
        }

        // The new category will be added to the state after receiving the corresponding websocket event.

        return {data: newCategory};
    };
}

export function renameCategory(categoryId: string, displayName: string): ActionFunc {
    return patchCategory(categoryId, {
        display_name: displayName,
    });
}

export function deleteCategory(categoryId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const category = getCategory(state, categoryId);
        const currentUserId = getCurrentUserId(state);

        try {
            await Client4.deleteChannelCategory(currentUserId, category.team_id, category.id);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch(logError(error));
            return {error};
        }

        // The category will be deleted from the state after receiving the corresponding websocket event.

        return {data: true};
    };
}

export function setDMCategoryLimit(nextLimit: LimitVisibleDmsGmsValue) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        const {data} = await dispatch(savePreferences(currentUserId, [
            {
                user_id: currentUserId,
                category: Constants.Preferences.CATEGORY_SIDEBAR_SETTINGS,
                name: Preferences.LIMIT_VISIBLE_DMS_GMS,
                value: nextLimit,
            },
        ]));

        if (!data) {
            return {data: false};
        }

        return {data: true};
    };
}
