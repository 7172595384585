export const ACTIVITY_ROUTE = '/:team/activity';

export enum ActivityType {
    Mention = 'mention',
    Reaction = 'reaction',
}

export enum ActivitySortBy {
    PostedAt = 'posted_at',
    ActivityAt = 'activity_at',
}
