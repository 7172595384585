import {getCurrentUser} from 'mattermost-redux/selectors/entities/users';

import {General} from 'mattermost-redux/constants';
import {reportErrorToSentry} from 'utils/sentry';

import * as I18n from 'i18n/i18n';

import {GlobalState} from 'types/store';
import {Translations} from 'types/store/i18n';

export function getCurrentLocale(state: GlobalState): string {
    try {
        let defaultLocale = new Intl.Locale(navigator.language).minimize().baseName;
        if (!I18n.isLanguageAvailable(defaultLocale)) {
            defaultLocale = General.DEFAULT_LOCALE;
        }
        const currentLocale = getCurrentUser(state)?.locale;
        if (!currentLocale || !I18n.isLanguageAvailable(currentLocale)) {
            return defaultLocale;
        }
        return currentLocale;
    } catch (e) {
        reportErrorToSentry(e);
        return General.DEFAULT_LOCALE;
    }
}

export function getTranslations(state: GlobalState, locale: string): Translations {
    const localeInfo = I18n.getLanguageInfo(locale);

    let translations: Translations | undefined;
    if (localeInfo) {
        translations = state.views.i18n.translations[locale];
    }
    return translations ?? state.views.i18n.translations.en!;
}
