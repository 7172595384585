import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Team} from '@mattermost/types/teams';
import type {User} from 'features/users';

import type {AppDispatch} from 'stores/redux_store';
import {updateThreadReadForUser} from '../api/update_thread_read_for_user';
import type {ServerThread} from '../types/threads';

type Payload = {
    userId: User['id'];
    teamId: Team['id'];
    threadId: ServerThread['id'];
    timestamp: number;
};

export const updateThreadReadOnServer = createAsyncThunk(
    'threads/actions/updateThreadReadOnServer',
    async (payload: Payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const {teamId, threadId, timestamp, userId} = payload;

        await dispatch(updateThreadReadForUser({teamId, threadId, timestamp, userId})).unwrap();
    },
);
