import {SVGProps} from 'react';
export const CloseCircleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 16 16'
        fill='currentColor'
        {...props}
    >
        <path
            d='M8 1.333A6.66 6.66 0 0 1 14.668 8a6.66 6.66 0 0 1-6.666 6.666A6.66 6.66 0 0 1 1.334 8a6.66 6.66 0 0 1 6.667-6.667Zm2.394 3.333L8.001 7.06 5.607 4.666l-.94.94L7.061 8l-2.394 2.393.94.94L8.001 8.94l2.393 2.393.94-.94L8.941 8l2.393-2.394-.94-.94Z'
        />
    </svg>
);
