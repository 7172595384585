import {createContext, useContext} from 'react';

import {useInputAutocompleteState} from './use-input-autocomplete-state';

type InputAutocompleteContextType = ReturnType<typeof useInputAutocompleteState>

export const InputAutocompleteContext = createContext<InputAutocompleteContextType | undefined>(undefined);

export const useInputAutocompleteContext = () => {
    const value = useContext(InputAutocompleteContext);
    if (!value) {
        throw new Error('InputAutocompleteContext not found.');
    }
    return value;
};
