import {createSelector} from 'reselect';

import {getIsRhsOpen, getSelectedPostId} from 'selectors/rhs';
import type {GlobalState} from 'types/store';
import type {ClientThread} from '../types/extended';

import {getSelectedThreadId} from './get_selected_thread_id';

export const isThreadOpened = createSelector(
    (_: GlobalState, threadId: ClientThread['id']) => threadId,
    getSelectedThreadId,
    getIsRhsOpen,
    getSelectedPostId,
    (passedThreadId, selectedThreadId, isRHSOpen, selectedPostId) => {
        if (passedThreadId === selectedThreadId) {
            return true;
        }

        if (isRHSOpen) {
            return passedThreadId === selectedPostId;
        }
        return false;
    },
);
