import EmojiMap from 'utils/emoji_map';
import {localizeMessage} from 'utils/utils';

type Params = {
    emojiMap: EmojiMap;
    emojiName: string;
    isDmChannel: boolean;
    userDisplayName: string;
};

export const getNotificationBody = ({
    emojiMap,
    emojiName,
    isDmChannel,
    userDisplayName,
}: Params) => {
    const emoji = emojiMap.get(emojiName);
    const emojiText = emoji && EmojiMap.isSystemEmoji(emoji) ? String.fromCodePoint(parseInt(emoji.unified, 16)) : `:${emojiName}:`;
    const reactionText = localizeMessage('notification.reaction.reaction_on_message', 'Reaction on message');

    return `${isDmChannel ? '' : `${userDisplayName}: `}${reactionText} ${emojiText}`;
};
