import {ComponentProps} from 'react';

import {DropdownListItem} from '../../organisms/dropdown';
import {CheckIcon} from '../../icons/check';

import styles from './select-option.module.css';

type Props = ComponentProps<typeof DropdownListItem> & {
    selected?: boolean;
}

export const SelectOption = ({selected, ...props}: Props) => {
    return <DropdownListItem {...props} iconRight={selected && <CheckIcon className={styles.checkmark} size={16}/>} />;
};
