import {createAsyncThunk} from '@reduxjs/toolkit';

import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getDmUsers} from 'mattermost-redux/selectors/entities/getDmUsers';
import {getIsManualStatusForUserId} from 'mattermost-redux/selectors/entities/users';
import {UserTypes} from 'mattermost-redux/action_types';
import {Status} from 'features/users';
import type {Post} from '@mattermost/types/posts';

type Payload = Array<{
    post: Post;
    setOnline: boolean;
}>;

export const setOnlineStatusForNewPostCreators = createAsyncThunk(
    'posts/actions/setOnlineStatusForNewPostCreators',
    (payload: Payload, thunkAPI) => {
        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const currentUserId = getCurrentUserId(state);
        const dmUsers = getDmUsers(state);

        const creatorsIdsSet = new Set<string>();

        /**
         * Since status updates aren't real time, assume another user is online if they have posted and:
         */
        payload.forEach(({post, setOnline}) => {
            const {user_id: creatorId} = post;

            if (creatorId === currentUserId) {
                return;
            }

            if (!dmUsers.includes(creatorId)) {
                return;
            }

            /**
             * 1. The user hasn't set their status manually to something that isn't online
             */
            if (getIsManualStatusForUserId(state, creatorId)) {
                return;
            }

            /**
             * 2. The server hasn't told the client not to set the user to online. This happens when:
             *  a. The post is from the auto responder
             *  b. The post is a response to a push notification
             */
            if (!setOnline) {
                return;
            }

            creatorsIdsSet.add(creatorId);
        });

        const creatorIds = Array.from(creatorsIdsSet);

        if (creatorIds.length) {
            dispatch({
                type: UserTypes.RECEIVED_STATUSES,
                data: creatorIds.map((id) => {
                    return {user_id: id, status: Status.ONLINE};
                }),
            });
        }
    },
);
