import {memo, useCallback} from 'react';
import {ListChildComponentProps, areEqual} from 'react-window';

import {CustomEmoji, Emoji, SystemEmoji} from 'mattermost-redux/types/emojis';

import {CategoryOrEmojiRow, EmojiCursor} from 'components/emoji_picker/types';
import {isCategoryHeaderRow} from 'components/emoji_picker/utils';
import EmojiPickerCategorySection from 'components/emoji_picker/components/emoji_picker_category_row';
import EmojiPickerItem from 'components/emoji_picker/components/emoji_picker_item';
import {sendToStatist} from 'packages/statist';
import {EMOJI_PER_ROW} from '../constants';

interface Props extends ListChildComponentProps<CategoryOrEmojiRow[]> {
    cursorRowIndex: number;
    cursorEmojiId: SystemEmoji['unified'] | CustomEmoji['id'];
    onEmojiClick: (emoji: Emoji) => void;
    onEmojiMouseOver: (cursor: EmojiCursor) => void;
}

function EmojiPickerCategoryOrEmojiRow({index, style, data, cursorRowIndex, cursorEmojiId, onEmojiClick, onEmojiMouseOver}: Props) {
    const row = data[index];
    const onClick = useCallback((emoji, rowIndex, colIndex) => {
        if (row?.items[0]?.categoryName === 'searchResults') {
            const resultNumber = (Math.max(rowIndex - 1, 0) * EMOJI_PER_ROW) + colIndex + 1;
            sendToStatist('searchEmoji.resultTap', {resultNumber});
        }

        onEmojiClick(emoji);
    }, [onEmojiClick, row?.items]);

    if (isCategoryHeaderRow(row)) {
        return (
            <EmojiPickerCategorySection
                categoryName={row.items[0].categoryName}
                style={style}
            />
        );
    }

    return (
        <div
            style={style}
            className='emoji-picker__row'
        >
            {row.items.map((emojiColumn, colIndex) => {
                const emoji = emojiColumn.item;
                const isSelected = emojiColumn.emojiId.toLowerCase() === cursorEmojiId.toLowerCase() && cursorRowIndex === index;

                return (
                    <EmojiPickerItem
                        key={`${emojiColumn.categoryName}-${emojiColumn.emojiId}`}
                        emoji={emoji}
                        rowIndex={row.index}
                        colIndex={colIndex}
                        isSelected={isSelected}
                        onClick={onClick}
                        onMouseOver={onEmojiMouseOver}
                    />
                );
            })}
        </div>
    );
}

export default memo(EmojiPickerCategoryOrEmojiRow, areEqual);
