import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Post} from '@mattermost/types/posts';
import {getDmUsers} from 'mattermost-redux/selectors/entities/getDmUsers';
import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';
import {getCurrentUserId, getUsers} from 'mattermost-redux/selectors/entities/common';
import {getUserByUsername} from 'mattermost-redux/selectors/entities/users';
import {getProfilesByUsernames, getStatusesByIds} from 'mattermost-redux/actions/users';
import {fetchUsersByIdsDebounced} from 'features/users/actions/fetch_users_by_ids';
import {parseUsernamesFromPosts, collectUserIdsFromPosts} from '../utils';

export const getUsersAndStatusesForPosts = createAsyncThunk(
    'posts/actions/getUsersAndStatusesForPosts',
    // eslint-disable-next-line consistent-return
    async (posts: Post[], thunkAPI) => {
        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        if (posts.length === 0) {
            return Promise.resolve();
        }

        const currentUserId = getCurrentUserId(state);
        const users = getUsers(state);
        const dmUsers = getDmUsers(state);

        // Profiles of users mentioned in the posts
        const usernamesFromPostsTextAndAttachments = parseUsernamesFromPosts(posts);

        const usernamesToLoad = usernamesFromPostsTextAndAttachments.filter(
            (username) => !getUserByUsername(state, username),
        );

        // Statuses and profiles of the users who made the posts
        const userIdsFromPosts = collectUserIdsFromPosts(posts);

        const usersToLoad = userIdsFromPosts.filter((userId) => {
            // Текущий пользователь у нас и так есть
            if (userId === currentUserId) {
                return false;
            }

            /**
             * @TODO: унести потом это в condition для экшена загрузки пользователя
             */
            // Этот пользователь уже загружен
            if (users[userId]) {
                return false;
            }

            return true;
        });

        const userIdsToLoadStatusesFor = userIdsFromPosts.filter((userId) => {
            // Текущий пользователь у нас и так есть
            if (userId === currentUserId) {
                return false;
            }

            /**
             * Частое обновление статуса важно только для пользователей
             * с которыми есть диалоги у текущего пользователя
             */
            if (dmUsers.includes(userId)) {
                return true;
            }

            return false;
        });

        const actions = [];

        if (usersToLoad.length) {
            actions.push(dispatch(fetchUsersByIdsDebounced(usersToLoad)));
        }

        if (userIdsToLoadStatusesFor.length) {
            actions.push(dispatch(getStatusesByIds(userIdsToLoadStatusesFor)));
        }

        if (usernamesToLoad.length) {
            actions.push(dispatch(getProfilesByUsernames(Array.from(usernamesToLoad))));
        }

        return Promise.all(actions);
    },
);
