import {createSelector} from 'reselect';

import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';

export const getCurrentUserNotificationSoundEnabled = createSelector(getCurrentUser, (user) => {
    if (user?.notify_props?.desktop_sound) {
        return user.notify_props.desktop_sound === 'true';
    }

    return false;
});
