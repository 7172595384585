import {Perf10t} from './Perf10t';
import {Marks} from './marks';
import {Measures} from './measures';

export const markChannelLinkClicked = (markOptions: Parameters<Perf10t['mark']>[1]) => {
    if (!window.perf10t) {
        return;
    }

    const isChannelMeasureInProgress = window.perf10t.getEntriesByName(Measures.OPEN_CHANNEL_LOADING_TIME).length > 0;
    if (!isChannelMeasureInProgress) {
        /**
         * Очищаем все предыдущие метки и измерения,
         * если мы еще не приступили к измерениям для
         * предыдущего канала (для случая, когда пользователь
         * быстро переключает каналы)
         */
        window.perf10t.clearMarks([
            Marks.CHANNEL_LINK_CLICKED,
            Marks.CHANNEL_LOADING_STARTED,
            Marks.CHANNEL_LOADING_FINISHED,
            Marks.CHANNEL_OPENED,
        ]);
        window.perf10t.clearMeasures([
            Measures.OPEN_CHANNEL_LOADING_TIME,
            Measures.OPEN_CHANNEL_REQUESTS_TIME,
        ]);
    }

    return window.perf10t.mark(
        Marks.CHANNEL_LINK_CLICKED,
        markOptions,
    );
};
