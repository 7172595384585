/* eslint-disable lines-around-comment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import bing from 'sounds/bing.mp3';
// @ts-ignore
import crackle from 'sounds/crackle.mp3';
// @ts-ignore
import down from 'sounds/down.mp3';
// @ts-ignore
import hello from 'sounds/hello.mp3';
// @ts-ignore
import ripple from 'sounds/ripple.mp3';
// @ts-ignore
import upstairs from 'sounds/upstairs.mp3';
/* eslint-enable @typescript-eslint/ban-ts-comment */
/* eslint-enable lines-around-comment */

export enum NotificationSound {
    BING = 'Bing',
    CRACKLE = 'Crackle',
    DOWN = 'Down',
    HELLO = 'Hello',
    RIPPLE = 'Ripple',
    UPSTAIRS = 'Upstairs',
}

export const DEFAULT_NOTIFICATION_SOUND = NotificationSound.BING;

export const NotificationSoundsMap = new Map([
    [NotificationSound.BING, bing],
    [NotificationSound.CRACKLE, crackle],
    [NotificationSound.DOWN, down],
    [NotificationSound.HELLO, hello],
    [NotificationSound.RIPPLE, ripple],
    [NotificationSound.UPSTAIRS, upstairs],
]);
