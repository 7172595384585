import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';
import type {User} from 'features/users';
import type {Team} from '@mattermost/types/teams';
import type {ThreadsStats} from '../types/threads';

type Payload = {

    /**
     * The ID of the user. This can also be "me" which will point to the current user.
     */
    userId: User['id'] | 'me';

    /**
     * The ID of the team in which the thread is.
     */
    teamId: Team['id'];

    signal?: AbortController['signal'];
};

/**
 *
 * @example "/users/{user_id}/teams/{team_id}/threads/stats"
 */
export const getThreadsStatsForUser = createAsyncThunk(
    'threads/api/getThreadsStatsForUser',
    async (payload: Payload, thunkAPI) => {
        const {teamId, userId, signal} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/threads/stats`;

        try {
            const {data} = await requester.get<ThreadsStats>(url, {
                ...options,
                signal,
            });

            return data;
        } catch (e) {
            const error = e as AxiosError;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
);
