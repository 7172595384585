import type {ParsedPreferenceChangedEvent, PreferenceChangedEvent} from '../types/preference_changed';

export function parsePreferenceChangedEvent(
    preferenceChangedEvent: PreferenceChangedEvent,
): ParsedPreferenceChangedEvent {
    return {
        ...preferenceChangedEvent,
        data: {
            ...preferenceChangedEvent.data,

            // это поле в сообщении приходит в виде JSON-строки
            preference: JSON.parse(preferenceChangedEvent.data.preference),
        },
    };
}
