import type {Post} from '@mattermost/types/posts';
import type {User} from 'features/users';

export function collectUserIdsFromPost(post: Post): string[] {
    const userIdsSet = new Set<User['id']>();

    const userId = post.user_id;

    userIdsSet.add(userId);

    if (!post.metadata) {
        return Array.from(userIdsSet);
    }

    if (!post.metadata.embeds) {
        return Array.from(userIdsSet);
    }

    post.metadata.embeds.forEach((embed) => {
        if (embed.type !== 'permalink') {
            return;
        }

        if (!embed.data) {
            return;
        }

        if (!embed.data.post) {
            return;
        }

        if (!embed.data.post.user_id) {
            return;
        }

        userIdsSet.add(embed.data.post.user_id);
    });

    return Array.from(userIdsSet);
}
