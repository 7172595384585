import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100vh;
    background-color: var(--center-channel-bg, white);
`;

const Loader = styled.span`
    color: var(--button-bg, #1c58d9);
    width: 60px;
    height: 60px;
    border: 6px solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader-03 linear infinite;
    position: relative;

    @keyframes loader-03 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const SplashScreen = React.memo(() => {
    return <Wrapper><Loader data-testid='splash_screen_loader'/></Wrapper>;
});
