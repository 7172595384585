import localForage from 'localforage';
import {extendPrototype} from 'localforage-observable';

import ZenObservable from 'zen-observable';

declare global {
    interface Window {
        Observable: typeof ZenObservable;
    }
}

if (typeof window !== 'undefined') {
    // We need to polyfill Observable
    window.Observable = window.Observable || ZenObservable;
}

export const draftsLocalForageAPI = extendPrototype(localForage).createInstance({
    name: 'TiMe',
    storeName: 'drafts',
    version: 1,
});
