import {createSelector} from 'reselect';

import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';
import {DEFAULT_NOTIFICATION_SOUND} from '../constants/notification_sound';

export const getCurrentUserNotificationSound = createSelector(getCurrentUser, (user) => {
    if (user?.notify_props?.desktop_notification_sound) {
        return user.notify_props.desktop_notification_sound;
    }

    return DEFAULT_NOTIFICATION_SOUND;
});
