import {reportErrorToSentry} from 'utils/sentry';

export async function saveItemToStorage(localForage: LocalForage, key: string, value: any) {
    await localForage.ready();

    try {
        await localForage.setItem(key, value);
    } catch (e: any) {
        reportErrorToSentry(e);
    }
}
