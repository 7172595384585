import capitalize from '@tinkoff/utils/string/capitalize';

export function getAvatarInitials(text: string, username: string) {
    if (!text) {
        return capitalize(username.slice(0, 2));
    }

    const [firstName = '', lastName = ''] = text.split(' ');

    if (!lastName) {
        return capitalize(firstName.slice(0, 2));
    }

    return (firstName?.[0]?.toUpperCase?.() || '') + lastName[0];
}
