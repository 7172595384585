import {createSelector} from '@reduxjs/toolkit';

import isEqual from '@tinkoff/utils/is/equal';

import {isNewProfileEnabled} from 'mattermost-redux/selectors/entities/preferences';

import {getUser} from 'mattermost-redux/selectors/entities/users';

import type {RootState} from 'stores/redux_store';

import type {Profile} from '../types/profile';

const getUserPosition = createSelector(getUser, (user) => user?.position);

export function getUserProfile(state: RootState, userId: Profile['user_id']) {
    return state.profiles[userId];
}

export const getProfileByUserId = createSelector(
    getUserProfile,
    getUserPosition,
    isNewProfileEnabled,
    (profile, position, isNewProfileEnabled) => {
        if (isNewProfileEnabled) {
            return profile;
        }

        // фоллбэк на позицию пользователя, чтобы показать в профилях
        return {
            position,
        } as Profile;
    },
    {
        memoizeOptions: {
            resultEqualityCheck: isEqual,
        },
    },
);
