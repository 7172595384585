import {useSelector} from 'react-redux';

import {useEffect} from 'react';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {setSentryUser} from 'utils/sentry';

export const SentryUserSetter = () => {
    const currentUserId = useSelector(getCurrentUserId);

    useEffect(() => {
        if (currentUserId) {
            setSentryUser({id: currentUserId});
        }
    }, [currentUserId]);

    return null;
};
