export function clearErrorScreenTimeout() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (window.__errorScreenTimeout__) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        clearTimeout(window.__errorScreenTimeout__);
    }

    deactivateErrorScreen();
}

export function deactivateErrorScreen() {
    const errorScreenNode = document.querySelector('.error-screen');

    if (errorScreenNode && errorScreenNode.classList.contains('error-screen-visible')) {
        errorScreenNode.classList.remove('error-screen-visible');
    }
}
