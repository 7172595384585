import {createAsyncThunk} from '@reduxjs/toolkit';

import {ChannelCategoryTypes} from 'mattermost-redux/action_types';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import type {ThunkConfig} from 'stores/redux_store';
import {getSidebarCategoriesForTeamForUser} from '../api/get_sidebar_categories_for_team_for_user';

import {getUsersForDMChannels} from './get_users_for_dm_channels';
import {getUsersForGMChannels} from './get_users_for_gm_channels';

import {receivedChannelCategories} from './received_channel_categories';

export const loadSidebarForTeam = createAsyncThunk<void, {teamId: string}, ThunkConfig>(
    'sidebar/actions/loadSidebarForTeam',
    async (payload, thunkApi) => {
        const state = thunkApi.getState();
        const dispatch = thunkApi.dispatch;

        const currentUserId = getCurrentUserId(state);
        const {teamId} = payload;

        const [result] = await Promise.all([
            dispatch(
                getSidebarCategoriesForTeamForUser({
                    teamId,
                    userId: currentUserId,
                }),
            ).unwrap(),
            dispatch(getUsersForDMChannels({teamId, noDebounce: true})).unwrap(),
            dispatch(getUsersForGMChannels({teamId})).unwrap(),
        ]);

        dispatch(receivedChannelCategories(result.categories));

        dispatch({
            type: ChannelCategoryTypes.RECEIVED_CATEGORY_ORDER,
            data: {
                teamId,
                order: result.order,
            },
        });
    },
);
