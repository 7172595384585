import {createAsyncThunk} from '@reduxjs/toolkit';

import type {AppDispatch} from 'stores/redux_store';

import type {PostedEvent} from '../types/posted_event';
import {parsePostedEvent} from '../utils/parse_posted_event';

import {handleNewPost} from './handle_new_post';

import {getUsersAndStatusesForPosts} from './get_users_and_statuses_for_post';
import {setOnlineStatusForNewPostCreators} from './set_online_status_for_new_post_creators';

export const handlePostedEvent = createAsyncThunk(
    'posts/actions/handlePostedEvent',
    async (payload: PostedEvent, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const parsedPostedEvent = parsePostedEvent(payload);
        const {data} = parsedPostedEvent;
        const {post, set_online: setOnline} = data;

        dispatch(setOnlineStatusForNewPostCreators([{post, setOnline}]));
        await dispatch(getUsersAndStatusesForPosts([post])).unwrap();

        await dispatch(handleNewPost({post, eventData: payload.data})).unwrap();
    },
);
