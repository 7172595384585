import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';
import type {User} from 'features/users';
import type {Team} from '@mattermost/types/teams';
import type {ServerThreads} from '../types/threads';

type Payload = {

    /**
     * The ID of the user. This can also be "me" which will point to the current user.
     */
    userId: User['id'] | 'me';

    /**
     * The ID of the team in which the thread is.
     */
    teamId: Team['id'];

    /**
     * Since filters the threads based on their LastUpdateAt timestamp.
     */
    since?: number;

    /**
     * Deleted will specify that even deleted threads should be returned (For mobile sync).
     *
     * @default false
     */
    deleted?: boolean;

    /**
     * Extended will enrich the response with participant details.
     *
     * @default false
     */
    extended?: boolean;

    /**
     * Page specifies which part of the results to return, by PageSize.
     *
     * @default 0
     */
    page?: number;

    /**
     * PageSize specifies the size of the returned chunk of results.
     *
     * @default 30
     */
    pageSize?: number;

    /**
     * TotalsOnly will not fetch any threads and just fetch the total counts
     *
     * @default false
     */
    totalsOnly?: boolean;

    /**
     * Before specifies thread id as a cursor for pagination and will return `PageSize` threads before the cursor
     */
    before?: string;

    /**
     * After specifies thread id as a cursor for pagination and will return `PageSize` threads after the cursor
     */
    after?: string;

    /**
     * Unread will make sure that only threads with unread replies are returned
     */
    unread?: boolean;

    /**
     * ThreadsOnly will fetch threads but not calculate totals and will return 0
     */
    threadsOnly?: boolean;

    /**
     * Setting this to true will not return total count threads
     */
    skipTotal?: boolean;

    signal?: AbortController['signal'];
};

/**
 * Get all threads that user is following
 *
 * Must be logged in as the user or have edit_other_users permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetUserThreads
 * @example "/users/{user_id}/teams/{team_id}/threads"
 */
export const getUserThreads = createAsyncThunk('threads/api/getUserThreads', async (payload: Payload, thunkAPI) => {
    const {
        teamId,
        userId,
        deleted = false,
        extended = false,
        page = 0,
        pageSize = 30,
        since,
        totalsOnly = false,
        after,
        before,
        skipTotal,
        threadsOnly,
        unread,
        signal,
    } = payload;
    const dispatch = thunkAPI.dispatch as AppDispatch;

    const requester = Client4.requester;

    const options = omit(['url'], Client4.getTimeApiClientOptions({}));

    const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/threads`;

    try {
        const {data} = await requester.get<ServerThreads>(url, {
            ...options,
            params: {
                deleted,
                since,
                page,
                per_page: pageSize,
                totalsOnly,
                extended,
                after,
                before,
                skipTotal,
                threadsOnly,
                unread,
            },
            signal,
        });

        return data;
    } catch (e) {
        const error = e as AxiosError;

        dispatch(forceLogoutIfNecessaryThunkAxios(error));
        dispatch(logAxiosError(error));

        throw error;
    }
});
