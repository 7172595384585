import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function LockLineIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)} {...props}>
            <svg
                style={{minWidth: size, width: size, height: size}}
                viewBox='0 0 16 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M11 6.85V5.835C11 4.197 9.845 3 8.2 3C6.562 3 5.4 4.197 5.4 5.835V6.85H5.288C4.546 6.85 4 7.396 4 8.138V11.512C4 12.254 4.546 12.8 5.288 12.8H11.112C11.854 12.8 12.4 12.254 12.4 11.512V8.138C12.4 7.396 11.854 6.85 11.112 6.85H11ZM6.45 5.835C6.45 4.771 7.143 4.05 8.2 4.05C9.264 4.05 9.95 4.771 9.95 5.835V6.85H6.45V5.835ZM11.35 11.533C11.35 11.652 11.252 11.75 11.133 11.75H5.267C5.148 11.75 5.05 11.652 5.05 11.533V8.117C5.05 7.998 5.148 7.9 5.267 7.9H11.133C11.252 7.9 11.35 7.998 11.35 8.117V11.533Z' />
            </svg>
        </span>
    );
}
