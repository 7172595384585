import {getCustomEmojisByName} from 'mattermost-redux/selectors/entities/emojis';
import {isCustomEmojiEnabled} from 'selectors/emojis';
import {EmojiIndicesByAlias} from 'utils/emoji';
import {getCustomEmojisByName as getCustomEmojisByNameAction} from 'mattermost-redux/actions/emojis';

export function loadCustomEmojisIfNeeded(emojis: string[]) {
    return (dispatch, getState) => {
        if (!emojis || emojis.length === 0) {
            return {data: false};
        }

        const state = getState();
        const customEmojiEnabled = isCustomEmojiEnabled(state);
        if (!customEmojiEnabled) {
            return {data: false};
        }

        const systemEmojis = EmojiIndicesByAlias;
        const customEmojisByName = getCustomEmojisByName(state);
        const nonExistentCustomEmoji = state.entities.emojis.nonExistentEmoji;
        const emojisToLoad: string[] = [];

        emojis.forEach((emoji) => {
            if (!emoji) {
                return;
            }

            if (systemEmojis.has(emoji)) {
                // It's a system emoji, no need to fetch
                return;
            }

            if (nonExistentCustomEmoji.has(emoji)) {
                // We've previously confirmed this is not a custom emoji
                return;
            }

            if (customEmojisByName.has(emoji)) {
                // We have the emoji, no need to fetch
                return;
            }

            emojisToLoad.push(emoji);
        });

        return dispatch(getCustomEmojisByNameAction(emojisToLoad));
    };
}
