import {hot} from 'react-hot-loader/root';
import {lazy, PureComponent} from 'react';
import {Provider} from 'react-redux';
import {Router, Route, Switch} from 'react-router-dom';

import {AxiosError, isAxiosError} from 'axios';

import {PopoverOverlayProvider} from '@time-webkit/all/molecules/popover';

import IntlProvider from 'components/intl_provider';

import {browserHistory} from 'utils/browser_history';
import store, {AppDispatch} from 'stores/redux_store';

import {makeAsyncComponent} from 'components/async_load';

import CRTPostsChannelResetWatcher from 'components/threading/channel_threads/posts_channel_reset_watcher';
import {ErrorBoundary} from 'components/error_boundary/error_boundary';
import {ErrorFallback} from 'components/error_boundary/error_fallback';

import {AppVisibilityWatcher} from 'features/app_activity/components/app_visibility_watcher';

import ErrorPage from 'components/error_page';

import {loadConfigAndMe} from 'actions/views/root';

import {redirectToOfflinePage} from 'utils/offline-ping';
import {isDesktopApp} from 'utils/user_agent';

import {initializePlugins} from 'plugins';
import {sendToStatist, type EventType} from '@time-webkit/statist';
import {ServerError} from '@mattermost/types/errors';

import {wasAppReloaded} from '@time-webkit/performance';

import {reportErrorToSentry} from 'utils/sentry';

import {SplashScreen} from './splash_screen';
import appStyle from './app.module.css';
import {HttpErrorProvider} from './http_error_context';
import {withResolve} from './with_resolve';
import 'plugins/export.js';
import {SentryInitializer} from './sentry_initializer';

import '@time-webkit/all/utils/global-css';

const LazyRoot = lazy(() => import(/* webpackChunkName: "components-root"*/ 'components/root'));
const LazyAuthsController = lazy(() => import(/* webpackChunkName: "features-auths"*/ 'features/auths'));
const LazySignup = lazy(() => import(/* webpackChunkName: "components-signup-signup_controller"*/ 'components/signup/signup_controller'));
const LazyInstallationSignUp = lazy(() => import(/* webpackChunkName: "components-installation_signup"*/ 'components/installation_signup'));

const Root = makeAsyncComponent('Root', LazyRoot, <SplashScreen />);
const AuthsController = makeAsyncComponent('AuthsController', LazyAuthsController);
const Signup = makeAsyncComponent('SignUp', LazySignup);
const InstallationSignUp = makeAsyncComponent('InstallationSignUp', LazyInstallationSignUp);

export const loadApplicationData = async (dispatch: AppDispatch) => {
    try {
        await dispatch(loadConfigAndMe());
    } catch (error) {
        if (isAxiosError(error)) {
            // С 500 показываем экран "Что-то пошло не так". Для этого пробрасываем ошибку до ErrorBoundary
            if (error.response && error.response.status >= 500) {
                throw error;
            }

            if (error.code === AxiosError.ERR_NETWORK) {
                return redirectToOfflinePage();
            }
        } else {
            const serverError = error as ServerError;

            // С 500 показываем экран "Что-то пошло не так". Для этого пробрасываем ошибку до ErrorBoundary
            if (serverError.status_code && serverError.status_code >= 500) {
                throw error;
            }

            if (serverError.status_code === AxiosError.ERR_NETWORK) {
                return redirectToOfflinePage();
            }
        }
    }

    await initializePlugins();
};

const DeferredApp = withResolve(loadApplicationData, SplashScreen);

enum DesktopEvents {
    RELOAD_PAGE = 'reload-page',
    CLEAN_CACHE_AND_RELOAD_PAGE = 'clean-cache-and-reload-page',
    CLEAN_ALL_DATA_AND_RELOAD_PAGE = 'clean-all-data-and-reload-page',
}

const statistEventsMap = new Map<DesktopEvents, EventType>([
    [DesktopEvents.RELOAD_PAGE, 'app.view.reload'],
    [DesktopEvents.CLEAN_CACHE_AND_RELOAD_PAGE, 'app.view.cashReload'],
    [DesktopEvents.CLEAN_ALL_DATA_AND_RELOAD_PAGE, 'app.view.dataReload'],
]);

type DesktopMessage = {
    origin: string;
    data?: {
        type?: DesktopEvents;
    };
}

class App extends PureComponent {
    componentDidMount() {
        // Для Desktop версии события генерируются в приложении Electron
        if (isDesktopApp()) {
            window.addEventListener('message', this.onDesktopMessageListener);
            return;
        }

        window.addEventListener('beforeunload', this.onBeforeUnload);

        if (wasAppReloaded()) {
            sendToStatist('app.webReload');
        }
    }

    handleError = (error: Error) => {
        reportErrorToSentry(error);
    }

    private onDesktopMessageListener = (desktopMessage: DesktopMessage) => {
        if (desktopMessage.origin !== window.location.origin || !desktopMessage?.data?.type) {
            return;
        }

        const statistEvent = statistEventsMap.get(desktopMessage.data.type);

        if (statistEvent) {
            // Если пользователь нажал перезагрузку с очисткой всех данных, то необходимо зафорсить
            // отправку сохраненных в local storage событий
            const forceSend = desktopMessage.data.type === DesktopEvents.CLEAN_ALL_DATA_AND_RELOAD_PAGE;
            sendToStatist(statistEvent, {}, forceSend);
        }
    }

    private onBeforeUnload = () => {
        sendToStatist('app.appClose');
    }

    componentWillUnmount() {
        // Для Desktop версии события генерируются в приложении Electron
        if (isDesktopApp()) {
            window.removeEventListener('message', this.onDesktopMessageListener);
            return;
        }

        window.removeEventListener('beforeunload', this.onBeforeUnload);
    }

    render() {
        return (
            <ErrorBoundary fallback={<ErrorFallback />} onError={this.handleError}>
                <HttpErrorProvider>
                    <PopoverOverlayProvider className={appStyle.fullscreenWrapper}>
                        <Provider store={store}>
                            <DeferredApp>
                                <IntlProvider>
                                    <SentryInitializer />
                                    <AppVisibilityWatcher />
                                    <CRTPostsChannelResetWatcher />
                                    <Router history={browserHistory}>
                                        <Switch>
                                            <Route
                                                path={'/error'}
                                                component={ErrorPage}
                                            />
                                            <Route
                                                path={'/auths'}
                                                component={AuthsController}
                                            />
                                            <Route
                                                path={'/signup_user_complete'}
                                                component={Signup}
                                            />
                                            <Route
                                                path={'/installation_signup'}
                                                component={InstallationSignUp}
                                            />
                                            <Route
                                                path='/'
                                                component={Root}
                                            />
                                        </Switch>
                                    </Router>
                                </IntlProvider>
                            </DeferredApp>
                        </Provider>
                    </PopoverOverlayProvider>
                </HttpErrorProvider>
            </ErrorBoundary>
        );
    }
}

export default hot(App);
