import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';

import type {AppDispatch} from 'stores/redux_store';
import type {GlobalState} from 'types/store';
import {changeThreadFollow} from '../actions/change_thread_follow';
import {fetchUserThread} from '../actions/fetch_user_thread';
import {getHasUnreadThreadsByTeamId} from '../actions/get_has_unread_threads_by_team_id';
import {getSelectedThreadId} from '../selectors/get_selected_thread_id';

import {selectThreadById} from '../selectors/select_thread_by_id';
import type {ThreadFollowChangedEvent} from '../types/thread_follow_changed_event';

export const handleThreadFollowChanged = createAsyncThunk(
    'threads/wsEventsHandlers/handleThreadFollowChanged',
    async (msg: ThreadFollowChangedEvent, thunkAPI) => {
        const {data} = msg;

        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const thread = selectThreadById(state, data.thread_id);

        if (!thread && data.state && data.reply_count > 0) {
            const currentUserId = getCurrentUserId(state);
            const currentTeamId = getCurrentTeamId(state);

            await dispatch(
                fetchUserThread({
                    teamId: currentTeamId,
                    threadId: data.thread_id,
                    userId: currentUserId,
                    extended: true,
                }),
            ).unwrap();
        }

        const currentTeamId = getCurrentTeamId(state);

        if (thread && (thread?.unread_mentions > 0 || thread?.unread_replies > 0)) {
            dispatch(getHasUnreadThreadsByTeamId({teamId: thread?.team_id || currentTeamId}));
        }

        const updatedState = thunkAPI.getState() as GlobalState;

        const selectedThreadId = getSelectedThreadId(updatedState);

        return dispatch(
            changeThreadFollow({
                following: data.state,
                threadId: data.thread_id,
                selectedThreadId,
            }),
        );
    },
);
