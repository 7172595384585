import classNames from 'classnames';

import {ForwardedRef, HTMLAttributes, KeyboardEvent, ReactNode, forwardRef, memo, useEffect} from 'react';

import {useFocusWithin} from '@react-aria/interactions';

import {mergeProps, useId} from '@react-aria/utils';

import {useTooltipTriggerState} from '@react-stately/tooltip';

import {useTooltipTrigger} from '@react-aria/tooltip';

import {TypographySize, useTypography} from '../../hooks/typography';

import {CloseCircleIcon} from '../../icons/close-circle';

import {useForwardedRef} from '../../hooks/use-forwarded-ref';

import {Spinner} from '../../atoms/spinner';
import {Tooltip} from '../../atoms/tooltip';

import styles from './tag.module.css';

type TagSize = 'medium' | 'large';

type TagVariant = 'default' | 'error' | 'warning' | 'success'

type Props = HTMLAttributes<HTMLSpanElement> & {
    label: string;
    size?: TagSize;
    variant?: TagVariant;
    icon?: ReactNode;
    loading?: boolean;
    selected?: boolean;
    disabled?: boolean;
    tooltip?: string;
    onDelete?: (value: string) => void;
    onUnselect?: (value: string) => void;
    children?: ReactNode;
    someProps?: number;
};

const TagInternal =
    forwardRef(({
        label,
        icon: givenIcon,
        loading,
        variant = 'default',
        size = 'medium',
        className,
        selected,
        children,
        disabled,
        onDelete,
        onUnselect,
        tooltip,
        onKeyDown: givenKeyDown,
        ...props
    }: Props, forwarededRef: ForwardedRef<HTMLSpanElement>) => {
        const ref = useForwardedRef<HTMLSpanElement>(forwarededRef);
        const tooltipTriggerState = useTooltipTriggerState({delay: 200});
        const {triggerProps, tooltipProps} = useTooltipTrigger({isDisabled: !tooltip}, tooltipTriggerState, ref);
        const labelId = useId();
        const canBeDeleted = Boolean(onDelete);
        const {focusWithinProps} = useFocusWithin({
            onBlurWithin: () => {
                onUnselect?.(label);
            },
        });

        const iconCloseSize = size === 'large' ? 16 : 12;

        let typographySize: TypographySize;
        switch (size) {
        case 'medium':
            typographySize = TypographySize.BodyM;
            break;
        case 'large':
            typographySize = TypographySize.BodyL;
            break;
        }

        let icon: ReactNode = null;
        if (loading) {
            icon = <Spinner size={16} aria-labelledby={labelId} />;
        } else if (givenIcon) {
            icon = givenIcon;
        }

        const typographyStyle = useTypography({size: typographySize});

        const handleKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Backspace' && canBeDeleted) {
                onDelete?.(label);
                return;
            }
            givenKeyDown?.(e);
        };

        useEffect(() => {
            if (selected) {
                ref.current?.focus();
            }
        }, [selected, ref]);

        return (
            <>
                <span
                    {...mergeProps(props, focusWithinProps, tooltip ? triggerProps : {})}
                    aria-busy={loading}
                    tabIndex={-1}
                    ref={ref}
                    onKeyDown={handleKeyDown}
                    className={classNames(styles.root, typographyStyle, className, {
                        [styles.medium]: size === 'medium',
                        [styles.large]: size === 'large',
                        [styles.error]: variant === 'error',
                        [styles.warning]: variant === 'warning',
                        [styles.success]: variant === 'success',
                        [styles.selected]: selected,
                        [styles.disabled]: disabled || loading,
                    })}
                >
                    {icon && <span className={styles.iconLeft}>{icon}</span>}
                    {children && <span className={classNames(styles.content, styles.contentPadding)}>{children}</span>}
                    {!children && label && <span id={labelId} className={classNames(styles.label, styles.contentPadding)}>{label}</span>}

                    {canBeDeleted && (
                        <button
                            tabIndex={-1}
                            type='button'
                            aria-label='delete'
                            onClick={() => onDelete?.(label)}
                            className={styles.buttonDelete}
                        >
                            {
                                <CloseCircleIcon
                                    width={iconCloseSize}
                                    height={iconCloseSize}
                                />
                            }
                        </button>
                    )}
                </span>
                {tooltipTriggerState.isOpen && <Tooltip {...tooltipProps} state={tooltipTriggerState} targetRef={ref}>{tooltip}</Tooltip>}
            </>
        );
    },
    );

const Tag = memo(TagInternal);
Tag.displayName = 'Tag';

export {Tag};
