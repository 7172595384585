import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import {getChannelAdmins} from '../api/get_channel_admins';
import type {AppDispatch} from 'stores/redux_store';
import {getChannelMembers} from '../api/get_channel_members';
import {receivedUsers} from 'features/users';
import {receivedPartOfUsersInChannel} from 'features/sidebar';
import {getChannelMembersByIds} from '../api/get_channel_members_by_ids';
import {ChannelTypes} from 'mattermost-redux/action_types';

type Payload = {
    page: number;
    perPage: number;
    channelId: Channel['id'];
};

const SHORT_TERM_CACHE = 5 * 60 * 1000;

export const getChannelMembersForChannelMembersList = createAsyncThunk(
    'channels/actions/getChannelMembersForChannelMembersList',
    async (payload: Payload, thunkAPI) => {
        const {channelId, page, perPage} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const channelAdmins = await dispatch(
            getChannelAdmins({
                page: 0,

                /**
                 * Hope that 60 is enough to get _all_ admins
                 */
                perPage: 60,
                channelId,
                withCache: {
                    ttl: SHORT_TERM_CACHE,
                },
            }),
        ).unwrap();

        if (thunkAPI.signal.aborted) {
            return thunkAPI.rejectWithValue('aborted');
        }

        const channelMembers = await dispatch(
            getChannelMembers({
                page,
                perPage,
                channelId,
                withCache: {
                    ttl: SHORT_TERM_CACHE,
                },
            }),
        ).unwrap();

        const concatenatedChannelMembers = channelAdmins.concat(channelMembers);

        await dispatch(receivedUsers(concatenatedChannelMembers));
        dispatch(receivedPartOfUsersInChannel([channelId, concatenatedChannelMembers]));

        if (thunkAPI.signal.aborted) {
            return thunkAPI.rejectWithValue('aborted');
        }

        /**
         * В канале может не быть админов вовсе, поэтому проверяем их наличие
         */
        if (channelAdmins.length) {
            const adminChannelMembers = await dispatch(
                getChannelMembersByIds({
                    userIds: channelAdmins.map(({id}) => id),
                    channelId,
                    withCache: {
                        ttl: SHORT_TERM_CACHE,
                    },
                }),
            ).unwrap();

            dispatch({
                type: ChannelTypes.RECEIVED_CHANNEL_MEMBERS,
                data: adminChannelMembers,
            });

            if (thunkAPI.signal.aborted) {
                return thunkAPI.rejectWithValue('aborted');
            }
        }

        if (channelMembers.length) {
            const nonAdminChannelMembers = await dispatch(
                getChannelMembersByIds({
                    userIds: channelMembers.map(({id}) => id),
                    channelId,
                    withCache: {
                        ttl: SHORT_TERM_CACHE,
                    },
                }),
            ).unwrap();

            dispatch({
                type: ChannelTypes.RECEIVED_CHANNEL_MEMBERS,
                data: nonAdminChannelMembers,
            });

            if (thunkAPI.signal.aborted) {
                return thunkAPI.rejectWithValue('aborted');
            }
        }

        return {
            admins: channelAdmins,
            members: channelMembers,
        };
    },
);
