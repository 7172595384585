import {HTMLAttributes, ReactNode, memo} from 'react';

import classNames from 'classnames';

import {TypographySize, useTypography} from '../../hooks/typography';

import styles from './dropdown-list-item.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    label: string | ReactNode;
    secondaryLabel?: string;
    icon?: ReactNode;
    iconRight?: ReactNode;
    textRight?: string;
    focused?: boolean;
};

export const DropdownListItem = memo(({label, secondaryLabel, icon, iconRight, className, textRight, focused, ...props}: Props) => {
    const [labelTypography, secondaryLabelTypography, textRightTypography] = useTypography([
        {size: TypographySize.BodyL, noDefaultColor: true},
        {size: TypographySize.BodyM, noDefaultColor: true},
        {size: TypographySize.BodyS, noDefaultColor: true},
    ]);

    const hasIcon = Boolean(icon);

    return (
        <div
            {...props} className={classNames(styles.root, className, {[styles.focused]: focused})}
        >
            {icon && <div className={styles.icon}>{icon}</div>}
            <div className={classNames(styles.label, {[styles.withIcon]: hasIcon})}>
                {typeof label === 'string' ? <div className={classNames(styles.labelText, labelTypography)}>{label}</div> : label}
            </div>
            {secondaryLabel && (
                <div
                    className={classNames(styles.secondaryLabel, secondaryLabelTypography, {
                        [styles.withIcon]: hasIcon,
                    })}
                >
                    {secondaryLabel}
                </div>
            )}
            {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
            {textRight && <div className={classNames(styles.textRight, textRightTypography)}>{textRight}</div>}
        </div>
    );
});

DropdownListItem.displayName = 'DropdownListItem';
