import {createSelector} from 'reselect';

import {selectManuallyReadThreadIds} from './select_manually_read_thread_ids';
import {selectThreadById} from './select_thread_by_id';

export const isThreadManuallyReadByThreadId = createSelector(
    selectManuallyReadThreadIds,
    selectThreadById,
    (manuallyReadThreadIds, thread) => (thread?.id ? manuallyReadThreadIds.includes(thread.id) : false),
);
