import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Post} from '@mattermost/types/posts';
import type {Team} from '@mattermost/types/teams';
import type {User} from 'features/users';

import {setUnreadThreadByPostId} from '../api/set_unread_thread_by_post_id';
import type {AppDispatch} from 'stores/redux_store';
import type {ClientThread} from '../types/extended';

type Payload = {
    userId: User['id'];
    teamId: Team['id'];
    threadId: ClientThread['id'];
    postId: Post['id'];
};

export const markThreadAsUnreadOnServer = createAsyncThunk(
    'threads/actions/markThreadAsUnreadOnServer',
    async (payload: Payload, thunkAPI) => {
        const {postId, teamId, threadId, userId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const result = await dispatch(
            setUnreadThreadByPostId({
                postId,
                teamId,
                threadId,
                userId,
            }),
        ).unwrap();

        return result;
    },
);
