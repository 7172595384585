import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import type {AppDispatch} from 'stores/redux_store';
import type {GlobalState} from 'types/store';
import {getUserThreads} from '../api/get_user_threads';

import type {ServerThreads} from '../types/threads';

import {handleReceivedThreads} from './handle_received_threads';

const PAGE_SIZE = 10;

type FulfilledMeta = {teamId: string; pageSize: number};

type Payload = {
    threadId: string;
};

export const getAllUserThreadsBeforeThread = createAsyncThunk<ServerThreads, Payload, {fulfilledMeta: FulfilledMeta}>(
    'threads/actions/getAllUserThreadsBeforeThread',
    async (payload, thunkAPI) => {
        const {threadId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as GlobalState;

        const currentUserId = getCurrentUserId(state);
        const currentTeamId = getCurrentTeamId(state);

        const result = await dispatch(
            getUserThreads({
                teamId: currentTeamId,
                userId: currentUserId,
                page: 0,
                pageSize: PAGE_SIZE,
                before: threadId,
                extended: true,
                skipTotal: true,
            }),
        ).unwrap();

        dispatch(handleReceivedThreads({threads: result.threads, shouldGetMissingProfiles: false}));

        return thunkAPI.fulfillWithValue(result, {teamId: currentTeamId, pageSize: PAGE_SIZE});
    },
);
