import {useCallback, useState} from 'react';
import {flow} from 'lodash';

import uniqueId from '@tinkoff/utils/uniqueId';
import uniq from '@tinkoff/utils/array/uniq';
import identity from '@tinkoff/utils/function/identity';

type Props = {
    tags: string[];
    separators: string[];
    formatLabel?: (label: string) => string;
    onChange?: (tags: string[]) => void;
};

export const useInputTagState = ({tags, separators, formatLabel = identity, onChange}: Props) => {
    const [selectedTag, setSelectedTag] = useState<string | undefined>();
    const [editableTag, setEditableTag] = useState<string | undefined>();
    const [newTagKey, setNewTagKey] = useState<string | undefined>();

    const commitKeys = [...separators, 'Enter', 'Escape'];

    const addTag = useCallback(
        (label: string) => {
            if (getTagCanBeAdded(label, tags)) {
                onChange?.([...tags, label]);
                setNewTagKey(uniqueId().toString());
            }
        },
        [onChange, tags],
    );

    const wrappedAddTag = useCallback((label: string) => flow(formatLabel, addTag)(label), [formatLabel, addTag]);

    const updateTag = useCallback(
        (label: string) => (nextLabel: string) => {
            if (label !== nextLabel) {
                let nextTags: string[] = [];
                if (getTagCanBeAdded(nextLabel, tags)) {
                    nextTags = tags.map((tag) => (tag === label ? nextLabel : tag));
                } else {
                    nextTags = tags.filter((tag) => tag !== label);
                }
                onChange?.(nextTags);
            }
            setSelectedTag(undefined);
            setEditableTag(undefined);
        },
        [onChange, tags],
    );

    const wrappedUpdateTag = useCallback((label: string, nextLabel: string) => flow(formatLabel, updateTag(label))(nextLabel), [formatLabel, updateTag]);

    const removeTag = useCallback(
        (label: string) => {
            const nextTags = tags.filter((tag) => tag !== label);
            onChange?.(nextTags);
            if (selectedTag === label) {
                setSelectedTag(undefined);
            }
        },
        [onChange, selectedTag, tags],
    );

    const wrappedRemoveTag = useCallback((label: string) => flow(formatLabel, removeTag)(label), [formatLabel, removeTag]);

    const unselectTag = useCallback(() => {
        setSelectedTag(undefined);
    }, []);

    const addTagsFromString = useCallback(
        (input: string) => {
            const nextTags = uniq(
                input.
                    split(new RegExp(`[${separators.join('')}]`)).
                    map(flow((tag) => tag.trim(), formatLabel)).
                    filter((tag) => tag !== '' && !tags.includes(tag)),
            );

            setNewTagKey(uniqueId().toString());

            onChange?.(tags.concat(nextTags));
        },
        [formatLabel, onChange, separators, tags],
    );

    return {
        selectedTag,
        editableTag,
        newTagKey,
        separators,
        commitKeys,
        addTag: wrappedAddTag,
        updateTag: wrappedUpdateTag,
        editTag: setEditableTag,
        selectTag: setSelectedTag,
        unselectTag,
        removeTag: wrappedRemoveTag,
        addTagsFromString,
    };
};

function getTagCanBeAdded(label: string, tags: string[]): boolean {
    const tag = label.trim();
    if (tag === '') {
        return false;
    }
    if (tags.includes(tag)) {
        return false;
    }
    return true;
}
