import {createContext} from 'react';

import noop from '@tinkoff/utils/function/noop';

import {InviteMembersState} from './use_invite_members_state';

const defaultState: InviteMembersState = {
    sendInvites: () => Promise.resolve(),
    reset: noop,
    result: [],
    profiles: [],
    requestError: null,
    success: false,
    errors: [],
    criticalErrors: [],
    warnings: [],
};

export const inviteMembersContext = createContext<InviteMembersState>(defaultState);

export const InviteMembersStateProvider = inviteMembersContext.Provider;
