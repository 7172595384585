import type {ParsedSidebarCategoryUpdatedEvent, SidebarCategoryUpdatedEvent} from '../types/sidebar_category_updated';

export function parseSidebarCategoryUpdatedEvent(
    sidebarCategoryUpdated: SidebarCategoryUpdatedEvent,
): ParsedSidebarCategoryUpdatedEvent {
    try {
        return {
            ...sidebarCategoryUpdated,
            data: {
                ...sidebarCategoryUpdated.data,

                // это поле в сообщении приходит в виде JSON-строки
                updatedCategories: sidebarCategoryUpdated.data.updatedCategories ? JSON.parse(sidebarCategoryUpdated.data.updatedCategories) : undefined,
            },
        };
    } catch {
        return {
            ...sidebarCategoryUpdated,
            data: {
                ...sidebarCategoryUpdated.data,
                updatedCategories: [],
            },
        };
    }
}
