import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function Email3Icon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)}{...props}>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 18 14'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M17.3332 2.00016C17.3332 1.0835 16.5832 0.333496 15.6665 0.333496H2.33317C1.4165 0.333496 0.666504 1.0835 0.666504 2.00016V12.0002C0.666504 12.9168 1.4165 13.6668 2.33317 13.6668H15.6665C16.5832 13.6668 17.3332 12.9168 17.3332 12.0002V2.00016ZM15.6665 2.00016L8.99984 6.16683L2.33317 2.00016H15.6665ZM15.6665 12.0002H2.33317V3.66683L8.99984 7.8335L15.6665 3.66683V12.0002Z' />
            </svg>
        </span>
    );
}
