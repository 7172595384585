import {ComponentPropsWithoutRef, HTMLAttributes, ReactNode} from 'react';

import classNames from 'classnames';

import {useLinkTypography} from './use-link-typography';

import styles from './link.module.css';

export type LinkSize = 'medium' | 'large';
export type LinkVariant = 'default' | 'clear' | 'flat';

type Props<Component extends keyof JSX.IntrinsicElements> = ComponentPropsWithoutRef<Component> &
HTMLAttributes<HTMLOrSVGElement> & {
    size?: LinkSize;
    variant?: LinkVariant;
    as?: Component;
    pseudo?: boolean;
    clear?: boolean;
    icon?: ReactNode;
    iconRight?: ReactNode;
};

const DEFAULT_TAG = 'a';

export const Link = <Component extends keyof JSX.IntrinsicElements = typeof DEFAULT_TAG>({
    size = 'large',
    variant = 'default',
    as: element,
    pseudo,
    icon,
    iconRight,
    children,
    ...props
}: Props<Component>) => {
    const typographyStyle = useLinkTypography(size, variant);

    const LinkComponent: keyof JSX.IntrinsicElements = element ?? DEFAULT_TAG;

    return (
        <LinkComponent
            {...props}
            className={classNames(styles.root, styles[variant], typographyStyle, {
                [styles.pseudo]: pseudo,
            })}
        >
            {icon && <span className={styles.icon}>{icon}</span>}
            <span className={styles.text}>
                {children}
            </span>
            {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
        </LinkComponent>
    );
};
