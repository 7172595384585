import type {EventParameters, EventType, EventTypeWithParams} from './methods';

import {sendToStatist} from './methods';
import {detectEngSymbols} from './utils/detect_eng_symbols';
import {detectRusSymbols} from './utils/detect_rus_symbols';

const TIMEOUT_THRESHOLD_MILLISECONDS = 2000;

export class SearchTimeMeasurer {
    private timeTable = new Map<string, number>();
    readonly eventType: EventType | EventTypeWithParams;
    private lastMeasure: {
        timestamp: number;
        params: EventParameters;
    } | null = null;
    private sendLastMeasureTimeout: NodeJS.Timeout | null = null;

    constructor(eventType: EventType | EventTypeWithParams) {
        this.eventType = eventType;
    }

    fromDate(text: string, date: number) {
        this.timeTable.set(text, date);
    }

    measure(text: string, resultsCount?: number, params: Partial<EventParameters> = {}, shouldRemoveText = true) {
        if (resultsCount === undefined) {
            this.timeTable.set(text, Date.now());
            return;
        }

        const startedAt = this.timeTable.get(text);
        if (!startedAt) {
            return;
        }

        this.sendLastMeasure();
        const searchTime = Date.now() - startedAt;
        const resultExist = resultsCount > 0;
        const inputSymbols = text.length;
        const engSymbols = detectEngSymbols(text);
        const rusSymbols = detectRusSymbols(text);

        this.lastMeasure = {
            timestamp: Date.now(),
            params: {
                searchTime,
                timeout: false,
                resultExist,

                // @TODO: обновить типы в SDK
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore-next-line
                inputSymbols,
                engSymbols,
                rusSymbols,
                ...params,
            },
        };
        if (shouldRemoveText) {
            // Локальный поиск не должен удалять текст, чтобы ответ от сервера был обработан
            this.timeTable.delete(text);
        }
        this.sendLastMeasureTimeout = setTimeout(() => this.sendLastMeasure(), TIMEOUT_THRESHOLD_MILLISECONDS);
    }

    private sendLastMeasure(isForcedByDispose = false) {
        if (this.sendLastMeasureTimeout) {
            clearTimeout(this.sendLastMeasureTimeout);
        }

        if (!this.lastMeasure || !this.lastMeasure.params) {
            return;
        }

        if (!isForcedByDispose && 'timeout' in this.lastMeasure.params) {
            this.lastMeasure.params.timeout = Date.now() - this.lastMeasure.timestamp < TIMEOUT_THRESHOLD_MILLISECONDS;
        }

        sendToStatist(this.eventType, this.lastMeasure.params);
        this.lastMeasure = null;
    }

    dispose() {
        this.timeTable.clear();
        this.sendLastMeasure(true);
    }
}
