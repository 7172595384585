import React, {CSSProperties} from 'react';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';

import {isMobile} from 'utils/utils';

import SubMenuItem from './menu_items/submenu_item';

import MenuHeader from './menu_header';
import MenuGroup from './menu_group';
import MenuItemAction from './menu_items/menu_item_action';
import MenuItemExternalLink from './menu_items/menu_item_external_link';
import MenuItemLink from './menu_items/menu_item_link';
import MenuItemToggleModalRedux from './menu_items/menu_item_toggle_modal_redux';
import {MenuContext, MenuContextType} from './menu_context';

import './menu.scss';

type Props = {
    children?: React.ReactNode;
    openLeft?: boolean;
    openUp?: boolean;
    alwaysOpenUp?: boolean;
    id?: string;
    ariaLabel: string;
    customStyles?: CSSProperties;
    className?: string;
    listId?: string;
    menuClassName?: string;
}

type State = Pick<MenuContextType, 'openedSubMenuItem'>;

export default class Menu extends React.PureComponent<Props, State> {
    public static Header = MenuHeader
    public static Group = MenuGroup
    public static ItemAction = MenuItemAction
    public static ItemExternalLink = MenuItemExternalLink
    public static ItemLink = MenuItemLink
    public static ItemToggleModalRedux = MenuItemToggleModalRedux
    public static ItemSubMenu = SubMenuItem
    public node: React.RefObject<HTMLUListElement>; //Public because it is used by tests
    private observer: MutationObserver;

    public constructor(props: Props) {
        super(props);

        this.state = {
            openedSubMenuItem: null,
        };

        this.node = React.createRef();
        this.observer = new MutationObserver(this.hideUnneededDividers);
    }

    public hideUnneededDividers = () => { //Public because it is used by tests
        if (this.node.current === null) {
            return;
        }

        this.observer.disconnect();
        const children = Object.values(this.node.current.children).slice(0) as HTMLElement[];

        let isAtBeginning = true;
        let prevDividerChild = null;
        for (const child of children) {
            if (child.classList.contains('menu-divider') || child.classList.contains('mobile-menu-divider')) {
                child.style.display = 'none';
                if (!isAtBeginning && !prevDividerChild) {
                    prevDividerChild = child;
                }
            } else {
                if (prevDividerChild) {
                    prevDividerChild.style.display = 'block';
                    prevDividerChild = null;
                }

                if (isAtBeginning) {
                    isAtBeginning = false;
                }
            }
        }

        this.observer.observe(this.node.current, {attributes: true, childList: true, subtree: true});
    }

    public componentDidMount() {
        this.hideUnneededDividers();
    }

    public componentDidUpdate() {
        this.hideUnneededDividers();
    }

    public componentWillUnmount() {
        this.observer.disconnect();
    }

    // Used from DotMenu component to know in which direction show the menu
    public rect() {
        if (this.node && this.node.current) {
            return this.node.current.getBoundingClientRect();
        }
        return null;
    }

    private getContextValue = memoizeOne((openedSubMenuItem: symbol | null): MenuContextType => {
        return {
            openedSubMenuItem,
            onOpenSubMenuItem: (item) => this.setState({openedSubMenuItem: item}),
            onCloseSubMenuItem: () => this.setState({openedSubMenuItem: null}),
        };
    })

    /**
     * @description По умолчанию всплытие используется для закрытия меню, но
     * если пользователь нажмет не по элементу меню, а по самому меню,
     * то нам нужно прервать всплытие, чтобы меню не исчезало. MM legacy :rage:
     * @param event
     */
    handleMenuClick = (event: React.MouseEvent) => {
        if (event.target === this.node.current) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    public render() {
        const {children, openUp, alwaysOpenUp, openLeft, id, listId, ariaLabel, customStyles, menuClassName} = this.props;
        let styles: CSSProperties = {
            position: 'relative',
        };
        if (customStyles) {
            styles = customStyles;
        } else {
            if (openLeft) {
                styles.left = 'inherit';
                styles.right = 0;
            }
            if (alwaysOpenUp || (openUp && !isMobile())) {
                styles.bottom = '100%';
                styles.top = 'auto';
            }
        }

        return (
            <div
                aria-label={ariaLabel}
                className={classNames('a11y__popup Menu', menuClassName)}
                id={id}
                role='menu'
            >
                <ul
                    id={listId}
                    ref={this.node}
                    style={styles}
                    className={classNames('Menu__content dropdown-menu', this.props.className)}
                    onClick={this.handleMenuClick}
                >
                    <MenuContext.Provider value={this.getContextValue(this.state.openedSubMenuItem)}>
                        {children}
                    </MenuContext.Provider>
                </ul>
            </div>
        );
    }
}
