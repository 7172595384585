import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';
import type {User} from 'features/users';
import type {Team} from '@mattermost/types/teams';
import type {ServerThread} from '../types/threads';

type Payload = {

    /**
     * The ID of the user. This can also be "me" which will point to the current user.
     */
    userId: User['id'] | 'me';

    /**
     * The ID of the team in which the thread is.
     */
    teamId: Team['id'];

    threadId: ServerThread['id'];

    timestamp: number;
};

/**
 * Mark a thread that user is following read state to the timestamp
 *
 * Mark a thread that user is following as read
 *
 * Must be logged in as the user or have `edit_other_users` permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/UpdateThreadReadForUser
 * @example "/users/{user_id}/teams/{team_id}/threads/{thread_id}/read/{timestamp}"
 */
export const updateThreadReadForUser = createAsyncThunk(
    'threads/api/updateThreadReadForUser',
    async (payload: Payload, thunkAPI) => {
        const {teamId, userId, threadId, timestamp} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/threads/${threadId}/read/${timestamp}`;

        try {
            const {data} = await requester.put<ServerThread>(url, null, {
                ...options,
            });

            return data;
        } catch (e) {
            const error = e as AxiosError;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
);
