import {AxiosError, AxiosInstance, isAxiosError} from 'axios';

import {ApiV5Error} from './api_v5_error';

const isApiV5ErrorResponse = (error: unknown): error is AxiosError => {
    return (
        isAxiosError(error) &&
        Boolean(error.config?.url?.includes('/api/v5'))
    );
};

export function grpcErrorInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (isApiV5ErrorResponse(error)) {
                const apiV5Error = ApiV5Error.fromGrpcError(error.response?.data);
                if (apiV5Error) {
                    return Promise.reject(apiV5Error);
                }
            }
            return Promise.reject(error);
        },
    );
}
