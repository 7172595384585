import {createAsyncThunk} from '@reduxjs/toolkit';

import type {ChannelCategory} from '@mattermost/types/channel_categories';
import type {AppDispatch} from 'stores/redux_store';
import {getSidebarCategoryForTeamForUser} from '../api/get_sidebar_category_for_team_for_user';
import type {Team} from '@mattermost/types/teams';
import type {GlobalState} from 'types/store';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import {getCategoriesForTeam} from 'mattermost-redux/selectors/entities/channel_categories';
import {isFavoritesChannelCategory} from '../utils/isFavoritesChannelCategory';

import {receivedChannelCategory} from './received_channel_category';

type ThunkConfig = {
    dispatch: AppDispatch;
    getState: () => GlobalState;
    fulfilledMeta: {favoritesCategoryId?: ChannelCategory['id']};
};

type Payload = {
    categoryId: ChannelCategory['id'];
    teamId: Team['id'];
};

export const fetchAndAddCreatedCategoryToSidebar = createAsyncThunk<ChannelCategory, Payload, ThunkConfig>(
    'sidebar/actions/fetchAndAddCreatedCategoryToSidebar',
    async (payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as GlobalState;
        const {categoryId, teamId} = payload;
        const teamCategories = getCategoriesForTeam(state, teamId);
        const favoritesCategory = teamCategories.find(isFavoritesChannelCategory);

        const currentUserId = getCurrentUserId(state);

        const newCategory = await dispatch(
            getSidebarCategoryForTeamForUser({
                categoryId,
                teamId,
                userId: currentUserId,
            }),
        ).unwrap();

        await dispatch(receivedChannelCategory(newCategory));

        return thunkAPI.fulfillWithValue(newCategory, {
            favoritesCategoryId: favoritesCategory?.id,
        });
    },
);
