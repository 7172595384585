import {ComponentProps} from 'react';

import {useInviteMembersState} from './use_invite_members_state';
import {InviteMembersStateProvider} from './invite_members_context';
import {InviteMembersModal} from './invite_members_modal';

export const InviteMembersModalConnected = (props: ComponentProps<typeof InviteMembersModal>) => {
    const store = useInviteMembersState();

    return (
        <InviteMembersStateProvider value={store}>
            <InviteMembersModal {...props} />
        </InviteMembersStateProvider>
    );
};
