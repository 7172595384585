import {createPortal} from 'react-dom';
import {PropsWithChildren, useEffect, useRef} from 'react';

import {useEventCallback, useOnClickOutside} from 'usehooks-ts';

import {DropdownContainer} from './dropdown-container';
import {useDropdownPosition} from './use-dropdown-position';

type Props = Omit<Parameters<typeof useDropdownPosition>[0], 'dropdownRef' | 'isOpen'> & {
    open?: boolean;
}

export const Dropdown = ({
    open,
    onClose,
    children,
    ...props
}: PropsWithChildren<Props>) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownProps = useDropdownPosition({...props, dropdownRef, isOpen: open});

    useOnClickOutside(dropdownRef, () => {
        onClose?.();
    });

    const handleKeyDown = useEventCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape' && open) {
            onClose?.();
        }
    });

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const dropdown = createPortal(
        <DropdownContainer
            {...dropdownProps}
            onMouseDown={(e) => e.preventDefault()}
            ref={dropdownRef}
        >
            {children}
        </DropdownContainer>,
        document.body,
    );

    return open ? dropdown : null;
};

