import {createAction} from '@reduxjs/toolkit';

import type {ClientThread} from '../types/extended';

type Payload = {
    threadId: ClientThread['id'];
    following: boolean;
    selectedThreadId?: ClientThread['id'];
}

export const changeThreadFollow = createAction<Payload>('threads/actions/changeThreadFollow');
