import {CustomEmoji, SystemEmoji} from '@mattermost/types/emojis';
import * as Emoji from 'utils/emoji';

/**
 * Wrap the contents of the store so that we don't need to construct an ES6 map where most of the content
 * (the system emojis) will never change. It provides the get/has functions of a map and an iterator so
 * that it can be used in for..of loops
 */
export default class EmojiMap {
    private customEmojis: Map<CustomEmoji['name'], CustomEmoji>;
    private customEmojisArray: Array<CustomEmoji['name']>;

    constructor(customEmojis: Map<CustomEmoji['name'], CustomEmoji>) {
        this.customEmojis = customEmojis;

        // Store customEmojis to an array so we can iterate it more easily
        this.customEmojisArray = Array.from(customEmojis.values()).map(({name}) => name);
    }

    public has(name: CustomEmoji['name']) {
        return Emoji.EmojiIndicesByAlias.has(name) || this.customEmojis.has(name);
    }

    public hasSystemEmoji(name: CustomEmoji['name']) {
        return Emoji.EmojiIndicesByAlias.has(name);
    }

    public hasUnicode(codepoint: string) {
        return Emoji.EmojiIndicesByUnicode.has(codepoint);
    }

    public get customEmojiNames() {
        return Object.freeze(this.customEmojisArray);
    }

    public get(name: CustomEmoji['name']): SystemEmoji | CustomEmoji | undefined {
        if (Emoji.EmojiIndicesByAlias.has(name)) {
            return Emoji.Emojis[Emoji.EmojiIndicesByAlias.get(name)!];
        }

        return this.customEmojis.get(name);
    }

    public getUnicode(codepoint: string) {
        return Emoji.Emojis[Emoji.EmojiIndicesByUnicode.get(codepoint)!];
    }

    static isCustomEmoji(emoji: CustomEmoji | SystemEmoji): emoji is CustomEmoji {
        return Boolean((emoji as CustomEmoji).id);
    }

    static isSystemEmoji(emoji: CustomEmoji | SystemEmoji): emoji is SystemEmoji {
        return !(emoji as CustomEmoji).id;
    }

    static isCustomEmojiEqual(emojiName: CustomEmoji['name'], prevMap: EmojiMap, nextMap: EmojiMap) {
        const prevEmoji = prevMap.get(emojiName) as CustomEmoji | undefined;
        const nextEmoji = nextMap.get(emojiName) as CustomEmoji | undefined;

        return prevEmoji?.id === nextEmoji?.id;
    }

    /**
     * Check equality of two emoji maps
     */
    static isEqual(prevEmojiMap: EmojiMap, nextEmojiMap: EmojiMap, checkId = true) {
        if (prevEmojiMap.customEmojisCount !== nextEmojiMap.customEmojisCount) {
            return false;
        }

        // We compare only custom emojis because system should always be the same
        for (const nextEmojiName of nextEmojiMap.customEmojiNames) {
            if (!prevEmojiMap.has(nextEmojiName)) {
                return false;
            }

            if (checkId && !EmojiMap.isCustomEmojiEqual(nextEmojiName, prevEmojiMap, nextEmojiMap)) {
                return false;
            }
        }

        return true;
    }

    public get customEmojisCount() {
        return this.customEmojisArray.length;
    }

    [Symbol.iterator]() {
        const customEmojisArray = this.customEmojisArray;
        const customEmojisMap = this.customEmojis;

        return {
            systemIndex: 0,
            customIndex: 0,
            next() {
                if (this.systemIndex < Emoji.Emojis.length) {
                    const emoji = Emoji.Emojis[this.systemIndex];

                    this.systemIndex += 1;

                    return {value: [emoji.short_names[0], emoji]};
                }

                if (this.customIndex < customEmojisArray.length) {
                    const emojiName = customEmojisArray[this.customIndex];
                    const emoji = customEmojisMap.get(emojiName)!;

                    this.customIndex += 1;
                    const name = emoji.name;
                    return {value: [name, emoji]};
                }

                return {done: true};
            },
        };
    }
}
