import * as Redux from 'redux';

import {batchActions} from 'utils/batch_actions';

import {
    actionsToMarkChannelAsRead,
    actionsToMarkChannelAsUnread,
    actionsToMarkChannelAsViewed,
    markChannelAsReadOnServer,
} from 'mattermost-redux/actions/channels';
import * as PostActions from 'mattermost-redux/actions/posts';

import {WebsocketEvents} from 'mattermost-redux/constants';

import {getCurrentChannelId, isManuallyUnread} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import * as PostSelectors from 'mattermost-redux/selectors/entities/posts';

import {ActionFunc, DispatchFunc, GetStateFunc} from 'mattermost-redux/types/actions';
import type {Post} from 'mattermost-redux/types/posts';

import {isFromWebhook, isSystemMessage, shouldIgnorePost} from 'mattermost-redux/utils/post_utils';

import type {GlobalState} from 'types/store';

import {ActionTypes} from 'utils/constants';
import {sendPostNotification} from 'features/notifications/actions/send_post_notification';
import type {PostedEvent} from 'features/posts/types/posted_event';
import {isComment, isPostOwner} from 'utils/post_utils';
import {getMentionsFromMessageProps} from 'features/notifications/actions/send_post_notification/utils';

import {getLastViewedAtByThreadId} from 'features/threads/selectors/get_last_viewed_at_by_thread_id';
import {isThreadOpened} from 'features/threads/selectors/is_thread_opened';
import {selectThreadById} from 'features/threads/selectors/select_thread_by_id';
import {updateThreadLastViewedAt} from 'features/threads/actions/update_thread_last_viewed_at';
import {isAppFocused} from 'features/app_activity/selector/is_app_focused';
import {getThreadPostFollowState} from 'features/threads/selectors/get_thread_post_follow_state';

export function completePostReceive(
    post: Post,
    websocketMessageProps: PostedEvent['data'],
    fetchedChannelMember: boolean,
) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const isThreadPost = isComment(post);
        const rootPost = PostSelectors.getPost(state, post.root_id);

        const actions: Redux.AnyAction[] = [];

        if (post.channel_id === getCurrentChannelId(getState())) {
            actions.push({
                type: ActionTypes.INCREASE_POST_VISIBILITY,
                data: post.channel_id,
                amount: 1,
            });
        }

        if (isThreadPost && rootPost) {
            actions.push(PostActions.receivedPost({...rootPost, last_reply_at: post.create_at}, true));
        }

        actions.push(PostActions.receivedNewPost(post, true), {
            type: WebsocketEvents.STOP_TYPING,
            data: {
                id: post.channel_id + post.root_id,
                userId: post.user_id,
                now: Date.now(),
            },
        });

        const isThreadPostByCurrentUser = isThreadPost && isPostOwner(state, post);

        if (!isThreadPostByCurrentUser) {
            actions.push(
                ...setChannelReadAndViewed(dispatch, getState, post, websocketMessageProps, fetchedChannelMember),
            );
        }

        dispatch(batchActions(actions));

        if (isThreadPost) {
            dispatch(setThreadRead(post));
        }

        return dispatch(
            sendPostNotification({
                meta: websocketMessageProps,
                post,
            }) as unknown as ActionFunc,
        );
    };
}

// setChannelReadAndViewed returns an array of actions to mark the channel read and viewed, and it dispatches an action
// to asynchronously mark the channel as read on the server if necessary.
export function setChannelReadAndViewed(
    dispatch: DispatchFunc,
    getState: GetStateFunc,
    post: Post,
    websocketMessageProps: PostedEvent['data'],
    fetchedChannelMember: boolean,
): Redux.AnyAction[] {
    const state = getState();
    const currentUserId = getCurrentUserId(state);

    // ignore system message posts, except when added to a team
    if (shouldIgnorePost(post, currentUserId)) {
        return [];
    }

    let markAsRead = false;
    let markAsReadOnServer = false;

    // Skip marking a channel as read (when the user is viewing a channel)
    // if they have manually marked it as unread.
    if (!isManuallyUnread(getState(), post.channel_id)) {
        if (post.user_id === getCurrentUserId(state) && !isSystemMessage(post) && !isFromWebhook(post)) {
            markAsRead = true;
            markAsReadOnServer = false;
        } else if (post.channel_id === getCurrentChannelId(state) && isAppFocused(state)) {
            markAsRead = true;
            markAsReadOnServer = true;
        }
    }

    if (markAsRead) {
        if (markAsReadOnServer) {
            dispatch(markChannelAsReadOnServer(post.channel_id));
        }

        return [
            ...actionsToMarkChannelAsRead(getState, post.channel_id),
            ...actionsToMarkChannelAsViewed(getState, post.channel_id),
        ];
    }

    return actionsToMarkChannelAsUnread(
        getState,
        websocketMessageProps.team_id,
        post.channel_id,
        getMentionsFromMessageProps(websocketMessageProps),
        fetchedChannelMember,
        post.root_id === '',
    );
}

export function setThreadRead(post: Post) {
    return (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState() as GlobalState;

        const thread = selectThreadById(state, post.root_id);
        const isFollowing = getThreadPostFollowState(state, post.root_id);

        // mark a thread as read (when the user is viewing the thread)
        if (thread && isFollowing && isThreadOpened(state, thread.id)) {
            // update the new messages line (when there are no previous unreads)
            if (thread.last_reply_at < getLastViewedAtByThreadId(state, thread.id)) {
                dispatch(updateThreadLastViewedAt({threadId: thread.id, lastViewedAt: post.create_at}));
            }
        }

        return {data: true};
    };
}
