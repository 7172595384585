import {createSelector} from '@reduxjs/toolkit';

import type {RootState} from 'stores/redux_store';

import {getDirectMessagesChannelCategories} from './get_direct_messages_channel_categories';

export const getDirectMessagesChannelCategoriesForTeam = createSelector(
    getDirectMessagesChannelCategories,
    (_: RootState, teamId: string) => teamId,
    (categories, teamId) => {
        /**
         * DM/GM channels have empty team_id for now
         * But, we will account for future
         */
        if (!teamId) {
            return categories;
        }

        return categories.filter((category) => category.team_id === teamId);
    },
);
