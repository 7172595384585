export abstract class HttpError extends Error {
    static fromHttpStatus(httpStatus: number) {
        if (httpStatus === 400) {
            return new InvalidRequestError();
        }

        if (httpStatus === 401) {
            return new NotAuthorizedError();
        }

        if (httpStatus === 402) {
            return new NoLicenseError();
        }

        if (httpStatus === 403) {
            return new NoPermissionError();
        }

        if (httpStatus === 408) {
            return new TimeoutError();
        }

        if (httpStatus === 503) {
            return new UnavailableError();
        }
    }
}

export class InvalidRequestError extends HttpError {}

export class NotAuthorizedError extends HttpError {}

export class NoPermissionError extends HttpError {}

export class TimeoutError extends HttpError {}

export class UnavailableError extends HttpError {}

export class UnknownHttpError extends HttpError {}

export class NoLicenseError extends HttpError {}
