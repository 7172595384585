import {createSelector} from 'reselect';

import pathOr from '@tinkoff/utils/object/pathOr';
import prop from '@tinkoff/utils/object/prop';
import isEqual from '@tinkoff/utils/is/equal';

import {getAllPosts, getPost} from 'mattermost-redux/selectors/entities/posts';
import type {Post} from 'mattermost-redux/types/posts';
import type {GlobalState} from 'types/store';
import {comparePosts} from 'mattermost-redux/utils/post_utils';

const EMPTY_POSTS_LIST: Array<Post['id']> = [];

const getPostIdFromPost: (post: Post) => Post['id'] = prop('id');

export const getPostsInThreadIds = (state: GlobalState, rootPostId: Post['id']) =>
    pathOr(['entities', 'posts', 'postsInThread', rootPostId], EMPTY_POSTS_LIST, state) as Array<Post['id']>;

export const getPostsForRootPost = createSelector(
    getAllPosts,
    getPostsInThreadIds,
    getPost,
    (posts, postsForThread, rootPost) => {
        const threadPosts: Post[] = [];

        if (rootPost) {
            threadPosts.push(rootPost);
        }

        postsForThread?.forEach((id) => {
            const post = posts[id];

            if (post) {
                threadPosts.push(post);
            }
        });

        threadPosts.sort(comparePosts);

        return threadPosts;
    },
    {
        memoizeOptions: {
            resultEqualityCheck: isEqual,
        },
    },
);

export const getPostIdsForRootPost = createSelector(getPostsForRootPost, (posts) => posts.map(getPostIdFromPost), {
    memoizeOptions: {
        resultEqualityCheck: isEqual,
    },
});
