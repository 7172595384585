import type {Channel} from '@mattermost/types/channels';
import {isDmChannel} from 'features/sidebar';
import {isGmChannel} from 'features/sidebar/utils/isGmChannel';
import {localizeAndFormatMessage, localizeMessage} from 'utils/utils';

import type {Meta} from './types';

export function getNotificationTitle(channel: Channel | undefined, meta: Meta, isComment?: boolean) {
    if (!channel?.id && meta.channel_display_name) {
        return isComment ? localizeAndFormatMessage('notification.crt', 'Reply in {title}', {
            title: meta.channel_display_name,
        }) : meta.channel_display_name;
    }

    if (channel?.id && channel.display_name) {
        return isComment ? localizeAndFormatMessage('notification.crt', 'Reply in {title}', {
            title: channel.display_name,
        }) : channel.display_name;
    }

    if (
        (!channel &&
            isDmChannel({
                type: meta.channel_type,
            } as Channel)) ||
        isGmChannel({
            type: meta.channel_type,
        } as Channel)
    ) {
        return localizeMessage('notification.dm', 'Direct Message');
    }

    return localizeMessage('channel_loader.posted', 'Posted');
}
