import classnames from 'classnames';

import {useTypography} from '../../../hooks/typography';

import {BUTTON_SIZE, TYPOGRAPHY_TEXT_SIZES} from './constants';
import styles from './styles.module.css';

export function useTextClass(buttonSize: BUTTON_SIZE) {
    const [largeTextCls, mediumTextCls, smallTextCls, xSmallTextCls] = useTypography(TYPOGRAPHY_TEXT_SIZES);

    switch (buttonSize) {
    case BUTTON_SIZE.LARGE:
        return classnames(largeTextCls, styles.textBase);
    case BUTTON_SIZE.MEDIUM:
        return classnames(mediumTextCls, styles.textBase);
    case BUTTON_SIZE.SMALL:
        return classnames(smallTextCls, styles.textBase);
    case BUTTON_SIZE.XSMALL:
        return classnames(xSmallTextCls, styles.textBase);
    }
}
