import classnames from 'classnames';

import {HTMLAttributes} from 'react';

import styles from './styles.module.css';

type Props = {
    size?: number;
    color?: string;
} & HTMLAttributes<HTMLSpanElement>

export const Spinner = ({size = 24, color = 'rgb(var(--time-webkit-color-main-rgb, 22, 109, 224)', ...props}: Props) => {
    return (
        <span {...props} className={classnames(styles.base)} style={{width: `${size}px`, height: `${size}px`}} data-testid='spinner' role='progressbar'>
            <svg viewBox='0 0 100 100' className={styles.svg}>
                <circle fill='none' className={styles.circle} style={{stroke: color}} cx='50' cy='50' r='44' />
            </svg>
        </span>
    );
};
