import type {ReactNode, HTMLAttributes} from 'react';
import isString from '@tinkoff/utils/is/string';

import classNames from 'classnames';

import {TypographySize, useTypography} from '../../../hooks/typography';

import styles from './styles.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    avatar?: ReactNode;
    avatarClassName?: string;
    textPrimary: ReactNode;
    textSecondary?: ReactNode;
    icon?: ReactNode;
    actionButtons?: ReactNode[];
};

export const UserListItem = ({
    avatar,
    avatarClassName,
    textPrimary: textPrimaryProp,
    textSecondary: textSecondaryProp,
    icon,
    actionButtons,
    className,
    ...rest
}: Props) => {
    const [usernameTypographyCls, nicknameTypographyCls] = useTypography([
        {
            size: TypographySize.BodyL,
        },
        {
            size: TypographySize.BodyS,
        },
    ]);

    const textPrimary = isString(textPrimaryProp) ? (
        <span className={classNames(usernameTypographyCls, styles.text)}>{textPrimaryProp}</span>
    ) : (
        textPrimaryProp
    );
    const textSecondary = isString(textSecondaryProp) ? (
        <span className={classNames(nicknameTypographyCls, styles.text, styles.textSecondary)}>{textSecondaryProp}</span>
    ) : (
        textSecondaryProp
    );

    return (
        <div className={classNames(styles.wrapper, className)} {...rest}>
            {avatar && <div className={classNames(styles.avatar, avatarClassName)}>{avatar}</div>}
            <p className={styles.meta}>
                {textPrimary}
                {textSecondary}
            </p>
            {actionButtons && <div className={styles.actions}>{actionButtons}</div>}
            {icon}
        </div>
    );
};
