import {createSelector} from 'reselect';

import {getMyChannelMembership} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';
import {NotificationLevel} from 'mattermost-redux/constants/channels';

export const getCurrentUserChannelNotificationLevel = createSelector(
    getMyChannelMembership,
    getCurrentUser,
    (membership, user) => {
        if (!membership) {
            return NotificationLevel.NONE;
        }

        const globalNotificationLevel = user.notify_props?.desktop;

        if (!globalNotificationLevel) {
            return NotificationLevel.NONE;
        }

        const channelNotificationLevel = membership.notify_props.desktop || NotificationLevel.DEFAULT;

        if (channelNotificationLevel === NotificationLevel.DEFAULT) {
            return globalNotificationLevel;
        }

        return channelNotificationLevel;
    },
);
