import {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {initSentry} from 'utils/sentry';
import {isAnalyticsEnabled} from 'mattermost-redux/selectors/entities/preferences';

import {SentryUserSetter} from './sentry_user_setter';
import {SentryServerSetter} from './sentry_server_setter';

export const SentryInitializer = () => {
    const isEnabled = useSelector(isAnalyticsEnabled);
    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        if (isEnabled) {
            initSentry();
            setInitialized(true);
        }
    }, [isEnabled]);

    if (isInitialized) {
        return (
            <>
                <SentryUserSetter />
                <SentryServerSetter />
            </>
        );
    }

    return null;
};
