import {combineReducers} from 'redux';

import pathSet from '@tinkoff/utils/object/pathSet';
import pathOr from '@tinkoff/utils/object/pathOr';

import {ChannelCategoryTypes, TeamTypes, UserTypes, ChannelTypes} from 'mattermost-redux/action_types';

import {GenericAction} from 'mattermost-redux/types/actions';
import {ChannelCategory} from 'mattermost-redux/types/channel_categories';
import {Team} from 'mattermost-redux/types/teams';
import {IDMappedObjects, RelationOneToOne} from 'mattermost-redux/types/utilities';

import {removeItem} from 'mattermost-redux/utils/array_utils';
import {receivedChannelCategories} from 'features/sidebar';
import {receivedChannelCategory} from 'features/sidebar/actions/received_channel_category';
import {fetchAndAddCreatedCategoryToSidebar} from 'features/sidebar/actions/fetch_and_add_created_category_to_sidebar';

export function byId(state: IDMappedObjects<ChannelCategory> = {}, action: GenericAction) {
    switch (action.type) {
    case receivedChannelCategories.type: {
        const categories = (action as ReturnType<typeof receivedChannelCategories>).payload;

        return categories.reduce((nextState, category) => {
            return {
                ...nextState,
                [category.id]: {
                    ...nextState[category.id],
                    ...category,
                },
            };
        }, state);
    }

    case receivedChannelCategory.type: {
        const category = (action as ReturnType<typeof receivedChannelCategory>).payload;

        return pathSet([category.id], category, state);
    }

    case ChannelCategoryTypes.CATEGORY_DELETED: {
        const categoryId: ChannelCategory['id'] = action.data;

        const nextState = {...state};

        Reflect.deleteProperty(nextState, categoryId);

        return nextState;
    }

    case ChannelTypes.LEAVE_CHANNEL: {
        const channelId: string = action.data.id;

        const nextState = {...state};
        let changed = false;

        for (const category of Object.values(state)) {
            const index = category.channel_ids.indexOf(channelId);

            if (index === -1) {
                continue;
            }

            const nextChannelIds = [...category.channel_ids];
            nextChannelIds.splice(index, 1);

            nextState[category.id] = {
                ...category,
                channel_ids: nextChannelIds,
            };

            changed = true;
        }

        return changed ? nextState : state;
    }
    case TeamTypes.LEAVE_TEAM: {
        const team: Team = action.data;

        const nextState = {...state};
        let changed = false;

        for (const category of Object.values(state)) {
            if (category.team_id !== team.id) {
                continue;
            }

            Reflect.deleteProperty(nextState, category.id);
            changed = true;
        }

        return changed ? nextState : state;
    }

    case UserTypes.LOGOUT_SUCCESS:
        return {};
    default:
        return state;
    }
}

export function orderByTeam(state: RelationOneToOne<Team, Array<ChannelCategory['id']>> = {}, action: GenericAction) {
    switch (action.type) {
    case ChannelCategoryTypes.RECEIVED_CATEGORY_ORDER: {
        const teamId: string = action.data.teamId;
        const order: string[] = action.data.order;

        return {
            ...state,
            [teamId]: order,
        };
    }
    case fetchAndAddCreatedCategoryToSidebar.fulfilled.type: {
        const actionResult = (action as ReturnType<typeof fetchAndAddCreatedCategoryToSidebar.fulfilled>);
        const teamId = actionResult.payload.team_id;
        const newCategoryId = actionResult.payload.id;

        const prevOrder = pathOr([teamId] as const, [], state) as Array<ChannelCategory['id']>;
        if (prevOrder.includes(newCategoryId)) {
            return state;
        }

        const newOrder = [
            actionResult.meta.favoritesCategoryId,
            newCategoryId,
            ...prevOrder.filter((id) => id !== actionResult.meta.favoritesCategoryId),
        ].filter(Boolean);

        return {
            ...state,
            [teamId]: newOrder,
        };
    }

    case ChannelCategoryTypes.CATEGORY_DELETED: {
        const categoryId: ChannelCategory['id'] = action.data;

        const nextState = {...state};

        for (const teamId of Object.keys(nextState)) {
            // removeItem only modifies the array if it contains the category ID, so other teams' state won't be modified
            nextState[teamId] = removeItem(state[teamId], categoryId);
        }

        return nextState;
    }

    case TeamTypes.LEAVE_TEAM: {
        const team: Team = action.data;

        if (!state[team.id]) {
            return state;
        }

        const nextState = {...state};
        Reflect.deleteProperty(nextState, team.id);

        return nextState;
    }

    case UserTypes.LOGOUT_SUCCESS:
        return {};
    default:
        return state;
    }
}

export default combineReducers({
    byId,
    orderByTeam,
});
