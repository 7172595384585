import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {resetReloadPostsInChannel} from 'mattermost-redux/actions/posts';

const PostsChannelResetWatcher = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetReloadPostsInChannel());
    }, [dispatch]);

    return null;
};

export default PostsChannelResetWatcher;
