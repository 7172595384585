import type {Post} from '@mattermost/types/posts';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {createThunkAction} from 'stores/create_thunk_action';

import {changeThreadFollow} from './change_thread_follow';
import {markThreadAsUnreadOnServer} from './mark_thread_as_unread_on_server';
import {markThreadManuallyUnread} from './mark_thread_manually_unread';

export const markThreadPostUnread = (post: Post) =>
    createThunkAction('threads/actions/markThreadPostUnread', (dispatch, getState) => {
        const state = getState();
        const threadId = post.root_id || post.id;
        const currentTeamId = getCurrentTeamId(state);
        const currentUserId = getCurrentUserId(state);

        dispatch(
            changeThreadFollow({
                following: true,
                threadId,
            }),
        );

        dispatch(markThreadManuallyUnread({threadId, lastViewedAt: post.create_at - 1}));

        return dispatch(
            markThreadAsUnreadOnServer({
                postId: post.id,
                teamId: currentTeamId,
                threadId,
                userId: currentUserId,
            }),
        );
    });
