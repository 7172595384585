import {connect} from 'react-redux';

import {getUserById} from 'mattermost-redux/selectors/entities/common';

import type {UserProfile} from 'mattermost-redux/types/users';
import type {GlobalState} from 'types/store';

import {getDisplayNameByUser} from 'utils/utils';

import ProfilePicture from './profile_picture';

type OwnProps = {
    userId?: UserProfile['id'];
    username?: UserProfile['username'];
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
    const user = props.userId ? getUserById(state, props.userId) : undefined;

    return {
        username: getDisplayNameByUser(state, user) || props.username,
    };
};

export default connect(mapStateToProps)(ProfilePicture);
