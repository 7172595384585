import type {Post} from '@mattermost/types/posts';
import type {User} from 'features/users';

import {collectUserIdsFromPost} from './collect_user_ids_from_post';

export function collectUserIdsFromPosts(posts: Post[]) {
    const userIdsSet = new Set<User['id']>();

    posts.forEach((post) => {
        const userIds = collectUserIdsFromPost(post);
        userIds.forEach((userId) => userIdsSet.add(userId));
    });

    return Array.from(userIdsSet);
}
