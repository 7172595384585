import {createSelector} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {RootState} from 'stores/redux_store';

import {getAllChannelCategoriesByIds} from './get_all_channel_categories_by_ids';

export const getChannelChannelCategories = createSelector(
    getAllChannelCategoriesByIds,
    (_: RootState, channel: Channel) => channel,
    (categories, channel) => {
        return Object.values(categories).filter((category) => category?.channel_ids.includes(channel?.id));
    },
);
