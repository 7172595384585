import type {ActionResult} from 'mattermost-redux/types/actions';

import empty from '@tinkoff/utils/is/empty';

import {sendToStatist} from '../methods';
import {parseSearchParams} from '../utils/parse_search_params';
import {SearchParams} from '../types';
import {detectRusSymbols} from '../utils/detect_rus_symbols';
import {detectEngSymbols} from '../utils/detect_eng_symbols';
import {detectPhrases} from '../utils/detect_phrases';

type UpperScreenTypeEventParams = {
    terms: string;
    timeInMS: number;
    foundFiles: ActionResult;
    foundPosts: ActionResult;
    hasError: boolean;
}

// eslint-disable-next-line no-process-env
const isStatistDisabled = !process.env.STATIST_ENABLED;

const excludedParamNames: Array<keyof SearchParams> = [
    'ExcludedTerms',
    'ExcludedChannels',
    'ExcludedUsers',
    'ExcludedAfterDate',
    'ExcludedBeforeDate',
    'ExcludedExtensions',
    'ExcludedDate',
];

const hasExcludedParam = (searchParams: SearchParams) => {
    for (const excludedParamName of excludedParamNames) {
        if (!empty(searchParams[excludedParamName])) {
            return true;
        }
    }

    return false;
};

export function trackSearchUpperScreenType({terms, timeInMS, foundFiles, foundPosts, hasError}: UpperScreenTypeEventParams) {
    if (isStatistDisabled) {
        return;
    }

    let resultExist = false;
    let resultErrorType = '';

    if (foundFiles.error) {
        resultErrorType = resultErrorType || foundFiles.error.message;
    } else {
        resultExist = resultExist || foundFiles.data.order.length > 0;
    }

    if (foundPosts.error) {
        resultErrorType = resultErrorType || foundPosts.error.message;
    } else {
        resultExist = resultExist || foundPosts.data.order.length > 0;
    }

    const searchParamsList = parseSearchParams(terms);

    const eventParams = {
        smartSearch: false,
        chosenFrom: false,
        chosenIn: false,
        chosenOn: false,
        chosenBefore: false,
        chosenAfter: false,
        chosenExtension: false,
        chosenExclude: false,
        chosenPhrases: detectPhrases(terms),
        resultExist,
        resultTime: timeInMS,
        resultError: hasError,
        resultErrorType,
        inputSymbols: terms.length,
        rusSymbols: detectRusSymbols(terms),
        engSymbols: detectEngSymbols(terms),
    };

    for (const searchParams of searchParamsList) {
        eventParams.chosenFrom = eventParams.chosenFrom || !empty(searchParams.FromUsers);
        eventParams.chosenIn = eventParams.chosenIn || !empty(searchParams.InChannels);
        eventParams.chosenOn = eventParams.chosenOn || !empty(searchParams.OnDate);
        eventParams.chosenBefore = eventParams.chosenBefore || !empty(searchParams.BeforeDate);
        eventParams.chosenAfter = eventParams.chosenAfter || !empty(searchParams.AfterDate);
        eventParams.chosenExtension = eventParams.chosenExtension || !empty(searchParams.Extensions);
        eventParams.chosenExclude = eventParams.chosenExclude || hasExcludedParam(searchParams);
    }

    eventParams.smartSearch =
        eventParams.chosenFrom ||
        eventParams.chosenIn ||
        eventParams.chosenOn ||
        eventParams.chosenBefore ||
        eventParams.chosenAfter ||
        eventParams.chosenExtension ||
        eventParams.chosenExclude ||
        eventParams.chosenPhrases;

    sendToStatist('searchUpperscreen.type', eventParams);
}
