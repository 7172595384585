import {useEffect} from 'react';

import {useSelector} from 'react-redux';

import {getConfig} from 'mattermost-redux/selectors/entities/general';

import {setSentryServer} from 'utils/sentry';

export const SentryServerSetter = () => {
    const config = useSelector(getConfig) || {};
    useEffect(() => {
        if (config.SiteURL) {
            setSentryServer(config.SiteURL);
        }
    }, [config.SiteURL]);

    return null;
};
