import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';

import {isUser} from 'mattermost-redux/utils/user_utils';
import {createThunkAction} from '../../../stores/create_thunk_action';
import {UserTypes} from 'mattermost-redux/action_types';

export const receivedUser = (user: object) => createThunkAction('users/actions/receivedUser', (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());

    if (isUser(user)) {
        let newUser = user;

        if (user.id === currentUser?.id) {
            newUser = {
                ...currentUser,
                ...user,
            };
        }

        dispatch({
            type: UserTypes.RECEIVED_USER,
            payload: newUser,
        });

        return newUser;
    }
});
