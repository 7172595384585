import type {PreferenceType} from '@mattermost/types/preferences';
import {Preferences} from 'mattermost-redux/constants';
import type {LimitVisibleDmsGmsPreference} from '../types';

export function isLimitVisibleDmsGmsPreference(preference: PreferenceType): preference is LimitVisibleDmsGmsPreference {
    return (
        preference.category === Preferences.CATEGORY_SIDEBAR_SETTINGS &&
        preference.name === Preferences.LIMIT_VISIBLE_DMS_GMS
    );
}
