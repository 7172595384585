import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Team} from '@mattermost/types/teams';
import {getCategoriesForTeam} from 'mattermost-redux/selectors/entities/channel_categories';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getSidebarCategoryForTeamForUser} from '../api/get_sidebar_category_for_team_for_user';
import {isDirectMessagesChannelCategory} from '../utils/isDirectMessagesChannelCategory';
import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';

import {receivedChannelCategory} from './received_channel_category';

export const loadDmCategoryForTeam = createAsyncThunk(
    'sidebar/actions/loadDmCategoryForTeam',
    async (
        payload: {
            teamId: Team['id'];
        },
        thunkAPI,
    ) => {
        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const {teamId} = payload;
        const currentUserId = getCurrentUserId(state);

        const categories = getCategoriesForTeam(state, teamId);

        const dmCategory = categories.find(isDirectMessagesChannelCategory);

        if (!dmCategory) {
            return;
        }

        const newDmCategory = await dispatch(
            getSidebarCategoryForTeamForUser({
                categoryId: dmCategory.id,
                teamId,
                userId: currentUserId,
            }),
        ).unwrap();

        return dispatch(receivedChannelCategory(newDmCategory));
    },
);
