import React from 'react';

export enum ModalSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export const KeyCodes = {
    ENTER: ['Enter', 13],
};

export const nodeRef = {} as React.MutableRefObject<HTMLElement>;
