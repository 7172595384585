import React from 'react';

import {CloseIcon} from '../../../../icons';

import styles from './styles.module.css';

type Props = {
    onClick: () => void;
};

export const ModalCloser: React.FunctionComponent<Props> = (props) => {
    const {onClick} = props;

    return (
        <button type='button' onClick={onClick} className={styles.button}>
            <CloseIcon className={styles.svg} />
        </button>
    );
};
