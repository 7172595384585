import {Subject} from 'rxjs';

import {HttpErrorObservable} from './http_error_observable';
import {HttpError, UnknownHttpError} from './http_error';

const httpError$ = new Subject<HttpError>(
);

export function interceptHttpError(): HttpErrorObservable {
    return httpError$.asObservable();
}

export function handleHttpError(status: number) {
    const httpError = HttpError.fromHttpStatus(status);
    httpError$.next(httpError ?? new UnknownHttpError());
}
