import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const LockIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' {...props} ref={ref}>
        <path
            fill='currentColor'
            d='M12 6c.733 0 1.333.6 1.333 1.333v6c0 .734-.6 1.334-1.333 1.334H4c-.733 0-1.333-.6-1.333-1.333v-6C2.667 6.6 3.267 6 4 6h.667V4.667A3.3 3.3 0 0 1 8 1.334a3.3 3.3 0 0 1 3.333 3.333V6H12ZM8 2.667c-1.133 0-2 .867-2 2V6h4V4.667c0-1.133-.867-2-2-2Z'
        />
    </svg>
));
