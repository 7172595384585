type Props = {
    size?: number;
    className?: string;
};

export const DownloadOutlineIcon: React.VoidFunctionComponent<Props> = ({size = 24, className}) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size} height={size} className={className}>
            <path fill='currentColor' d='M19,18H5v2h14V18z M11,2h2v10.2l4.5-4.5l1.4,1.4L12,16L5.1,9.1l1.4-1.4l4.5,4.5V2z' />
        </svg>
    );
};
