import {FormattedMessage, useIntl} from 'react-intl';

import {type ReactNode, useCallback, useMemo, useEffect, useState, useContext} from 'react';

import classNames from 'classnames';

import {useId} from '@react-aria/utils';

import {useForm, Controller} from 'react-hook-form';

import {useInterval} from 'usehooks-ts';

import {sendToStatist} from '@time-webkit/statist';

import {Select} from '@time-webkit/all/atoms/select';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {BUTTON_VARIANT, Button} from '@time-webkit/all/atoms/button';

import {ContentCopy} from '@time-webkit/all/icons/content-copy';

import {InputChannels} from '@time-webkit/all/organisms/input-channels';

import {type Team, type TeamInvitationRole} from '@mattermost/types/teams';
import {type Channel} from '@mattermost/types/channels';

import {FormError} from '@time-webkit/all/atoms/form-error';

import {Link} from '@time-webkit/all/atoms/link';

import {Alert, AlertType} from '@time-webkit/all/atoms/alert';

import {ButtonCopyToClipboard} from '../../button-copy-to-clipboard';

import {inviteMembersContext} from '../invite_members_context';

import {InputInviteByEmail} from './input-invite-by-email';

import styles from './invite_members_form.module.css';

type Props = {
    team: Team;
    role: TeamInvitationRole;
    channels?: Channel[];
    emailMaxLength?: number;
    autocompleteChannels?: Channel[];
    emailsLimit?: number;
    channelsLimit?: number;
    allowedGuestDomains?: string[];
    allowedTeamDomains?: string[];
    renderInviteMemberLink?: () => ReactNode;
    onCancel?: () => void;
}

type InviteMembersFormFields = {
    emails: string[];
    role: TeamInvitationRole;
    channels: Channel[];
}

const ROLES: Array<{
    value: TeamInvitationRole;
    name: string;
    displayName: string;
}> = [
    {
        value: 'team_user',
        name: 'User',
        displayName: 'invite.members.roles.team_user.name',
    },
    {
        value: 'team_guest',
        name: 'Guest',
        displayName: 'invite.members.roles.team_guest.name',
    },
];

const EMAILS_LIMIT_DEFAULT = 50;
const CHANNELS_LIMIT_DEFAULT = 10;
const EMAIL_MAX_LENGTH = 128;
const AUTOCOMPLETE_CHANNELS_DEFAULT: Channel[] = [];
const INITIAL_ROLE: TeamInvitationRole = 'team_user';
const INITIAL_CHANNELS: Channel[] = [];

const DELAY_MS = 60_000;

export const InviteMembersForm =
    (
        {
            team,
            role: initialRole = INITIAL_ROLE,
            channels: initialChannels = INITIAL_CHANNELS,
            emailMaxLength = EMAIL_MAX_LENGTH,
            autocompleteChannels = AUTOCOMPLETE_CHANNELS_DEFAULT,
            emailsLimit = EMAILS_LIMIT_DEFAULT,
            channelsLimit = CHANNELS_LIMIT_DEFAULT,
            allowedGuestDomains,
            allowedTeamDomains,
            renderInviteMemberLink,
            onCancel,
        }: Props,
    ) => {
        const intl = useIntl();
        const inviteByEmailErrorId = useId();
        const inviteByEmailWarningId = useId();
        const channelsErrorId = useId();
        const {rateLimitTimeoutMinutes, result, criticalErrors, rateLimitIssues, profiles, warnings, errors: invitationErrors, requestError, reset, sendInvites, failedToSendInvites} = useContext(inviteMembersContext);
        const [emailsLimitExcess, setEmailsLimitExcess] = useState(0);
        const [rateLimitIssueTimeout, setRateLimitIssueTimeout] = useState(rateLimitTimeoutMinutes);
        const {control, watch, handleSubmit, getValues, setValue, formState} = useForm<InviteMembersFormFields>({
            defaultValues: {
                emails: [],
                role: initialRole,
                channels: initialChannels,
            },
            mode: 'onChange',
        });
        const selectedRole = watch('role');
        const [bodyMTrueTypography, bodyMTypography] = useTypography([{size: TypographySize.BodyMTrue}, {size: TypographySize.BodyM}]);

        useInterval(
            () => {
                setRateLimitIssueTimeout(rateLimitIssueTimeout - 1);
            },
            rateLimitIssueTimeout > 0 ? DELAY_MS : null,
        );

        const roleOptions = useMemo(
            () =>
                ROLES.map((role) => ({
                    value: role.value,
                    label: intl.formatMessage({id: role.displayName, defaultMessage: role.name}),
                })),
            [intl],
        );

        const handleDeleteInvalidEmails = useCallback(() => {
            const {emails} = getValues();
            const validEmails = emails.filter((email) => !criticalErrors.find((error) => error.email === email));
            setValue('emails', validEmails);
        }, [criticalErrors, getValues, setValue]);

        const withdrawEmailsLimitExcess = useCallback(() => {
            setEmailsLimitExcess(0);
        }, [setEmailsLimitExcess]);

        const submitForm = (formFields: InviteMembersFormFields) => {
            return sendInvites({...formFields, team});
        };

        useEffect(() => {
            setRateLimitIssueTimeout(rateLimitTimeoutMinutes);
        }, [rateLimitTimeoutMinutes]);

        useEffect(() => {
            const subscription = watch((data) => {
                const {emails, role, channels} = data as unknown as InviteMembersFormFields;
                if (emails.length === 0 || (role === 'team_guest' && channels.length === 0)) {
                    reset();
                    return;
                }
                sendInvites({
                    emails,
                    role,
                    channels,
                    team,
                    dryRun: true,
                });
            });
            return () => subscription.unsubscribe();
        }, [team, reset, sendInvites, watch]);

        const renderWarnings = (content: string) => (
            <div
                id={inviteByEmailWarningId}
                className={classNames(styles.inputDescription, bodyMTypography)}
            >
                {content}
            </div>
        );

        const buttonCopyToClipboard = (
            <ButtonCopyToClipboard
                size='medium'
                copyText={result.map((item) => item.email).join(' ')}
                icon={<ContentCopy width={16} height={16} />}
                messageSuccess={intl.formatMessage({
                    id: 'invite.members.list_has_been_copied',
                    defaultMessage: 'List has been copied',
                })}
            >
                {intl.formatMessage({
                    id: 'invite.members.copy_email_list',
                    defaultMessage: 'Copy email list',
                })}
            </ButtonCopyToClipboard>
        );

        return (
            <form
                data-test-id='InviteMembersForm'
                action='#'
                noValidate={true}
                onSubmit={handleSubmit(submitForm)}
                aria-label={intl.formatMessage({
                    id: 'invite.members.invite_members',
                    defaultMessage: 'Invite to the {team}',
                }, {team: team.display_name})}
            >
                <div className={styles.inputEmailsWrapper}>
                    <Controller
                        name='emails'
                        control={control}
                        rules={{
                            validate: (value) => value.length > 0,
                        }}
                        render={({field}) => {
                            const error = Boolean(formState.errors.emails) || criticalErrors.length > 0;

                            return (
                                <>
                                    <InputInviteByEmail
                                        data-test-id='InputInviteByEmail'
                                        tags={field.value}
                                        limit={emailsLimit}
                                        placeholder={intl.formatMessage({
                                            id: 'invite.members.members_placeholder',
                                            defaultMessage: 'Enter email',
                                        })}
                                        label={
                                            intl.formatMessage({
                                                id: 'invite.members.members_label',
                                                defaultMessage: 'Invite members',
                                            })
                                        }
                                        profiles={profiles}
                                        invitationErrors={invitationErrors}
                                        error={error}
                                        autoFocus={true}
                                        maxLength={emailMaxLength}
                                        onChange={(value) => {
                                            const delta = Math.max(value.length - emailsLimit, 0);
                                            const limitedValue = value.slice(0, value.length - delta);

                                            setEmailsLimitExcess(delta);

                                            if (delta > 0) {
                                                sendToStatist('team.invite.listLimit', {
                                                    teamid: team.id,
                                                    userCount: value.length,
                                                });
                                            }
                                            field.onChange(limitedValue);
                                        }}
                                        onBlur={field.onBlur}
                                        allowedDomains={selectedRole === 'team_guest' ? allowedGuestDomains : allowedTeamDomains}
                                        aria-errormessage={inviteByEmailErrorId}
                                        aria-describedby={inviteByEmailWarningId}
                                    />
                                    {
                                        emailsLimit && field.value.length >= emailsLimit && !error && (
                                            renderWarnings(intl.formatMessage({
                                                id: 'invite.members.limit_reached_description',
                                                defaultMessage: 'You have added the maximum number of users',
                                            }))
                                        )
                                    }
                                </>
                            );
                        }}
                    />
                    <FormError
                        id={inviteByEmailErrorId}
                        className={styles.error}
                        show={Boolean(formState.errors.emails && criticalErrors.length === 0)}
                        message={intl.formatMessage(
                            {id: 'invite.members.no_emails_error', defaultMessage: 'Add more emails'},
                            {count: emailsLimit},
                        )}
                    />
                    {
                        criticalErrors.length > 0 && (
                            <div className={styles.errorWithAction}>
                                <FormError
                                    id={inviteByEmailErrorId}
                                    className={styles.error}
                                    show={true}
                                    message={criticalErrors.length === 1 ? criticalErrors[0]!.getLocalizedMessage(intl) : intl.formatMessage(
                                        {id: 'invite.members.users_with_error', defaultMessage: '{count} users with error'},
                                        {count: criticalErrors.length},
                                    )}
                                />
                                {criticalErrors.length > 1 && (
                                    <Link
                                        as='button'
                                        type='button'
                                        size='medium'
                                        pseudo={true}
                                        onClick={handleDeleteInvalidEmails}
                                    >
                                        <FormattedMessage
                                            id='invite.members.delete_emails'
                                            defaultMessage='Delete'
                                        />
                                    </Link>
                                )}
                            </div>
                        )
                    }
                    {
                        criticalErrors.length === 0 && warnings.length > 0 && (
                            renderWarnings(warnings.length === 1 ? warnings[0]!.getLocalizedMessage(intl) : intl.formatMessage(
                                {id: 'invite.members.users_with_warning', defaultMessage: '{count} users with warning'},
                                {count: warnings.length},
                            ))
                        )
                    }
                    {emailsLimitExcess > 0 && (
                        <Alert
                            data-test-id='Alert'
                            size='medium'
                            className={styles.inputEmailsAlert}
                            type={AlertType.Warning}
                            message={intl.formatMessage(
                                {
                                    id: 'invite.members.overflow_limit_label',
                                    defaultMessage: 'You can invite not more than {count} users',
                                },
                                {count: emailsLimit},
                            )}
                            secondaryMessage={intl.formatMessage(
                                {
                                    id: 'invite.members.overflow_limit_secondary_label',
                                    defaultMessage: '{count} users were deleted - try calling them in a new invitation',
                                },
                                {count: emailsLimitExcess},
                            )}
                            onClose={withdrawEmailsLimitExcess}
                        />
                    )}
                    {failedToSendInvites && (
                        <Alert
                            className={styles.inputEmailsAlert}
                            size='medium'
                            type={AlertType.Danger}
                            message={intl.formatMessage({
                                id: 'invite.members.unable_to_invite_due_to_server_problems',
                                defaultMessage: 'Unable to invite users due to server problems',
                            })}
                            secondaryMessage={
                                intl.formatMessage({
                                    id: 'invite.members.try_contacting_admin',
                                    defaultMessage: 'Try contacting the administrator and sending an invitation later',
                                })
                            }
                        >
                            {buttonCopyToClipboard}
                        </Alert>
                    )}
                    {rateLimitIssues.length > 0 && rateLimitIssueTimeout > 0 && (
                        <Alert
                            className={styles.inputEmailsAlert}
                            size='medium' type={AlertType.Warning}
                            message={intl.formatMessage({
                                id: 'invite.members.rate_limit_issues_v1',
                                defaultMessage: 'We can send all invitations in {minutes, plural, =1 {# minute} other {# minutes}}',
                            }, {
                                minutes: rateLimitIssueTimeout,
                            })}
                            secondaryMessage={
                                rateLimitIssues[0]!.remaininSendings! > 0 ? intl.formatMessage({
                                    id: 'invite.members.invite_users_later_v1',
                                    defaultMessage: 'Invite users later or send up to {count, plural, =1 {# email} other {# emails}} now',
                                }, {count: rateLimitIssues[0]!.remaininSendings}) : intl.formatMessage({
                                    id: 'invite.members.invite_all_users_later',
                                    defaultMessage: 'Invite users later',
                                })
                            }
                        >
                            {buttonCopyToClipboard}
                        </Alert>
                    )}
                </div>
                <fieldset>
                    <legend className={classNames(styles.legend, bodyMTrueTypography)}>
                        {intl.formatMessage({
                            id: 'invite.members.access_parameters',
                            defaultMessage: 'Access parameters',
                        })}
                    </legend>
                    <Controller
                        name='role'
                        control={control}
                        render={({field}) => (
                            <Select
                                data-test-id='RoleOptions'
                                options={roleOptions}
                                initialValue={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                label={intl.formatMessage({
                                    id: 'invite.members.select_role',
                                    defaultMessage: 'Role',
                                })}
                            />
                        )}
                    />

                    {selectedRole === 'team_guest' && (
                        <>
                            <Controller
                                name='channels'
                                control={control}
                                rules={{
                                    validate: (value) => value.length > 0,
                                }}
                                render={({field}) => (
                                    <InputChannels
                                        data-test-id='InputChannels'
                                        className={styles.inputChannels}
                                        label={
                                            intl.formatMessage({
                                                id: 'invite.members.channels_label',
                                                defaultMessage: 'Channels',
                                            })
                                        }
                                        placeholder={intl.formatMessage({
                                            id: 'invite.members.channels_placeholder',
                                            defaultMessage: 'Add up to {count} channels',
                                        }, {count: channelsLimit})}
                                        noMatchesText={intl.formatMessage({
                                            id: 'invite.members.no_matches',
                                            defaultMessage: 'No matches',
                                        })}
                                        error={Boolean(formState.errors.channels)}
                                        limit={channelsLimit}
                                        channels={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        autocompleteChannels={autocompleteChannels}
                                        aria-errormessage={channelsErrorId}
                                    />
                                )}
                            />
                            <FormError
                                id={channelsErrorId}
                                className={styles.error}
                                show={Boolean(formState.errors.channels)}
                                message={intl.formatMessage(
                                    {id: 'invite.members.no_channels_error', defaultMessage: 'Add more channels'},
                                    {count: channelsLimit},
                                )}
                            />
                        </>
                    )}
                    {requestError && (
                        <Alert
                            className={styles.inputEmailsAlert}
                            size='medium'
                            type={AlertType.Danger}
                            message={intl.formatMessage({
                                id: 'invite.members.error.server_error.title',
                                defaultMessage: 'Something went wrong',
                            })}
                            secondaryMessage={intl.formatMessage({
                                id: 'invite.members.error.server_error.description',
                                defaultMessage: 'Try again later',
                            })}
                        />
                    )}
                </fieldset>
                <div className={styles.footer}>
                    <div data-test-id='CopyInviteLink' className={styles.copyInviteLinkWrapper}>
                        {selectedRole === 'team_user' &&
                            renderInviteMemberLink?.()
                        }
                    </div>
                    <Button data-test-id='CancelInviteByEmail' variant={BUTTON_VARIANT.TERTIARY} onPress={onCancel}>
                        {
                            intl.formatMessage({
                                id: 'invite.members.cancel',
                                defaultMessage: 'Cancel',
                            })
                        }
                    </Button>
                    <Button data-test-id='SubmitInviteByEmail' type='submit' loading={formState.isSubmitting} isDisabled={formState.isSubmitting}>
                        {
                            intl.formatMessage({
                                id: 'invite.members.send_invites',
                                defaultMessage: 'Send',
                            })
                        }
                    </Button>
                </div>
            </form>
        );
    };
