import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const ContentCopy = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            d='M19 21H8V7h11v14Zm0-16H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Zm-3-4H4a2 2 0 0 0-2 2v14h2V3h12V1Z'
            fill='currentColor'
        />
    </svg>
));
