import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function TildeIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon icon-tilde', className)} {...props}>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 16 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M1.33325 10C1.33325 10 1.33325 6 5.33325 6C7.99992 6 8.33325 8.33333 10.3333 8.33333C12.9999 8.33333 12.9999 6 12.9999 6H14.6666C14.6666 6 14.6666 10 10.6666 10C7.99992 10 6.99992 7.66667 5.66659 7.66667C2.99992 7.66667 2.99992 10 2.99992 10H1.33325Z'/>
            </svg>
        </span>
    );
}
