import {useMemo, useReducer} from 'react';

import {init, listBoxReducer} from './listbox-reducer';
import {ListBoxItem} from './listbox.types';

type Props<Payload = unknown> = {
    items: Array<ListBoxItem<Payload>>;
    selectedValue?: ListBoxItem['value'];
};

export const useListBoxState = <Payload = unknown>({items, selectedValue}: Props<Payload>): [state: typeof state, actions: typeof actions] => {
    const [state, dispatch] = useReducer<typeof listBoxReducer, {items: ListBoxItem[]; selectedValue?: ListBoxItem['value']}>(listBoxReducer, {items, selectedValue}, init);

    const actions = useMemo(() => ({
        onFocusItem: (item: ListBoxItem) => dispatch({type: 'focus_item', value: item.value}),
        onSelectItem: (item: ListBoxItem) => dispatch({type: 'select_item', value: item.value}),
        onClearFocus: () => dispatch({type: 'clear_focus'}),
        onFocusNextItem: () => dispatch({type: 'focus_next_item'}),
        onFocusPreviousItem: () => dispatch({type: 'focus_previous_item'}),
        onFocusFirstItem: () => dispatch({type: 'focus_first_item'}),
        onFocusLastItem: () => dispatch({type: 'focus_last_item'}),
        onReset: (items: ListBoxItem[], selectedValue?: ListBoxItem['value']) => dispatch({type: 'reset', items, selectedValue}),
    }), []);

    return [state, actions];
};
