import {getChannelAndMyMember, getChannelMembersByIds, getChannel as getChannelAction} from 'mattermost-redux/actions/channels';
import {savePreferences} from 'mattermost-redux/actions/preferences';
import {getTeamMembersByIds} from 'mattermost-redux/actions/teams';
import * as UserActions from 'mattermost-redux/actions/users';
import {Preferences as PreferencesRedux, General} from 'mattermost-redux/constants';
import {
    getChannel,
    getChannelMembersInChannels,
    getCurrentChannelId,
} from 'mattermost-redux/selectors/entities/channels';
import {getBool} from 'mattermost-redux/selectors/entities/preferences';
import {getCurrentTeamId, getTeamMember} from 'mattermost-redux/selectors/entities/teams';
import * as Selectors from 'mattermost-redux/selectors/entities/users';

import {loadStatusesForProfilesList} from 'actions/status_actions';

import {Preferences, UserStatuses} from 'utils/constants';
import {getUserIdFromChannelName} from 'utils/getUserIdFromChannelName';
import {reportErrorToSentry} from 'utils/sentry';
import {getGMChannelShowPreference} from 'features/sidebar/selectors/get_gm_channel_show_preference';
import {getDMChannelShowPreference} from 'features/sidebar/selectors/get_dm_channel_show_preference';
import {getUsersForGMChannels} from 'features/sidebar/actions/get_users_for_gm_channels';
import {getUsersForDMChannels} from 'features/sidebar/actions/get_users_for_dm_channels';

export function loadProfilesAndStatusesInChannel(
    channelId,
    page = 0,
    perPage = General.PROFILE_CHUNK_SIZE,
    sort = '',
    options = {},
) {
    return async (doDispatch) => {
        const {data} = await doDispatch(UserActions.getProfilesInChannel(channelId, page, perPage, sort, options));
        if (data) {
            doDispatch(loadStatusesForProfilesList(data));
        }
        return {data: true};
    };
}

export function loadProfilesAndReloadTeamMembers(page, perPage, teamId, options = {}) {
    return async (doDispatch, doGetState) => {
        const newTeamId = teamId || getCurrentTeamId(doGetState());
        const {data} = await doDispatch(UserActions.getProfilesInTeam(newTeamId, page, perPage, '', options));
        if (data) {
            await doDispatch(loadTeamMembersForProfilesList(data, newTeamId, true));
        }

        return {data: true};
    };
}

export function loadProfilesAndReloadChannelMembers(page, perPage, channelId, sort = '', options = {}) {
    return async (doDispatch, doGetState) => {
        const newChannelId = channelId || getCurrentChannelId(doGetState());
        const {data} = await doDispatch(UserActions.getProfilesInChannel(newChannelId, page, perPage, sort, options));
        if (data) {
            await doDispatch(loadChannelMembersForProfilesList(data, newChannelId, true));
        }

        return {data: true};
    };
}

export function loadProfilesAndTeamMembers(page, perPage, teamId, options) {
    return async (doDispatch, doGetState) => {
        const newTeamId = teamId || getCurrentTeamId(doGetState());
        const {data} = await doDispatch(UserActions.getProfilesInTeam(newTeamId, page, perPage, '', options));
        if (data) {
            await doDispatch(loadTeamMembersForProfilesList(data, newTeamId));
        }

        return {data: true};
    };
}

export function searchProfilesAndTeamMembers(term = '', options = {}) {
    return async (doDispatch, doGetState) => {
        const newTeamId = options.team_id || getCurrentTeamId(doGetState());
        const {data} = await doDispatch(UserActions.searchProfiles(term, options));
        if (data) {
            await doDispatch(loadTeamMembersForProfilesList(data, newTeamId));
        }

        return {data: true};
    };
}

export function searchProfilesAndChannelMembers(term, options = {}) {
    return async (doDispatch, doGetState) => {
        const newChannelId = options.in_channel_id || getCurrentChannelId(doGetState());
        const {data} = await doDispatch(UserActions.searchProfiles(term, options));
        if (data) {
            await doDispatch(loadChannelMembersForProfilesList(data, newChannelId));
        }

        return {data: true};
    };
}

export function loadProfilesAndTeamMembersAndChannelMembers(page, perPage, teamId, channelId, options) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const teamIdParam = teamId || getCurrentTeamId(state);
        const channelIdParam = channelId || getCurrentChannelId(state);
        const {data} = await doDispatch(UserActions.getProfilesInChannel(channelIdParam, page, perPage, '', options));
        if (data) {
            const {data: listData} = await doDispatch(loadTeamMembersForProfilesList(data, teamIdParam));
            if (listData) {
                doDispatch(loadChannelMembersForProfilesList(data, channelIdParam));
            }
        }

        return {data: true};
    };
}

export function loadTeamMembersForProfilesList(profiles, teamId, reloadAllMembers = false) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const teamIdParam = teamId || getCurrentTeamId(state);
        const membersToLoad = {};
        for (let i = 0; i < profiles.length; i++) {
            const pid = profiles[i].id;

            if (reloadAllMembers === true || !getTeamMember(state, teamIdParam, pid)) {
                membersToLoad[pid] = true;
            }
        }

        const userIdsToLoad = Object.keys(membersToLoad);
        if (userIdsToLoad.length === 0) {
            return {data: true};
        }

        await doDispatch(getTeamMembersByIds(teamIdParam, userIdsToLoad));

        return {data: true};
    };
}

export function loadProfilesWithoutTeam(page, perPage, options) {
    return async (doDispatch) => {
        const {data} = await doDispatch(UserActions.getProfilesWithoutTeam(page, perPage, options));

        return data;
    };
}

export function loadTeamMembersAndChannelMembersForProfilesList(profiles, teamId, channelId) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const teamIdParam = teamId || getCurrentTeamId(state);
        const channelIdParam = channelId || getCurrentChannelId(state);
        const {data} = await doDispatch(loadTeamMembersForProfilesList(profiles, teamIdParam));
        if (data) {
            doDispatch(loadChannelMembersForProfilesList(profiles, channelIdParam));
        }

        return {data: true};
    };
}

export function loadChannelMembersForProfilesList(profiles, channelId, reloadAllMembers = false) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const channelIdParam = channelId || getCurrentChannelId(state);
        const membersToLoad = {};
        for (let i = 0; i < profiles.length; i++) {
            const pid = profiles[i].id;

            const members = getChannelMembersInChannels(state)[channelIdParam];
            if (reloadAllMembers === true || !members || !members[pid]) {
                membersToLoad[pid] = true;
            }
        }

        const list = Object.keys(membersToLoad);
        if (list.length === 0) {
            return {data: true};
        }

        await doDispatch(getChannelMembersByIds(channelIdParam, list));
        return {data: true};
    };
}

export function loadNewDMIfNeeded(channelId, isMyMemberFetched = false) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const currentUserId = Selectors.getCurrentUserId(state);

        const channel = getChannel(state, channelId);

        if (!channel) {
            if (isMyMemberFetched) {
                await doDispatch(getChannelAction(channelId));
            } else {
                await doDispatch(getChannelAndMyMember(channelId));
            }
        }

        const updatedState = doGetState();

        const updatedChannel = getChannel(updatedState, channelId);

        const teammateId = getUserIdFromChannelName(updatedChannel, currentUserId);

        if (!teammateId) {
            return;
        }

        const pref = getDMChannelShowPreference(state, teammateId, false);

        if (pref) {
            return;
        }

        const now = Date.now();

        await doDispatch(
            savePreferences(currentUserId, [
                {
                    user_id: currentUserId,
                    category: Preferences.CATEGORY_DIRECT_CHANNEL_SHOW,
                    name: teammateId,
                    value: 'true',
                },
                {
                    user_id: currentUserId,
                    category: Preferences.CATEGORY_CHANNEL_OPEN_TIME,
                    name: channelId,
                    value: now.toString(),
                },
            ]),
        );
        await doDispatch(getUsersForDMChannels());
    };
}

export function loadNewGMIfNeeded(channelId, isMyMemberFetched = false) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const currentUserId = Selectors.getCurrentUserId(state);

        const channel = getChannel(state, channelId);

        if (!channel) {
            if (isMyMemberFetched) {
                await doDispatch(getChannelAction(channelId));
            } else {
                await doDispatch(getChannelAndMyMember(channelId));
            }
        }

        const updatedState = doGetState();
        const pref = getGMChannelShowPreference(updatedState, channelId, false);

        if (pref) {
            return;
        }

        await doDispatch(
            savePreferences(currentUserId, [
                {
                    user_id: currentUserId,
                    category: Preferences.CATEGORY_GROUP_CHANNEL_SHOW,
                    name: channelId,
                    value: 'true',
                },
            ]),
        );
        await doDispatch(getUsersForGMChannels());
    };
}

export function loadProfilesForGroupChannels(groupChannels, fetchOptions = {}) {
    return (doDispatch, doGetState) => {
        const state = doGetState();
        const userIdsInChannels = Selectors.getUserIdsInChannels(state);

        const groupChannelsToFetch = groupChannels.reduce((acc, {id}) => {
            const userIdsInGroupChannel = userIdsInChannels[id] || new Set();

            if (userIdsInGroupChannel.size === 0) {
                acc.push(id);
            }
            return acc;
        }, []);

        if (groupChannelsToFetch.length > 0) {
            doDispatch(UserActions.getProfilesInGroupChannels(groupChannelsToFetch, fetchOptions));
            return {data: true};
        }

        return {data: false};
    };
}

export function autocompleteUsersInTeam(username) {
    return async (doDispatch, doGetState) => {
        const currentTeamId = getCurrentTeamId(doGetState());
        const {data} = await doDispatch(UserActions.autocompleteUsers(username, currentTeamId));
        return data;
    };
}

export function autocompleteUsers(username) {
    return async (doDispatch) => {
        const {data} = await doDispatch(UserActions.autocompleteUsers(username));
        return data;
    };
}

export function autoResetStatus() {
    return async (doDispatch, doGetState) => {
        const state = doGetState();

        const currentUserId = Selectors.getCurrentUserId(state);

        const {data, error} = await doDispatch(UserActions.getStatus(currentUserId));

        /**
         * @type {import('@mattermost/types/users').UserStatus}
         */
        let userStatus = data;

        // @see https://time-sentry.tinkoff.ru/organizations/sentry/issues/462/
        if (!userStatus) {
            reportErrorToSentry(error);

            /**
             * Так как запрос провалился,
             * мы пытаемся сами построить инфу о статусе пользователя
             */
            userStatus = {
                status: Selectors.getStatusForUserId(state, currentUserId),
                manual: Selectors.getIsManualStatusForUserId(state, currentUserId),
                user_id: currentUserId,
            };
        }

        if (userStatus.status === UserStatuses.OUT_OF_OFFICE || !userStatus.manual) {
            return userStatus;
        }

        const autoReset = getBool(
            doGetState(),
            PreferencesRedux.CATEGORY_AUTO_RESET_MANUAL_STATUS,
            currentUserId,
            false,
        );

        if (autoReset) {
            doDispatch(UserActions.setStatus({user_id: currentUserId, status: 'online'}));
            return userStatus;
        }

        return userStatus;
    };
}
