import {ComponentProps, memo} from 'react';

import classNames from 'classnames';

import {DropdownListItem} from '../dropdown';

import {LockOutlineIcon, TildeIcon} from '../../icons';

import {TypographySize, useTypography} from '../../hooks/typography';

import {InputChannels} from './input-channels';

import styles from './dropdown-channel-list-item.module.css';

type Channel = ComponentProps<typeof InputChannels>['channels'][number];

type Props = {
    channel: Channel;
    isFocused?: boolean;
};

const DropdownChannelListItemInternal = ({channel, isFocused}: Props) => {
    const [bodyLTypography, bodySTypography] = useTypography([
        {size: TypographySize.BodyL},
        {size: TypographySize.BodyS},
    ]);
    return (
        <DropdownListItem
            icon={<span className={styles.icon}>{getIcon(channel)}</span>}
            label={
                <>
                    <span className={bodyLTypography}>{channel.display_name}</span>
                    <span className={classNames(bodySTypography, styles.channelName)}>{`~${channel.name}`}</span>
                </>
            }
            focused={isFocused}
        />
    );
};

function getIcon(channel: Channel) {
    if (channel.type === 'P') {
        return <LockOutlineIcon width={16} height={16} />;
    }
    return <TildeIcon width={16} height={16} />;
}

export const DropdownChannelListItem = memo(DropdownChannelListItemInternal);
DropdownChannelListItem.displayName = 'DropdownChannelListItem';
