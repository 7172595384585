import {useRef} from 'react';
import {useTooltipTriggerState} from '@react-stately/tooltip';
import {useTooltipTrigger} from '@react-aria/tooltip';

const DEFAULT_DELAY = 400;

export const useTooltip = (delay: number = DEFAULT_DELAY) => {
    const tooltipState = useTooltipTriggerState({delay});
    const triggerRef = useRef<HTMLElement>(null);
    const {triggerProps, tooltipProps} = useTooltipTrigger({}, tooltipState, triggerRef);

    return {
        tooltipState,
        triggerRef,
        triggerProps,
        tooltipProps,
    };
};
