export enum Marks {
    CHANNEL_LINK_CLICKED = 'channel_link_clicked',
    CHANNEL_LOADING_STARTED = 'channel_loading_started',
    CHANNEL_LOADING_FINISHED = 'channel_loading_finished',
    CHANNEL_OPENED = 'channel_opened',
    THREAD_LINK_CLICKED = 'thread_link_clicked',
    THREAD_LOADING_STARTED = 'thread_loading_started',
    THREAD_LOADING_FINISHED = 'thread_loading_finished',
    THREAD_OPENED = 'thread_opened',
    MESSAGE_SENT = 'message_sent',
    MESSAGE_LOADING_STARTED = 'message_loading_started',
    MESSAGE_LOADING_FINISHED = 'message_loading_finished',
    MESSAGE_SHOWN = 'message_shown',
    APP_LOADING_STARTED = 'app_loading_started',
    APP_LOADING_FINISHED = 'app_loading_finished',
}
