import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import LocalStorageStore from 'stores/local_storage_store';

const ENABLE_ANALITICS_KEY = 'ENABLE_ANALITICS';

// setPreviousTeamId is a pseudo-action that writes not to the Redux store, but back to local
// storage.
//
// See LocalStorageStore for context.
export function setPreviousTeamId(teamId) {
    return (dispatch, getState) => {
        const state = getState();
        const userId = getCurrentUserId(state);

        LocalStorageStore.setPreviousTeamId(userId, teamId, state);

        return {data: true};
    };
}

export function setCurrentTeamId(teamId) {
    return (_, getState) => {
        const state = getState();
        const userId = getCurrentUserId(state);

        LocalStorageStore.setCurrentTeamId(userId, teamId, state);

        return {data: true};
    };
}

export function getEnableAnalitics() {
    const enableAnalytics = localStorage.getItem(ENABLE_ANALITICS_KEY);

    if (!enableAnalytics) {
        return true;
    }

    return enableAnalytics === 'true';
}

export function setEnableAnalitics(value) {
    if (!value) {
        return;
    }
    localStorage.setItem(ENABLE_ANALITICS_KEY, value);
}
