import {ComponentProps, ReactNode} from 'react';

import {ListBoxItem} from '../listbox';

import {InputAutocomplete} from './input-autocomplete';
import {useInputAutocompleteState} from './use-input-autocomplete-state';
import {InputAutocompleteContext} from './input-autocompltete-context';

type Props<Payload = unknown> = Omit<ComponentProps<typeof InputAutocomplete>, 'items' | 'autocompleteItems' | 'onAddItem' | 'onSelectItem' | 'renderItem'> & {
    items: Array<ListBoxItem<Payload>>;
    autocompleteItems: Array<ListBoxItem<Payload>>;
    limit?: number;
    onChange?: (nextItems: ListBoxItem[]) => void;
    renderItem: (item: ListBoxItem<Payload>, isFocused?: boolean, isSelected?: boolean) => ReactNode;
}

const DEFAULT_LIMIT = 100;

export const InputAutocompleteConnected = <Payload = unknown>({items, limit = DEFAULT_LIMIT, children, onChange, ...props}: Props<Payload>) => {
    const context = useInputAutocompleteState({items, limit, onChange});

    return (
        <InputAutocomplete
            {...props}
            items={items}
            limit={context.limit}
            selectedItem={context.selectedItem}
            newTagKey={context.newTagKey}
            onAddItem={context.addItem}
            onSelectItem={context.selectItem}
        >
            <InputAutocompleteContext.Provider value={context}>
                {children}
            </InputAutocompleteContext.Provider>
        </InputAutocomplete>
    );
};
