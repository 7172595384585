import {DateTime} from 'luxon';

// renderer rules: https://gist.github.com/LeviSnoot/d9147767abeef2f770e9ddcd91eb85aa

export const customTimestampRenderer = (timestamp: number, type?: string) => {
    let result;
    switch (type) {
    case 't': {
        result = DateTime.fromMillis(timestamp).toLocaleString(DateTime.TIME_SIMPLE);
        break;
    }
    case 'T': {
        result = DateTime.fromMillis(timestamp).toLocaleString(DateTime.TIME_WITH_SECONDS);
        break;
    }
    case 'd': {
        result = DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATE_SHORT);
        break;
    }
    case 'D': {
        result = DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATE_FULL);
        break;
    }
    case 'f': {
        result = `${DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATE_FULL)} ${DateTime.fromMillis(
            timestamp,
        ).toLocaleString(DateTime.TIME_SIMPLE)}`;
        break;
    }
    case 'F': {
        result = `${DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATE_HUGE)} ${DateTime.fromMillis(
            timestamp,
        ).toLocaleString(DateTime.TIME_SIMPLE)}`;
        break;
    }
    case 'R': {
        result = DateTime.fromMillis(timestamp).toRelative();
        break;
    }
    default: {
        result = `${DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATE_FULL)} ${DateTime.fromMillis(
            timestamp,
        ).toLocaleString(DateTime.TIME_SIMPLE)}`;
    }
    }

    return '`' + result + '`';
};
