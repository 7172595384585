import {createContext, useContext} from 'react';

import {useInputTagState} from './use-input-tag-state';

type InputTagContextType = ReturnType<typeof useInputTagState>

export const InputTagContext = createContext<InputTagContextType | undefined>(undefined);

export const useInputTagContext = () => {
    const value = useContext(InputTagContext);
    if (!value) {
        throw new Error('InputTagContext not found.');
    }
    return value;
};
