
import {TypographySize, useTypography} from '../../hooks/typography';

import {InputSize} from './input.types';

export const useInputTypography = (size: InputSize) =>
    useTypography({size: getTypographySize(size), noDefaultColor: true});

function getTypographySize(size: InputSize) {
    switch (size) {
    case 'medium':
        return TypographySize.BodyM;
    case 'large':
        return TypographySize.BodyL;
    default:
        return TypographySize.BodyM;
    }
}
