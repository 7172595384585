import {ForwardedRef, InputHTMLAttributes, ChangeEvent, forwardRef} from 'react';

import classNames from 'classnames';

import styles from './input-control.module.css';
import {InputSize} from './input.types';
import {useInputTypography} from './use-input-typography';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value' | 'onChange' | 'onInput' | 'prefix' | 'postfix'> & {
    size: InputSize;
    value?: string;
    error?: boolean;
    prefix?: React.ReactNode;
    postfix?: React.ReactNode;
    onChange?: (value: string) => void;
};

export const InputControl = forwardRef(
    ({className, value, size, error, prefix, postfix, onChange, ...props}: Props, ref: ForwardedRef<HTMLInputElement>) => {
        const typographyStyle = useInputTypography(size);
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value);
        };

        return (
            <span className={classNames(styles.root, className)}>
                {prefix}
                <input
                    {...props}
                    ref={ref}
                    value={value}
                    onInput={handleChange}
                    aria-invalid={Boolean(error)}
                    className={classNames(styles.input, typographyStyle, {[styles.error]: error})}
                />
                {postfix}
            </span>
        );
    },
);

InputControl.displayName = 'InputControl';
