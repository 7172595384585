import {createSelector} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {RootState} from 'stores/redux_store';

import {getChannelChannelCategories} from './get_channel_channel_categories';

export const getChannelChannelCategoriesForTeam = createSelector(
    getChannelChannelCategories,
    (_: RootState, channel: Channel, teamId: string) => teamId,
    (categories, teamId) => {
        /**
         * DM/GM channels have empty team_id for now
         * But, we will account for future
         */
        if (!teamId) {
            return categories;
        }

        return categories.filter((category) => category.team_id === teamId);
    },
);
