import {AxiosInstance, isAxiosError} from 'axios';
import {handleHttpError} from 'packages/http/src';

export function addHttpErrorInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (isAxiosError(error) && error.response) {
                handleHttpError(error.response.status);
            }
            return Promise.reject(error);
        },
    );
}
