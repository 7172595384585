import {createAsyncThunk} from '@reduxjs/toolkit';

import Queue from 'p-queue';

import {getCurrentChannelId, getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import type {AppDispatch, RootState} from 'stores/redux_store';

import type {PostedEvent} from '../types/posted_event';

import {parsePostedEvent} from '../utils/parse_posted_event';

import {getSelectedPostId} from 'selectors/rhs';

import {ChannelType} from 'features/sidebar';

import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';

import {handlePostedEvent} from './handle_new_post_event';

const newPostsQueue = new Queue({concurrency: 4, intervalCap: 4, interval: 500});

function getPostPriority(postedEvent: PostedEvent, state: RootState) {
    let priority = 0;

    const parsedPostedEvent = parsePostedEvent(postedEvent);
    const currentChannelId = getCurrentChannelId(state);
    const currentUserId = getCurrentUserId(state);
    const currentTeamId = getCurrentTeamId(state);
    const postChannelType = parsedPostedEvent.data.channel_type;
    const postTeamId = parsedPostedEvent.data.team_id;
    const postMentions = parsedPostedEvent.data.mentions;
    const postFollowers = parsedPostedEvent.data.followers;
    const selectedThreadRootPostId = getSelectedPostId(state);

    const postChannelId = parsedPostedEvent.data.post.channel_id;
    const postRootId = parsedPostedEvent.data.post.root_id;

    /**
     * Если пост в текущей команде
     */
    if (currentTeamId === postTeamId) {
        priority += 1;
    }

    /**
     * Если пост в текущем канале
     */
    if (currentChannelId === postChannelId) {
        priority += 1;
    }

    /**
     * Если пост в показываемом треде
     */
    if (postRootId === selectedThreadRootPostId) {
        priority += 1;
    }

    /**
     * Если пост в личке и групповом чате
     */
    if (postChannelType === ChannelType.DM || postChannelType === ChannelType.GM) {
        priority += 3;
    }

    /**
     * Если в посте упомянут есть текущий пользователь
     */
    if (postMentions?.includes(currentUserId)) {
        priority += 2;
    }

    /**
     * Если текущий пользователь следит за постом
     */
    if (postFollowers?.includes(currentUserId)) {
        priority += 1;
    }

    return priority;
}

export const handleNewPostEventWithQueue = createAsyncThunk(
    'posts/actions/handleNewPostEventWithQueue',
    // eslint-disable-next-line consistent-return
    async (payload: PostedEvent, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const currentUserId = getCurrentUserId(state);

        const parsedPostedEvent = parsePostedEvent(payload);

        const postCreatorId = parsedPostedEvent.data.post.user_id;
        const isCurrentUserPost = postCreatorId === currentUserId;

        const priority = getPostPriority(payload, state);

        if (isCurrentUserPost) {
            /**
             * Посты пользователя кидаем мимо очереди,
             * чтобы они всегда попадали куда нужно
             */
            dispatch(handlePostedEvent(payload));
            return;
        }

        await newPostsQueue.add(async () => dispatch(handlePostedEvent(payload)), {
            priority,
        });
    },
);
