import React from 'react';
import classnames from 'classnames';

import {useTypography} from '../../../hooks/typography';

import type {Props} from './types';
import {LEVEL_TO_HEADING_TYPOGRAPHY} from './constants';

export const Heading: React.FunctionComponent<Props> = (props) => {
    const {level, children, customTag, size = LEVEL_TO_HEADING_TYPOGRAPHY[level], className: passedClassName} = props;

    const headingClassName = useTypography({
        size,
    });

    const className = classnames([passedClassName, headingClassName]);

    const HeadingTag = customTag || level;

    return <HeadingTag className={className}>{children}</HeadingTag>;
};
