import type {
    ThreadReadChangedInMarkChannelAsViewedEvent,
    ThreadReadForUserEvent,
    ThreadsReadForUserEvent,
} from '../types/thread_read_changed_event';

export function isThreadReadChangedInMarkChannelAsViewedEvent(
    event: ThreadReadChangedInMarkChannelAsViewedEvent | ThreadReadForUserEvent | ThreadsReadForUserEvent,
): event is ThreadReadChangedInMarkChannelAsViewedEvent {
    if (event.data.timestamp && !event.broadcast.team_id) {
        return true;
    }

    return false;
}

export function isThreadReadForUserEvent(
    event: ThreadReadChangedInMarkChannelAsViewedEvent | ThreadReadForUserEvent | ThreadsReadForUserEvent,
): event is ThreadReadForUserEvent {
    if ('thread_id' in event.data && !event.broadcast.channel_id) {
        return true;
    }

    return false;
}
export function isThreadsReadForUserEvent(
    event: ThreadReadChangedInMarkChannelAsViewedEvent | ThreadReadForUserEvent | ThreadsReadForUserEvent,
): event is ThreadsReadForUserEvent {
    if (!('thread_id' in event.data) && !event.broadcast.channel_id) {
        return true;
    }

    return false;
}
