import {AnyAction} from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';

import type {GlobalState} from 'types/store';

export function createThunkAction<R = unknown>(
    /**
     * Name of the action
     *
     * @todo reserved for later
     */
    name: string,

    /**
     * Actual thunk
     */
    callback: ThunkAction<R, GlobalState, undefined, AnyAction>,
) {
    return callback;
}
