import {createSelector} from '@reduxjs/toolkit';

import type {RootState} from 'stores/redux_store';
import type {Channel} from '@mattermost/types/channels';

export const getReadOnlyStateByChannelId = createSelector(
    (state: RootState) => state.readOnlyChannels,
    (_: RootState, channelId: Channel['id']) => channelId,
    (readOnlyChannels, channelId): boolean => readOnlyChannels[channelId] ?? false,
);
