
import {ComponentType, PropsWithChildren, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {AppDispatch} from 'stores/redux_store';

export const withResolve =
    <Props extends PropsWithChildren<object>>(resolve: (dispatch: AppDispatch) => Promise<void>, Component: ComponentType<Omit<Props, 'children'>>) =>
        ({children, ...props}: Props): JSX.Element => {
            const dispatch = useDispatch();

            const [isLoaded, setLoaded] = useState(false);

            useEffect(() => {
                let ignore = false;
                async function init() {
                    try {
                        await resolve(dispatch);
                    } finally {
                        if (!ignore) {
                            setLoaded(true);
                        }
                    }
                }
                if (!isLoaded) {
                    init();
                }
                return () => {
                    ignore = true;
                };
            }, [dispatch, isLoaded]);

            if (isLoaded) {
                return <>{children}</>;
            }

            return <Component {...props} />;
        };
