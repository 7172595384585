import {createAction} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {Team} from '@mattermost/types/teams';

type Payload = {
    channelId: Channel['id'];

    /**
     * This is used to update thread unread messages and mentions counts
     *
     * Defaults to current team id
     */
    fallbackTeamId: Team['id'];
};

export const removeChannelThreads = createAction<Payload>('threads/actions/removeChannelThreads');
