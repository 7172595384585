import {createSlice} from '@reduxjs/toolkit';

import type {Channel, ChannelModeration} from '@mattermost/types/channels';
import {getModerationsForChannel} from '../actions/get_moderations_for_channel';
import {updateModerationsForChannel} from '../actions/update_moderations_for_channel';

export const channelPermissionsSlice = createSlice({
    name: 'channelPermissions',
    initialState: {
        channelPermissions: {} as {
            [channelId: Channel['id']]: ChannelModeration[];
        },
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getModerationsForChannel.pending, (state, action) => {
            state.channelPermissions[action.meta.arg.id] = [] as ChannelModeration[];
            state.loading = true;
        });
        builder.addCase(getModerationsForChannel.fulfilled, (state, action) => {
            state.channelPermissions[action.meta.arg.id] = action.payload;
            state.loading = false;
        });
        builder.addCase(updateModerationsForChannel.fulfilled, (state, action) => {
            state.channelPermissions[action.meta.arg.channelId] = action.payload;
        });
    },
});
