import {TypographySize, useTypography} from '../../../hooks/typography';

import {AlertSize} from '.';

export const useAlertTypography = (size: AlertSize) => {
    const [bodyXLTrueTypography, bodyLTrueTypography, bodyLTypography, bodyMTypography] = useTypography([
        {
            size: TypographySize.BodyXLTrue,
        }, {
            size: TypographySize.BodyLTrue,
        },
        {
            size: TypographySize.BodyL,
        }, {
            size: TypographySize.BodyM,
        }]);
    switch (size) {
    case 'medium':
        return [bodyLTrueTypography, bodyMTypography];
    case 'large':
        return [bodyXLTrueTypography, bodyLTypography];
    }
};
