/* eslint-disable react/require-optimization */
import React from 'react';

import {clearErrorScreenTimeout} from 'utils/error_screen';

const changedArray = (a: unknown[] = [], b: unknown[] = []) =>
    a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

interface FallbackProps {
    error: Error;
    resetErrorBoundary: (...args: unknown[]) => void;
}

interface ErrorBoundaryPropsWithComponent {
    onResetKeysChange?: (prevResetKeys: unknown[] | undefined, resetKeys: unknown[] | undefined) => void;
    onReset?: (...args: unknown[]) => void;
    onError?: (error: Error, info: {componentStack: string}) => void;
    resetKeys?: unknown[];
    fallback?: never;
    FallbackComponent: React.ComponentType<FallbackProps>;
    fallbackRender?: never;
}

declare function FallbackRender(
    props: FallbackProps,
): React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null;

interface ErrorBoundaryPropsWithRender {
    onResetKeysChange?: (prevResetKeys: unknown[] | undefined, resetKeys: unknown[] | undefined) => void;
    onReset?: (...args: unknown[]) => void;
    onError?: (error: Error, info: {componentStack: string}) => void;
    resetKeys?: unknown[];
    fallback?: never;
    FallbackComponent?: never;
    fallbackRender: typeof FallbackRender;
}

interface ErrorBoundaryPropsWithFallback {
    onResetKeysChange?: (prevResetKeys: unknown[] | undefined, resetKeys: unknown[] | undefined) => void;
    onReset?: (...args: unknown[]) => void;
    onError?: (error: Error, info: {componentStack: string}) => void;
    resetKeys?: unknown[];
    fallback: React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null;
    FallbackComponent?: never;
    fallbackRender?: never;
}

type ErrorBoundaryProps =
    | ErrorBoundaryPropsWithFallback
    | ErrorBoundaryPropsWithComponent
    | ErrorBoundaryPropsWithRender;

type ErrorBoundaryState = {error: Error | null};

const initialState: ErrorBoundaryState = {error: null};

type BoundaryComponentProps = React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>;

class ErrorBoundary extends React.Component<BoundaryComponentProps, ErrorBoundaryState> {
    static getDerivedStateFromError(error: Error) {
        return {error};
    }

    state = initialState;
    resetErrorBoundary = (...args: unknown[]) => {
        this.props.onReset?.(...args);
        this.reset();
    };

    reset() {
        this.setState(initialState);
    }

    componentDidMount() {
        window.addEventListener('error',
            (event) => {
                // Игнорируем ошибки ResizeObserver
                // https://github.com/adobe/react-spectrum/issues/1924
                if (event.message === 'ResizeObserver loop limit exceeded' || event.message === 'ResizeObserver loop completed with undelivered notifications.') {
                    return;
                }

                this.setState({
                    error: {
                        message: event.message,
                        name: event.message,
                    },
                });
            });

        window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
            this.setState({
                error: event.reason,
            });
        });
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.props.onError?.(error, info);
    }

    componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
        const {error} = this.state;
        const {resetKeys} = this.props;

        if (error) {
            // Если словили ошибку - ненадо ещё и показывать сообщение о проблемах с коннектом
            clearErrorScreenTimeout();
        }

        // There's an edge case where if the thing that triggered the error
        // happens to *also* be in the resetKeys array, we'd end up resetting
        // the error boundary immediately. This would likely trigger a second
        // error to be thrown.
        // So we make sure that we don't check the resetKeys on the first call
        // of cDU after the error is set

        if (error !== null && prevState.error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
            this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys);
            this.reset();
        }
    }

    render() {
        const {error} = this.state;

        const {fallbackRender, FallbackComponent, fallback} = this.props;

        if (error !== null) {
            const props = {
                error,
                resetErrorBoundary: this.resetErrorBoundary,
            };
            if (React.isValidElement(fallback)) {
                return fallback;
            } else if (typeof fallbackRender === 'function') {
                return fallbackRender(props);
            } else if (FallbackComponent) {
                return <FallbackComponent {...props} />;
            }
            throw new Error(
                'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
            );
        }

        return this.props.children;
    }
}

function withErrorBoundary<P>(
    Component: React.ComponentType<P>,
    errorBoundaryProps: ErrorBoundaryProps,
): React.ComponentType<P> {
    const Wrapped: React.ComponentType<P> = (props) => {
        return (
            <ErrorBoundary {...errorBoundaryProps}>
                <Component {...props} />
            </ErrorBoundary>
        );
    };

    // Format for display in DevTools
    const name = Component.displayName || Component.name || 'Unknown';
    Wrapped.displayName = `withErrorBoundary(${name})`;

    return Wrapped;
}

function useErrorHandler(givenError?: unknown): (error: unknown) => void {
    const [error, setError] = React.useState<unknown>(null);
    if (givenError != null) {
        throw givenError;
    }
    if (error != null) {
        throw error;
    }
    return setError;
}

export {ErrorBoundary, withErrorBoundary, useErrorHandler};
export type {
    FallbackProps,
    ErrorBoundaryPropsWithComponent,
    ErrorBoundaryPropsWithRender,
    ErrorBoundaryPropsWithFallback,
    ErrorBoundaryProps,
};

/*
eslint
  @typescript-eslint/sort-type-union-intersection-members: "off",
  @typescript-eslint/no-throw-literal: "off",
  @typescript-eslint/prefer-nullish-coalescing: "off"
*/
