import {BUTTON_SIZE} from './constants';

export function useSpinnerSize(size: BUTTON_SIZE) {
    switch (size) {
    case BUTTON_SIZE.LARGE:
    case BUTTON_SIZE.MEDIUM:
        return 20;
    case BUTTON_SIZE.SMALL:
    case BUTTON_SIZE.XSMALL:
        return 16;
    }
}
