import {CSSProperties, memo} from 'react';
import {useIntl} from 'react-intl';

import {Spinner} from '@time-webkit/all/atoms/spinner';

type Props = {
    position?: CSSProperties['position'];
    style?: CSSProperties;
    message?: string;
};

const LoadingScreen = memo<Props>((props) => {
    const {formatMessage} = useIntl();

    const defaultMessage = formatMessage({
        id: 'loading_screen.loading',
        defaultMessage: 'Loading',
    });

    const {position = 'relative', message = defaultMessage, style = {}} = props;

    return (
        <div
            className='loading-screen'
            style={{position, ...style}}
            data-testid='LoadingScreen'
        >
            <div
                aria-label={message}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Spinner />
            </div>
        </div>
    );
});

LoadingScreen.displayName = 'LoadingScreen';

export default LoadingScreen;
