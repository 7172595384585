export enum LogLevel {
    Error = 'ERROR',
    Warning = 'WARNING',
    Info = 'INFO',
    Debug = 'DEBUG',
}

export type ClientResponse<T> = {
    response: Response;
    headers: Map<string, string>;
    data: T;
};

type ErrorOffline = {
    message: string;
    url: string;
};
type ErrorInvalidResponse = {
    intl: {
        id: string;
        defaultMessage: string;
    };
};
export type ErrorApi = {
    id: string;
    message: string;
    detailed_error: string;
    request_id: string;
    status_code: number;
};
export type Client4Error = ErrorOffline | ErrorInvalidResponse | ErrorApi;

export type Options = {
    headers?: {[x: string]: string};
    method?: string;
    url?: string;
    credentials?: 'omit' | 'same-origin' | 'include';
    body?: any;
    retries?: number;
    signal?: AbortSignal;
};

export type StatusOK = {
    status: 'OK';
};
