import React from 'react';

import {LoadingImagePreview} from './loading-image-preview';

import styles from './styles.module.css';

export type SizeConstants = {
    maxWidth: number;
    maxHeight: number;
    minWidth: number;
    minHeight: number;
    smallImageWidth: number;
    smallImageHeight: number;
    minImageSize: number;
    minImageSizeForInternalButton: number;
};

const getPlaceholderSize = (originalWidth: number, originalHeight: number, constants: SizeConstants) => {
    let width = originalWidth;
    let height = originalHeight;

    if (width <= constants.minWidth && height <= constants.minHeight) {
        return {
            width: constants.smallImageWidth,
            height: constants.smallImageHeight,
        };
    }

    const ratio = width / height;

    if (width > constants.maxWidth) {
        width = constants.maxWidth;
        height = width / ratio;
    }

    if (height > constants.maxHeight) {
        height = constants.maxHeight;
        width = height * ratio;
    }

    return {
        width,
        height,
    };
};

export type Props = {
    dimensions: {
        width: number;
        height: number;
    };
    className?: string;
    sizeConstants: SizeConstants;
};

export const Placeholder = ({dimensions, className, sizeConstants}: Props) => {
    const {width, height} = getPlaceholderSize(dimensions.width, dimensions.height, sizeConstants);

    return (
        <div className={className}>
            <div className={styles.loadingPreviewWrapper}>
                <LoadingImagePreview />
            </div>
            <div style={{width, height}} />
        </div>
    );
};
