/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React from 'react';

import debounce from '@tinkoff/utils/function/debounce';

import classNames from 'classnames';

import EventEmitter from 'mattermost-redux/utils/event_emitter';

import QuickInput from 'components/quick_input';
import Constants from 'utils/constants';
import * as UserAgent from 'utils/user_agent';
import * as Utils from 'utils/utils';
import {sendToStatist} from '@time-webkit/statist';
const EXECUTE_CURRENT_COMMAND_ITEM_ID = Constants.Integrations.EXECUTE_CURRENT_COMMAND_ITEM_ID;
const OPEN_COMMAND_IN_MODAL_ITEM_ID = Constants.Integrations.OPEN_COMMAND_IN_MODAL_ITEM_ID;
const KeyCodes = Constants.KeyCodes;

export default class SuggestionBox extends React.PureComponent {
    static get propTypes() {
        return {

            /**
             * The list component to render, usually SuggestionList
             */
            listComponent: PropTypes.func.isRequired,

            /**
             * Where the list will be displayed relative to the input box, defaults to 'top'
             */
            listPosition: PropTypes.oneOf(['top', 'bottom']),

            /**
             * The input component to render (it is passed through props to the QuickInput)
             */
            inputComponent: PropTypes.elementType,

            /**
             * The date component to render
             */
            dateComponent: PropTypes.func,

            /**
             * The value of in the input
             */
            value: PropTypes.string.isRequired,

            /**
             * Array of suggestion providers
             */
            providers: PropTypes.arrayOf(PropTypes.object).isRequired,

            /**
             * CSS class for the div parent of the input box
             */
            containerClass: PropTypes.string,

            /**
             * Set to true to draw dividers between types of list items, defaults to false
             */
            renderDividers: PropTypes.bool,

            /**
             * Set to true to render a message when there were no results found, defaults to false
             */
            renderNoResults: PropTypes.bool,

            /**
             * Set to true if we want the suggestions to take in the complete word as the pretext, defaults to false
             */
            shouldSearchCompleteText: PropTypes.bool,

            /**
             * Set to allow TAB to select an item in the list, defaults to true
             */
            completeOnTab: PropTypes.bool,

            /**
             * Function called when input box gains focus
             */
            onFocus: PropTypes.func,

            /**
             * Function called when input box loses focus
             */
            onBlur: PropTypes.func,

            /**
             * Function called when input box value changes
             */
            onChange: PropTypes.func,

            /**
             * Function called when a key is pressed and the input box is in focus
             */
            onKeyDown: PropTypes.func,
            onKeyPress: PropTypes.func,
            onComposition: PropTypes.func,

            onSelect: PropTypes.func,
            onSearchTypeSelected: PropTypes.func,

            /**
             * Function called when an item is selected
             */
            onItemSelected: PropTypes.func,

            /**
             * Flags if the suggestion_box is for the RHS (Reply).
             */
            isRHS: PropTypes.bool,

            /**
             * The number of characters required to show the suggestion list, defaults to 1
             */
            requiredCharacters: PropTypes.number,

            /**
             * If true, the suggestion box is opened on focus, default to false
             */
            openOnFocus: PropTypes.bool,

            /**
             * If true, the suggestion box is disabled
             */
            disabled: PropTypes.bool,

            /**
             * If true, it displays allow to display a default list when empty
             */
            openWhenEmpty: PropTypes.bool,

            /**
             * If true, replace all input in the suggestion box with the selected option after a select, defaults to false
             */
            replaceAllInputOnSelect: PropTypes.bool,

            /**
             * An optional, opaque identifier that distinguishes the context in which the suggestion
             * box is rendered. This allows the reused component to otherwise respond to changes.
             */
            contextId: PropTypes.string,

            /**
             * If true, listen for clicks on a mention and populate the input with said mention, defaults to false
             */
            listenForMentionKeyClick: PropTypes.bool,

            /**
             * Allows parent to access received suggestions
             */
            onSuggestionsReceived: PropTypes.func,

            /**
             * To show suggestions even when focus is lost
             */
            forceSuggestionsWhenBlur: PropTypes.bool,

            clearOnEscape: PropTypes.bool,

            actions: PropTypes.shape({
                addMessageIntoHistory: PropTypes.func.isRequired,
            }).isRequired,
        };
    }

    static defaultProps = {
        listPosition: 'top',
        containerClass: '',
        renderDividers: false,
        renderNoResults: false,
        shouldSearchCompleteText: false,
        completeOnTab: true,
        isRHS: false,
        requiredCharacters: 1,
        openOnFocus: false,
        openWhenEmpty: false,
        clearOnEscape: true,
        replaceAllInputOnSelect: false,
        listenForMentionKeyClick: false,
        forceSuggestionsWhenBlur: false,
    };

    isMounted = false;

    suggestionReadOut = React.createRef();

    // Keep track of whether we're composing a CJK character so we can make suggestions for partial characters
    composing = false;

    pretext = '';

    // Used for preventing suggestion list to close when scrollbar is clicked
    preventSuggestionListCloseFlag = false;

    // pretext: the text before the cursor
    // matchedPretext: a list of the text before the cursor that will be replaced if the corresponding autocomplete term is selected
    // terms: a list of strings which the previously typed text may be replaced by
    // items: a list of objects backing the terms which may be used in rendering
    // components: a list of react components that can be used to render their corresponding item
    // selection: the term currently selected by the keyboard
    state = {
        focused: false,
        cleared: true,
        matchedPretext: [],
        items: [],
        terms: [],
        components: [],
        selection: '',
        allowDividers: true,
        presentationType: 'text',
        suggestionBoxAlgn: undefined,
    };

    inputRef = React.createRef();

    setStateOnMounted(state) {
        if (this.isMounted) {
            this.setState(state);
        }
    }

    componentDidMount() {
        this.isMounted = true;

        if (this.props.listenForMentionKeyClick) {
            EventEmitter.addListener('mention_key_click', this.handleMentionKeyClick);
        }
        this.handlePretextChanged(this.pretext, false);
    }

    componentWillUnmount() {
        this.isMounted = false;
        EventEmitter.removeListener('mention_key_click', this.handleMentionKeyClick);
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props;

        // Post was just submitted, update pretext property.
        if (value === '' && this.pretext !== value) {
            this.handlePretextChanged(value);
            return;
        }

        if (prevProps.contextId !== this.props.contextId) {
            const textbox = this.getTextbox();
            const pretext = textbox.value.substring(0, textbox.selectionEnd).toLowerCase();

            this.handlePretextChanged(pretext);
        }
    }

    handleMentionKeyClick = (mentionKey, isRHS) => {
        if (this.props.isRHS !== isRHS) {
            return;
        }

        let insertText = '@' + mentionKey;

        // if the current text does not end with a whitespace, then insert a space
        if (this.props.value && (/[^\s]$/).test(this.props.value)) {
            insertText = ' ' + insertText;
        }

        this.addTextAtCaret(insertText, '');
    };

    getTextbox = () => {
        if (!this.inputRef.current) {
            return null;
        }

        return this.inputRef.current;
    };

    handleEmitClearSuggestions = (delay = 0) => {
        setTimeout(() => {
            this.clear();
        }, delay);
    };

    preventSuggestionListClose = () => {
        this.preventSuggestionListCloseFlag = true;
    };

    handleFocusOut = (e) => {
        if (this.preventSuggestionListCloseFlag) {
            this.preventSuggestionListCloseFlag = false;
            return;
        }

        // Focus is switching TO e.relatedTarget, so only treat this as a blur event if we're not switching
        // between children (like from the textbox to the suggestion list)
        if (this.container.contains(e.relatedTarget)) {
            return;
        }

        if (UserAgent.isIos() && !e.relatedTarget) {
            // On Safari and iOS classic app, the autocomplete stays open
            // when you tap outside of the post textbox or search box.
            return;
        }

        if (!this.props.forceSuggestionsWhenBlur) {
            this.handleEmitClearSuggestions();
        }

        this.setStateOnMounted({focused: false});

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleFocusIn = (e) => {
        // Focus is switching FROM e.relatedTarget, so only treat this as a focus event if we're not switching
        // between children (like from the textbox to the suggestion list). PreventSuggestionListCloseFlag is
        // checked because if true, it means that the focusIn comes from a click in the suggestion box, an
        // option choice, so we don't want the focus event to be triggered
        if (this.container.contains(e.relatedTarget) || this.preventSuggestionListCloseFlag) {
            return;
        }

        this.setStateOnMounted({focused: true});

        if (this.props.openOnFocus || this.props.openWhenEmpty) {
            setTimeout(() => {
                const textbox = this.getTextbox();
                if (textbox) {
                    const pretext = textbox.value.substring(0, textbox.selectionEnd);
                    if (this.props.openWhenEmpty || pretext.length >= this.props.requiredCharacters) {
                        if (this.pretext !== pretext) {
                            this.handlePretextChanged(pretext);
                        }
                    }
                }
            });
        }

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleChange = (e) => {
        const textbox = this.getTextbox();
        const pretext = this.props.shouldSearchCompleteText ? textbox.value.trim() : textbox.value.substring(0, textbox.selectionEnd);

        if (!this.composing && this.pretext !== pretext) {
            this.handlePretextChanged(pretext);
        }

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    onChange = (...args) => {
        if (this.props.onChange) {
            this.props.onChange(...args);
        }
    };

    handleCompositionStart = () => {
        this.composing = true;
        if (this.props.onComposition) {
            this.props.onComposition();
        }
    };

    handleCompositionUpdate = (e) => {
        if (!e.data) {
            return;
        }

        // The caret appears before the CJK character currently being composed, so re-add it to the pretext
        const textbox = this.getTextbox();
        const pretext = textbox.value.substring(0, textbox.selectionStart) + e.data;

        this.handlePretextChanged(pretext);
        if (this.props.onComposition) {
            this.props.onComposition();
        }
    };

    handleCompositionEnd = () => {
        this.composing = false;
        if (this.props.onComposition) {
            this.props.onComposition();
        }
    };

    addTextAtCaret = (term, matchedPretext) => {
        const textbox = this.getTextbox();

        // @see https://time-sentry.tinkoff.ru/organizations/sentry/issues/488/
        if (!textbox) {
            return;
        }

        const caret = textbox.selectionEnd;
        const text = this.props.value;
        const pretext = textbox.value.substring(0, textbox.selectionEnd);

        let prefix;
        let keepPretext = false;
        if (pretext.toLowerCase().endsWith(matchedPretext.toLowerCase())) {
            prefix = pretext.substring(0, pretext.length - matchedPretext.length);
        } else {
            // the pretext has changed since we got a term to complete so see if the term still fits the pretext
            const termWithoutMatched = term.substring(matchedPretext.length);
            const overlap = SuggestionBox.findOverlap(pretext, termWithoutMatched);

            keepPretext = overlap.length === 0;
            prefix = pretext.substring(0, pretext.length - overlap.length - matchedPretext.length);
        }

        const suffix = text.substring(caret);

        let newValue;
        if (keepPretext) {
            newValue = pretext;
        } else {
            newValue = prefix + term + ' ' + suffix;
        }

        textbox.value = newValue;

        if (this.props.onChange) {
            // fake an input event to send back to parent components
            const e = {
                target: textbox,
            };

            // don't call handleChange or we'll get into an event loop
            this.props.onChange(e);
        }

        // set the caret position after the next rendering
        window.requestAnimationFrame(() => {
            if (textbox.value === newValue) {
                Utils.setCaretPosition(textbox, prefix.length + term.length + 1);
            }
        });
    };

    replaceText = (term) => {
        const textbox = this.getTextbox();
        textbox.value = term;

        if (this.props.onChange) {
            // fake an input event to send back to parent components
            const e = {
                target: textbox,
            };

            // don't call handleChange or we'll get into an event loop
            this.props.onChange(e);
        }
    };

    handleCompleteWord = ({term, matchedPretext, event}) => {
        let fixedTerm = term;
        let finish = false;
        let openCommandInModal = false;
        if (term.endsWith(EXECUTE_CURRENT_COMMAND_ITEM_ID)) {
            fixedTerm = term.substring(0, term.length - EXECUTE_CURRENT_COMMAND_ITEM_ID.length);
            finish = true;
        }

        if (term.endsWith(OPEN_COMMAND_IN_MODAL_ITEM_ID)) {
            fixedTerm = term.substring(0, term.length - OPEN_COMMAND_IN_MODAL_ITEM_ID.length);
            finish = true;
            openCommandInModal = true;
        }

        if (!finish) {
            if (this.props.replaceAllInputOnSelect) {
                this.replaceText(fixedTerm);
            } else {
                this.addTextAtCaret(fixedTerm, matchedPretext);
            }
        }

        if (this.props.onItemSelected) {
            const items = this.state.items;
            const terms = this.state.terms;
            for (let i = 0; i < terms.length; i++) {
                if (terms[i] === fixedTerm) {
                    this.props.onItemSelected(items[i]);
                    break;
                }
            }
        }

        this.clear();

        if (openCommandInModal) {
            const appProvider = this.props.providers.find((p) => p.openAppsModalFromCommand);
            if (!appProvider) {
                return false;
            }
            appProvider.openAppsModalFromCommand(fixedTerm);
            this.props.actions.addMessageIntoHistory(fixedTerm);
            this.inputRef.current.value = '';
            this.handleChange({target: this.inputRef.current});
            return false;
        }

        // https://time-sentry.tinkoff.ru/organizations/sentry/issues/2630/
        this.inputRef.current?.focus();

        if (finish && this.props.onKeyPress) {
            let ke = event;
            if (!event || Utils.isKeyPressed(event, Constants.KeyCodes.TAB)) {
                ke = new KeyboardEvent('keydown', {
                    bubbles: true,
                    cancelable: true,
                    keyCode: 13,
                });
                if (event) {
                    event.preventDefault();
                }
            }
            this.props.onKeyPress(ke);
            return true;
        }

        if (!finish) {
            for (const provider of this.props.providers) {
                if (provider.handleCompleteWord) {
                    provider.handleCompleteWord(fixedTerm, matchedPretext, this.handlePretextChanged);
                }
            }
        }
        return false;
    };

    selectNext = () => {
        this.setSelectionByDelta(1);
    };

    selectPrevious = () => {
        this.setSelectionByDelta(-1);
    };

    setSelectionByDelta = (delta) => {
        let selectionIndex = this.state.terms.indexOf(this.state.selection);

        if (selectionIndex === -1) {
            this.setStateOnMounted({
                selection: '',
            });
            return;
        }

        selectionIndex += delta;

        if (selectionIndex < 0) {
            selectionIndex = 0;
        } else if (selectionIndex > this.state.terms.length - 1) {
            selectionIndex = this.state.terms.length - 1;
        }

        this.setStateOnMounted({
            selection: this.state.terms[selectionIndex],
        });
    };

    setSelection = (term) => {
        this.setStateOnMounted({
            selection: term,
        });
    };

    clear = () => {
        if (!this.state.cleared) {
            this.setStateOnMounted({
                cleared: true,
                matchedPretext: [],
                terms: [],
                items: [],
                components: [],
                selection: '',
                suggestionBoxAlgn: undefined,
            });
            this.handlePretextChanged('');
        }
    };

    hasSuggestions = () => {
        return this.state.items.some((item) => !item.loading);
    };

    handleKeyDown = (e) => {
        if ((this.props.openWhenEmpty || this.props.value) && this.hasSuggestions()) {
            const ctrlOrMetaKeyPressed = e.ctrlKey || e.metaKey;
            if (Utils.isKeyPressed(e, KeyCodes.UP)) {
                this.selectPrevious();
                e.preventDefault();
            } else if (Utils.isKeyPressed(e, KeyCodes.DOWN)) {
                this.selectNext();
                e.preventDefault();
            } else if (
                (Utils.isKeyPressed(e, KeyCodes.ENTER) && !ctrlOrMetaKeyPressed) ||
                (this.props.completeOnTab && Utils.isKeyPressed(e, KeyCodes.TAB))
            ) {
                let matchedPretext = '';
                for (let i = 0; i < this.state.terms.length; i++) {
                    if (this.state.terms[i] === this.state.selection) {
                        matchedPretext = this.state.matchedPretext[i];
                        this.trackEditorSearchResultTapByKey({resultNumber: i + 1});
                    }
                }

                // If these don't match, the user typed quickly and pressed enter before we could
                // update the pretext, so update the pretext before completing
                if (this.pretext.toLowerCase().endsWith(matchedPretext.toLowerCase())) {
                    if (this.handleCompleteWord({term: this.state.selection, matchedPretext, event: e})) {
                        return;
                    }
                } else {
                    this.nonDebouncedPretextChanged(this.pretext, true);
                }

                if (this.props.onKeyDown) {
                    this.props.onKeyDown(e);
                }
                e.preventDefault();
            } else if (Utils.isKeyPressed(e, KeyCodes.ESCAPE) && this.props.clearOnEscape) {
                this.clear();
                this.setStateOnMounted({presentationType: 'text'});
                e.preventDefault();
                e.stopPropagation();
            } else if (this.props.onKeyDown) {
                this.props.onKeyDown(e);
            }
        } else if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    };

    /**
     * В selection хранится текстовое представление выбранного элемента (:holypeka:)
     * из списка предложений, мы проверяем использует ли аналитику provider
     * и находим тип провайдера, сравнивая символ триггера с первым символом
     * представления (: в :holypeka:)
     * Чтобы не заводить дополнительных полей, берем событие из searchTimeMeasurer
     * и меняем тип события с `.result` на `.resultTap`
     * @TODO: уничтожить вместе со старым редактором, когда придет время
     */
    trackEditorSearchResultTapByKey({resultNumber}) {
        let statistEventName = '';
        for (const provider of this.props.providers) {
            if (provider.searchTimeMeasurer && this.state.selection[0] === provider.triggerCharacter) {
                statistEventName = provider.searchTimeMeasurer.eventType.replace('result', 'resultTap');
                break;
            }
        }

        if (!statistEventName) {
            return;
        }

        sendToStatist(statistEventName, {
            resultNumber,
        });
    }

    handleSelect = (e) => {
        if (this.props.onSelect) {
            this.props.onSelect(e);
        }
    };

    handleReceivedSuggestions = (suggestions) => {
        let newComponents = [];
        const newPretext = [];
        if (this.props.onSuggestionsReceived) {
            this.props.onSuggestionsReceived(suggestions);
        }

        for (let i = 0; i < suggestions.terms.length; i++) {
            newComponents.push(suggestions.component);
            newPretext.push(suggestions.matchedPretext);
        }

        if (suggestions.components) {
            newComponents = suggestions.components;
        }

        const terms = suggestions.terms;
        const items = suggestions.items;

        let selection = this.state.selection;

        const termsHasSelection = terms?.includes(this.state.selection);

        // Если пришел пустой список - обнуляем выбор
        if (!terms?.length) {
            selection = '';
        }

        /**
         * Если в новом списке нет того что выбрали,
         * то выставляем первый пункт из списка
         * (это включает в себя ситуацию когда выбор пустой)
         */
        if (!termsHasSelection && terms.length > 0) {
            selection = terms[0];
        }

        /**
         * В остальных случаях выбор будет
         * сохраняться
         */
        this.setStateOnMounted({
            cleared: false,
            selection,
            terms,
            items,
            components: newComponents,
            matchedPretext: newPretext,
        });

        return {selection, matchedPretext: suggestions.matchedPretext};
    };

    handleReceivedSuggestionsAndComplete = (suggestions) => {
        const {selection, matchedPretext} = this.handleReceivedSuggestions(suggestions);
        if (selection) {
            this.handleCompleteWord({term: selection, matchedPretext});
        }
    };

    nonDebouncedPretextChanged = (pretext, complete = false) => {
        this.pretext = pretext;
        let handled = false;
        let callback = this.handleReceivedSuggestions;
        if (complete) {
            callback = this.handleReceivedSuggestionsAndComplete;
        }
        for (const provider of this.props.providers) {
            handled = provider.handlePretextChanged(pretext, callback) || handled;

            if (handled) {
                if (!this.state.suggestionBoxAlgn && ['@', ':', '~', '/'].includes(provider.triggerCharacter)) {
                    const char = provider.triggerCharacter;
                    const pxToSubstract = Utils.getPxToSubstract(char);

                    // get the alignment for the box and set it in the component state
                    const suggestionBoxAlgn = Utils.getSuggestionBoxAlgn(this.getTextbox(), pxToSubstract);
                    this.setStateOnMounted({
                        suggestionBoxAlgn,
                    });
                }

                this.setStateOnMounted({
                    presentationType: provider.presentationType(),
                    allowDividers: provider.allowDividers(),
                });

                break;
            }
        }
        if (!handled) {
            this.clear();
        }
    };

    debouncedPretextChanged = debounce(Constants.SEARCH_TIMEOUT_MILLISECONDS, (pretext) =>
        this.nonDebouncedPretextChanged(pretext),
    );

    handlePretextChanged = (pretext, withDebounce = true) => {
        this.pretext = pretext;
        if (withDebounce) {
            this.debouncedPretextChanged(pretext);
        } else {
            this.nonDebouncedPretextChanged(pretext);
        }
    };

    blur = () => {
        this.inputRef.current.blur();
    };

    focus = () => {
        const input = this.inputRef.current;
        if (input.value === '""' || input.value.endsWith('""')) {
            input.selectionStart = input.value.length - 1;
            input.selectionEnd = input.value.length - 1;
        } else {
            input.selectionStart = input.value.length;
        }
        input.focus();

        this.handleChange({target: this.inputRef.current});
    };

    setContainerRef = (container) => {
        // Attach/detach event listeners that aren't supported by React
        if (this.container) {
            this.container.removeEventListener('focusin', this.handleFocusIn);
            this.container.removeEventListener('focusout', this.handleFocusOut);
        }

        if (container) {
            container.addEventListener('focusin', this.handleFocusIn);
            container.addEventListener('focusout', this.handleFocusOut);
        }

        // Save ref
        this.container = container;
    };

    getListPosition = (listPosition) => {
        if (!this.state.suggestionBoxAlgn) {
            return listPosition;
        }

        return listPosition === 'bottom' && this.state.suggestionBoxAlgn.placementShift ? 'top' : listPosition;
    };

    render() {
        const {dateComponent, listComponent, listPosition, renderNoResults, ...props} = this.props;

        const renderDividers = this.props.renderDividers && this.state.allowDividers;

        // Don't pass props used by SuggestionBox
        Reflect.deleteProperty(props, 'providers');
        Reflect.deleteProperty(props, 'onChange'); // We use onInput instead of onChange on the actual input
        Reflect.deleteProperty(props, 'onComposition');
        Reflect.deleteProperty(props, 'onItemSelected');
        Reflect.deleteProperty(props, 'completeOnTab');
        Reflect.deleteProperty(props, 'isRHS');
        Reflect.deleteProperty(props, 'requiredCharacters');
        Reflect.deleteProperty(props, 'openOnFocus');
        Reflect.deleteProperty(props, 'openWhenEmpty');
        Reflect.deleteProperty(props, 'clearOnEscape');
        Reflect.deleteProperty(props, 'onFocus');
        Reflect.deleteProperty(props, 'onBlur');
        Reflect.deleteProperty(props, 'containerClass');
        Reflect.deleteProperty(props, 'replaceAllInputOnSelect');
        Reflect.deleteProperty(props, 'renderDividers');
        Reflect.deleteProperty(props, 'contextId');
        Reflect.deleteProperty(props, 'listenForMentionKeyClick');
        Reflect.deleteProperty(props, 'forceSuggestionsWhenBlur');
        Reflect.deleteProperty(props, 'onSuggestionsReceived');
        Reflect.deleteProperty(props, 'actions');
        Reflect.deleteProperty(props, 'shouldSearchCompleteText');

        // This needs to be upper case so React doesn't think it's an html tag
        const SuggestionListComponent = listComponent;
        const SuggestionDateComponent = dateComponent;

        return (
            <div
                ref={this.setContainerRef}
                className={classNames(this.props.containerClass, {
                    notEmptyList: this.hasSuggestions(),
                })}
            >
                <div
                    ref={this.suggestionReadOut}
                    aria-live='polite'
                    role='alert'
                    className='sr-only'
                />
                <QuickInput
                    ref={this.inputRef}
                    autoComplete='off'
                    {...props}
                    onInput={this.handleChange}
                    onCompositionStart={this.handleCompositionStart}
                    onCompositionUpdate={this.handleCompositionUpdate}
                    onCompositionEnd={this.handleCompositionEnd}
                    onKeyDown={this.handleKeyDown}
                    onSelect={this.handleSelect}
                />
                {(this.props.openWhenEmpty || this.props.value.length >= this.props.requiredCharacters) &&
                    this.state.presentationType === 'text' && (
                    <div style={{width: this.state.width}}>
                        <SuggestionListComponent
                            ariaLiveRef={this.suggestionReadOut}
                            open={this.state.focused || this.props.forceSuggestionsWhenBlur}
                            pretext={this.pretext}
                            position={this.getListPosition(listPosition)}
                            renderDividers={renderDividers}
                            renderNoResults={renderNoResults}
                            onCompleteWord={this.handleCompleteWord}
                            preventClose={this.preventSuggestionListClose}
                            onItemHover={this.setSelection}
                            cleared={this.state.cleared}
                            matchedPretext={this.state.matchedPretext}
                            items={this.state.items}
                            terms={this.state.terms}
                            suggestionBoxAlgn={this.state.suggestionBoxAlgn}
                            selection={this.state.selection}
                            components={this.state.components}
                            inputRef={this.inputRef}
                            onLoseVisibility={this.blur}
                        />
                    </div>
                )}
                {(this.props.openWhenEmpty || this.props.value.length >= this.props.requiredCharacters) &&
                    this.state.presentationType === 'date' && (
                    <SuggestionDateComponent
                        items={this.state.items}
                        terms={this.state.terms}
                        components={this.state.components}
                        matchedPretext={this.state.matchedPretext}
                        onCompleteWord={this.handleCompleteWord}
                    />
                )}
            </div>
        );
    }

    // Finds the longest substring that's at both the end of b and the start of a. For example,
    // if a = "firepit" and b = "pitbull", findOverlap would return "pit".
    static findOverlap(a, b) {
        const aLower = a.toLowerCase();
        const bLower = b.toLowerCase();

        for (let i = bLower.length; i > 0; i--) {
            const substring = bLower.substring(0, i);

            if (aLower.endsWith(substring)) {
                return substring;
            }
        }

        return '';
    }
}
