import React from 'react';

import {Spinner} from '../../../atoms/spinner';

type Props = {
    loading?: string;
    progress?: number;
    className?: string;
};

export const LoadingImagePreview: React.FC<Props> = ({loading, progress, className}: Props) => {
    let progressView = null;

    if (progress) {
        progressView = <span>{`${loading} ${progress}%`}</span>;
    }

    return (
        <div className={className}>
            <Spinner />
            {progressView}
        </div>
    );
};
