// ARIA Listbox pattern https://www.w3.org/WAI/ARIA/apg/patterns/listbox/

import {useCallback, KeyboardEvent} from 'react';

import {useListBoxState} from './use-listbox-state';

type State = ReturnType<typeof useListBoxState>[0]
type Actions = ReturnType<typeof useListBoxState>[1]

export const useListBoxKeyboard = (state: State, actions: Actions) => {
    const onKeyDown = useCallback((e: KeyboardEvent<HTMLElement>) => {
        switch (e.key) {
        case 'ArrowDown':
            actions.onFocusNextItem();
            e.preventDefault();
            break;
        case 'ArrowUp':
            actions.onFocusPreviousItem();
            e.preventDefault();
            break;
        case 'Home':
            actions.onFocusFirstItem();
            e.preventDefault();
            break;
        case 'End':
            actions.onFocusLastItem();
            e.preventDefault();
            break;
        case 'Enter':
            if (state.focusedItem) {
                actions.onSelectItem(state.focusedItem);
            }
            e.preventDefault();
            break;
        default:
            break;
        }
    }, [state.focusedItem, actions]);

    return {
        onKeyDown,
    };
};
