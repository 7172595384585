export enum PermissionType {
    CREATE_POST = 'create_post',
    CREATE_POST_IN_THREAD = 'create_post_in_thread',
    CHANNEL_MENTIONS = 'channel_mentions',
    CHANNEL_KTALK = 'use_plugin_ktalk',
}

export enum ChannelReadOnlyActionType {
    ADD = 'addChannel',
    REMOVE = 'removeChannel'
}
