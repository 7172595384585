import type {User} from 'features/users';
import type {AppDispatch} from 'stores/redux_store';
import type {GlobalState} from 'types/store';
import {autocompleteUsers as autocompleteUsersAction} from 'mattermost-redux/actions/users';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import type {UserAutocomplete} from '@mattermost/types/autocomplete';
import type {Channel} from '@mattermost/types/channels';

type AutocompleteResult = {
    error?: Error;
    data: UserAutocomplete;
};

export const autocompleteUsers =
    (username: User['username'], channelId?: Channel['id']) =>
        async (dispatch: AppDispatch, getState: () => GlobalState) => {
            const state = getState();
            const currentTeamId = getCurrentTeamId(state);

            const result = (await dispatch(
                autocompleteUsersAction(username, currentTeamId, channelId),
            )) as AutocompleteResult;

            if (!result.error && result.data) {
                return result.data;
            }

            return {
                users: [],
                out_of_channel: [],
            } as UserAutocomplete;
        };
