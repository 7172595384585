import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const AlertOutlineIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            d='M21.638,16.575L14.64,3.578C14.117,2.605,13.105,2,12,2S9.883,2.605,9.359,3.578L2.362,16.575
            c-0.505,0.939-0.481,2.046,0.065,2.962C2.974,20.453,3.937,21,5.003,21h13.994c1.066,0,2.029-0.547,2.575-1.463
            C22.119,18.622,22.143,17.514,21.638,16.575z M18.995,18.998H5.001c-0.757,0-1.239-0.808-0.88-1.475l6.997-12.997
            C11.307,4.175,11.652,4,11.998,4s0.691,0.175,0.88,0.526l6.997,12.997C20.234,18.19,19.752,18.998,18.995,18.998z M12.5,13h-1L11,7
            h2L12.5,13z M12.999,16c0,0.552-0.448,1-1,1s-1-0.448-1-1s0.448-1,1-1C12.552,15,12.999,15.448,12.999,16z'
        />
    </svg>
));
