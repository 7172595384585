import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function ClockIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)}{...props}>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 18 18'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M8.99984 15.6665C12.6665 15.6665 15.6665 12.6665 15.6665 8.99984C15.6665 5.33317 12.6665 2.33317 8.99984 2.33317C5.33317 2.33317 2.33317 5.33317 2.33317 8.99984C2.33317 12.6665 5.33317 15.6665 8.99984 15.6665ZM8.99984 0.666504C13.5832 0.666504 17.3332 4.4165 17.3332 8.99984C17.3332 13.5832 13.5832 17.3332 8.99984 17.3332C4.4165 17.3332 0.666504 13.5832 0.666504 8.99984C0.666504 4.4165 4.4165 0.666504 8.99984 0.666504ZM11.9457 13.124L8.1665 9.34484V4.83317H9.83317V8.65484L13.124 11.9457L11.9457 13.124Z' />
            </svg>
        </span>
    );
}
