import {useEffect, useRef} from 'react';

import {EventType, EventTypeWithParams} from './methods';
import {SearchTimeMeasurer} from './search_time_measurer';

export const useSearchTimeMeasurer = (eventType: EventType | EventTypeWithParams) => {
    const statistics = useRef(new SearchTimeMeasurer(eventType)).current;
    const measureSearchTime = useRef(statistics.measure.bind(statistics)).current;

    useEffect(() => {
        return () => statistics.dispose();
    });

    return measureSearchTime;
};
