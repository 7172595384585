import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const LockOutlineIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref} data-testid='lock-outline-icon'>
        <path
            fill='currentColor'
            d='M18 20v-9H6v9h12Zm0-11a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h1V7a5 5 0 0 1 5-5 5 5 0 0 1 5 5v2h1Zm-6-5a3 3 0 0 0-3 3v2h6V7a3 3 0 0 0-3-3Z'
        />
    </svg>
));
