import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';
import {createThunkAction} from 'stores/create_thunk_action';
import {reportMessageToSentry} from 'utils/sentry';

import type {User} from '../types/user';
import {UserTypes} from 'mattermost-redux/action_types';

export const receivedUsers = (users: User[]) => createThunkAction('users/actions/receivedUsers', (dispatch, getState): User[] => {
    if (!Array.isArray(users)) {
        reportMessageToSentry(`receivedUsers: users is not an Array, users type=${typeof users}, users=${users}`);
        return [];
    }

    const currentUser = getCurrentUser(getState());

    const newUsers = users.map((user) => {
        if (user.id === currentUser?.id) {
            return {...currentUser, ...user};
        }

        return user;
    });

    dispatch({
        type: UserTypes.RECEIVED_USERS,
        payload: newUsers,
    });

    return newUsers;
});
