import {createSelector} from 'reselect';

import {threadsAdapter} from '../entity_adapters/thread_entity_adapter';

import type {GlobalState} from 'types/store';

import type {Team} from '@mattermost/types/teams';

import {getUserThreadsSliceState} from './get_user_threads_state';

export const selectAllUserThreadsByTeamId = createSelector(
    (_: GlobalState, teamId: Team['id']) => teamId,
    (state: GlobalState) => {
        const userThreadsState = getUserThreadsSliceState(state).threads;
        return threadsAdapter.getSelectors().selectAll(userThreadsState);
    },
    (teamId, threads) => {
        return threads.filter((thread) => {
            // У тредов в DM и GM каналах нет team_id так как GM/DM не привязаны к команде
            return thread.team_id === teamId || thread.team_id === '';
        });
    },
    {
        memoizeOptions: {

            // In most cases user has no more than 3 teams
            maxSize: 3,
        },
    },
);
