import React from 'react';

export function makeAsyncComponent<E extends React.ComponentType<any>>(
    displayName: string,
    LazyComponent: React.LazyExoticComponent<E>,
    fallback: React.SuspenseProps['fallback'] = null,
) {
    const Component = (props: any) => (
        <React.Suspense fallback={fallback}>
            <LazyComponent {...props} />
        </React.Suspense>
    );
    Component.displayName = displayName;
    return Component;
}
