import {createSelector} from 'reselect';

import type {GlobalState} from 'types/store';

import {threadsAdapter} from '../entity_adapters/thread_entity_adapter';
import type {ClientThread} from '../types/extended';

import {getUserThreadsSliceState} from './get_user_threads_state';

export const selectThreadById = createSelector(
    (state: GlobalState) => getUserThreadsSliceState(state).threads,
    (_: GlobalState, id: ClientThread['id']) => id,
    (userThreads, id) => threadsAdapter.getSelectors().selectById(userThreads, id),
);
