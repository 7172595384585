import type {ReactNode} from 'react';
import classNames from 'classnames';

import {Heading, HeadingLevel} from '../../../../atoms/heading';
import {TypographySize, useTypography} from '../../../../hooks/typography';

import styles from './styles.module.css';

type Props = {
    title: ReactNode;
    description?: string;
    closer?: React.ReactNode;
};

export const ModalHeader: React.VoidFunctionComponent<Props> = (props) => {
    const {title, description, closer, ...titleProps} = props;
    const descriptionTypographyClassName = useTypography({
        size: TypographySize.BodyS,
    });

    return (
        <div
            className={classNames(styles.header, {
                [styles.hasCloser]: Boolean(closer),
            })}
        >
            {
                title &&
                    <Heading className={styles.heading} level={HeadingLevel.FIFTH} {...titleProps}>
                        {title}
                    </Heading>
            }
            {description ? (
                <p className={classNames(descriptionTypographyClassName, styles.description)}>{description}</p>
            ) : null}
            {closer}
        </div>
    );
};
