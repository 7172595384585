import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const CalendarClockIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20' {...props} ref={ref}>
        <path
            d='M12.5 10.83h1.25v2.35l2.03 1.18-.62 1.08-2.66-1.53v-3.08Zm3.33-4.16H4.17v9.16h3.89a5.84 5.84 0 0 1 7.77-7.77v-1.4ZM4.17 17.5c-.93 0-1.67-.75-1.67-1.67V4.17c0-.93.74-1.67 1.67-1.67H5V.83h1.67V2.5h6.66V.83H15V2.5h.83a1.67 1.67 0 0 1 1.67 1.67v5.08a5.8 5.8 0 0 1-.04 8.2 5.83 5.83 0 0 1-8.21.05H4.17Zm9.16-8.21a4.04 4.04 0 1 0 0 8.08 4.04 4.04 0 0 0 0-8.08Z'
            fill='currentColor'
        />
    </svg>
));
