import {SearchTypes} from 'mattermost-redux/action_types';
import {getMyChannelMember} from 'mattermost-redux/actions/channels';
import {
    getChannel,
    getMyChannelMember as getMyChannelMemberSelector,
} from 'mattermost-redux/selectors/entities/channels';
import * as PostActions from 'mattermost-redux/actions/posts';
import * as PostSelectors from 'mattermost-redux/selectors/entities/posts';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import {canEditPost, comparePosts} from 'mattermost-redux/utils/post_utils';

import {addRecentEmoji} from 'actions/emoji_actions';
import * as StorageActions from 'actions/storage';
import {loadNewDMIfNeeded, loadNewGMIfNeeded} from 'actions/user_actions';
import * as RhsActions from 'actions/views/rhs';
import {isEmbedVisible, isInlineImageVisible} from 'selectors/posts';
import {getSelectedPostId, getSelectedPostCardId, getRhsState} from 'selectors/rhs';
import {ActionTypes, Constants, RHSStates, StoragePrefixes} from 'utils/constants';
import {matchEmoticons} from 'utils/emoticons';

import {setDraftAction} from 'features/drafts';

import {markThreadPostUnread} from 'features/threads/actions/mark_thread_post_unread';

import {handleNewPost as handleNewPostForSidebar} from 'features/sidebar/actions/handle_new_post';

import {completePostReceive} from './new_post';

export function handleNewPost(post, msg) {
    return async (dispatch, getState) => {
        let websocketMessageProps = {};
        const state = getState();
        if (msg) {
            websocketMessageProps = msg.data;
        }

        const myChannelMember = getMyChannelMemberSelector(state, post.channel_id);
        const myChannelMemberDoesntExist =
            !myChannelMember || (Object.keys(myChannelMember).length === 0 && myChannelMember.constructor.name === 'Object');

        let isMyMemberFetched = false;
        if (myChannelMemberDoesntExist) {
            await dispatch(getMyChannelMember(post.channel_id));
            isMyMemberFetched = true;
        }

        dispatch(completePostReceive(post, websocketMessageProps, myChannelMemberDoesntExist));

        if (msg && msg.data) {
            if (msg.data.channel_type === Constants.DM_CHANNEL) {
                await dispatch(loadNewDMIfNeeded(post.channel_id, isMyMemberFetched));
            } else if (msg.data.channel_type === Constants.GM_CHANNEL) {
                await dispatch(loadNewGMIfNeeded(post.channel_id, isMyMemberFetched));
            }
        }

        dispatch(handleNewPostForSidebar(post));

        return {data: true};
    };
}

const getPostsForIds = PostSelectors.makeGetPostsForIds();

export function flagPost(postId) {
    return async (dispatch, getState) => {
        await dispatch(PostActions.flagPost(postId));
        const state = getState();
        const rhsState = getRhsState(state);

        if (rhsState === RHSStates.FLAG) {
            addPostToSearchResults(postId, state, dispatch);
        }

        return {data: true};
    };
}

export function unflagPost(postId) {
    return async (dispatch, getState) => {
        await dispatch(PostActions.unflagPost(postId));
        const state = getState();
        const rhsState = getRhsState(state);

        if (rhsState === RHSStates.FLAG) {
            removePostFromSearchResults(postId, state, dispatch);
        }

        return {data: true};
    };
}

export function createPost(post, files) {
    return async (dispatch) => {
        // parse message and emit emoji event
        const emojis = matchEmoticons(post.message);

        if (emojis) {
            for (const emoji of emojis) {
                const trimmed = emoji.substring(1, emoji.length - 1);
                dispatch(addRecentEmoji(trimmed));
            }
        }

        const result = await dispatch(PostActions.createPost(post, files));

        dispatch(
            setDraftAction({
                draft: null,
                id: post.root_id || post.channel_id,
                isThread: Boolean(post.root_id),
            }),
        );

        return result;
    };
}

export function addReaction(postId, emojiName) {
    return async (dispatch) => {
        const result = await dispatch(PostActions.addReaction(postId, emojiName));
        dispatch(addRecentEmoji(emojiName));
        return result;
    };
}

export function addReactionToPost(postId, emojiName) {
    return (dispatch) => dispatch(PostActions.addReaction(postId, emojiName));
}

export function searchForTerm(term) {
    return (dispatch) => {
        dispatch(RhsActions.updateSearchTerms(term));
        dispatch(RhsActions.showSearchResults());
        return {data: true};
    };
}

function addPostToSearchResults(postId, state, dispatch) {
    const results = state.entities.search.results;
    const index = results.indexOf(postId);
    if (index === -1) {
        const newPost = PostSelectors.getPost(state, postId);
        const posts = getPostsForIds(state, results).reduce((acc, post) => {
            acc[post.id] = post;
            return acc;
        }, {});

        posts[newPost.id] = newPost;

        // https://time-sentry.tinkoff.ru/organizations/sentry/issues/2681/
        const newResults = [...results, postId].filter((newResultsPostId) => Boolean(posts[newResultsPostId]?.id));

        newResults.sort((a, b) => comparePosts(posts[a], posts[b]));

        dispatch({
            type: SearchTypes.RECEIVED_SEARCH_POSTS,
            data: {posts, order: newResults},
        });
    }
}

function removePostFromSearchResults(postId, state, dispatch) {
    let results = state.entities.search.results;
    const index = results.indexOf(postId);
    if (index > -1) {
        results = [...results];
        results.splice(index, 1);

        const posts = getPostsForIds(state, results);

        dispatch({
            type: SearchTypes.RECEIVED_SEARCH_POSTS,
            data: {posts, order: results},
        });
    }
}

export function pinPost(postId) {
    return async (dispatch, getState) => {
        await dispatch(PostActions.pinPost(postId));
        const state = getState();
        const rhsState = getRhsState(state);

        if (rhsState === RHSStates.PIN) {
            addPostToSearchResults(postId, state, dispatch);
        }
        return {data: true};
    };
}

export function unpinPost(postId) {
    return async (dispatch, getState) => {
        await dispatch(PostActions.unpinPost(postId));
        const state = getState();
        const rhsState = getRhsState(state);

        if (rhsState === RHSStates.PIN) {
            removePostFromSearchResults(postId, state, dispatch);
        }
        return {data: true};
    };
}

export function setEditingPost(postId = '', refocusId = '', title = '', isRHS = false) {
    return async (dispatch, getState) => {
        const state = getState();
        const post = PostSelectors.getPost(state, postId);

        if (!post || post.pending_post_id === postId) {
            return {data: false};
        }

        const config = state.entities.general.config;
        const license = state.entities.general.license;
        const userId = getCurrentUserId(state);
        const channel = getChannel(state, post.channel_id);
        const teamId = channel.team_id || '';

        const canEditNow = canEditPost(state, config, license, teamId, post.channel_id, userId, post);

        // Only show the modal if we can edit the post now, but allow it to be hidden at any time

        if (canEditNow) {
            dispatch({
                type: ActionTypes.TOGGLE_EDITING_POST,
                data: {postId, refocusId, title, isRHS, show: true},
            });
        }

        return {data: canEditNow};
    };
}

export function unsetEditingPost() {
    return {
        type: ActionTypes.TOGGLE_EDITING_POST,
        data: {
            show: false,
        },
    };
}

export function markPostAsUnread(post, location) {
    return async (dispatch, getState) => {
        const state = getState();
        const userId = getCurrentUserId(state);

        // if this is from within ThreadViewer (e.g. post dot-menu), mark the thread as unread and followed
        if (location === 'RHS_ROOT' || location === 'RHS_COMMENT') {
            await dispatch(markThreadPostUnread(post));
        } else {
            // use normal channel unread system
            await dispatch(PostActions.setUnreadPost(userId, post.id));
        }

        return {data: true};
    };
}

export function deleteAndRemovePost(post) {
    return async (dispatch, getState) => {
        const {error} = await dispatch(PostActions.deletePost(post));
        if (error) {
            return {error};
        }

        if (post.id === getSelectedPostId(getState())) {
            dispatch({
                type: ActionTypes.SELECT_POST,
                postId: '',
                channelId: '',
                timestamp: 0,
            });
        }

        if (post.id === getSelectedPostCardId(getState())) {
            dispatch({
                type: ActionTypes.SELECT_POST_CARD,
                postId: '',
                channelId: '',
            });
        }

        dispatch(PostActions.removePost(post));

        return {data: true};
    };
}

export function toggleEmbedVisibility(postId) {
    return (dispatch, getState) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);
        const visible = isEmbedVisible(state, postId);

        dispatch(StorageActions.setGlobalItem(StoragePrefixes.EMBED_VISIBLE + currentUserId + '_' + postId, !visible));
    };
}

export function resetEmbedVisibility() {
    return StorageActions.actionOnGlobalItemsWithPrefix(StoragePrefixes.EMBED_VISIBLE, () => null);
}

export function toggleInlineImageVisibility(postId, imageKey) {
    return (dispatch, getState) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);
        const visible = isInlineImageVisible(state, postId, imageKey);

        dispatch(
            StorageActions.setGlobalItem(
                StoragePrefixes.INLINE_IMAGE_VISIBLE + currentUserId + '_' + postId + '_' + imageKey,
                !visible,
            ),
        );
    };
}

export function resetInlineImageVisibility() {
    return StorageActions.actionOnGlobalItemsWithPrefix(StoragePrefixes.INLINE_IMAGE_VISIBLE, () => null);
}

/*
 * It is called from either center or rhs text input when shortcut for react to last message is pressed
 *
 * @param {string} emittedFrom - It can be either "CENTER", "RHS_ROOT" or "NO_WHERE"
 */

export function emitShortcutReactToLastPostFrom(emittedFrom) {
    return {
        type: ActionTypes.EMITTED_SHORTCUT_REACT_TO_LAST_POST,
        payload: emittedFrom,
    };
}
