import {createAction} from '@reduxjs/toolkit';

import type {AnyThread} from '../types/extended';

type Payload = {
    threadId: string;
    teamId: string;
    channelId: string;
    lastViewedAt?: number;
    prevUnreadMentions?: number;
    newUnreadMentions: number;
    prevUnreadReplies?: number;
    newUnreadReplies: number;
    thread?: AnyThread;
};

export const handleThreadsReadChanged = createAction<Payload>('threads/actions/handleThreadsReadChanged');
