import {useMemo, useState} from 'react';

import {useDispatch} from 'react-redux';

import {keyBy} from 'lodash';

import {type Team, type TeamInviteWithError, type TeamInvitationRole} from '@mattermost/types/teams';
import {type UserProfile} from '@mattermost/types/users';
import {type Channel} from '@mattermost/types/channels';
import {Client4} from 'mattermost-redux/client';

import {sendToStatist} from '@time-webkit/statist';

import {receivedUsers} from 'features/users';

import {InvitationError} from './invitation_error';
import {InvitationResult, createInvitationResult} from './invitation_result';
import {InvitationErrorCode} from './invite_members_form/invitation_error_code';

type State = {
    result: InvitationResult[];
    profiles: UserProfile[];
    requestError: unknown | null;
    success: boolean;
    errors: InvitationError[];
    warnings: InvitationError[];
    sendingIssues: InvitationError[];
    rateLimitIssues: InvitationError[];
    criticalErrors: InvitationError[];
    remainingSendings?: number;
};

export type InviteMembersState = ReturnType<typeof useInviteMembersState>

let controller: AbortController | undefined;

const initialState: State = {
    result: [],
    profiles: [],
    errors: [],
    warnings: [],
    criticalErrors: [],
    sendingIssues: [],
    rateLimitIssues: [],
    requestError: null,
    success: false,
};

export function useInviteMembersState() {
    const errorsLog = new Set<string>();
    let uniqueErrors: Record<string, number> = {};
    let uniqueCriticalErrorsCount = 0;
    let uniqueWarningsCount = 0;

    const [state, setState] = useState<State>(initialState);
    const dispatch = useDispatch();

    const reset = () => {
        setState(initialState);
    };

    const profilesByEmail = useMemo(() => keyBy(state.profiles, (profile) => profile.email), [state.profiles]);
    const failedToSendInvites = useMemo(() => state.result.length > 0 && state.result.length === state.sendingIssues.length, [state.result.length, state.sendingIssues.length]);
    const rateLimitTimeoutMinutes = useMemo(() => {
        const timeoutSeconds = state.rateLimitIssues.length > 0 ? state.rateLimitIssues[0]?.retryAfterSeconds ?? 0 : 0;
        return Math.max(Math.ceil(timeoutSeconds / 60), 1);
    }, [state.rateLimitIssues]);

    const sendInvites = async ({emails, role, channels, team, dryRun}: {emails: string[]; role: TeamInvitationRole; channels: Channel[]; team: Team; dryRun?: boolean}) => {
        controller?.abort();
        controller = new AbortController();
        let invites: TeamInviteWithError[] = [];
        let profiles: UserProfile[] = [];
        let success = false;

        setState((prevState) => ({...prevState, requestError: false}));

        try {
            if (role === 'team_guest') {
                const channelIds = channels.map((channel) => channel.id);
                invites = await Client4.sendEmailGuestInvitesToChannelsGracefully(team.id, channelIds, emails, '', dryRun, controller.signal);
            } else {
                invites = await Client4.sendEmailInvitesToTeamAndChannelsGracefully(team.id, emails, dryRun, controller.signal);
            }

            const userIds = invites.map((item) => item.user_id).filter((userId): userId is string => userId !== undefined && userId !== '');

            if (userIds.length > 0) {
                profiles = await Client4.getProfilesByIds(userIds, {}, controller.signal);
                dispatch(receivedUsers(profiles));
            }
        } catch (err) {
            setState((prevState) => ({...prevState, requestError: err}));
        }

        const profilesByEmail = keyBy(profiles, (profile) => profile.email);

        const result = invites.map((invite) => createInvitationResult(invite, profilesByEmail[invite.email]));

        const errors = result.map((result) => result.error).filter((error): error is InvitationError => error !== null);

        const criticalErrors = errors.filter((error) => error.type === 'error');

        const warnings = errors.filter((error) => error.type === 'warning');

        const sendingIssues = errors.filter((error) => error.type === 'sending_issue');

        const rateLimitIssues = errors.filter((error) => error.type === 'limit_exceeded');

        const hasCriticalErrors = criticalErrors.length > 0;

        const failedToSend = sendingIssues.length === result.length;

        const hasRateLimitIssues = rateLimitIssues.length > 0;

        const hasInvitedUsers = profiles.length > 0;

        const failedToSendDueToRateLimit = hasRateLimitIssues && !hasInvitedUsers;

        if (!dryRun && !hasCriticalErrors && !failedToSend && !failedToSendDueToRateLimit) {
            success = true;
        }

        setState((prevState) => ({
            ...prevState,
            result,
            profiles,
            errors,
            sendingIssues,
            criticalErrors,
            rateLimitIssues,
            warnings,
            success,
        }));

        (function sendStatistEvents() {
            uniqueCriticalErrorsCount = 0;
            uniqueWarningsCount = 0;
            uniqueErrors = {};

            const openChannels = channels.filter((channel) => channel.type === 'O');
            const privateChannels = channels.filter((channel) => channel.type === 'P');

            errors.forEach((error) => {
                const errorKey = JSON.stringify([error.email, error.code]);
                if (!errorsLog.has(errorKey)) {
                    if (!uniqueErrors[error.code]) {
                        uniqueErrors[error.code] = 0;
                    }
                    if (error.type === 'error') {
                        uniqueCriticalErrorsCount++;
                    } else {
                        uniqueWarningsCount++;
                    }
                    uniqueErrors[error.code]++;
                    errorsLog.add(errorKey);
                }
            });

            if (success) {
                sendToStatist('team.invite.send', {
                    teamid: team.id,
                    userCount: profiles.length,
                    userRole: role === 'team_guest' ? 'guest' : 'member',
                    channelCount: channels.length,
                    channelOpenCount: openChannels.length,
                    channelPrivateCount: privateChannels.length,
                });
            }

            if (uniqueCriticalErrorsCount > 0) {
                sendToStatist('team.invite.listError', {
                    teamid: team.id,
                    errorCount: uniqueCriticalErrorsCount,
                    emailCheck: uniqueErrors[InvitationErrorCode.API_INVALID_EMAIL] ?? 0,
                    emailLong: 0,
                    emailExist: 0,
                    userInTeam: 0,
                    userRegistered: 0,
                    userGuestOnly: uniqueErrors[InvitationErrorCode.API_INVALID_MEMBER_ROLE] ?? 0,
                    userMemberOnly: uniqueErrors[InvitationErrorCode.API_INVALID_GUEST_ROLE] ?? 0,
                    userSentMember: uniqueErrors[InvitationErrorCode.API_INVALID_TEAM_MEMBER] ?? 0,
                    userSentGuest: 0,
                });
            }
            if (uniqueWarningsCount > 0) {
                sendToStatist('team.invite.listWarning', {
                    teamid: team.id,
                    errorCount: uniqueWarningsCount,
                    userInvited: uniqueErrors[InvitationErrorCode.API_INVITATION_HAS_BEEN_SENT] ?? 0,
                });
            }
        }());
    };

    return {
        ...state,
        sendInvites,
        profilesByEmail,
        failedToSendInvites,
        rateLimitTimeoutMinutes,
        reset,
    };
}

