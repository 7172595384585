import type {Ref} from 'react';
import React from 'react';
import classNames from 'classnames';

import {Tooltip} from '../../../atoms/tooltip';
import {useTooltip} from '../../../hooks/tooltip';
import {DownloadOutlineIcon} from '../../../icons/download-outline';
import {CheckIcon} from '../../../icons/check';
import {LinkVariantIcon} from '../../../icons/link-variant';

import styles from './styles.module.css';

type Props = {
    className?: string;
    showCopyLink: boolean;
    downloadTooltipText?: string;
    downloadTooltipAriaText?: string;
    isInternalImage: boolean;
    fileURL?: string;
    src?: string;
    copyTooltipText?: string;
    linkCopiedRecently: boolean;
    copyLinkAriaText?: string;
    onCopyLink: () => void;
    showDownloadButton?: boolean;
};

export const Actions = ({
    className,
    downloadTooltipText,
    downloadTooltipAriaText,
    isInternalImage,
    fileURL,
    src,
    copyTooltipText,
    showCopyLink,
    showDownloadButton,
    linkCopiedRecently,
    copyLinkAriaText,
    onCopyLink,
}: Props) => {
    const downloadTooltipSettings = useTooltip();
    const copyLinkTooltipSettings = useTooltip();

    if (!showCopyLink && !showDownloadButton) {
        return null;
    }

    const downloadTooltip = (
        <Tooltip
            targetRef={downloadTooltipSettings.triggerRef}
            state={downloadTooltipSettings.tooltipState}
            {...downloadTooltipSettings.tooltipProps}
        >
            {downloadTooltipText!}
        </Tooltip>
    );

    const copyLinkTooltip = (
        <Tooltip
            targetRef={copyLinkTooltipSettings.triggerRef}
            state={copyLinkTooltipSettings.tooltipState}
            {...copyLinkTooltipSettings.tooltipProps}
        >
            {copyTooltipText!}
        </Tooltip>
    );

    return (
        <div className={classNames(styles.actionsContainer, className)}>
            {showCopyLink && (
                <div className={styles.action}>
                    <button
                        type='button'
                        ref={copyLinkTooltipSettings.triggerRef as Ref<HTMLButtonElement>}
                        {...copyLinkTooltipSettings.triggerProps}
                        className={classNames(styles.imageCopyLink, {
                            [styles.imageCopyLinkRecentlyCopied]: linkCopiedRecently,
                        })}
                        aria-label={copyLinkAriaText}
                        onClick={onCopyLink}
                    >
                        {linkCopiedRecently ? <CheckIcon size={20} /> : <LinkVariantIcon size={20} />}
                    </button>
                    {copyLinkTooltipSettings.tooltipState.isOpen && copyLinkTooltip}
                </div>
            )}
            {showDownloadButton && (
                <div className={styles.action}>
                    <a
                        ref={downloadTooltipSettings.triggerRef as Ref<HTMLAnchorElement>}
                        {...downloadTooltipSettings.triggerProps}
                        href={isInternalImage ? fileURL : src}
                        className={styles.imageDownload}
                        target='_blank'
                        rel='noopener noreferrer'
                        download={true}
                        role={isInternalImage ? 'button' : undefined}
                        aria-label={downloadTooltipAriaText}
                    >
                        <DownloadOutlineIcon size={20} />
                    </a>
                    {downloadTooltipSettings.tooltipState.isOpen && downloadTooltip}
                </div>
            )}
        </div>
    );
};
