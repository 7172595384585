import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Post} from '@mattermost/types/posts';
import type {PostedEvent} from '../types/posted_event';
import type {AppDispatch, RootState} from 'stores/redux_store';
import {getMyChannelMember as fetchMyChannelMember} from 'mattermost-redux/actions/channels';
import {getMyChannelMember} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {completePostReceive} from 'actions/new_post';
import {loadNewDMIfNeeded, loadNewGMIfNeeded} from 'actions/user_actions';
import {ChannelType} from 'features/sidebar';
import {handleNewPost as handleNewPostForSidebar} from 'features/sidebar/actions/handle_new_post';

type Payload = {
    post: Post;
    eventData: PostedEvent['data'];
};

export const handleNewPost = createAsyncThunk('posts/actions/handleNewPost', async (payload: Payload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch as AppDispatch;
    const state = thunkAPI.getState() as RootState;
    const {post, eventData} = payload;
    const currentUserId = getCurrentUserId(state);

    const myChannelMember = getMyChannelMember(state, post.channel_id);

    const hasMyChannelMember = myChannelMember?.user_id === currentUserId;

    if (!hasMyChannelMember) {
        await dispatch(fetchMyChannelMember(post.channel_id));
    }

    await dispatch(completePostReceive(post, eventData, !hasMyChannelMember));

    if (!eventData) {
        return {data: true};
    }

    if (eventData.channel_type === ChannelType.DM) {
        await dispatch(loadNewDMIfNeeded(post.channel_id));
    }
    if (eventData.channel_type === ChannelType.GM) {
        await dispatch(loadNewGMIfNeeded(post.channel_id));
    }

    dispatch(handleNewPostForSidebar(post));

    return {data: true};
});
