export const TIMEOUT = 2500; // 2.5s

export enum RejectReason {
    NOT_A_MEMBER = 'not_a_member',
    IS_CURRENT_USER_POST = 'is_current_user_post',
    IS_CURRENT_USER_WEBHOOK_POST = 'is_current_user_webhook_post',
    IS_SYSTEM_MESSAGE = 'is_system_message',
    NOTIFICATIONS_TURNED_OFF = 'notifications_turned_off',
    CURRENT_USER_NOT_AVAILABLE = 'current_user_not_available',
    CHANNEL_MUTED = 'channel_muted',
    NOT_MENTIONED = 'not_mentioned',
    NOT_FOLLOWING_THREAD = 'not_following_thread',
    THREAD_IS_VISIBLE = 'thread_is_visible',
    CURRENT_CHANNEL_POST = 'current_channel_post',
}
