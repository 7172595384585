
import omit from '@tinkoff/utils/object/omit';

import {Client4} from 'mattermost-redux/client';
import type {ActivityPayload, ActivityResponse} from '../types';

export const fetchActivity = async (payload: ActivityPayload): Promise<ActivityResponse> => {
    const requester = Client4.requester;

    const options = omit(['url'], Client4.getTimeApiClientOptions({}));

    const queryObject: Record<string, string> = {};

    if (payload.limit) {
        queryObject.limit = payload.limit.toString();
    }

    if (payload.pageToken) {
        queryObject.page_token = payload.pageToken;
    }

    if (payload.sortBy) {
        queryObject.sort_by = payload.sortBy;
    }

    if (payload.signal) {
        options.signal = payload.signal;
    }

    const queryString = (new URLSearchParams(queryObject)).toString();
    const url = `${Client4.getUrl()}/api/v4/users/${payload.userId}/sidebar/activity${queryString ? `?${queryString}` : ''}`;

    const {data} = await requester.get<ActivityResponse>(url, options);

    return data;
};
