import {store} from './helpers';

export const MENU_SHOW = 'CONTEXTMENU_SHOW';
export const MENU_HIDE = 'CONTEXTMENU_HIDE';

function dispatchGlobalEvent(eventName, opts, target = window) {
    const event = new window.CustomEvent(eventName, {detail: opts});

    target.dispatchEvent(event);
    Object.assign(store, opts);
}

export const showMenu = (opts = {}, target) => {
    dispatchGlobalEvent(MENU_SHOW, Object.assign({}, opts, {type: MENU_SHOW}), target);
};

export const hideMenu = (opts = {}, target) => {
    dispatchGlobalEvent(MENU_HIDE, Object.assign({}, opts, {type: MENU_HIDE}), target);
};
