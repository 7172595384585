import {ComponentProps, ForwardedRef, forwardRef, useMemo, useRef} from 'react';

import {useIntl} from 'react-intl';

import {useTooltipTriggerState} from '@react-stately/tooltip';

import {useTooltipTrigger} from '@react-aria/tooltip';

import classNames from 'classnames';

import {keyBy} from 'lodash';

import {InputTag} from '@time-webkit/all/organisms/input-tag';
import {InfoOutlineIcon} from '@time-webkit/all/icons/info-outline';
import {Tooltip} from '@time-webkit/all/atoms/tooltip';

import {UserProfile} from '@mattermost/types/users';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {InvitationError} from '../../invitation_error';

import {TagProfile} from './tag-profile';

import styles from './input-invite-by-email.module.css';

type Props = ComponentProps<typeof InputTag> & {
    profiles?: UserProfile[];
    invitationErrors?: InvitationError[];
    allowedDomains?: string[];
    loadingStatuses?: Record<string, boolean>;
}

const DEFAULT_ERRORS: InvitationError[] = [];
const DEFAULT_PROFILES: UserProfile[] = [];
const DEFAULT_LOADING_STATUS: Record<string, boolean> = {};
const DEFAULT_ALLOWED_DOMAINS: string[] = [];

export const InputInviteByEmail = forwardRef(
    (
        {
            tags,
            profiles = DEFAULT_PROFILES,
            allowedDomains = DEFAULT_ALLOWED_DOMAINS,
            loadingStatuses = DEFAULT_LOADING_STATUS,
            invitationErrors = DEFAULT_ERRORS,
            error,
            maxLength,
            ...props
        }: Props,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const intl = useIntl();
        const [bodySTrueTypography, bodyXSTypography] = useTypography([{size: TypographySize.BodySTrue, noDefaultColor: true}, {size: TypographySize.BodyXS, noDefaultColor: true}]);
        const tooltipIconRef = useRef<HTMLSpanElement>(null);
        const tooltipTriggerState = useTooltipTriggerState({delay: 200});
        const {triggerProps, tooltipProps} = useTooltipTrigger({}, tooltipTriggerState, tooltipIconRef);

        const errorsByEmail = useMemo(() => keyBy(invitationErrors, (error) => error.email), [invitationErrors]);
        const profilesByEmail = useMemo(() => keyBy(profiles, (profile) => profile.email), [profiles]);

        const isInvalid = useMemo(() => {
            return invitationErrors.some((error) => error?.type === 'error');
        }, [invitationErrors]);

        const renderTag = (label: string) => {
            const profile = profilesByEmail[label];
            const error = errorsByEmail[label];
            const loading = loadingStatuses[label];

            return (
                <TagProfile
                    key={label}
                    label={label}
                    profile={profile}
                    loading={loading}
                    variant={error?.type}
                    tooltip={error?.getLocalizedMessage(intl)}
                    maxLength={maxLength}
                />
            );
        };

        return (
            <>
                <InputTag
                    {...props}
                    ref={ref}
                    tags={tags}
                    maxLength={maxLength}
                    error={error || isInvalid}
                    formatLabel={(label) => label.toLowerCase()}
                    iconRight={
                        allowedDomains.length > 0 ? (
                            <span
                                {...triggerProps}
                                ref={tooltipIconRef}
                                className={styles.iconInfo}
                            >
                                <InfoOutlineIcon
                                    width={16}
                                    height={16}
                                />
                            </span>) : null
                    }
                >
                    {tags.map(renderTag)}
                </InputTag>
                {tooltipTriggerState.isOpen && (
                    <Tooltip
                        {...tooltipProps}
                        state={tooltipTriggerState}
                        targetRef={tooltipIconRef}
                    >
                        <p className={classNames(styles.domainListTitle, bodySTrueTypography)}>
                            {intl.formatMessage({
                                id: 'invite.members.allowed_domains',
                                defaultMessage: 'Allowed domains',
                            }, {count: allowedDomains.length})}
                        </p>
                        {allowedDomains.length > 1 ? (
                            <ul className={styles.domainList}>
                                {allowedDomains.map((domain) => (
                                    <li className={bodyXSTypography} key={domain}>{`∙ @${domain}`}</li>
                                ))}
                            </ul>
                        ) : (
                            <p className={classNames(styles.domainListSubtitle, bodySTrueTypography)}>
                                {`@${allowedDomains[0]}`}
                            </p>
                        )}

                    </Tooltip>
                )}
            </>
        );
    },
);

InputInviteByEmail.displayName = 'InputInviteByEmail';
