/* eslint-disable @typescript-eslint/ban-types */
import {PureComponent} from 'react';
import {Overlay} from 'react-bootstrap';
import memoize from 'memoize-one';

import {popOverOverlayPosition} from 'utils/position_utils';
import {Constants} from 'utils/constants';

import EmojiPickerTabs from './emoji_picker_tabs';

type Props = {
    show: boolean;
    container?: React.ReactNode;
    target: any;
    onEmojiClick: Function;
    onGifClick?: Function;
    onHide: Function;
    topOffset?: number;
    rightOffset?: number;
    leftOffset?: number;
    spaceRequiredAbove?: number;
    spaceRequiredBelow?: number;
    enableGifPicker?: boolean;
    defaultHorizontalPosition?: 'left' | 'right';
};
export default class EmojiPickerOverlay extends PureComponent<Props> {
    // An emoji picker in the center channel is contained within the post list, so it needs space
    // above for the channel header and below for the post textbox
    static CENTER_SPACE_REQUIRED_ABOVE = 476;
    static CENTER_SPACE_REQUIRED_BELOW = 497;

    // An emoji picker in the RHS isn't constrained by the RHS, so it just needs space to fit
    // the emoji picker itself
    static RHS_SPACE_REQUIRED_ABOVE = 420;
    static RHS_SPACE_REQUIRED_BELOW = 420;

    // Reasonable defaults calculated from from the center channel
    static defaultProps = {
        spaceRequiredAbove: EmojiPickerOverlay.CENTER_SPACE_REQUIRED_ABOVE,
        spaceRequiredBelow: EmojiPickerOverlay.CENTER_SPACE_REQUIRED_BELOW,
        enableGifPicker: false,
    };

    private handleClickOutside = (e: MouseEvent) => {
        if (!this.props.show) {
            return;
        }

        // @TODO: ПЕРЕЙТИ НА НОРМАЛЬНУЮ ЛИБУ ДЛЯ ОВЕРЛЕЕВ/ПОПАПОВ
        const tabs = document.getElementById('emoji-picker-tabs');
        const picker = document.getElementById('emojiPicker');

        if (tabs?.contains(e.target) || picker?.contains(e.target)) {
            return;
        }

        this.props.onHide();
    };

    private handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === Constants.KeyCodes.ESCAPE[0]) {
            this.props.onHide();
        }
    }

    componentDidMount(): void {
        if (this.props.show) {
            this.addEventListeners();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.show && !prevProps.show) {
            setTimeout(() => {
                this.addEventListeners();
            }, 300);
        }

        if (!this.props.show && prevProps.show) {
            this.removeEventListeners();
        }
    }

    private addEventListeners = () => {
        window.addEventListener('click', this.handleClickOutside);
        window.addEventListener('keyup', this.handleKeyUp);
    }

    private removeEventListeners = () => {
        window.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount(): void {
        this.removeEventListeners();
    }

    emojiPickerPosition = memoize((emojiTrigger, show) => {
        let calculatedRightOffset: number = Constants.DEFAULT_EMOJI_PICKER_RIGHT_OFFSET;

        if (!show) {
            return calculatedRightOffset;
        }

        if (emojiTrigger) {
            calculatedRightOffset =
                window.innerWidth -
                emojiTrigger.getBoundingClientRect().left -
                Constants.DEFAULT_EMOJI_PICKER_LEFT_OFFSET;

            if (calculatedRightOffset < Constants.DEFAULT_EMOJI_PICKER_RIGHT_OFFSET) {
                calculatedRightOffset = Constants.DEFAULT_EMOJI_PICKER_RIGHT_OFFSET;
            }
        }

        return calculatedRightOffset;
    });

    getPlacement = memoize((target, spaceRequiredAbove, spaceRequiredBelow, defaultHorizontalPosition, show) => {
        if (!show) {
            return 'top';
        }

        if (target) {
            const targetBounds = target.getBoundingClientRect();
            return popOverOverlayPosition(
                targetBounds,
                window.innerHeight,
                spaceRequiredAbove,
                spaceRequiredBelow,
                defaultHorizontalPosition,
            );
        }

        return 'top';
    });

    render() {
        const {target, rightOffset, spaceRequiredAbove, spaceRequiredBelow, defaultHorizontalPosition, show} =
            this.props;

        const calculatedRightOffset =
            typeof rightOffset === 'undefined' ? this.emojiPickerPosition(target(), show) : rightOffset;
        const placement = this.getPlacement(
            target(),
            spaceRequiredAbove,
            spaceRequiredBelow,
            defaultHorizontalPosition,
            show,
        );

        return (
            <Overlay
                show={show}
                placement={placement}
                rootClose={false}
                container={this.props.container}
                onHide={this.props.onHide}
                target={target}
                animation={false}
            >
                <EmojiPickerTabs
                    enableGifPicker={this.props.enableGifPicker}
                    onEmojiClose={this.props.onHide}
                    onEmojiClick={this.props.onEmojiClick}
                    onGifClick={this.props.onGifClick}
                    rightOffset={calculatedRightOffset}
                    topOffset={this.props.topOffset}
                    leftOffset={this.props.leftOffset}
                />
            </Overlay>
        );
    }
}
