import type {Channel} from '@mattermost/types/channels';
import type {Team} from '@mattermost/types/teams';
import type {NotificationSound} from '../constants/notification_sound';

type Payload = {
    title: string;
    body: string;
    channel: Pick<Channel, 'name' | 'type'>;
    teamId: Team['id'];
    silent: boolean;
    data: {soundName: NotificationSound};
    url: string;
};

const DISPATCH_NOTIFICATION_MSG_TYPE = 'dispatch-notification';

export function sendDesktopAppNotification(payload: Payload) {
    return window.postMessage(
        {
            type: DISPATCH_NOTIFICATION_MSG_TYPE,
            message: payload,
        },
        window.location.origin,
    );
}
