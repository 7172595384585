import {ComponentProps, ReactNode, useEffect, useMemo, useRef, useState} from 'react';

import {useFocusWithin} from '@react-aria/interactions';

import noop from '@tinkoff/utils/function/noop';

import classNames from 'classnames';

import {useId} from '@react-aria/utils';

import {Dropdown, DropdownList} from '../dropdown';

import {InputTagStandalone} from '../input-tag';

import {ListBoxItem, useListBoxKeyboard, useListBoxState} from '../listbox';

import styles from './input-autocomplete.module.css';

const COMMIT_KEYS: string[] = [];

type Props<Payload = unknown> = Omit<ComponentProps<typeof InputTagStandalone>, 'tags' | 'selectedTag' | 'editableTag' | 'onAddTag' | 'onSelectTag' | 'commitKeys'> & {
    items: Array<ListBoxItem<Payload>>;
    selectedItem?: ListBoxItem['label'];
    autocompleteItems: Array<ListBoxItem<Payload>>;
    maxHeight?: number;
    onSelectItem?: (label: ListBoxItem['label']) => void;
    onAddItem?: (item: ListBoxItem) => void;
    renderItem: (item: ListBoxItem<Payload>, isFocused?: boolean, isSelected?: boolean) => ReactNode;
};

export const InputAutocomplete = <Payload = unknown>({
    items,
    selectedItem,
    autocompleteItems,
    renderItem,
    className,
    maxHeight,
    onAddItem,
    onInputChange,
    onSelectItem,
    ...props
}: Props<Payload>) => {
    const dropdownId = useId();
    const isFirstRender = useRef(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isClosedByUser, setClosedByUser] = useState(false);
    const [listBoxState, listBoxActionsOriginal] = useListBoxState({items: autocompleteItems});
    const tags = useMemo(() => items.map((item) => item.label), [items]);
    const listBoxActions = useMemo(() => (
        {
            ...listBoxActionsOriginal,
            onSelectItem: (item: ListBoxItem) => {
                listBoxActionsOriginal.onSelectItem(item);
                onAddItem?.(item);
                setClosedByUser(true);
            },
        }
    ), [listBoxActionsOriginal, onAddItem]);
    const [isFocused, setFocused] = useState(document.activeElement === inputRef.current);
    const {onKeyDown} = useListBoxKeyboard(listBoxState, listBoxActions);

    const isAutocompleteEmpty = autocompleteItems.length === 0;
    const isPopoverOpen = isFocused && !isAutocompleteEmpty && !isClosedByUser;

    const {focusWithinProps} = useFocusWithin({
        onFocusWithinChange: (isFocused) => {
            setFocused(isFocused);
        },
    });

    const handleInputChange = (value: string) => {
        if (value) {
            setClosedByUser(false);
        }
        onInputChange?.(value);
    };

    useEffect(() => {
        if (!isFirstRender.current) {
            listBoxActions.onReset(autocompleteItems);
        }
    }, [autocompleteItems, listBoxActions, isFirstRender]);

    useEffect(() => {
        isFirstRender.current = false;
    }, [isFirstRender]);

    return (
        <>
            <div
                {...focusWithinProps}
                className={classNames(styles.root, className)}
                ref={containerRef}
            >
                <InputTagStandalone
                    {...props}
                    commitKeys={COMMIT_KEYS}
                    selectedTag={selectedItem}
                    role='combobox'
                    aria-expanded={isPopoverOpen}
                    aria-controls={isPopoverOpen ? dropdownId : undefined}
                    ref={inputRef}
                    tags={tags}
                    onKeyDown={onKeyDown}
                    onInputChange={handleInputChange}
                    onSelectTag={onSelectItem}
                    onAddTag={noop}
                    onAddTagsFromString={noop}
                />
            </div>
            <Dropdown
                targetRef={containerRef}
                placement='bottom left'
                open={isPopoverOpen}
                shouldFlip={true}
                shouldMirrorWidth={true}
                maxHeight={maxHeight}
                onClose={() => setClosedByUser(true)}
            >
                <DropdownList
                    id={dropdownId}
                    items={autocompleteItems}
                    state={listBoxState}
                    actions={listBoxActions}
                    renderItem={renderItem}
                />
            </Dropdown>
        </>
    );
};
