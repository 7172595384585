import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function UserIcon({size = 16, className, ...props}: Props) {
    return (
        <span
            className={classNames('icon time-icon', className)}
            {...props}
        >
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 16 18'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M14 12.2498L11.3333 10.6665C11.5 10.4165 11.75 10.1665 11.9167 9.83317C12.5833 8.6665 13 7.1665 13 5.6665C13 2.6665 11 0.666504 8 0.666504C5 0.666504 3 2.6665 3 5.6665C3 7.1665 3.41667 8.6665 4.08333 9.9165C4.25 10.2498 4.41667 10.4998 4.66667 10.7498L2 12.3332C1.08333 12.6665 0.5 13.6665 0.5 14.6665C0.5 16.1665 1.66667 17.3332 3.16667 17.3332H12.8333C14.3333 17.3332 15.5 16.1665 15.5 14.6665C15.5 13.6665 14.9167 12.6665 14 12.2498ZM4.66667 5.6665C4.66667 3.33317 6.16667 2.33317 8 2.33317C9.83333 2.33317 11.3333 3.33317 11.3333 5.6665C11.3333 7.1665 10.8333 8.83317 9.83333 9.83317C9.33333 10.3332 8.75 10.6665 8 10.6665C7.25 10.6665 6.66667 10.3332 6.16667 9.83317C5.16667 8.83317 4.66667 7.1665 4.66667 5.6665ZM12.8333 15.6665H3.16667C2.58333 15.6665 2.16667 15.2498 2.16667 14.6665C2.16667 14.2498 2.41667 13.9165 2.75 13.7498L6 11.8332C6.66667 12.1665 7.33333 12.3332 8 12.3332C8.66667 12.3332 9.33333 12.1665 9.91667 11.8332L13.1667 13.7498C13.5 13.9165 13.75 14.2498 13.75 14.6665C13.8333 15.2498 13.4167 15.6665 12.8333 15.6665Z' />
            </svg>
        </span>
    );
}
