import {TypographyProp, TypographySize} from '../../../hooks/typography';

export const TYPOGRAPHY_TEXT_SIZES: TypographyProp[] = [
    {
        size: TypographySize.BodyLTrue,
        noDefaultColor: true,
    },
    {
        size: TypographySize.BodyLTrue,
        noDefaultColor: true,
    },
    {
        size: TypographySize.BodyMTrue,
        noDefaultColor: true,
    },
    {
        size: TypographySize.BodyMTrue,
        noDefaultColor: true,
    },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BUTTON_SIZE {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    XSMALL = 'xSmall',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BUTTON_VARIANT {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',

    /**
     * @deprecated - используйте компонент Link
     */
    LINK = 'link',
    FLAT = 'flat',
    OUTLINE = 'outline'
}
