import path from '@tinkoff/utils/object/path';

import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {createThunkAction} from 'stores/create_thunk_action';
import {receivedChannelCategory} from '../actions/received_channel_category';
import {SidebarLogger} from '../logger';
import type {SidebarCategoryUpdatedEvent} from '../types/sidebar_category_updated';
import {parseSidebarCategoryUpdatedEvent} from '../utils/parseSidebarCategoryUpdatedEvent';

export const handleSidebarCategoryUpdatedEvent = (msg: SidebarCategoryUpdatedEvent) =>
    createThunkAction('sidebar/wsEventsHandlers/handlePreferencesChanged', (dispatch, getState) => {
        const state = getState();

        const currentTeamId = getCurrentTeamId(state);
        const parsedMessage = parseSidebarCategoryUpdatedEvent(msg);
        const messageTeamId = path(['broadcast', 'team_id'] as const, parsedMessage);

        /**
         * На самом деле в этом событии команда имеет не пустое значение ("")
         * только при обработке бэкендом запроса на обновление категорий пользователя.
         * Но, проверка на текущую команду была в старом обработчике, поэтому перенес.
         *
         * Возможно, в светлом будущем заработает.
         * Логика такая, что при переключении команды происходит
         * перегрузка сайдбара со всеми категориями
         *
         * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/UpdateSidebarCategoriesForTeamForUser
         */
        if (!messageTeamId || messageTeamId !== currentTeamId) {
            return;
        }

        /**
         * В случае "реального" обновления событие содержит категории которые были обновлены,
         * поэтому мы можем их заменить в состоянии без похода в АПИ
         */
        const updatedCategories = path(['data', 'updatedCategories'] as const, parsedMessage);

        if (!updatedCategories?.length) {
            return;
        }

        for (const updatedCategory of updatedCategories) {
            dispatch(receivedChannelCategory(updatedCategory));
        }

        SidebarLogger.info({
            event: 'sidebar-categories-updated',
            updatedCategories: updatedCategories.map(({id}) => id),
        });
    });
