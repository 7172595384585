export const IMAGE_TYPE_GIF = 'gif';
export const TEXT_TYPES = ['txt', 'rtf'];
export const IMAGE_TYPES = ['jpg', 'gif', 'bmp', 'png', 'jpeg', 'tiff', 'tif', 'psd'];
export const AUDIO_TYPES = [
    'mp3',
    'wav',
    'wma',
    'm4a',
    'flac',
    'aac',
    'm4r',
    'aiff',
    'alac',
    'au',
    'amr',
    'awb',
    'm4b',
    'm4p',
    'mpc',
    'ra',
    'wv',
];
export const VIDEO_TYPES = [
    'mp4',
    'avi',
    'webm',
    'mkv',
    'wmv',
    'mpg',
    'mov',
    'flv',
    'swf',
    'vob',
    'qt',
    'ogv',
    'ogx',
    'ogg',
    '3gp',
    '3g2',
    'mpeg',
    'm2v',
    'mpe',
    'mpv',
    'viv',
    'amv',
];
export const PRESENTATION_TYPES = ['ppt', 'pptx'];
export const SPREADSHEET_TYPES = ['xlsx', 'csv'];
export const WORD_TYPES = ['doc', 'docx'];
export const CODE_TYPES = [
    'applescript',
    'as',
    'atom',
    'bas',
    'bash',
    'boot',
    'c',
    'c++',
    'cake',
    'cc',
    'cjsx',
    'cl2',
    'clj',
    'cljc',
    'cljs',
    'cljs.hl',
    'cljscm',
    'cljx',
    '_coffee',
    'coffee',
    'cpp',
    'cs',
    'csharp',
    'cson',
    'css',
    'd',
    'dart',
    'delphi',
    'dfm',
    'di',
    'diff',
    'django',
    'docker',
    'dockerfile',
    'dpr',
    'erl',
    'ex',
    'exs',
    'f90',
    'f95',
    'freepascal',
    'fs',
    'fsharp',
    'gcode',
    'gemspec',
    'go',
    'groovy',
    'gyp',
    'h',
    'h++',
    'handlebars',
    'hbs',
    'hic',
    'hpp',
    'hs',
    'html',
    'html.handlebars',
    'html.hbs',
    'hx',
    'iced',
    'irb',
    'java',
    'jinja',
    'jl',
    'js',
    'json',
    'jsp',
    'jsx',
    'kt',
    'ktm',
    'kts',
    'lazarus',
    'less',
    'lfm',
    'lisp',
    'log',
    'lpr',
    'lua',
    'm',
    'mak',
    'matlab',
    'md',
    'mk',
    'mkd',
    'mkdown',
    'ml',
    'mm',
    'nc',
    'obj-c',
    'objc',
    'osascript',
    'pas',
    'pascal',
    'perl',
    'php',
    'php3',
    'php4',
    'php5',
    'php6',
    'pl',
    'plist',
    'podspec',
    'pp',
    'ps',
    'ps1',
    'py',
    'r',
    'rb',
    'rs',
    'rss',
    'ruby',
    'scala',
    'scm',
    'scpt',
    'scss',
    'sh',
    'sld',
    'sql',
    'st',
    'styl',
    'swift',
    'tex',
    'thor',
    'v',
    'vb',
    'vbnet',
    'vbs',
    'veo',
    'xhtml',
    'xml',
    'xsl',
    'yaml',
    'zsh',
];
export const PDF_TYPES = ['pdf'];
export const PATCH_TYPES = ['patch'];
export const SVG_TYPES = ['svg'];

export const FILE_TYPES = {
    TEXT: 'text',
    IMAGE: 'image',
    AUDIO: 'audio',
    VIDEO: 'video',
    SPREADSHEET: 'spreadsheet',
    CODE: 'code',
    WORD: 'word',
    PRESENTATION: 'presentation',
    PDF: 'pdf',
    PATCH: 'patch',
    SVG: 'svg',
    OTHER: 'other',
};

export const MAX_FILENAME_LENGTH = 35;
