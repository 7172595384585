import {ComponentProps, useCallback} from 'react';

import {TagStandalone} from '../input-tag';

import {ListBoxItem} from '../listbox';

import {useInputAutocompleteContext} from './input-autocompltete-context';

type Props = Omit<ComponentProps<typeof TagStandalone>, 'selected' | 'onDelete' | 'onUnselect'> & {
    value: ListBoxItem['value'];
};

export const TagAutocompleteConnected = ({value, label, ...props}: Props) => {
    const {removeItem, unselectItem, selectedItem} = useInputAutocompleteContext();

    const handleDelete = useCallback(() => removeItem(value), [value, removeItem]);

    return (
        <TagStandalone
            {...props}
            label={label}
            selected={selectedItem === label}
            onDelete={handleDelete}
            onUnselect={unselectItem}
        />
    );
};
