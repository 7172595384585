import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import {hash} from 'object-code';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';
import type {Channel, ChannelMembership} from '@mattermost/types/channels';
import type {User} from 'features/users';

type Payload = {

    /**
     * Channel GUID
     */
    channelId: Channel['id'];

    /**
     * List of user ids
     */
    userIds: Array<User['id']>;
    withCache?: {ttl: number};
};

/**
 * Get a list of channel members based on the provided user ids.
 *
 * Must have the `read_channel` permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetChannelMembersByIds
 * @example "/channels/{channel_id}/members/ids"
 */
export const getChannelMembersByIds = createAsyncThunk(
    'sidebar/api/getChannelMembersByIds',
    async (payload: Payload, thunkAPI) => {
        const {channelId, userIds, withCache} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/channels/${channelId}/members/ids`;

        try {
            const {data} = await requester.post<ChannelMembership[]>(url, userIds, {
                ...options,
                id: `getChannelMembersByIds-${hash(userIds)}`,
                cache: withCache ? {...withCache, methods: ['post'], etag: false, interpretHeader: false} : false,
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
);
