import pathEq from '@tinkoff/utils/object/pathEq';

import type {GlobalState} from '@mattermost/types/store';
import {getConfig} from 'mattermost-redux/selectors/entities/general';

const CLIENT_CONFIG_KEY = 'EnableFilterSidebarDirect';
const ENABLED_VALUE = 'true';

/**
 * Включена ли новая логика фильтрации категории ЛС
 */
export const isFilteredSidebarEnabled = (state: GlobalState) => {
    const config = getConfig(state);

    return pathEq([CLIENT_CONFIG_KEY], ENABLED_VALUE, config);
};
