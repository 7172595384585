import {useCallback} from 'react';

import {autocompleteChannels} from 'actions/channel_actions';

import {autocompleteUsers} from 'features/interactive_dialog/actions/autocomplete_users';

import {useAppDispatch} from 'stores/redux_store';

import DialogElement from './dialog_element';

import type {OwnProps} from './types';

function DialogElementConnected(props: OwnProps) {
    const {channelId} = props;
    const dispatch = useAppDispatch();
    const autocompleteChannelsAction = useCallback((...args: any[]) => dispatch(autocompleteChannels(...args)), [dispatch]);

    const autocompleteUsersInCurrentChannel = useCallback(
        (search: string) => dispatch(autocompleteUsers(search, channelId)),
        [channelId, dispatch],
    );

    return (
        <DialogElement
            {...props}
            autocompleteUsers={autocompleteUsersInCurrentChannel}
            autocompleteChannels={autocompleteChannelsAction}
        />
    );
}

export default DialogElementConnected;
