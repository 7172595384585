import type {ParsedPostedEvent, PostedEvent} from '../types/posted_event';

export function parsePostedEvent(event: PostedEvent): ParsedPostedEvent {
    const parsed = {...event, data: {...event.data}} as unknown as ParsedPostedEvent;

    if (Array.isArray(event.data.followers)) {
        parsed.data.followers = event.data.followers;
    }

    if (Array.isArray(event.data.mentions)) {
        parsed.data.mentions = event.data.mentions;
    }

    if (event.data.followers && typeof event.data.followers === 'string') {
        parsed.data.followers = JSON.parse(event.data.followers);
    }

    if (event.data.mentions && typeof event.data.mentions === 'string') {
        parsed.data.mentions = JSON.parse(event.data.mentions);
    }

    if (!parsed.data.mentions) {
        parsed.data.mentions = [];
    }

    if (!parsed.data.followers) {
        parsed.data.followers = [];
    }

    if (typeof event.data.post === 'object') {
        parsed.data.post = event.data.post;
    }

    if (typeof event.data.post === 'string') {
        parsed.data.post = JSON.parse(event.data.post);
    }

    return parsed;
}
