const PERMISSIONS: readonly NotificationPermission[] = ['default', 'denied', 'granted'];

export async function requestPermissionToNotify(): Promise<NotificationPermission> {
    const permission = await Notification.requestPermission();

    if (PERMISSIONS.includes(permission)) {
        return permission;
    }

    // Handle browsers that don't support the promise-based syntax.
    const callbackPermission = await new Promise<NotificationPermission>((resolve) => {
        Notification.requestPermission(resolve);
    });

    return callbackPermission;
}
