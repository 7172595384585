import {createListenerMiddleware} from '@reduxjs/toolkit';

import type {Post} from '@mattermost/types/posts';

import {ChannelTypes, PostTypes, TeamTypes} from 'mattermost-redux/action_types';

import type {AppDispatch} from 'stores/redux_store';
import type {GlobalState} from 'types/store';
import {getChannel} from 'mattermost-redux/selectors/entities/channels';
import {removeChannelThreads} from '../actions/remove_channel_threads';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {removeThread} from '../actions/remove_thread';
import type {Team} from '@mattermost/types/teams';
import {removeTeamThreads} from '../actions/remove_team_threads';
import {getHasUnreadThreadsByTeamId} from '../actions/get_has_unread_threads_by_team_id';

export const userThreadsListener = createListenerMiddleware<GlobalState, AppDispatch, void>();

const CHANNEL_GONE_ACTION_TYPES = [ChannelTypes.RECEIVED_CHANNEL_DELETED, ChannelTypes.LEAVE_CHANNEL];

const TEAM_GONE_ACTION_TYPES = [TeamTypes.LEAVE_TEAM, TeamTypes.RECEIVED_TEAM_DELETED];

const ACTION_TYPES_TO_REACT_TO = [
    ...CHANNEL_GONE_ACTION_TYPES,
    PostTypes.POST_REMOVED,
    PostTypes.POST_DELETED,
    TEAM_GONE_ACTION_TYPES,
];

userThreadsListener.startListening({
    predicate(action) {
        const hasActionType = ACTION_TYPES_TO_REACT_TO.includes(action.type);

        return hasActionType;
    },
    effect(action, listenerAPI) {
        const {dispatch, getState} = listenerAPI;

        if (CHANNEL_GONE_ACTION_TYPES.includes(action.type)) {
            const channelId = action?.data?.id;
            const teamId = action?.data?.team_id;

            if (!channelId) {
                return;
            }

            const state = getState();
            const channel = getChannel(state, channelId);

            if (!channel) {
                return;
            }

            const fallbackTeamId = getCurrentTeamId(state);

            dispatch(
                removeChannelThreads({
                    channelId,
                    fallbackTeamId,
                }),
            );
            dispatch(getHasUnreadThreadsByTeamId({teamId: teamId || fallbackTeamId}));
        }

        if (action.type === PostTypes.POST_REMOVED || action.type === PostTypes.POST_DELETED) {
            const post = action.data as Post;

            if (!post.root_id && post.reply_count > 0) {
                const state = getState();

                const channel = getChannel(state, post?.channel_id);

                if (!channel) {
                    dispatch(removeThread({threadId: post.id}));
                    return;
                }

                const teamId = channel.team_id || getCurrentTeamId(state);

                dispatch(getHasUnreadThreadsByTeamId({teamId}));
                dispatch(removeThread({threadId: post.id}));
            }
        }

        if (TEAM_GONE_ACTION_TYPES.includes(action.type)) {
            const {id} = action.data as Team;

            dispatch(
                removeTeamThreads({
                    teamId: id,
                }),
            );
        }
    },
});

export const userThreadsListenerMiddleware = userThreadsListener.middleware;
