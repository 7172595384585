import Observable from 'zen-observable';
import baseLocalForage from 'localforage';
import {extendPrototype} from 'localforage-observable';

import {persistStore} from 'redux-persist';

import {featuresMiddlewares, featuresReducers} from 'features/reducers';

import {General, RequestStatus} from 'mattermost-redux/constants';
import configureServiceStore from 'mattermost-redux/store';

import {clearUserCookie} from 'actions/views/cookie';
import appReducers from 'reducers';
import {getBasePath} from 'selectors/general';
import type {GlobalState} from 'types/store';

let localForage: typeof baseLocalForage;

if (typeof window !== 'undefined') {
    // We need to polyfill Observable
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Observable = window.Observable || Observable;
    localForage = extendPrototype(baseLocalForage);
}

export default function configureStore(preloadedState: Partial<GlobalState> = {}) {
    const store = configureServiceStore({
        appReducers: {...appReducers, ...featuresReducers},
        preloadedState,
        middlewares: featuresMiddlewares,
    });

    const persistor = persistStore(store, null, () => {
        store.dispatch({
            type: General.STORE_REHYDRATION_COMPLETE,
            complete: true,
        });
    });

    // eslint-disable-next-line no-process-env
    if (process.env.NODE_ENV === 'test') {
        return store;
    }

    localForage.
        ready().
        then(() => {
            let purging = false;

            // Clean up after a logout
            store.subscribe(() => {
                const state = store.getState();
                const basePath = getBasePath(state);

                if (state.requests.users.logout.status === RequestStatus.SUCCESS && !purging) {
                    purging = true;

                    persistor.
                        purge().
                        catch(
                            (e: any) => {
                                // eslint-disable-next-line no-console
                                console.log(`[TiMe]: Caught error on logging out "${e}"`);
                            },
                        ).
                        finally(() => {
                            clearUserCookie();

                            // Preserve any query string parameters on logout, including parameters
                            // used by the application such as extra and redirect_to.
                            window.location.href = `${basePath}${window.location.search}`;

                            setTimeout(() => {
                                purging = false;
                            }, 500);
                        });
                }
            });
        }).
        catch((e) => {
            // eslint-disable-next-line no-console
            console.error('Failed to initialize localForage', e);
        });

    return store;
}
