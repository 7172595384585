import {createSelector} from 'reselect';

import {getMyChannelMembership} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

export const isCurrentUserChannelMember = createSelector(
    getMyChannelMembership,
    getCurrentUserId,
    (membership, currentUserId) => membership?.user_id === currentUserId,
);
