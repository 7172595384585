import {ComponentProps, ForwardedRef, forwardRef} from 'react';

import {InputTag} from './input-tag';
import {InputTagContext} from './input-tag-context';
import {useInputTagState} from './use-input-tag-state';

type Props = Omit<ComponentProps<typeof InputTag>, 'limit' | 'commitKeys' | 'newTagKey' | 'selectedTag' | 'onAddTag' | 'onSelectTag' | 'onAddTagsFromString'> & {
    limit?: number;
    separators?: string[];
    formatLabel?: (label: string) => string;
    onChange?: (nextTags: string[]) => void;
};

const DEFAULT_LIMIT = 100;
const DEFAULT_SEPARATORS = [',', ';', ' ', '\n'];

export const InputTagConnected = forwardRef(({
    tags,
    limit = DEFAULT_LIMIT,
    separators = DEFAULT_SEPARATORS,
    formatLabel,
    onChange,
    children,
    ...props
}: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const context = useInputTagState({tags, separators, formatLabel, onChange});
    return (
        <InputTag
            {...props}
            ref={ref}
            tags={tags}
            limit={limit}
            commitKeys={context.commitKeys}
            selectedTag={context.selectedTag}
            newTagKey={context.newTagKey}
            onAddTag={context.addTag}
            onSelectTag={context.selectTag}
            onAddTagsFromString={context.addTagsFromString}
        >
            <InputTagContext.Provider value={context}>{children}</InputTagContext.Provider>
        </InputTag>
    );
});
