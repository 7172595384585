import classnames from 'classnames';

import {SVGProps, type ReactNode} from 'react';

import {NotificationOutline} from '../../../icons/notification-outline';
import {AlertOutlineIcon} from '../../../icons/alert-outline';
import {InfoOutlineIcon} from '../../../icons/info-outline';

import styles from './styles.module.css';
import {useAlertTypography} from './use-alert-typography';

export enum AlertType {
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
}

export type AlertSize = 'large' | 'medium';

export type Props = {
    message?: string;
    secondaryMessage?: string;
    type?: AlertType;
    size?: AlertSize;
    className?: string;
    onClose?: () => void;
    children?: ReactNode;
};

const typeToIconComponent = {
    [AlertType.Info]: InfoOutlineIcon,
    [AlertType.Warning]: NotificationOutline,
    [AlertType.Danger]: AlertOutlineIcon,
};

export const Alert = ({message, secondaryMessage, size = 'large', type = AlertType.Info, className, onClose, children}: Props) => {
    const isCloseable = Boolean(onClose);
    const IconComponent = typeToIconComponent[type];
    const [messageTypography, secondaryMessageTypography] = useAlertTypography(size);

    const closeButtonSize = getButtonSize(size);

    return (
        <div role='alert' className={classnames(styles.alert, styles[type], styles[size], className)}>
            <div
                className={classnames(
                    styles.contentWrapper,
                    styles[type],
                    styles[size], {
                        [styles.secondaryMessageOnly]: !message && secondaryMessage,
                    })}
            >
                {size === 'large' && <IconComponent aria-hidden={true} className={styles.icon} width={20} height={20} />}
                {message && <div className={classnames(styles.message, messageTypography)}>{message}</div>}
                {secondaryMessage && <div className={classnames(styles.secondaryMessage, secondaryMessageTypography)}>{secondaryMessage}</div>}
                {isCloseable && (
                    <button type='button' className={styles.buttonClose} onClick={onClose} aria-label='close'>
                        <IconClose width={closeButtonSize} height={closeButtonSize} />
                    </button>
                )}
                {children && (
                    <div className={styles.body}>{children}</div>
                )}
            </div>
        </div>
    );
};

const IconClose = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        aria-hidden={true}
        viewBox='0 0 24 24'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' />
    </svg>
);

function getButtonSize(size: AlertSize) {
    switch (size) {
    case 'large':
        return 20;
    case 'medium':
        return 16;
    }
}
