import classNames from 'classnames';

import {useRef, ReactNode, KeyboardEvent, ClipboardEvent, forwardRef, ForwardedRef, ComponentProps, useLayoutEffect, useState} from 'react';

import {useId} from '@react-aria/utils';

import {InputContainer, useInput} from '../../atoms/input';

import {TypographySize, useTypography} from '../../hooks/typography';

import {AutoresizeableInput} from '../../atoms/autoresizeable-input';

import {useForwardedRef} from '../../hooks/use-forwarded-ref';

import {useCommitableInput} from './use-commitable-input';

import styles from './input-tag.module.css';

const INPUT_SIZE = 'medium';

type Props = Omit<ComponentProps<typeof AutoresizeableInput>, 'size' | 'value' | 'onChange' | 'onPaste'> & {
    tags: string[];
    limit: number;
    commitKeys: string[];
    error?: boolean;
    children?: ReactNode;
    newTagKey?: string;
    selectedTag?: string;
    iconRight?: ReactNode;
    label?: string;
    onAddTag?: (value: string) => void;
    onSelectTag?: (value: string) => void;
    onAddTagsFromString?: (input: string) => void;
    onInputChange?: (value: string) => void;
};

export const InputTag = forwardRef(({
    id,
    className,
    placeholder,
    error,
    children,
    maxLength,
    tags,
    commitKeys,
    newTagKey,
    limit,
    selectedTag,
    iconRight,
    label,
    onAddTag,
    onSelectTag,
    onAddTagsFromString,
    onKeyDown,
    onInputChange,
    onFocus,
    onBlur,
    ...props
}: Props, forwaredeRef: ForwardedRef<HTMLInputElement>) => {
    const counterTypographyStyle = useTypography({size: TypographySize.BodyM});
    const inputRef = useForwardedRef<HTMLInputElement>(forwaredeRef);
    const containerRef = useRef<HTMLDivElement>(null);
    const {value, onChange: onCommitableInputChange, onKeyDown: onCommitableInputKeyDown} = useCommitableInput({commitKeys, key: newTagKey, onCommit: onAddTag});
    const inputId = useId(id);
    const [isTouched, setTouched] = useState(false);
    const containerProps = useInput({inputRef, value, hideLabelOnFocus: true, inputId});

    const isEmpty = tags.length === 0;
    const counter = tags.length ? `${tags.length}/${limit}` : `${limit}`;

    const hasLabel = isEmpty && Boolean(label) && !containerProps.hideLabel;
    const hasPlaceholder = isEmpty && !hasLabel;

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && value === '') {
            const lastTag = tags[tags.length - 1];
            if (lastTag) {
                onSelectTag?.(lastTag);
                e.preventDefault();
                return;
            }
        }
        onCommitableInputKeyDown(e);
        onKeyDown?.(e);
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        onAddTagsFromString?.(e.clipboardData.getData('text'));
        e.preventDefault();
    };

    const handleInputChange = (value: string) => {
        if (tags.length < limit) {
            onCommitableInputChange(value);
            onInputChange?.(value);
        }
    };

    useLayoutEffect(() => {
        if (isTouched && !selectedTag && document.activeElement !== inputRef.current) {
            inputRef.current?.focus();
        }
    }, [isTouched, inputRef, selectedTag]);

    return (
        <InputContainer
            {...containerProps}
            label={hasLabel ? label : ''}
            className={classNames(styles.root, className)}
            size={INPUT_SIZE}
            error={error}
            multiline={true}
            ref={containerRef}
        >
            <div className={styles.contentWrapper}>
                {children}
                <span className={styles.inputWrapper}>
                    <AutoresizeableInput
                        {...props}
                        id={inputId}
                        ref={inputRef}
                        value={value}
                        error={error}
                        maxLength={maxLength}
                        className={styles.input}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onFocus={(event) => {
                            setTouched(true);
                            onFocus?.(event);
                        }}
                        onBlur={(event) => {
                            onAddTag?.(value);
                            onBlur?.(event);
                        }}
                        onPaste={handlePaste}
                        size={INPUT_SIZE}
                        placeholder={hasPlaceholder ? placeholder : ''}
                    />
                    <span className={styles.iconWrapper}>
                        {counter && <span className={classNames(styles.counter, counterTypographyStyle)}>{counter}</span>}
                        {iconRight}
                    </span>
                </span>
            </div>
        </InputContainer>
    );
});

InputTag.displayName = 'InputTag';
