import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Team} from '@mattermost/types/teams';
import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getThreadsStatsForUser} from '../api/get_threads_stats_for_user';

type Payload = {
    teamId: Team['id'];
};

/**
 * Checks if user has unread threads in a team
 */
export const getHasUnreadThreadsByTeamId = createAsyncThunk(
    'threads/actions/getHasUnreadThreadsByTeamId',
    async (payload: Payload, thunkAPI) => {
        const {teamId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as GlobalState;

        const currentUserId = getCurrentUserId(state);

        if (!currentUserId || !teamId) {
            return thunkAPI.rejectWithValue('no user or team id');
        }

        const result = await dispatch(
            getThreadsStatsForUser({
                teamId,
                userId: currentUserId,
            }),
        ).unwrap();

        return result;
    },
);
