import {useEffect} from 'react';

import {mark, Marks, measure, MeasureNames} from '@time-webkit/performance';
import {sendToStatist} from '@time-webkit/statist';

import type {Channel} from '@mattermost/types/channels';

export const useMeasureThreadOpenPerformance = (channel?: Channel) => {
    useEffect(() => {
        if (!channel) {
            return;
        }

        requestAnimationFrame(() => {
            mark(Marks.THREAD_OPENED, {
                detail: {
                    channelId: channel.id,
                    channelType: channel.type,
                },
            });
            measure({
                measureName: MeasureNames.OPEN_THREAD,
                callback: ({
                    channelId,
                    channelType,
                    postCount,
                    loadingTime,
                    requestTime,
                }) => {
                    sendToStatist('app.threadOpen', {
                        channelid: channelId,
                        channelType,
                        loadTime: loadingTime,
                        requestTime,
                        postCnt: postCount,
                    });
                },
            });
        });
    }, []);
};
