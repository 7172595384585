import {ComponentProps, ReactNode, useRef, forwardRef, useImperativeHandle} from 'react';
import classNames from 'classnames';

import {useId} from '@react-aria/utils';

import {InputControl} from './input-control';
import {InputContainer} from './input-container';
import {InputSize} from './input.types';
import {useInput} from './use-input';

import styles from './input.module.css';

type Props = Omit<ComponentProps<typeof InputControl>, 'size'> & {
    error?: boolean;
    size?: InputSize;
    label?: string;
    iconRight?: ReactNode;
    iconLeft?: ReactNode;
    sizeClassName?: string;
    hideLabelOnFocus?: boolean;
};

export const Input = forwardRef<HTMLInputElement, Props>(({
    id,
    value = '',
    size = 'medium',
    disabled,
    error,
    style,
    label,
    className,
    sizeClassName,
    placeholder,
    iconRight,
    iconLeft,
    prefix,
    postfix,
    hideLabelOnFocus,
    ...props
}, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const inputId = useId(id);
    const containerProps = useInput({inputRef, value, hideLabelOnFocus, isDisabled: disabled, inputId});

    const hasLabel = Boolean(label) && !containerProps.hideLabel;
    const hasPlaceholder = !hasLabel || containerProps.showLabelReduced;

    useImperativeHandle(ref, () => {
        return inputRef.current as HTMLInputElement;
    });

    return (
        <InputContainer
            {...containerProps}
            size={size}
            error={error}
            disabled={disabled}
            style={style}
            className={classNames(className, styles.root)}
            sizeClassName={sizeClassName}
            label={label}
        >
            {iconLeft && iconLeft}
            <InputControl
                {...props}
                id={inputId}
                value={value}
                className={classNames(styles.input, styles[size], {[styles.hasLabel]: hasLabel})}
                ref={inputRef}
                size={size}
                error={error}
                disabled={disabled}
                prefix={hasPlaceholder ? prefix : null}
                postfix={hasPlaceholder ? postfix : null}
                placeholder={hasPlaceholder ? placeholder : ''}
            />
            {iconRight && iconRight}
        </InputContainer>
    );
});
