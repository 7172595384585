import {ErrorPageTypes} from 'utils/constants';

import {UserProfile} from 'mattermost-redux/types/users';

import {ClientConfig, ClientLicense} from 'mattermost-redux/types/config';

import {isGuest} from 'mattermost-redux/utils/user_utils';

export const notFoundParams = {
    type: ErrorPageTypes.PAGE_NOT_FOUND,
};

const mfaPaths = ['/mfa/setup', '/mfa/confirm'];

const mfaAuthServices = ['', 'email', 'ldap'];

export function checkIfMFARequired(
    user: UserProfile | undefined,
    license: ClientLicense,
    config: Partial<ClientConfig>,
    path: string,
): boolean {
    if (
        license.MFA === 'true' &&
        config.EnableMultifactorAuthentication === 'true' &&
        config.EnforceMultifactorAuthentication === 'true' &&
        config.MultifactorAuthenticationType !== 'SMS' &&
        mfaPaths.indexOf(path) === -1
    ) {
        if (
            user &&
            isGuest(user.roles) &&
            config.GuestAccountsEnforceMultifactorAuthentication !== 'true'
        ) {
            return false;
        }
        if (
            user &&
            !user.mfa_active &&
            mfaAuthServices.indexOf(user.auth_service) !== -1
        ) {
            return true;
        }
    }
    return false;
}

export const SIGNIN_ROUTE = '/auths';
