import {createSelector} from 'reselect';

import type {Channel} from '@mattermost/types/channels';

import type {GlobalState} from 'types/store';
import {threadsAdapter} from '../entity_adapters/thread_entity_adapter';

import {getUserThreadsSliceState} from './get_user_threads_state';

export const selectThreadsByChannelId = createSelector(
    (state: GlobalState) => threadsAdapter.getSelectors().selectAll(getUserThreadsSliceState(state).threads),
    (_: GlobalState, channelId: Channel['id']) => channelId,
    (threads, channelId) => {
        return threads.filter((thread) => thread.channel_id === channelId || thread.post.channel_id === channelId);
    },
);
