import {Reducer} from 'redux';
import {configureStore as createStore} from '@reduxjs/toolkit';

import {createDynamicMiddlewares} from 'redux-dynamic-middlewares';

import {GlobalState} from 'mattermost-redux/types/store';

import serviceReducers from '../reducers';

import reducerRegistry from './reducer_registry';

import {createReducer} from './helpers';
import initialState from './initial_state';

/**
 * Configures and constructs the redux store. Accepts the following parameters:
 * preloadedState - Any preloaded state to be applied to the store after it is initially configured.
 * appReducer - An object containing any app-specific reducer functions that the client needs.
 */
function configureStore<S extends GlobalState, AppReducers extends Record<string, Reducer>>({
    appReducers,
    preloadedState,
    middlewares = [],
}: {
    appReducers: AppReducers;
    preloadedState: Partial<S>;
    middlewares: any[];
}) {
    const baseReducer = createReducer<S>(serviceReducers, appReducers);

    const dynamicMiddlewaresInstance = createDynamicMiddlewares();

    const store = createStore({
        reducer: baseReducer,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        preloadedState: {
            ...initialState,
            ...preloadedState,
        },

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }).
                concat(dynamicMiddlewaresInstance.enhancer).
                concat(middlewares),
        // eslint-disable-next-line no-process-env
        devTools: process.env.NODE_ENV === 'development',
    });

    reducerRegistry.setChangeListener((reducers: Record<string, Reducer>) => {
        store.replaceReducer(createReducer(reducers, serviceReducers, appReducers));
        Object.values(reducers).forEach((reducer) => {
            if ('middleware' in reducer) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dynamicMiddlewaresInstance.addMiddleware(reducer.middleware);
            }
        });
    });

    return store;
}

export default configureStore;
