import {AriaPositionProps, useOverlayPosition} from '@react-aria/overlays';
import {HTMLAttributes, RefObject} from 'react';

type Props = Omit<AriaPositionProps, 'overlayRef'> & {
    dropdownRef: RefObject<HTMLElement>;
    shouldMirrorWidth?: boolean;
}

type Result = HTMLAttributes<HTMLElement>

export const useDropdownPosition = ({shouldMirrorWidth, dropdownRef, ...props}: Props): Result => {
    const result = useOverlayPosition({...props, overlayRef: dropdownRef});

    let width: number | undefined;
    if (shouldMirrorWidth) {
        width = props.targetRef.current?.getBoundingClientRect().width;
    }
    return {
        ...result.overlayProps,
        style: {
            ...result.overlayProps.style,
            width,
        },
    };
};
