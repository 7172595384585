import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {createThunkAction} from 'stores/create_thunk_action';
import {changeThreadFollow} from '../actions/change_thread_follow';
import {handleThreadArrived} from '../actions/handle_thread_arrived';
import {updateThreadReadOnServer} from '../actions/update_thread_read_on_server';
import {isThreadManuallyReadByThreadId} from '../selectors/is_thread_manually_read_by_thread_id';
import {isThreadOpened} from '../selectors/is_thread_opened';
import type {ThreadUpdatedEvent} from '../types/thread_updated_event';
import {parseThreadUpdatedEvent} from '../utils/parse_thread_updated_event';

export const handleThreadUpdated = (msg: ThreadUpdatedEvent) =>
    createThunkAction('threads/wsEventsHandlers/handleThreadUpdated', async (dispatch, getState) => {
        try {
            const {data, broadcast} = parseThreadUpdatedEvent(msg);

            const state = getState();
            const currentUserId = getCurrentUserId(state);
            const currentTeamId = getCurrentTeamId(state);
            const {thread} = data;
            const threadId = thread.id;

            if (isThreadOpened(state, threadId) && !isThreadManuallyReadByThreadId(state, threadId)) {
                let lastViewedAt = Date.now();

                // Sometimes `Date.now()` was generating a timestamp before the
                // last_reply_at of the thread, thus marking the thread as unread
                // instead of read. Here we set the timestamp to after the
                // last_reply_at if this happens.
                if (lastViewedAt < thread.last_reply_at) {
                    lastViewedAt = thread.last_reply_at + 1;
                }

                // prematurely update thread data as read
                // so we won't flash the indicators when
                // we mark the thread as read on the server
                thread.last_viewed_at = lastViewedAt;
                thread.unread_mentions = 0;
                thread.unread_replies = 0;

                // mark thread as read on the server
                dispatch(
                    updateThreadReadOnServer({
                        userId: currentUserId,
                        teamId: currentTeamId,
                        threadId,
                        timestamp: lastViewedAt,
                    }),
                );
            }

            await dispatch(
                handleThreadArrived({
                    thread,
                    teamId: broadcast.team_id,
                    previousUnreadMentions: data.previous_unread_mentions,
                    previousUnreadReplies: data.previous_unread_replies,
                }),
            );

            // thread_updated событие приходит только если пользователь следит за тредом
            dispatch(
                changeThreadFollow({
                    following: true,
                    threadId,
                }),
            );

            // eslint-disable-next-line no-empty
        } catch {}
    });
