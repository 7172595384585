import type {User} from 'features/users';
import type {GlobalState} from 'types/store';
import {getBool as getBoolPreference} from 'mattermost-redux/selectors/entities/preferences';
import {Preferences} from 'mattermost-redux/constants';

export const getDMChannelShowPreference = (
    state: GlobalState,
    directChannelTeammateId: User['id'],
    fallbackValue = false,
) => getBoolPreference(state, Preferences.CATEGORY_DIRECT_CHANNEL_SHOW, directChannelTeammateId, fallbackValue);
