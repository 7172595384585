import {isDesktopApp, isEdge} from 'utils/user_agent';
import icon128 from 'images/icon128x128.png';
import iconWS from 'images/icon_WS.png';
import {reportErrorToSentry} from 'utils/sentry';

import {requestPermissionToNotify} from './request_permission_to_notify';

let requestedNotificationPermission = false;

export class SendPlatformNotificationError extends Error {}

/**
 * Displays a platform notification with the configured parameters.
 *
 * If successful in showing a notification, it resolves with a callback to manually close the
 * notification. If no error occurred but the user did not grant permission to show notifications, it
 * resolves with a no-op callback. Not all platforms support all features, and may
 * choose different semantics for the notifications.
 */
export async function sendPlatformNotification(
    params: Omit<NotificationOptions, ''> & {
        onClick?: Notification['onclick'];
        title?: string;
        keepIconForDesktop?: boolean;
    },
) {
    const {
        title = '',
        body = '',
        requireInteraction = false,
        silent = false,
        onClick,
        keepIconForDesktop,
        ...auxNotificationOptions
    } = params;

    if (!('Notification' in window)) {
        throw new SendPlatformNotificationError('Notification API is not supported');
    }

    if (typeof Notification.requestPermission !== 'function') {
        throw new SendPlatformNotificationError('Notification.requestPermission API is not supported');
    }

    if (Notification.permission !== 'granted' && requestedNotificationPermission) {
        // User didn't allow notifications
        throw new SendPlatformNotificationError('User did not allow notifications');
    }

    const notificationOptions: NotificationOptions = {
        icon: icon128,
        body,
        tag: body,
        requireInteraction,
        silent,
        ...auxNotificationOptions,
    };

    if (isEdge()) {
        notificationOptions.icon = iconWS;
    }

    requestedNotificationPermission = true;

    if (Notification.permission === 'default') {
        const permission = await requestPermissionToNotify();

        if (permission !== 'granted') {
            // User has denied notification for the site
            throw new SendPlatformNotificationError('User did not allow notifications');
        }
    }

    if (!keepIconForDesktop && isDesktopApp()) {
        delete notificationOptions.icon;
    }

    const notification = new Notification(title, notificationOptions);

    if (onClick) {
        notification.addEventListener('click', onClick);
    }

    notification.addEventListener('error', () => {
        reportErrorToSentry('Notification failed to show.');
    });

    return () => {
        notification.close();
    };
}
