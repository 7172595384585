import classnames from 'classnames';

import {isWindows} from 'utils/user_agent';

import type {Props} from './types';
import styles from './styles.module.css';

function constructClassName(props: Props) {
    const colorCls = !props.noDefaultColor && styles['base-color'];

    return classnames(styles.base, styles[props.size], colorCls, {
        [styles.windows]: isWindows(),
    });
}

/**
 * Генерирует класс типографии
 */
export function useTypography(props: Props[]): string[];
export function useTypography(props: Props): string;
export function useTypography(props: Props | Props[]) {
    if (Array.isArray(props)) {
        return props.map(constructClassName);
    }

    return constructClassName(props);
}
