import {ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef} from 'react';

import classNames from 'classnames';

import styles from './input-container.module.css';
import {InputSize} from './input.types';
import {useLabelTypography} from './use-label-typography';

type Props = HTMLAttributes<HTMLDivElement> & {
    size: InputSize;
    disabled?: boolean;
    error?: boolean;
    className?: string;
    sizeClassName?: string;
    focusVisible?: boolean;
    multiline?: boolean;
    label?: string;
    showLabelReduced?: boolean;
    hideLabel?: boolean;
    inputId?: string;
};

export const InputContainer = forwardRef(
    (
        {
            size,
            disabled,
            error,
            className,
            children,
            showLabelReduced,
            focusVisible,
            sizeClassName,
            multiline,
            label,
            inputId,
            hideLabel,
            ...props
        }: PropsWithChildren<Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const labelTypographyStyle = useLabelTypography(size, showLabelReduced);

        return (
            <div
                {...props}
                ref={ref}
                className={classNames(styles.root, className, !sizeClassName && styles[size], {
                    [styles.error]: error,
                    [styles.disabled]: disabled,
                    [styles.focusVisible]: focusVisible,
                }, sizeClassName)}
            >
                <div className={classNames(styles.contentWrapper, !sizeClassName && [styles[size]], {[styles.multiline]: multiline})}>
                    {label && !hideLabel ? (
                        <label
                            htmlFor={inputId}
                            className={classNames(styles.label, labelTypographyStyle, styles[size], {
                                [styles.reduced]: showLabelReduced,
                                [styles.error]: error,
                            })}
                        >
                            {label}
                        </label>
                    ) : null}
                    {children}
                </div>
            </div>
        );
    },
);

InputContainer.displayName = 'InputContainer';
