import {ForwardedRef, useRef} from 'react';

export const useForwardedRef = <T>(forwardedRef?: ForwardedRef<T>) => {
    const ref = useRef<T>(null);
    if (typeof forwardedRef === 'function') {
        forwardedRef(ref.current);
        return ref;
    }
    return forwardedRef ?? ref;
};
