import {isDetailWithViolations, isErrorInfo} from './type_guards';
import type {GrpcErrorInfo, GrpcError} from './types';

const isGrpcErrorFromServer = (error: unknown): error is GrpcError => {
    return (
        error instanceof Object &&
        'code' in error &&
        'message' in error &&
        'details' in error
    );
};

export class ApiV5Error {
    code: number;
    message: string;
    errorInfo: GrpcErrorInfo;
    errorFields: Array<{field: string; description: string}> | null;

    static fromGrpcError(grpcError: unknown) {
        if (!isGrpcErrorFromServer(grpcError)) {
            return undefined;
        }

        const errorInfo = grpcError.details.find(isErrorInfo);
        if (!errorInfo) {
            // something went wrong, internal server error
            return undefined;
        }

        let errorFields = null;
        const detailWithViolations = grpcError.details.find(isDetailWithViolations);

        if (detailWithViolations) {
            errorFields = detailWithViolations.field_violations;
        }

        return new ApiV5Error({
            code: grpcError.code,
            message: grpcError.message,
            errorInfo,
            errorFields,
        });
    }

    private constructor({
        code,
        message,
        errorInfo,
        errorFields,
    }: {
        code: number;
        message: string;
        errorInfo: GrpcErrorInfo;
        errorFields: Array<{field: string; description: string}> | null;
    }) {
        this.code = code;
        this.message = message;
        this.errorInfo = errorInfo;
        this.errorFields = errorFields;
    }
}
