export enum TypographySize {
    HeadingHero = 'Heading-Hero',
    Heading1 = 'Heading-1',
    Heading2 = 'Heading-2',
    Heading3 = 'Heading-3',
    Heading4 = 'Heading-4',
    Heading5 = 'Heading-5',
    Heading6 = 'Heading-6',
    BodyXXLTrue = 'Body-XXL-True',
    BodyXLTrue = 'Body-XL-True',
    BodyLTrue = 'Body-L-True',
    BodyMTrue = 'Body-M-True',
    BodySTrue = 'Body-S-True',
    BodyXSTrue = 'Body-XS-True',
    BodyXSSTrue = 'Body-XSS-True',
    BodyXXL = 'Body-XXL',
    BodyXL = 'Body-XL',
    BodyL = 'Body-L',
    BodyM = 'Body-M',
    BodyS = 'Body-S',
    BodyXS = 'Body-XS',
    BodyXXS = 'Body-XXS',
}
