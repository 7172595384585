import {ComponentProps, ForwardedRef, MouseEvent, KeyboardEvent, forwardRef, memo, useCallback, useRef} from 'react';

import classNames from 'classnames';

import {AutoresizeableInput} from '../../atoms/autoresizeable-input';

import {Tag} from './tag';

import styles from './tag-editable.module.css';
import {useCommitableInput} from './use-commitable-input';

type Props = Omit<ComponentProps<typeof Tag>, 'onKeyDown' | 'onDoubleClick'> & {
    commitKeys: string[];
    maxLength?: number;
    editMode?: boolean;
    onEdit?: (label: string) => void;
    onCommit?: (label: string, nextLabel: string) => void;
};

const TagEditableInternal = forwardRef(
    (
        {
            label,
            className,
            maxLength,
            selected,
            editMode,
            commitKeys,
            children,
            tooltip,
            size = 'medium',
            onDelete,
            onEdit,
            onCommit,
            onClick,
            ...props
        }: Props,
        ref: ForwardedRef<HTMLSpanElement>,
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);

        const handleCommit = (value: string) => {
            if (value.trim() === '') {
                onDelete?.(label);
            } else {
                onCommit?.(label, value);
            }
        };

        const inputProps = useCommitableInput({initialValue: label, commitKeys, onCommit: handleCommit});

        const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
            if (e.key === 'Enter' && selected && !editMode) {
                onEdit?.(label);
            }
        };

        const input = (
            <AutoresizeableInput
                {...inputProps}
                aria-label={label}
                ref={inputRef}
                autoFocus={true}
                size={size}
                maxLength={maxLength}
                onBlur={() => handleCommit?.(inputProps.value)}
            />
        );

        const handleDoubleClick = useCallback(() => {
            onEdit?.(label);
        }, [label, onEdit]);

        const handleClick = useCallback(
            (e: MouseEvent<HTMLInputElement>) => {
                if (editMode) {
                    e.stopPropagation();
                }
                onClick?.(e);
            },
            [editMode, onClick],
        );

        return (
            <Tag
                {...props}
                ref={ref}
                onKeyDown={handleKeyDown}
                selected={selected}
                label={label}
                onDelete={editMode ? undefined : onDelete}
                size={size}
                tooltip={editMode ? undefined : tooltip}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                className={classNames(styles.root, className, {
                    [styles.editMode]: editMode,
                })}
            >
                {editMode ? input : children}
            </Tag>
        );
    },
);

const TagEditable = memo(TagEditableInternal);
TagEditable.displayName = 'TagEditable';

export {TagEditable};
