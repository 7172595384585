import type {User} from 'features/users';

import type {Meta} from './types';

export function getMentionsFromMessageProps(meta: Pick<Meta, 'mentions'>): Array<User['id']> {
    if (typeof meta.mentions === 'string') {
        return JSON.parse(meta.mentions) || [];
    }

    if (Array.isArray(meta.mentions)) {
        return meta.mentions;
    }

    return [];
}

export function getFollowersFromMessageProps(meta: Meta): Array<User['id']> {
    if (typeof meta.followers === 'string') {
        return JSON.parse(meta.followers) || [];
    }

    if (Array.isArray(meta.followers)) {
        return meta.followers;
    }

    return [];
}
