import type {ParsedPreferencesChangedEvent, PreferencesChangedEvent} from '../types/preferences_changed';

export function parsePreferencesChangedEvent(
    preferencesChangedEvent: PreferencesChangedEvent,
): ParsedPreferencesChangedEvent {
    return {
        ...preferencesChangedEvent,
        data: {
            ...preferencesChangedEvent.data,

            // это поле в сообщении приходит в виде JSON-строки
            preferences: JSON.parse(preferencesChangedEvent.data.preferences),
        },
    };
}
