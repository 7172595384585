import {ComponentProps, ForwardedRef, forwardRef} from 'react';

import classNames from 'classnames';

import {InputControl, useInputTypography} from '../../atoms/input';

import styles from './autoresizeable-input.module.css';

type Props = ComponentProps<typeof InputControl>

export const AutoresizeableInput = forwardRef(({className, value, size, ...props}: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const inputTypography = useInputTypography(size);
    return (
        <span className={classNames(styles.root, className)}>
            <span aria-hidden={true} className={classNames(styles.text, inputTypography)}>{value === '' ? '\u00A0' : value}</span>
            <InputControl {...props} ref={ref} className={styles.input} value={value} size={size}/>
        </span>
    );
});

AutoresizeableInput.displayName = 'AutoresizeableInput';
