export enum Status {
    OUT_OF_OFFICE = 'ooo',
    OFFLINE = 'offline',
    AWAY = 'away',
    DND = 'dnd',
    ONLINE = 'online'
}

export enum CommentsNotify {
    NEVER = 'never',
    ROOT = 'root',
    ANY = 'any',
    CRT = 'crt'
}

export enum UserNotify {
    ALL = 'all',
    HERE = 'here',
    MENTION = 'mention',
    NONE = 'none'
}

export enum BooleanNotify {
    TRUE = 'true',
    FALSE = 'false'
}
