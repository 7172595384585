import {useLayoutEffect} from 'react';

import {useAppDispatch} from 'stores/redux_store';
import {changeAppFocus, changeAppVisibility} from '../slices/app_visibility_slice';

/**
 * Watches visibility change and dispatches actions to change redux store props
 */
export function AppVisibilityWatcher() {
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                dispatch(changeAppVisibility(false));
            }

            if (document.visibilityState === 'visible') {
                dispatch(changeAppVisibility(true));
            }
        };
        const handleFocus = () => dispatch(changeAppFocus(true));
        const handleBlur = () => dispatch(changeAppFocus(false));

        // Initial visibility check
        handleVisibilityChange();

        // Initial focus check
        dispatch(changeAppFocus(document.hasFocus()));

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, [dispatch]);

    return null;
}
