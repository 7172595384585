import type {EventTypeWithParamsByProject, EventTypeByProject, UserPropertiesByProject} from '@tinkoff/statist-browser-typed-client-itsa.corporatemessenger.clientv1.web.events';
import {getEnableAnalitics} from 'actions/local_storage';

import {reportErrorToSentry} from 'utils/sentry';

type ProjectName = keyof EventTypeWithParamsByProject;
export type EventTypeWithParams = keyof EventTypeWithParamsByProject[ProjectName];
export type EventParameters = EventTypeWithParamsByProject[ProjectName][EventTypeWithParams];
export type EventType = keyof EventTypeByProject[ProjectName];
type UserProperties = UserPropertiesByProject[ProjectName]['set'];

/* eslint-disable no-process-env */
const projectName = process.env.STATIST_CLIENT! as ProjectName;
const isStatistDisabled = !process.env.STATIST_ENABLED;
/* eslint-enable no-process-env */

export function sendToStatist(eventName: EventType | EventTypeWithParams, params: EventParameters = {}, force = false) {
    const DISABLE_METRICS = !getEnableAnalitics();

    if (isStatistDisabled || DISABLE_METRICS) {
        return;
    }

    try {
        window.statistClient?.send(projectName, eventName, params);

        if (force) {
            window.statistClient?.forceSend();
        }
    } catch (error) {
        reportErrorToSentry(error);
    }
}

export function updateUserPropsInStatist(nextProps: UserProperties) {
    if (isStatistDisabled) {
        return;
    }

    try {
        window.statistClient?.updateUserProperties(projectName, 'set', nextProps);
    } catch (error) {
        reportErrorToSentry(error);
    }
}
