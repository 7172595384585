import classNames from 'classnames';

import {useIntl} from 'react-intl';

import {useContext, useState} from 'react';

import {partition} from 'lodash';

import {useInterval} from 'usehooks-ts';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';
import {UserListItem} from '@time-webkit/all/molecules/user-list-item';
import {Avatar} from '@time-webkit/all/atoms/avatar';
import {EmailOutlineIcon} from '@time-webkit/all/icons/email-outline';

import {Client4} from 'mattermost-redux/client';

import {inviteMembersContext} from '../invite_members_context';

import {Alert, AlertType} from '@time-webkit/all/atoms/alert';

import {ContentCopy} from '@time-webkit/all/icons/content-copy';

import {ButtonCopyToClipboard} from '../../button-copy-to-clipboard';

import styles from './invite_members_summary.module.css';

const DELAY_MS = 60_000;

export const InviteMembersSummary = () => {
    const intl = useIntl();
    const {result, sendingIssues, rateLimitIssues, rateLimitTimeoutMinutes} = useContext(inviteMembersContext);
    const bodyLTrueTypography = useTypography({size: TypographySize.BodyLTrue});

    const [resultWithError, resultWithoutError] = partition(result, (item) => item.error && item.error.type !== 'warning');
    const [newUsers, existingUsers] = partition(resultWithoutError, (item) => !item.profile);

    const hasSendingIssues = sendingIssues.length > 0;
    const hasRateLimitIssues = rateLimitIssues.length > 0;
    const hasExistingUsers = existingUsers.length > 0;
    const hasNewUsers = newUsers.length > 0;
    const hasMixedList = hasNewUsers && hasExistingUsers;
    const hasAlert = hasSendingIssues || hasRateLimitIssues;
    const showSubtitle = hasMixedList || hasAlert;

    const subheaderClassName = classNames(bodyLTrueTypography, styles.subheader);

    const [rateLimitIssueTimeout, setRateLimitIssueTimeout] = useState(rateLimitTimeoutMinutes);

    useInterval(
        () => {
            setRateLimitIssueTimeout(rateLimitIssueTimeout - 1);
        },
        rateLimitIssueTimeout > 0 ? DELAY_MS : null,
    );

    const buttonCopyToClipboard = (
        <ButtonCopyToClipboard
            size='medium'
            icon={<ContentCopy width={16} height={16} />}
            copyText={resultWithError.map((item) => item.email).join(' ')}
            messageSuccess={intl.formatMessage({
                id: 'invite.members.list_has_been_copied',
                defaultMessage: 'List has been copied',
            })}
        >
            {intl.formatMessage({
                id: 'invite.members.copy_email_list',
                defaultMessage: 'Copy email list',
            })}
        </ButtonCopyToClipboard>
    );

    return (
        <div className={styles.root}>
            {hasSendingIssues && (
                <Alert
                    type={AlertType.Danger}
                    size='medium'
                    className={styles.alert}
                    message={intl.formatMessage({
                        id: 'invite.members.error.sending_error',
                        defaultMessage: '{count, plural, =1 {# user} other {# users}} {count, plural, =1 {was} other {were}} not invited due to server problems',
                    }, {
                        count: sendingIssues.length,
                    })}
                    secondaryMessage={
                        intl.formatMessage({
                            id: 'invite.members.try_contacting_admin',
                            defaultMessage: 'Try contacting the administrator and sending an invitation later',
                        })
                    }
                >
                    {buttonCopyToClipboard}
                </Alert>
            )}
            {hasRateLimitIssues && (
                <Alert
                    size='medium' type={AlertType.Warning}
                    className={styles.alert}
                    message={intl.formatMessage({
                        id: 'invite.members.rate_limit_issues_v2',
                        defaultMessage: 'Failed to invite {users, plural, =1 {# user} other {# users}}',
                    }, {
                        users: rateLimitIssues.length,
                    })}
                    secondaryMessage={
                        rateLimitIssueTimeout > 0 ? intl.formatMessage({
                            id: 'invite.members.invite_users_later_v2',
                            defaultMessage: 'Try sending invitations again in {minutes, plural, =1 {# minute} other {# minutes}}',
                        }, {
                            minutes: rateLimitIssueTimeout,
                        }) : intl.formatMessage({
                            id: 'invite.members.invite_users_later_v3',
                            defaultMessage: 'Try sending invitations again',
                        })
                    }
                >
                    {buttonCopyToClipboard}
                </Alert>
            )}
            {showSubtitle && hasExistingUsers && (
                <h5 className={subheaderClassName}>
                    {intl.formatMessage({
                        id: 'invite.members.users_have_been_added',
                        defaultMessage: 'Users have been added to the team',
                    })}
                </h5>
            )}
            {existingUsers.map((item) => {
                const profile = item.profile!;
                const avatarUrl = Client4.getProfilePictureUrl(profile!.id, profile!.last_picture_update || Date.now());
                return (
                    <UserListItem
                        avatarClassName={styles.avatar}
                        className={styles.userItem}
                        key={profile.id}
                        avatar={<Avatar username={profile.username} src={avatarUrl} size={36} />}
                        textPrimary={profile.username}
                        textSecondary={profile.email}
                    />
                );
            })}
            {showSubtitle && hasNewUsers && (
                <h5
                    className={classNames(subheaderClassName, {
                        [styles.subheaderWithTopMargin]: hasExistingUsers && hasAlert,
                    })}
                >
                    {intl.formatMessage({
                        id: 'invite.members.new_users_have_been_invited',
                        defaultMessage: 'New users have been invited',
                    })}
                </h5>
            )}
            {newUsers.map((item) => (
                <UserListItem
                    avatarClassName={styles.avatar}
                    className={styles.userItem}
                    key={item.email}
                    avatar={
                        <div className={styles.emailIcon}><EmailOutlineIcon /></div>
                    }
                    textPrimary={item.email}
                />
            ))}
        </div>
    );
};
