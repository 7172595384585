import classNames from 'classnames';

import {TypographySize, useTypography} from '../../../hooks/typography';

import styles from './tooltip-text-primary.module.css';

type Props = {
    children: string;
    className?: string;
};

export const TooltipTextPrimary = ({children, className}: Props) => {
    const typographyClass = useTypography({
        size: TypographySize.BodySTrue,
        noDefaultColor: true,
    });

    return <div className={classNames(styles.root, typographyClass, className)}>{children}</div>;
};
