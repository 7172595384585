import React, {useState, KeyboardEvent, SyntheticEvent} from 'react';
import classNames from 'classnames';

import {Actions} from './actions';

import styles from './styles.module.css';

type Props = {
    src: string;
    fileInfo?: { id: string; name: string };
    fileURL?: string;
    handleSmallImageContainer?: boolean;
    enablePublicLink?: boolean;
    canDownloadFile?: boolean;
    ariaFileThumbnailText?: string;
    copyLinkText?: string;
    copyLinkAriaText?: string;
    copiedText?: string;
    onCopyToClipboard?: (src: string) => void;
    getFilePublicLink?: (filedId: string) => Promise<string>;
    downloadTooltipText?: string;
    downloadTooltipAriaText?: string;
    handleImageClick: () => void;
    handleError: () => void;
    handleLoad: (e: SyntheticEvent) => void;
    smallImage: boolean;
    imageWidth?: number;
    minImageSizeForInternalButton: number;
};

let timeout: NodeJS.Timeout | null | undefined = null;

export const ImageView = ({
    ariaFileThumbnailText,
    fileInfo,
    handleImageClick,
    src,
    handleError,
    handleLoad,
    handleSmallImageContainer,
    smallImage,
    enablePublicLink,
    canDownloadFile,
    downloadTooltipText,
    downloadTooltipAriaText,
    onCopyToClipboard,
    getFilePublicLink,
    fileURL,
    copiedText,
    copyLinkText,
    copyLinkAriaText,
    imageWidth,
    minImageSizeForInternalButton,
}: Props) => {
    const [linkCopiedRecently, setLinkCopiedRecently] = useState(false);
    const [linkCopyInProgress, setLinkCopyInProgress] = useState(false);

    let ariaLabelImage = ariaFileThumbnailText;

    if (fileInfo) {
        ariaLabelImage += ` ${fileInfo.name}`.toLowerCase();
    }

    const isInternalImage = fileInfo !== undefined && fileInfo !== null;

    const onEnterKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleImageClick();
        }
    };
    const startCopyTimer = () => {
        setLinkCopiedRecently(true);
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            setLinkCopiedRecently(false);
            setLinkCopyInProgress(false);
        }, 1500);
    };
    const copyLinkToAsset = () => {
        if (linkCopyInProgress) {
            return;
        }

        if (!isInternalImage) {
            setLinkCopyInProgress(true);
            onCopyToClipboard?.(src ?? '');
            startCopyTimer();

            return;
        }

        if (!getFilePublicLink) {
            return;
        }

        setLinkCopyInProgress(true);

        getFilePublicLink(fileInfo.id).then((fileURL) => {
            onCopyToClipboard?.(fileURL ?? '');
            startCopyTimer();
        });
    };

    const image = (
        <img
            aria-label={ariaLabelImage}
            tabIndex={0}
            onClick={handleImageClick}
            onKeyDown={onEnterKeyDown}
            src={src}
            onError={handleError}
            onLoad={handleLoad}
        />
    );

    if (handleSmallImageContainer && smallImage) {
        return (
            <div className={styles.smallImageButtonsWrapper}>
                <div onClick={handleImageClick} className={styles.smallImageContainer}>
                    {image}
                </div>
                <Actions
                    className={classNames(styles.containerSmallImage, {
                        [styles.containerSmallImageNoCopyButton]: !enablePublicLink,
                    })}
                    showCopyLink={Boolean(enablePublicLink)}
                    showDownloadButton={canDownloadFile}
                    downloadTooltipText={downloadTooltipText}
                    downloadTooltipAriaText={downloadTooltipAriaText}
                    isInternalImage={isInternalImage}
                    fileURL={fileURL}
                    src={src}
                    copyTooltipText={linkCopiedRecently ? copiedText : copyLinkText}
                    linkCopiedRecently={linkCopiedRecently}
                    copyLinkAriaText={copyLinkAriaText}
                    onCopyLink={copyLinkToAsset}
                />
            </div>
        );
    }

    const utilityButtonsWrapper =
    smallImage && !isInternalImage ? null : (
        <Actions
            className={classNames({
                [styles.containerSmallImage]: imageWidth && imageWidth < minImageSizeForInternalButton,
                [styles.containerSmallImageNoCopyButton]:
            (!enablePublicLink || !isInternalImage) && imageWidth && imageWidth < minImageSizeForInternalButton,
            })}
            showCopyLink={Boolean(enablePublicLink || !isInternalImage)}
            showDownloadButton={canDownloadFile}
            downloadTooltipText={downloadTooltipText}
            downloadTooltipAriaText={downloadTooltipAriaText}
            isInternalImage={isInternalImage}
            fileURL={fileURL}
            src={src}
            copyTooltipText={linkCopiedRecently ? copiedText : copyLinkText}
            linkCopiedRecently={linkCopiedRecently}
            copyLinkAriaText={copyLinkAriaText}
            onCopyLink={copyLinkToAsset}
        />
    );

    return (
        <figure className={classNames(styles.imageLoadedContainer)}>
            {image}
            {utilityButtonsWrapper}
        </figure>
    );
};
