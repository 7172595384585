import {ComponentProps, useLayoutEffect, useState} from 'react';

import {InputControl} from '../../atoms/input';

type Props = {

    /**
     * уникальный ключ используется для сброса состояния хука
     */
    key?: string;

    /**
   * начальное значение input
   */
    initialValue?: string;

    /**
   * символы клавиатуры, при получении которых значение input должно быть сохранено
   */
    commitKeys: string[];

    /**
   * коллбек, который получает введенное пользователем значение после ввода символа завершения
   */
    onCommit?: (value: string) => void;
}

type Result = Required<Pick<ComponentProps<typeof InputControl>, 'onKeyDown' | 'onChange' | 'value'>>;

/**
 * этот хук синхронизирует внутреннее состояние компонента с внешним при вводе одного из символов, указывающих на то, что введенное значение должно быть сохранено
 */
export const useCommitableInput = ({key, initialValue, commitKeys, onCommit}: Props): Result => {
    const [value, setValue] = useState(initialValue ?? '');

    useLayoutEffect(() => {
        if (key !== undefined) {
            setValue('');
        }
    }, [key]);

    return {
        value,
        onKeyDown: (e) => {
            if (commitKeys.includes(e.key)) {
                onCommit?.(value);
                e.preventDefault();
            }
        },
        onChange: (nextValue) => {
            setValue(nextValue);
        },
    };
};
