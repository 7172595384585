import classNames from 'classnames';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {HTMLAttributes} from 'react';

import styles from './styles.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    show: boolean;
    message?: React.ReactNode;
    className?: string;
}

export const FormError: React.FC<Props> = ({message, show = false, className, ...props}) => {
    const typographyStyle = useTypography({size: TypographySize.BodyM});

    if (!show || !message) {
        return null;
    }

    return (<div {...props} data-test-id='FormError' role='alert' className={classNames(typographyStyle, styles.errorMessage, className)}>
        <p className={styles.error_text}>{message}</p>
    </div>);
};
