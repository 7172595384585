import {memo, HTMLAttributes, ComponentProps} from 'react';

import classNames from 'classnames';

import {Avatar as AtomAvatar} from '@time-webkit/all/atoms/avatar';

export type TAvatarSizeToken = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

// Keep both formats for backwards compatibility (plugins use this component)
type AtomAvatarSize = ComponentProps<typeof AtomAvatar>['size'];

const SIZE_MAP: Map<TAvatarSizeToken, AtomAvatarSize> = new Map([
    ['xxs', 16],
    ['xs', 20],
    ['sm', 24],
    ['md', 36],
    ['lg', 48],
    ['xl', 72],
    ['xxl', 128],
]);

type Props = {
    url?: string;
    username?: string;
    size?: AtomAvatarSize | TAvatarSizeToken;
    text?: string;
};

type Attrs = HTMLAttributes<HTMLElement>;

const Avatar = ({
    url,
    username,
    size = 36,
    text,
    ...attrs
}: Props & Attrs) => {
    const classes = classNames('Avatar', attrs.className);
    const atomSize = typeof size === 'string' ? SIZE_MAP.get(size) : size;

    if (text) {
        return (
            <div
                {...attrs}
                className={classNames(classes, 'Avatar-plain', `Avatar-plain-${atomSize}`)}
                data-content={text}
            />
        );
    }

    return (
        <AtomAvatar className='Avatar' src={url} size={atomSize} username={username} />
    );
};

export default memo(Avatar);
