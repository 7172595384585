import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import type {GlobalState} from 'types/store';
import {getUserStatuses} from 'mattermost-redux/selectors/entities/users';
import type {UserStatuses} from 'utils/constants';

export const getCurrentUserStatus = (state: GlobalState) => {
    const currentUserId = getCurrentUserId(state);

    return getUserStatuses(state)[currentUserId] as UserStatuses;
};
