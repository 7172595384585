import {User} from 'features/users';

/**
 * Used to get the id of the other user from a DM channel id (id1__id2)
 */
export function getUserIdFromChannelId(channelName: string, currentUserId: User['id'] = '') {
    const ids = channelName.split('__');

    let otherUserId = '';

    if (ids[0] === currentUserId) {
        otherUserId = ids[1];
    } else {
        otherUserId = ids[0];
    }

    return otherUserId;
}
