import {isCurrentTeamChannel} from 'features/channels/selectors/is_current_team_channel';
import {createThunkAction} from 'stores/create_thunk_action';
import {fetchUserThread} from '../actions/fetch_user_thread';
import {getHasUnreadThreadsInCurrentTeam} from '../actions/get_has_unread_threads_in_current_team';
import {handleThreadsReadChanged} from '../actions/handle_threads_read_changed';
import {markAllThreadsInChannelRead} from '../actions/mark_all_threads_in_channel_read';
import {markAllThreadsReadInTeam} from '../actions/mark_all_threads_read_in_team';
import {updateThreadLastViewedAt} from '../actions/update_thread_last_viewed_at';
import {isThreadOpened} from '../selectors/is_thread_opened';
import {selectThreadById} from '../selectors/select_thread_by_id';
import type {
    ThreadReadChangedInMarkChannelAsViewedEvent,
    ThreadReadForUserEvent,
    ThreadsReadForUserEvent,
} from '../types/thread_read_changed_event';
import {
    isThreadReadChangedInMarkChannelAsViewedEvent,
    isThreadReadForUserEvent,
    isThreadsReadForUserEvent,
} from '../utils/thread_read_changed_helpers';
import {reportErrorToSentry} from 'utils/sentry';

export const handleThreadReadChanged = (
    msg: ThreadReadChangedInMarkChannelAsViewedEvent | ThreadReadForUserEvent | ThreadsReadForUserEvent,
) =>
    createThunkAction('threads/wsEventsHandlers/handleThreadReadChanged', async (dispatch, getState) => {
        if (isThreadReadChangedInMarkChannelAsViewedEvent(msg)) {
            /**
             * @deprecated
             * событие срабатывает только при отправке сообщений из обсуждений в
             * основной канал (collapsed_reply_threads: false), такое поведение
             * отключено на сервере
             */

            const {data, broadcast} = msg;
            if (isCurrentTeamChannel(getState(), broadcast.channel_id)) {
                dispatch(getHasUnreadThreadsInCurrentTeam());
            }

            return dispatch(
                markAllThreadsInChannelRead({
                    channelId: broadcast.channel_id,
                    lastViewedAt: data.timestamp,
                }),
            );
        }

        if (isThreadReadForUserEvent(msg)) {
            const {data, broadcast} = msg;
            const state = getState();

            let thread = selectThreadById(state, data.thread_id);

            /**
             * Событие это происходит только для тредов
             * на которые пользователь подписан
             * Поэтому тут если у нас нет треда - нам нужно его запросить
             */
            if (!thread) {
                await dispatch(
                    fetchUserThread({
                        teamId: broadcast.team_id,
                        threadId: data.thread_id,
                        userId: broadcast.user_id,
                        extended: true,
                    }),
                );
            }

            const updatedState = getState();
            thread = selectThreadById(updatedState, data.thread_id);

            if (!thread) {
                reportErrorToSentry(`[THREAD_READ_CHANGED]: Can not fetch thread with id ${data.thread_id}`);
                return;
            }

            /**
             * Проверяем является ли событие обновлением непрочитанных для обсуждения:
             * сравниваем значения timestamp до и после обновления, если значение
             * увеличилось то событие обновляет непрочитанные обсуждения, в противном
             * случае это "отметить непрочитанными вручную" (или что угодно другое)
             */
            const isEventUpdatingThreadReadState = data.previous_timestamp && data.timestamp && data.timestamp > data.previous_timestamp;
            if (isEventUpdatingThreadReadState && data.timestamp < thread.last_viewed_at) {
                return;
            }

            const shouldUpdateThreadRead = data.timestamp && thread && !isThreadOpened(updatedState, thread.id);

            if (shouldUpdateThreadRead) {
                dispatch(updateThreadLastViewedAt({threadId: thread.id, lastViewedAt: data.timestamp}));
            }

            /**
             * Если изменение произошло в текущей команде
             * то перезапрашиваем статистику тредов
             *
             * @TODO: у нас есть сокет события, на которые мы могли бы положиться и не отправлять запрос
             * на сервер всякий раз, когда кто-то упоминает нас в обсуждении
             */
            if (isCurrentTeamChannel(updatedState, data.channel_id)) {
                dispatch(getHasUnreadThreadsInCurrentTeam());
            }

            return dispatch(
                handleThreadsReadChanged({
                    channelId: data.channel_id,
                    teamId: broadcast.team_id,
                    lastViewedAt: shouldUpdateThreadRead ? data.timestamp : thread.last_viewed_at,
                    newUnreadMentions: data.unread_mentions,
                    newUnreadReplies: data.unread_replies,
                    threadId: data.thread_id,
                    prevUnreadMentions: thread.unread_mentions ?? data.previous_unread_mentions,
                    prevUnreadReplies: thread.unread_replies ?? data.previous_unread_replies,
                    thread,
                }),
            );
        }

        if (isThreadsReadForUserEvent(msg)) {
            const {broadcast} = msg;

            return dispatch(
                markAllThreadsReadInTeam({
                    teamId: broadcast.team_id,
                }),
            );
        }
    });
