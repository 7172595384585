import {HTMLAttributes, ReactNode, useCallback, useEffect, useRef} from 'react';

import classNames from 'classnames';

import {ListBoxItem, useListBoxState} from '../listbox';

import styles from './listbox.module.css';

type State = ReturnType<typeof useListBoxState>[0]
type Actions = ReturnType<typeof useListBoxState>[1]

type Props<Payload = unknown> = HTMLAttributes<HTMLUListElement> & {
    state: State;
    actions: Actions;
    items: Array<ListBoxItem<Payload>>;
    renderItem: (item: ListBoxItem<Payload>, isFocused?: boolean, isSelected?: boolean) => ReactNode;
};

export const ListBox = <Payload = unknown>({
    state,
    actions,
    items,
    className,
    renderItem,
    ...props
}: Props<Payload>) => {
    const {focusedItem, selectedItem} = state;
    const {onSelectItem, onFocusItem, onClearFocus} = actions;
    const listRef = useRef<HTMLUListElement>(null);
    const elementsRefMap = useRef<Record<ListBoxItem['value'], HTMLElement | null>>({});
    const preventFocus = useRef(false);
    const delayTimeout = useRef<NodeJS.Timeout>();

    const handleOptionMouseEnter =
        useCallback((item: ListBoxItem) => () => {
            if (!item.disabled && !preventFocus.current) {
                onFocusItem?.(item);
            }
        }, [onFocusItem]);

    const handleOptionClick = useCallback(
        (item: ListBoxItem) => () => {
            if (!item.disabled) {
                onSelectItem?.(item);
            }
        },
        [onSelectItem],
    );

    // сфокусированный элемент не должен меняться во время прокрутки
    // решение через setTimeout не лучшее, но работает
    const scrollToItem = (element: HTMLElement, position: ScrollLogicalPosition) => {
        preventFocus.current = true;
        element.scrollIntoView({behavior: 'auto', block: position});
        delayTimeout.current = setTimeout(() => {
            preventFocus.current = false;
        }, 100);
    };

    useEffect(() => () => delayTimeout.current && clearTimeout(delayTimeout.current), []);

    useEffect(() => () => onClearFocus?.(), [onClearFocus]);

    useEffect(() => {
        if (focusedItem && listRef.current) {
            const itemRef = elementsRefMap.current[focusedItem.value];
            const boundingRect = itemRef?.getBoundingClientRect();
            const listBoundingRect = listRef.current.offsetParent?.getBoundingClientRect();
            if (boundingRect && listBoundingRect) {
                if (boundingRect.bottom > listBoundingRect.bottom || boundingRect.top < listBoundingRect.top) {
                    scrollToItem(itemRef!, 'nearest');
                }
            }
        }
    }, [focusedItem]);

    return (
        <ul
            {...props}
            ref={listRef}
            role='listbox'
            tabIndex={0}
            aria-activedescendant={focusedItem?.id ?? focusedItem?.value}
            className={classNames(styles.root, className)}
        >
            {items.map((item) => {
                const isFocused = item.value === focusedItem?.value;
                const isSelected = item.value === selectedItem?.value;
                return (
                    <li
                        ref={(ref) => {
                            elementsRefMap.current[item.value] = ref;
                        }}
                        tabIndex={-1}
                        id={item.id ?? item.value}
                        role={item.disabled ? 'none' : 'option'}
                        aria-selected={isSelected}
                        onClick={handleOptionClick(item)}
                        onMouseEnter={handleOptionMouseEnter(item)}
                        key={item.value}
                    >
                        {renderItem(item, isFocused, isSelected)}
                    </li>
                );
            })}
        </ul>
    );
};
