import {IntlShape} from 'react-intl';

import {TeamInviteWithError} from '@mattermost/types/teams';

import {InvitationErrorCode} from './invite_members_form/invitation_error_code';

export class InvitationError {
    static fromInvitation(invite: TeamInviteWithError): InvitationError | null {
        if (invite.error) {
            if (Object.values(InvitationErrorCode).includes(invite.error.id as InvitationErrorCode)) {
                let errorType: InvitationError['type'] = 'error';
                if (invite.error.id === InvitationErrorCode.API_SENDING_ISSUE) {
                    errorType = 'sending_issue';
                }
                if (invite.error.id === InvitationErrorCode.API_LIMIT_EXCEEDED) {
                    errorType = 'limit_exceeded';
                }
                return new InvitationError(
                    invite.error.id as InvitationErrorCode,
                    invite.email,
                    errorType,
                    invite.error.params?.retry_after_seconds,
                    invite.error.params?.remaining_sendings,
                );
            }
        }
        if (invite.got_invite_earlier) {
            return new InvitationError(InvitationErrorCode.API_INVITATION_HAS_BEEN_SENT, invite.email, 'warning');
        }
        return null;
    }

    constructor(
        public readonly code: InvitationErrorCode,
        public readonly email: string,
        public readonly type: 'error' | 'warning' | 'sending_issue' | 'limit_exceeded',
        public readonly retryAfterSeconds?: number,
        public readonly remaininSendings?: number,
    ) {}

    getLocalizedMessage(intl: IntlShape): string {
        switch (this.code) {
        case InvitationErrorCode.INVALID_EMAIL:
        case InvitationErrorCode.API_INVALID_EMAIL:
        case InvitationErrorCode.API_EMAIL_WRONG_FORMAT:
            return intl.formatMessage({
                id: 'invite.members.error.invalid_email',
                defaultMessage: 'Check email address',
            });
        case InvitationErrorCode.TOO_LONG_EMAIL:
        case InvitationErrorCode.API_EMAIL_WRONG_LENGTH:
            return intl.formatMessage({
                id: 'invite.members.error.too_long_email',
                defaultMessage: 'Too long email',
            });
        case InvitationErrorCode.API_INVALID_EMAIL_DOMAIN:
            return intl.formatMessage({
                id: 'invite.members.error.invalid_domain',
                defaultMessage: 'The following domain is not correct',
            });
        case InvitationErrorCode.API_INVALID_GUEST_ROLE:
            return intl.formatMessage({
                id: 'invite.members.error.invalid_role_guest',
                defaultMessage: 'You cannot invite user to channel as guest',
            });
        case InvitationErrorCode.API_INVALID_MEMBER_ROLE:
            return intl.formatMessage({
                id: 'invite.members.error.invalid_role_member',
                defaultMessage: 'You cannot invite user to channel as member',
            });
        case InvitationErrorCode.API_INVALID_TEAM_MEMBER:
            return intl.formatMessage({
                id: 'invite.members.error.invalid_team_member',
                defaultMessage: 'User is already in the team',
            });
        case InvitationErrorCode.API_INVALID_GUEST:
            return intl.formatMessage({
                id: 'invite.members.error.invalid_team_member_guest',
                defaultMessage: 'User is already in the team (guest)',
            });
        case InvitationErrorCode.API_INVITATION_HAS_BEEN_SENT:
            return intl.formatMessage({
                id: 'invite.members.error.invitation_has_been_sent',
                defaultMessage: 'Invitation has been sent',
            });
        case InvitationErrorCode.API_DEACTIVATED_MEMBER:
            return intl.formatMessage({
                id: 'invite.members.error.deactivated_member',
                defaultMessage: 'User deactivated',
            });
        case InvitationErrorCode.API_DEACTIVATED_GUEST:
            return intl.formatMessage({
                id: 'invite.members.error.deactivated_guest',
                defaultMessage: 'User deactivated (guest)',
            });
        case InvitationErrorCode.API_SENDING_ISSUE:
            return '';
        case InvitationErrorCode.API_LIMIT_EXCEEDED:
            return '';
        }
    }
}
