import {useRef} from 'react';
import type {ReactNode} from 'react';
import classNames from 'classnames';
import {useTooltip} from '@react-aria/tooltip';

import {useOverlayPosition, OverlayContainer} from '@react-aria/overlays';

import type {AriaPositionProps} from '@react-aria/overlays';
import type {TooltipTriggerState} from '@react-stately/tooltip';

import isString from '@tinkoff/utils/is/string';
import {mergeProps} from '@react-aria/utils';

import {TooltipTextPrimary} from './tooltip-text-primary';
import styles from './styles.module.css';

const ANCHOR_SIZE = 5;

export type Props = Omit<AriaPositionProps, 'overlayRef'> & {
    children: ReactNode;
    state: TooltipTriggerState;
    className?: string;
};

export const Tooltip = ({state, children, className, placement: placementProp = 'top', ...props}: Props) => {
    const {tooltipProps} = useTooltip(props, state);
    const overlayRef = useRef<HTMLDivElement>(null);

    const {placement, overlayProps, arrowProps} = useOverlayPosition({
        overlayRef,
        placement: placementProp,
        ...props,
        offset: (props.offset ?? 0) + ANCHOR_SIZE,
    });

    const content = isString(children) ? <TooltipTextPrimary>{children}</TooltipTextPrimary> : children;

    return (
        <OverlayContainer>
            <div
                ref={overlayRef}
                role='tooltip'
                className={classNames(styles.root, className)}
                {...mergeProps(tooltipProps, overlayProps)}
            >
                <div className={styles.anchor} data-placement={placement} {...arrowProps} />
                <div className={styles.content}>{content}</div>
            </div>
        </OverlayContainer>
    );
};
