import {addQuery} from '@tinkoff/url';

import type {User} from '../types/user';

export function getUserProfilePictureUrl(userId: User['id'], lastPictureUpdate?: User['last_picture_update']) {
    const route = `/api/v4/users/${userId}/image`;

    if (typeof lastPictureUpdate !== 'undefined') {
        return addQuery(route, {_: lastPictureUpdate.toString()}).toString();
    }

    return route;
}
