//TODO: remove this and use axios-retry package when https://github.com/axios/axios/pull/5090 is fixed
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosStatic} from 'axios';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import isRetryAllowed from 'is-retry-allowed';

type AxiosRetryConfig = {

    /**
     * The number of times to retry before failing
     * default: 3
     *
     * @type {number}
     */
    retries?: number;

    /**
     * Defines if the timeout should be reset between retries
     * default: false
     *
     * @type {boolean}
     */
    shouldResetTimeout?: boolean;

    /**
     * A callback to further control if a request should be retried. By default, it retries if the result did not have a response.
     * default: error => !error.response
     *
     * @type {Function}
     */
    retryCondition?: (error: AxiosError) => boolean | Promise<boolean>;

    /**
     * A callback to further control the delay between retry requests. By default there is no delay.
     *
     * @type {Function}
     */
    retryDelay?: (retryCount: number, error: AxiosError) => number;

    /**
     * A callback to get notified when a retry occurs, the number of times it has occurre, and the error
     *
     * @type {Function}
     */
    onRetry?: (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => void;
};

const namespace = 'axios-retry';

declare module 'axios' {
    interface AxiosRequestConfig {
        [namespace]?: AxiosRetryConfig;
    }
}

/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isNetworkError(error: AxiosError): boolean {
    return (
        !error.response &&
        Boolean(error.code) && // Prevents retrying cancelled requests
        error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
        isRetryAllowed(error)
    ); // Prevents retrying unsafe errors
}

const SAFE_HTTP_METHODS = ['get', 'head', 'options'];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(['put', 'delete']);

/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isRetryableError(error: AxiosError) {
    return (
        error.code !== 'ECONNABORTED' &&
        (!error.response || (error.response.status >= 500 && error.response.status <= 599))
    );
}

/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isSafeRequestError(error: AxiosError) {
    if (!error.config) {
        // Cannot determine if the request can be retried
        return false;
    }

    return isRetryableError(error) && SAFE_HTTP_METHODS.indexOf(error.config.method || '') !== -1;
}

/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isIdempotentRequestError(error: AxiosError) {
    if (!error.config) {
        // Cannot determine if the request can be retried
        return false;
    }

    return isRetryableError(error) && IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method || '') !== -1;
}

/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isNetworkOrIdempotentRequestError(error: AxiosError) {
    return isNetworkError(error) || isIdempotentRequestError(error);
}

/**
 * @return {number} - delay in milliseconds, always 0
 */
function noDelay() {
    return 0;
}

/**
 * @param  {number} [retryNumber=0]
 * @return {number} - delay in milliseconds
 */
export function exponentialDelay(retryNumber = 0, interval = 100) {
    const delay = Math.pow(2, retryNumber) * interval;
    const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
    return delay + randomSum;
}

type CurrentState = AxiosRetryConfig & {retryCount: number; lastRequestTime: number}

/**
 * Initializes and returns the retry state for the given request/config
 * @param  {AxiosRequestConfig} config
 * @return {Object}
 */
function getCurrentState(
    config: AxiosRequestConfig,
): CurrentState {
    const currentState = (config[namespace] || {}) as CurrentState;
    currentState.retryCount = currentState.retryCount || 0;
    config[namespace] = currentState;
    return currentState;
}

/**
 * Returns the axios-retry options for the current request
 * @param  {AxiosRequestConfig} config
 * @param  {AxiosRetryConfig} defaultOptions
 * @return {AxiosRetryConfig}
 */
function getRequestOptions(config: AxiosRequestConfig, defaultOptions: AxiosRetryConfig) {
    return {...defaultOptions, ...(config[namespace] || {})};
}

/**
 * Checks retryCondition if request can be retried. Handles it's retruning value or Promise.
 * @param  {number} retries
 * @param  {Function} retryCondition
 * @param  {Object} currentState
 * @param  {Error} error
 * @return {boolean}
 */
async function shouldRetry(
    retries: number,
    retryCondition: Required<AxiosRetryConfig>['retryCondition'],
    currentState: Record<string, any>,
    error: AxiosError,
) {
    const shouldRetryOrPromise = currentState.retryCount < retries && retryCondition(error);

    // This could be a promise
    if (typeof shouldRetryOrPromise === 'object') {
        try {
            const shouldRetryPromiseResult = await shouldRetryOrPromise;

            // keep return true unless shouldRetryPromiseResult return false for compatibility
            return shouldRetryPromiseResult !== false;
        } catch (_err) {
            return false;
        }
    }
    return shouldRetryOrPromise;
}

/*
 * @param {Axios} axios An axios instance (the axios object or one created from axios.create)
 * @param {Object} [defaultOptions]
 * @param {number} [defaultOptions.retries=3] Number of retries
 * @param {boolean} [defaultOptions.shouldResetTimeout=false]
 *        Defines if the timeout should be reset between retries
 * @param {Function} [defaultOptions.retryCondition=isNetworkOrIdempotentRequestError]
 *        A function to determine if the error can be retried
 * @param {Function} [defaultOptions.retryDelay=noDelay]
 *        A function to determine the delay between retry requests
 * @param {Function} [defaultOptions.onRetry=()=>{}]
 *        A function to get notified when a retry occurs
 */
export function addRetryInterceptors(axios: AxiosStatic | AxiosInstance, defaultOptions: AxiosRetryConfig) {
    axios.interceptors.request.use((config) => {
        const currentState = getCurrentState(config);
        currentState.lastRequestTime = Date.now();
        return config;
    });

    axios.interceptors.response.use((r) => r, async (error) => {
        const {config} = error;

        // If we have no information to retry the request
        if (!config) {
            return Promise.reject(error);
        }

        const {
            retries = 3,
            retryCondition = isNetworkOrIdempotentRequestError,
            retryDelay = noDelay,
            shouldResetTimeout = false,
            onRetry = () => {},
        } = getRequestOptions(config, defaultOptions);

        const currentState = getCurrentState(config);

        if (await shouldRetry(retries, retryCondition, currentState, error)) {
            currentState.retryCount += 1;
            const delay = retryDelay(currentState.retryCount, error);

            // Axios fails merging this configuration to the default configuration because it has an issue
            // with circular structures: https://github.com/mzabriskie/axios/issues/370
            // fixConfig(axios, config);

            if (!shouldResetTimeout && config.timeout && currentState.lastRequestTime) {
                const lastRequestDuration = Date.now() - currentState.lastRequestTime;

                // Minimum 1ms timeout (passing 0 or less to XHR means no timeout)
                config.timeout = Math.max(config.timeout - lastRequestDuration - delay, 1);
            }

            config.transformRequest = [(data: any) => data];

            onRetry(currentState.retryCount, error, config);

            // This is a temporary fix for https://github.com/axios/axios/pull/5090
            config.headers = config.headers.toJSON?.() || config.headers;

            return new Promise((resolve) => setTimeout(() => resolve(axios(config)), delay));
        }

        return Promise.reject(error);
    });
}
