import {useState} from 'react';
import classNames from 'classnames';

import {typographyStyles} from '../../../hooks/typography';

import type {Props} from './types';
import {getAvatarInitials} from './get-avatar-initials';
import styles from './styles.module.css';

export const Avatar = (props: Props) => {
    const {inline = false, size = 48, username = '', src, text = '', className: passedClassName = ''} = props;
    const [isImgLoadingFailed, setIsImgLoadingFailed] = useState(false);
    const shouldMountImg = Boolean(src);

    const initials = getAvatarInitials(text, username);

    const wrapperCls = classNames([
        styles.base,
        typographyStyles.base,
        styles[`size-${size}`],
        passedClassName,
    ]);

    return (
        <div className={classNames(wrapperCls, {[styles.baseInline]: inline})} aria-label={username}>
            {shouldMountImg && (
                <div className={styles.avatar}>
                    <img className={styles.img} src={src} loading='lazy' alt={props.username} onError={() => setIsImgLoadingFailed(true)} />
                </div>
            )}
            <div className={classNames(styles.initials, {[styles.initialsShown]: isImgLoadingFailed})}>{initials}</div>
        </div>
    );
};
