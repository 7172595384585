import {TypographySize, useTypography} from '../../hooks/typography';

import {InputSize} from './input.types';

export const useLabelTypography = (size: InputSize, isReduced?: boolean) => {
    return useTypography({size: getTypographySize(size, isReduced)});
};

function getTypographySize(size: InputSize, isReduced?: boolean) {
    switch (size) {
    case 'medium':
        return isReduced ? TypographySize.BodyXS : TypographySize.BodyM;
    case 'large':
        return isReduced ? TypographySize.BodyM : TypographySize.BodyL;
    }
}
