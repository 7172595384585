import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {AppDispatch} from 'stores/redux_store';
import {getChannelModerations} from '../api/get_channel_moderations';

export const getModerationsForChannel = createAsyncThunk(
    'channels/actions/getModerationsForChannel',
    async (channel: Channel, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const channelModerations = await dispatch(getChannelModerations({channelId: channel.id})).unwrap();

        return channelModerations;
    },
);
