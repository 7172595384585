import {createAsyncThunk} from '@reduxjs/toolkit';

import type {GlobalState} from 'types/store';
import {getCurrentUserNotificationSound} from '../../selectors/get_current_user_notification_sound';
import {getPostPermalinkUrl} from 'features/posts/selectors/get_post_permalink_url';
import {getCurrentUserNotificationSoundEnabled} from '../../selectors/get_current_user_notification_sound_enabled';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';

import {isCurrentUserNotAvailable} from 'features/channels/selectors/is_current_user_not_available';
import {getChannel, getCurrentChannelId, isMutedChannel} from 'mattermost-redux/selectors/entities/channels';
import {isThreadOpened} from 'features/threads/selectors/is_thread_opened';
import {isAppActive} from 'features/app_activity/selector/is_app_active';
import {getCurrentChannelName} from 'features/channels/selectors/get_current_channel_name';
import {getUserDisplayName} from 'features/users/selectors/get_user_display_name';
import {getUser} from 'mattermost-redux/selectors/entities/users';
import Constants from 'utils/constants';

import {isMobileApp} from 'utils/user_agent';

import {reportErrorToSentry, reportMessageToSentry} from 'utils/sentry';
import {browserHistory} from 'utils/browser_history';

import {getEmojiMap} from 'selectors/emojis';

import {playNotification} from '../../utils/play_notification_sound';

import {sendPlatformNotification, SendPlatformNotificationError} from '../../utils/send_platform_notification';

import {Client4} from 'mattermost-redux/client';

import type {Channel} from '@mattermost/types/channels';
import type {Post} from '@mattermost/types/posts';
import type {UserProfile} from '@mattermost/types/users';

import {getNotificationBody} from './get_notification_body';

export type Payload = {
    post_id: Post['id'];
    user_id: UserProfile['id'];
    emoji_name: string;
    channel_display_name: Channel['display_name'];
    channel_name: Channel['name'];
    channel_type: Channel['type'];
    channel_id: Channel['id'];
    root_id?: Post['id'];
    sender_name: string;
}

export const sendReactionNotification = createAsyncThunk(
    'notifications/actions/sendPostNotification',
    (payload: Payload, thunkAPI) => {
        const state = thunkAPI.getState() as GlobalState;

        if (isCurrentUserNotAvailable(state)) {
            return;
        }

        if (isMutedChannel(state, payload.channel_id)) {
            return;
        }

        const isThreadPost = Boolean(payload.root_id);

        if (isThreadPost && isThreadOpened(state, payload.root_id || '') && isAppActive(state)) {
            return;
        }

        const currentChannelId = getCurrentChannelId(state);
        const currentChannelName = getCurrentChannelName(state);
        const postChannel = getChannel(state, payload.channel_id);

        if (
            !isThreadPost &&
            (postChannel?.id === currentChannelId || currentChannelName === payload.channel_name) &&
            isAppActive(state)
        ) {
            return;
        }

        const teamId = postChannel?.team_id || getCurrentTeamId(state);
        const notificationSound = getCurrentUserNotificationSound(state);
        const soundEnabled = getCurrentUserNotificationSoundEnabled(state);
        const notificationUrl = getPostPermalinkUrl(state, payload.post_id, teamId);

        const user = getUser(state, payload.user_id);
        const userDisplayName = user ? getUserDisplayName(state, user, true, payload.sender_name) : payload.sender_name;
        const isDmChannel = payload.channel_type === Constants.DM_CHANNEL;

        const notificationTitle = isDmChannel ? userDisplayName : payload.channel_display_name;
        const notificationBody = getNotificationBody({
            emojiMap: getEmojiMap(state),
            emojiName: payload.emoji_name,
            isDmChannel,
            userDisplayName,
        });
        const notificationIcon =
            isDmChannel ? Client4.getProfilePictureUrl(payload.user_id, user?.last_picture_update || Date.now()) : undefined;
        const shouldPlayNotification = soundEnabled && notificationSound && !isMobileApp();

        if (shouldPlayNotification) {
            playNotification(notificationSound);
        }

        return sendPlatformNotification({
            title: notificationTitle,
            body: notificationBody,
            icon: notificationIcon,
            keepIconForDesktop: true,
            requireInteraction: false,

            // если звук нотификаций включен, он проигрывается в playNotification выше
            silent: true,
            onClick() {
                window.focus();
                browserHistory.push(notificationUrl);
            },
        }).catch((e: Error) => {
            if (e instanceof SendPlatformNotificationError) {
                reportMessageToSentry(e.message);
                return;
            }

            reportErrorToSentry(e);
        });
    },
);
