import {Perf10t} from './Perf10t';

declare global {
    interface Window {
        perf10t?: Perf10t;
    }
}

export const initialize = () => {
    window.perf10t = new Perf10t(window.performance);
};
