export enum InvitationErrorCode {
    INVALID_EMAIL = 'client.invalid_email',
    TOO_LONG_EMAIL = 'client.too_long_email',
    API_EMAIL_WRONG_FORMAT = 'model.email.wrong_format.app_error',
    API_EMAIL_WRONG_LENGTH = 'model.email.wrong_length.app_error',
    API_INVALID_EMAIL = 'api.team.invite_members.invalid_email.app_error',
    API_INVALID_EMAIL_DOMAIN = 'api.team.invite_members.invalid_domain.app_error',
    API_INVALID_MEMBER_ROLE = 'api.team.invite_members.invalid_user_role.app_error',
    API_INVALID_GUEST_ROLE = 'api.team.invite_guests.invalid_user_role.app_error',
    API_INVALID_TEAM_MEMBER ='api.team.invite_members.invalid_team_member.app_error',
    API_INVALID_GUEST = 'api.team.invite_guests.invalid_team_member.app_error',
    API_INVITATION_HAS_BEEN_SENT = 'api.invitation_has_been_sent',
    API_DEACTIVATED_MEMBER = 'api.team.invite_members.deactivate_team_member.app_error',
    API_DEACTIVATED_GUEST = 'api.team.invite_guests.deactivate_team_member.app_error',
    API_SENDING_ISSUE = 'api.team.invite_members.unable_to_send_email.app_error',
    API_LIMIT_EXCEEDED = 'app.email.rate_limit_exceeded.app_error',
}
