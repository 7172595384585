import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {OrderedChannelCategories} from '@mattermost/types/channel_categories';
import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';

type Payload = {

    /**
     * Team GUID
     */
    teamId: string;

    /**
     * User GUID
     */
    userId: string;
};

/**
 * Get a list of sidebar categories that will appear in the user's sidebar on the given team,
 * including a list of channel IDs in each category.
 *
 * Must be authenticated and have the `list_team_channels` permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetSidebarCategoriesForTeamForUser
 * @example /api/v4/users/{user_id}/teams/{team_id}/channels/categories
 */
export const getSidebarCategoriesForTeamForUser = createAsyncThunk(
    'sidebar/api/getSidebarCategoriesForTeamForUser',
    async (payload: Payload, thunkAPI) => {
        const {userId, teamId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/channels/categories`;

        try {
            const {data} = await requester.get<OrderedChannelCategories>(url, {
                ...options,
                signal: thunkAPI.signal,
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
);
