import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function CopyIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)}{...props}>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 13 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M11.6668 13.9998H4.3335V4.6665H11.6668V13.9998ZM11.6668 3.33317H4.3335C3.97987 3.33317 3.64074 3.47365 3.39069 3.72369C3.14064 3.97374 3.00016 4.31288 3.00016 4.6665V13.9998C3.00016 14.3535 3.14064 14.6926 3.39069 14.9426C3.64074 15.1927 3.97987 15.3332 4.3335 15.3332H11.6668C12.0205 15.3332 12.3596 15.1927 12.6096 14.9426C12.8597 14.6926 13.0002 14.3535 13.0002 13.9998V4.6665C13.0002 4.31288 12.8597 3.97374 12.6096 3.72369C12.3596 3.47365 12.0205 3.33317 11.6668 3.33317ZM9.66683 0.666504H1.66683C1.31321 0.666504 0.974069 0.80698 0.72402 1.05703C0.473972 1.30708 0.333496 1.64622 0.333496 1.99984V11.3332H1.66683V1.99984H9.66683V0.666504Z' />
            </svg>
        </span>
    );
}
