import {ComponentType} from 'react';
import {Redirect} from 'react-router-dom';

import {NotAuthorizedError} from '@time-webkit/http';

import {useAppSelector} from 'stores/redux_store';

import {useHttpError} from './http_error_context';

export const withAuthCheck = <Props extends object>(Component: ComponentType<Props>) => {
    return (props: Props) => {
        const notAuthorized = useHttpError(NotAuthorizedError);
        const noAccounts = useAppSelector((state) => state.entities.general.config.NoAccounts === 'true');

        if (notAuthorized) {
            return (
                <Redirect
                    to={{
                        pathname: noAccounts ? '/installation_signup' : '/auths',
                    }}
                />
            );
        }

        return <Component {...props} />;
    };
};
