import {batch} from 'react-redux';
import {AnyAction, Dispatch} from '@reduxjs/toolkit';

import {Action} from 'mattermost-redux/types/actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function batchActions(actions: Array<Action | AnyAction>, actionName?: string) {
    // eslint-disable-next-line no-process-env
    if (process.env.NODE_ENV === 'test') {
        // eslint-disable-next-line global-require
        const {batchActions: originalBatchActions} = require('redux-batched-actions');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return originalBatchActions(actions, actionName);
    }

    const batchThunk = (dispatch: Dispatch) => {
        batch(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            actions.forEach((action) => dispatch(action));
        });
    };

    return batchThunk as unknown as any;
}
