import {TeamInviteWithError} from '@mattermost/types/teams';

import {type UserProfile} from 'mattermost-redux/types/users';

import {InvitationError} from './invitation_error';

export type InvitationResult = {
    readonly email: string;
    readonly error: InvitationError | null;
    readonly profile?: UserProfile;
}

export function createInvitationResult(invitation: TeamInviteWithError, profile?: UserProfile): InvitationResult {
    return {
        email: invitation.email,
        error: InvitationError.fromInvitation(invitation),
        profile,
    };
}
