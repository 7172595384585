import {connect} from 'react-redux';
import compose from '@tinkoff/utils/function/compose';

import {Dispatch} from 'redux';

import {getConfig} from 'mattermost-redux/selectors/entities/general';

import {GlobalState} from 'types/store';

import {withAuthCheck} from 'components/with_auth_check';

import {setAccessDenied} from '../../features/fatalErrors';

import ErrorPage from './error_page';

function mapStateToProps(state: GlobalState) {
    const config = getConfig(state);

    return {
        siteName: config.SiteName,
        asymmetricSigningPublicKey: config.AsymmetricSigningPublicKey,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        setAccessDenied: () => dispatch(setAccessDenied()),
    };
}

export default compose(withAuthCheck, connect(mapStateToProps, mapDispatchToProps))(ErrorPage);
