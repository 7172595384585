import {useRef, RefObject, useCallback, PropsWithChildren, memo, useEffect} from 'react';
import {useOverlay, useOverlayPosition, AriaPositionProps} from '@react-aria/overlays';
import {mergeProps} from '@react-aria/utils';

import {Overlay} from 'components/overlay';

type Props = {
    isOpen: boolean;
    triggerRef: RefObject<Element>;
    placement?: AriaPositionProps['placement'];
    onClose?: () => void;
};

export const MenuOverlay = memo(({children, triggerRef, placement, onClose, isOpen}: PropsWithChildren<Props>) => {
    const popoverRef = useRef<HTMLDivElement>(null);

    const shouldCloseOnInteractOutside = useCallback((element: Element | null) => {
        return element !== null && triggerRef.current !== element && !triggerRef.current?.contains(element);
    }, [triggerRef]);

    const {overlayProps} = useOverlay(
        {
            isOpen,
            onClose,
            shouldCloseOnBlur: true,
            isDismissable: true,
            shouldCloseOnInteractOutside,
        },
        popoverRef,
    );

    const {
        overlayProps: positionProps,
    } = useOverlayPosition({
        targetRef: triggerRef,
        overlayRef: popoverRef,
        placement,
        isOpen,
    });

    const additionalProps = {
        onClick: () => onClose?.(),
    };

    useEffect(() => {
        if (isOpen) {
            popoverRef.current?.focus();
        }
    }, [isOpen]);

    return (
        <Overlay >
            <div {...mergeProps(overlayProps, positionProps, additionalProps)} tabIndex={-1} ref={popoverRef}>
                {children}
            </div>
        </Overlay>
    );
});

MenuOverlay.displayName = 'MenuOverlay';
