import {useCallback, useState} from 'react';

import uniqueId from '@tinkoff/utils/uniqueId';

import {ListBoxItem} from '../listbox';

type Props = {
    items: ListBoxItem[];
    limit: number;
    onChange?: (items: ListBoxItem[]) => void;
}

export const useInputAutocompleteState = ({items, limit, onChange}: Props) => {
    const [selectedItem, setSelectedItem] = useState<ListBoxItem['value'] | undefined>();
    const [newTagKey, setNewTagKey] = useState<string | undefined>();

    const addItem = useCallback((newItem: ListBoxItem) => {
        if (getItemCanBeAdded(newItem, items, limit)) {
            onChange?.([...items, newItem]);
            setNewTagKey(uniqueId().toString());
        }
    }, [limit, onChange, items]);

    const removeItem = useCallback((value: string) => {
        const nextItems = items.filter((item) => item.value !== value);
        const deletedItem = items.find((item) => item.value === value);
        onChange?.(nextItems);
        if (selectedItem === deletedItem?.label) {
            setSelectedItem(undefined);
        }
    }, [onChange, selectedItem, items]);

    const unselectItem = useCallback(() => {
        setSelectedItem(undefined);
    }, []);

    return {
        selectedItem,
        newTagKey,
        limit,
        addItem,
        selectItem: setSelectedItem,
        unselectItem,
        removeItem,
    };
};

function getItemCanBeAdded(newItem: ListBoxItem, items: ListBoxItem[], limit: number): boolean {
    if (limit && items.length >= limit) {
        return false;
    }
    if (items.find((item) => item.value === newItem.value)) {
        return false;
    }
    return true;
}
