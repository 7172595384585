type AsyncDebounceOptions = {
    leading?: boolean;
    trailing?: boolean;
    waitMilliseconds?: number;
}

/**
* Вызывает обернутую функцию не чаще, чем задано в параметре waitMilliseconds.
* @param trailing если установлено в true, то обернутая функция вызывается в конце интерфала ожидания,
* если false – то в начале интервала, таким образом значение false убирает задержку при первом вызове функции.
* @param waitMillisends величина интервала ожидания в миллисекундах
* @returns возвращает Promise, который заполняется при вызове обернутой функции. Особенность промиса в том, что
* он зависит от последующих вызовов функции. Например, если вызвать функцию три раза подряд, то каждый вызов вернет один и тот
* же Promise, соответствующий вызову обернутой функции с аргументами, которые были переданы в последнем случае. Если установить
* параметр trailing = false, то первый вызов функции будет возвращать свой собственный Promise, потому что обернутая функция
* в первом случае выполнится сразу, без задержки.
*/
export const asyncDebounce = <Arguments extends unknown[], Result>(
    func: (...args: Arguments) => Promise<Result>,
    {
        trailing = true,
        waitMilliseconds = 200,
    }: AsyncDebounceOptions = {},
): typeof func => {
    let timeout: NodeJS.Timeout | null = null;
    let resolve: (value: Result | PromiseLike<Result>) => void;
    let reject: (reason?: unknown) => void;
    let promise: Promise<Result> | undefined;
    let isPromiseFilled = false;

    const createPromise = () => {
        promise = new Promise((rs, rj) => {
            resolve = rs;
            reject = rj;
        });
        isPromiseFilled = false;
    };
    const fillPromise = (internalPromise: Promise<Result>) => {
        internalPromise.then(resolve).catch(reject);
        isPromiseFilled = true;
    };

    return async function fx(...args: Arguments) {
        if (!promise || isPromiseFilled) {
            createPromise();
        }

        if (trailing || timeout) {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                timeout = null;
                fillPromise(func(...args));
            }, waitMilliseconds);
        } else {
            fillPromise(func(...args));
            timeout = setTimeout(() => {
                timeout = null;
            }, waitMilliseconds);
        }
        return promise!;
    };
};

type Options = {
    delay?: number;
}

/**
 * Декоратор функции с аргументом в виде списка, создает задержку и группирует вызовы в один по кешируемому аргументу, если аргументов несколько - вызывает сразу.
 */
export function batchAsyncDebounce <CbArguments extends unknown[], CbResult>(func: () => (...args: CbArguments) => Promise<CbResult>, options?: Options): typeof func;
export function batchAsyncDebounce <Cacheable, Rest, CbArguments extends unknown[], CbResult>(func: (cacheable: Cacheable[], ...rest: Rest[]) => (...args: CbArguments) => Promise<CbResult>, options?: Options): typeof func;
export function batchAsyncDebounce<Cacheable, Rest, CbArguments extends unknown[], CbResult>(
    func: (cacheable: Cacheable[], ...rest: Rest[]) => (...args: CbArguments) => Promise<CbResult>,
    options?: Options,
): typeof func {
    const cache: Set<Cacheable> = new Set();

    const innerF = (...args: CbArguments) => {
        const payload = Array.from(cache);
        cache.clear();
        return func(payload)(...args);
    };

    const debouncedF = asyncDebounce(innerF, {waitMilliseconds: options?.delay ?? 200, trailing: false});

    return (cacheable, ...rest) => {
        if (rest.length) {
            return func(cacheable, ...rest);
        }

        cacheable?.forEach((i) => cache.add(i));
        return debouncedF;
    };
}
