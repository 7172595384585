import {asyncDelay as delay} from './asyncDelay';

type RetryConfig = {
    count: number;
    delay?: number;
}

type RunAsync<Output> = () => Promise<Output>;

export async function retry<Output>(
    runAsync: RunAsync<Output>,
    config: RetryConfig,
): Promise<Output> {
    const runWithDelay = async (count: number) => {
        if (config.delay && count < config.count) {
            await delay(config.delay);
        }
        return runAsync();
    };
    return retryUntil(runWithDelay, config.count);
}

async function retryUntil<Output>(
    doRetry: (count: number) => Promise<Output>,
    count: number,
): Promise<Output> {
    try {
        return await doRetry(count);
    } catch (err) {
        const nextCount = count - 1;
        if (nextCount > 0) {
            return retryUntil(doRetry, nextCount);
        }
        throw err;
    }
}

