import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function MessagesIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)} {...props}>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 16 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.74234 6.18068C4.15164 6.06106 4.58518 5.99787 5.03366 5.99787C6.59756 5.99787 8.0024 6.78517 8.77491 7.99132C9.17089 8.60954 9.40074 9.33779 9.40074 10.1265C9.40074 10.649 9.29655 11.1487 9.1078 11.6076C9.12212 11.6149 9.13621 11.6226 9.15007 11.6309L11.765 13.1844L11.5289 11.2717C11.4953 10.9996 11.6146 10.7312 11.8392 10.5738C13.0718 9.71005 13.8502 8.35219 13.8502 6.83812C13.8502 4.29639 11.6273 2.14941 8.77122 2.14941C6.1652 2.14941 4.08641 3.93686 3.74234 6.18068ZM2.21054 6.97673C2.20956 6.93016 2.20913 6.88396 2.20913 6.83812C2.20913 3.38304 5.19379 0.666687 8.77122 0.666687C12.3487 0.666687 15.3333 3.38304 15.3333 6.83812C15.3333 8.72268 14.4376 10.3928 13.0532 11.5145L13.2582 13.1746C13.3965 14.3224 12.1585 15.1511 11.1521 14.5451L8.56273 13.0067C8.43242 13.0032 8.30294 12.9962 8.17431 12.9859C7.42636 13.7261 6.38702 14.1922 5.24528 14.2436L3.69552 15.1637C2.85955 15.6707 1.84806 14.9664 1.95709 14.0399L1.95789 14.0331L1.95792 14.0332L2.06964 13.153C1.21203 12.4086 0.666626 11.3333 0.666626 10.1264C0.666626 8.85098 1.27336 7.72413 2.21054 6.97673ZM3.36841 7.96869C2.61748 8.45176 2.14977 9.24507 2.14977 10.1264C2.14977 10.97 2.58003 11.7327 3.2812 12.2192C3.50827 12.3768 3.62885 12.6475 3.59405 12.9216L3.51472 13.5466L4.65501 12.8696C4.76959 12.8016 4.9004 12.7657 5.03366 12.7657C5.97804 12.7657 6.80109 12.3529 7.30927 11.7371L7.31637 11.7285L7.31643 11.7285C7.69776 11.2803 7.91755 10.7232 7.91755 10.1264C7.91755 9.64393 7.77334 9.183 7.51821 8.78497C7.02472 8.01515 6.11628 7.48066 5.0337 7.48066C4.41173 7.48066 3.84727 7.65693 3.38196 7.96007C3.37747 7.963 3.37295 7.96587 3.36841 7.96869Z'
                />
            </svg>
        </span>
    );
}
