import type {Channel} from '@mattermost/types/channels';
import {isGmChannel} from 'features/sidebar/utils/isGmChannel';
import {getChannel} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import type {GlobalState} from 'types/store';
import {isDmChannel} from 'utils/isDmChannel';

export function isCurrentTeamChannel(state: GlobalState, channelId: Channel['id']) {
    if (!channelId) {
        return false;
    }

    const currentTeamId = getCurrentTeamId(state);
    const channel = getChannel(state, channelId);

    if (!channel) {
        return false;
    }

    // ЛС и групповые каналы не привязаны к команде и отображаются в любой команде
    if (isDmChannel(channel) || isGmChannel(channel)) {
        return true;
    }

    if (channel.team_id === currentTeamId) {
        return true;
    }

    return false;
}
