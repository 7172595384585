import {ComponentProps, ForwardedRef, forwardRef} from 'react';

import {useInputTagContext} from './input-tag-context';
import {TagEditable} from './tag-editable';

type Props = Omit<
ComponentProps<typeof TagEditable>,
'commitKeys' | 'editMode' | 'selected' | 'onDelete' | 'onUnselect' | 'onEdit' | 'onCommit'
>;

export const TagEditableConnected = forwardRef(({label, ...props}: Props, ref: ForwardedRef<HTMLElement>) => {
    const context = useInputTagContext();

    return (
        <TagEditable
            {...props}
            ref={ref}
            label={label}
            commitKeys={context.commitKeys}
            editMode={context.editableTag === label}
            selected={context.selectedTag === label}
            onDelete={context.removeTag}
            onUnselect={context.unselectTag}
            onEdit={context.editTag}
            onCommit={context.updateTag}
        />
    );
});
