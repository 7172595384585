import {ComponentProps, useCallback, useMemo, useState} from 'react';
import {Channel} from '@mattermost/types/channels';

import classNames from 'classnames';

import {InputAutocomplete, TagAutocomplete} from '../input-autocomplete';
import {DropdownListItem} from '../dropdown';
import {TypographySize, useTypography} from '../../hooks/typography';

import {ListBoxItem} from '../listbox';

import {TildeIcon} from '../../icons';

import {LockIcon} from '../../icons/lock';

import styles from './input-channels.module.css';
import {DropdownChannelListItem} from './dropdown-channel-list-item';

type Props = Omit<ComponentProps<typeof InputAutocomplete>, 'children' | 'items' | 'renderItem' | 'autocompleteItems' | 'onChange' | 'onInputChange'> & {
    minInputLength?: number;
    channels: Channel[];
    noMatchesText: string;
    onChange?: (channels: Channel[]) => void;
    autocompleteChannels: Channel[];
}

const NO_MATCHES_ITEM = 'no_matches';
const DEFAULT_MIN_INPUT_LENGTH = 2;
const DROPDOWN_HEIGHT = 180;

export const InputChannels = ({channels, noMatchesText, minInputLength = DEFAULT_MIN_INPUT_LENGTH, autocompleteChannels, onChange, ...props}: Props) => {
    const [bodyLTypography] = useTypography([{size: TypographySize.BodyL}]);

    const [filterByString, setFilterbyString] = useState('');

    const items = useMemo(() => channels.map(mapChannelToListBoxItem), [channels]);

    const autocompleteItems = useMemo(() => {
        const itemsFound = autocompleteChannels.filter((channel) => (filterByString ? channel.name.toLowerCase().includes(filterByString) || channel.display_name.toLowerCase().includes(filterByString) : true)).
            map(mapChannelToListBoxItem);

        return itemsFound.length > 0 ? itemsFound : [
            {
                value: NO_MATCHES_ITEM,
                label: noMatchesText,
                disabled: true,
            },
        ];
    }, [autocompleteChannels, filterByString, noMatchesText]);

    const handleInputChange = async (value: string) => {
        if (value.length >= minInputLength) {
            setFilterbyString(value.trim().toLowerCase());
        } else {
            setFilterbyString('');
        }
    };

    const handleChange = useCallback((items: ListBoxItem[]) => {
        onChange?.(items.map((item) => item.payload).filter((channel): channel is Channel => Boolean(channel)));
    }, [onChange]);

    return (
        <InputAutocomplete
            {...props}
            maxHeight={DROPDOWN_HEIGHT}
            items={items}
            onInputChange={handleInputChange}
            autocompleteItems={autocompleteItems}
            onChange={handleChange}
            renderItem={(item, isFocused) => {
                if (item.value === NO_MATCHES_ITEM) {
                    return (
                        <DropdownListItem
                            key={item.label}
                            label={
                                <span className={classNames(bodyLTypography, styles.textNoMatches)}>{item.label}</span>
                            }
                        />
                    );
                }
                return (
                    <DropdownChannelListItem key={item.value} channel={item.payload!} isFocused={isFocused} />
                );
            }}
        >
            {channels.map((channel) => (
                <TagAutocomplete
                    key={channel.name}
                    icon={getTagIcon(channel)}
                    value={channel.id}
                    label={channel.display_name}
                />
            ))}
        </InputAutocomplete>
    );
};

function getTagIcon(channel: Channel) {
    if (channel.type === 'P') {
        return <LockIcon className={styles.icon} width={16} height={16} />;
    }
    return <TildeIcon className={styles.icon} width={16} height={16} />;
}

function mapChannelToListBoxItem(channel: Channel): ListBoxItem<Channel> {
    return {
        value: channel.id,
        label: channel.display_name,
        payload: channel,
    };
}
