import {createSelector} from 'reselect';

import {getTeammateNameDisplaySetting} from 'mattermost-redux/selectors/entities/preferences';
import type {GlobalState} from 'types/store';
import type {User} from '../types/user';
import {displayUsername} from 'mattermost-redux/utils/user_utils';

export const getUserDisplayName = createSelector(
    getTeammateNameDisplaySetting,
    (state: GlobalState, user?: User) => user,
    (state: GlobalState, user?: User, useFallbackUsername?: boolean) => useFallbackUsername,
    (state: GlobalState, user?: User, useFallbackUsername?: boolean, fallbackName?: string) => fallbackName,
    (teamMateDisplaySetting, user, useFallbackUsername = true, fallbackName) => {
        return displayUsername(user, teamMateDisplaySetting, useFallbackUsername, fallbackName);
    },
);
