import {createSelector} from '@reduxjs/toolkit';

import values from '@tinkoff/utils/object/values';

import {getAllChannels} from 'mattermost-redux/selectors/entities/channels';
import type {RootState} from 'stores/redux_store';
import {isGmChannel} from '../utils/isGmChannel';

export const getGMChannelsForTeam = createSelector(
    getAllChannels,
    (_: RootState, teamId: string) => teamId,
    (allChannelsSet, teamId) => {
        return values(allChannelsSet).filter((channel) => {
            if (!isGmChannel(channel)) {
                return false;
            }

            // @TODO: DM каналы пока что шарятся на все команды, поэтому тут возвращаем true
            if (!channel.team_id) {
                return true;
            }

            return channel.team_id === teamId;
        });
    },
);
