import {createSelector} from '@reduxjs/toolkit';

import {isDirectChannel} from 'mattermost-redux/utils/channel_utils';

import {getUserIdFromChannelName} from 'utils/getUserIdFromChannelName';

import {getAllChannels} from './channels';
import {getCurrentUserId} from './common';

export const getDmUsers = createSelector(getAllChannels, getCurrentUserId, (channels, currentUserId) => {
    return Object.values(channels).reduce((acc, channel) => {
        if (!isDirectChannel(channel)) {
            return acc;
        }

        if (isDirectChannel(channel)) {
            const teammateId = getUserIdFromChannelName(channel, currentUserId);
            acc.push(teammateId);
        }

        return acc;
    }, [] as string[]);
});
