import type {AnyAction} from 'redux';

import {handleNewPosts as handleNewPostsForSidebar} from 'features/sidebar/actions/handle_new_post';

import type {Post} from 'mattermost-redux/types/posts';
import store from 'stores/redux_store';
import {getCurrentChannel} from 'mattermost-redux/selectors/entities/channels';
import {isDmChannel} from 'features/sidebar/utils/isDmChannel';
import {isGmChannel} from 'features/sidebar/utils/isGmChannel';
import {handlePostedEvent} from 'features/posts/actions/handle_new_post_event';
import type {PostedEvent, ParsedPostedEvent} from 'features/posts/types/posted_event';
import {getProfilesAndStatusesForPosts, getThreadsForPosts, receivedNewPost} from 'mattermost-redux/actions/posts';
import {WebsocketEvents} from 'mattermost-redux/constants';
import {batchActions} from 'utils/batch_actions';
import {actionsToMarkChannelAsUnread} from 'mattermost-redux/actions/channels';
import {getMentionsFromMessageProps} from '../../notifications/actions/send_post_notification/utils';
import {parseUsernamesFromPosts} from '../../posts/utils';
import {getUserByUsername} from 'mattermost-redux/selectors/entities/users';
import {getProfilesByUsernames} from 'mattermost-redux/actions/users';
import {ActionTypes} from 'utils/constants';
import {isAppFocused} from '../../app_activity/selector/is_app_focused';
import {sendPostsNotification} from 'features/notifications/actions/send_post_notification';
import {parsePostedEvent} from 'features/posts/utils/parse_posted_event';
import {reportErrorToSentry} from 'utils/sentry';

const DEBOUNCE_TIMEOUT = 500;
const SYNC_CHANNEL_TIMEOUT = DEBOUNCE_TIMEOUT * 2;
const STACK_LIMIT = 50;
const THRESHOLD_STACKED = 10;

export const debounceHandlePostedEvent = (delay = DEBOUNCE_TIMEOUT) => {
    let timeoutId: NodeJS.Timeout;
    let stackedMessages: PostedEvent[] = [];
    let handledMessagesCount = 0;
    let lastTimestamp: number;
    let lastDMLikeChannelId: Post['channel_id'];

    const flushMessages = () => {
        if (stackedMessages.length > 0) {
            const postsWithMeta: Array<{post: Post; meta: PostedEvent['data']}> = [];

            for (const message of stackedMessages) {
                let parsedPostedEvent: ParsedPostedEvent;
                try {
                    parsedPostedEvent = parsePostedEvent(message);
                    postsWithMeta.push({post: parsedPostedEvent.data.post, meta: message.data});
                } catch (e) {
                    reportErrorToSentry(e);
                    continue;
                }
            }

            const posts = postsWithMeta.map((e) => e.post);

            const actions = posts.map((post) => receivedNewPost(post, true));
            store.dispatch(batchActions(actions));

            store.dispatch(getThreadsForPosts(posts));
            getProfilesAndStatusesForPosts(posts, store.dispatch, store.getState);
            store.dispatch(handleNewPostsForSidebar(posts));
            store.dispatch(sendPostsNotification(postsWithMeta));
        }

        stackedMessages = [];
    };
    const flushMessagesOnTimeout = () => {
        clearTimeout(timeoutId);

        flushMessages();

        handledMessagesCount = 0;
    };

    return (message: PostedEvent) => {
        let post: ParsedPostedEvent['data']['post'];

        try {
            post = JSON.parse(message.data.post);
        } catch {
            return;
        }

        const state = store.getState();
        const currentChannel = getCurrentChannel(state);

        const shouldHandleEventSeparately = currentChannel && currentChannel.id === post.channel_id && (isDmChannel(currentChannel) || isGmChannel(currentChannel));
        if (shouldHandleEventSeparately) {
            const shouldSyncChannel = !lastTimestamp || (post.create_at - lastTimestamp > SYNC_CHANNEL_TIMEOUT && Boolean(lastDMLikeChannelId) && lastDMLikeChannelId === post.channel_id);

            lastTimestamp = post.create_at;
            lastDMLikeChannelId = post.channel_id;

            if (shouldSyncChannel) {
                return store.dispatch(handlePostedEvent(message));
            }

            const usernamesFromPostsTextAndAttachments = parseUsernamesFromPosts([post]);
            const usernamesToLoad = usernamesFromPostsTextAndAttachments.filter(
                (username) => !getUserByUsername(state, username),
            );

            if (usernamesToLoad.length > 0) {
                store.dispatch(getProfilesByUsernames(Array.from(usernamesToLoad)));
            }
            const actions: AnyAction[] = [];

            actions.push(
                {
                    type: ActionTypes.INCREASE_POST_VISIBILITY,
                    data: post.channel_id,
                    amount: 1,
                },
                receivedNewPost(post, true),
                {
                    type: WebsocketEvents.STOP_TYPING,
                    data: {
                        id: post.channel_id + post.root_id,
                        userId: post.user_id,
                        now: Date.now(),
                    },
                });

            const actionsUnreadChannel = isAppFocused(store.getState()) ? [] : actionsToMarkChannelAsUnread(
                store.getState,
                currentChannel.team_id,
                post.channel_id,
                getMentionsFromMessageProps(message.data),
                false,
                post.root_id === '',
            );

            // Может возникнуть ситуация, когда нам написали
            // из канала, которого нет в channel_ids категории
            // (если включена опция фильтрации на бэке)
            store.dispatch(handleNewPostsForSidebar([post]));

            return store.dispatch(batchActions(actions.concat(actionsUnreadChannel)));
        }

        if (timeoutId && handledMessagesCount > THRESHOLD_STACKED) {
            if (stackedMessages.push(message) > STACK_LIMIT) {
                flushMessages();
                return;
            }

            clearTimeout(timeoutId);
            timeoutId = setTimeout(flushMessagesOnTimeout, delay);
            return;
        }

        handledMessagesCount += 1;
        store.dispatch(handlePostedEvent(message));
        clearTimeout(timeoutId);
        timeoutId = setTimeout(flushMessagesOnTimeout, delay);
    };
};

export const handlePostedEventDebounced = debounceHandlePostedEvent();
