import {PropsWithChildren, createContext, useContext, useEffect, useState} from 'react';

import {HttpError, HttpErrorObservable, interceptHttpError} from '@time-webkit/http';

export type HttpErrorContext = {
    httpErrorObservable: HttpErrorObservable;
};

const httpErrorObservable = interceptHttpError();

const HttpErrorContext = createContext<HttpErrorContext>({httpErrorObservable});

export const HttpErrorProvider = ({children}: PropsWithChildren<unknown>) => (
    <HttpErrorContext.Provider value={{httpErrorObservable}}>
        {children}
    </HttpErrorContext.Provider>
);

export const useHttpError = (ErrorType: new () => HttpError) => {
    const {httpErrorObservable} = useContext(HttpErrorContext);
    const [error, setError] = useState<HttpError | undefined>();

    useEffect(() => {
        const subscription = httpErrorObservable.subscribe((httpError) => {
            if (httpError instanceof ErrorType) {
                setError(httpError);
            }
        });
        return () => subscription.unsubscribe();
    }, [httpErrorObservable, ErrorType]);

    return error;
};
