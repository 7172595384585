import {first} from 'lodash';

import {ListBoxItem} from './listbox.types';

type State = {
    items: ListBoxItem[];
    focusedItem?: ListBoxItem;
    selectedItem?: ListBoxItem;
};

type InitialArg = {items: ListBoxItem[]; selectedValue?: ListBoxItem['value']}

type Action =
    | {
        type: 'focus_item';
        value: ListBoxItem['value'];
    }
    | {
        type: 'focus_next_item';
    }
    | {
        type: 'focus_previous_item';
    }
    | {
        type: 'focus_first_item';
    }
    | {
        type: 'focus_last_item';
    }
    | {
        type: 'reset';
        items: ListBoxItem[];
        selectedValue?: ListBoxItem['value'];
    }
    | {
        type: 'select_item';
        value: ListBoxItem['value'];
    }
    | {
        type: 'clear_focus';
    };

export const init = ({items, selectedValue}: InitialArg): State => {
    const activeItems = items.filter((item) => !item.disabled);
    const selectedItem = activeItems.find((item) => item.value === selectedValue);
    const focusedItem = selectedItem ?? first(items);
    return {
        items: activeItems,
        selectedItem,
        focusedItem,
    };
};

export const listBoxReducer = (state: State, action: Action): State => {
    switch (action.type) {
    case 'focus_item': {
        const item = state.items.find((item) => item.value === action.value);
        if (item) {
            return {...state, focusedItem: item};
        }
        return state;
    }
    case 'focus_next_item':
        if (state.focusedItem) {
            const focusedItemIndex = state.items.findIndex((item) => item.value === state.focusedItem?.value);
            if (focusedItemIndex < state.items.length - 1) {
                return {...state, focusedItem: state.items[focusedItemIndex + 1]};
            }
        } else if (state.items.length > 0) {
            return {...state, focusedItem: state.items[0]};
        }
        return state;
    case 'focus_previous_item':
        if (state.focusedItem) {
            const focusedItemIndex = state.items.findIndex((item) => item.value === state.focusedItem?.value);
            if (focusedItemIndex > 0) {
                return {...state, focusedItem: state.items[focusedItemIndex - 1]};
            }
        }
        return state;
    case 'focus_first_item':
        if (state.items.length > 0) {
            return {...state, focusedItem: state.items[0]};
        }
        return state;
    case 'focus_last_item':
        if (state.items.length > 0) {
            return {...state, focusedItem: state.items[state.items.length - 1]};
        }
        return state;
    case 'reset':
        return init(action);
    case 'select_item': {
        const item = state.items.find((item) => item.value === action.value);
        if (item) {
            return {...state, selectedItem: item, focusedItem: item};
        }
        return state;
    }
    case 'clear_focus':
        return {...state, focusedItem: state.selectedItem};
    }
};
