import Queue from 'p-queue';

import uniq from '@tinkoff/utils/array/uniq';

import {Client4} from 'mattermost-redux/client';
import {EmojiTypes} from 'mattermost-redux/action_types';
import {General, Emoji} from '../constants';

import {
    getCustomEmojisByName as selectCustomEmojisByName,
    getNonExistentEmoji,
} from 'mattermost-redux/selectors/entities/emojis';
import {parseNeededCustomEmojisFromText} from 'mattermost-redux/utils/emoji_utils';

import {GetStateFunc, DispatchFunc, ActionFunc} from 'mattermost-redux/types/actions';
import {SystemEmoji, CustomEmoji} from 'mattermost-redux/types/emojis';

import {getUserById} from 'mattermost-redux/selectors/entities/common';

import {fetchUsersByIdsDebounced} from 'features/users/actions/fetch_users_by_ids';

import {logError} from './errors';
import {bindClientFunc, forceLogoutIfNecessary} from './helpers';

export let systemEmojis: Map<string, SystemEmoji> = new Map();

export function setSystemEmojis(emojis: Map<string, SystemEmoji>) {
    systemEmojis = emojis;
}

export function createCustomEmoji(emoji: any, image: any): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.createCustomEmoji,
        onSuccess: EmojiTypes.RECEIVED_CUSTOM_EMOJI,
        params: [emoji, image],
    });
}

export function getCustomEmoji(emojiId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getCustomEmoji,
        onSuccess: EmojiTypes.RECEIVED_CUSTOM_EMOJI,
        params: [emojiId],
    });
}

export function getCustomEmojiByName(name: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;

        try {
            data = await Client4.getCustomEmojiByName(name);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);

            if (error.status_code === 404) {
                dispatch({type: EmojiTypes.CUSTOM_EMOJI_DOES_NOT_EXIST, data: name});
            } else {
                dispatch(logError(error));
            }

            return {error};
        }

        dispatch({
            type: EmojiTypes.RECEIVED_CUSTOM_EMOJI,
            data,
        });

        return {data};
    };
}

export function getCustomEmojisByName(names: string[]): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        if (!names || names.length === 0) {
            return {data: true};
        }

        const getCustomEmojiByNameQueue = new Queue({concurrency: 2, intervalCap: 2, interval: 1000});

        const uniqNames = uniq(names);

        // @TODO: Переписать на метод множественной выборки, когда он появится
        await getCustomEmojiByNameQueue.addAll(uniqNames.map((emojiName) => () => dispatch(getCustomEmojiByName(emojiName))));

        await getCustomEmojiByNameQueue.onEmpty();

        return {data: true};
    };
}

export function getCustomEmojisInText(text: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        if (!text) {
            return {data: true};
        }

        const state = getState();

        const nonExistentEmoji = getNonExistentEmoji(state);
        const customEmojisByName = selectCustomEmojisByName(state);

        const emojisToLoad = parseNeededCustomEmojisFromText(text, systemEmojis, customEmojisByName, nonExistentEmoji);

        const result = await dispatch(getCustomEmojisByName(Array.from(emojisToLoad)));

        return result;
    };
}

export function getCustomEmojis(
    page = 0,
    perPage: number = General.PAGE_SIZE_DEFAULT,
    sort: string = Emoji.SORT_BY_NAME,
    loadUsers = false,
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;
        try {
            data = await Client4.getCustomEmojis(page, perPage, sort);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);

            dispatch(logError(error));
            return {error};
        }

        if (loadUsers) {
            dispatch(loadProfilesForCustomEmojis(data));
        }

        dispatch({
            type: EmojiTypes.RECEIVED_CUSTOM_EMOJIS,
            data,
        });

        return {data};
    };
}

export function loadProfilesForCustomEmojis(emojis: CustomEmoji[]): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();

        const userIdsToLoad = emojis.
            filter((emoji: CustomEmoji) => {
                const {creator_id: creatorId} = emoji;
                const emojiCreator = getUserById(state, creatorId);

                return !emojiCreator;
            }).
            map(({creator_id: creatorId}) => creatorId);

        if (userIdsToLoad.length > 0) {
            await dispatch(fetchUsersByIdsDebounced(userIdsToLoad));
        }

        return {data: true};
    };
}

export function deleteCustomEmoji(emojiId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.deleteCustomEmoji(emojiId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);

            dispatch(logError(error));
            return {error};
        }

        dispatch({
            type: EmojiTypes.DELETED_CUSTOM_EMOJI,
            data: {id: emojiId},
        });

        return {data: true};
    };
}

export function searchCustomEmojis(term: string, options: any = {}, loadUsers = false): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;
        try {
            data = await Client4.searchCustomEmoji(term, options);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);

            dispatch(logError(error));
            return {error};
        }

        if (loadUsers) {
            dispatch(loadProfilesForCustomEmojis(data));
        }

        dispatch({
            type: EmojiTypes.RECEIVED_CUSTOM_EMOJIS,
            data,
        });

        return {data};
    };
}

export function autocompleteCustomEmojis(name: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;
        try {
            data = await Client4.autocompleteCustomEmoji(name);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);

            dispatch(logError(error));
            return {error};
        }

        dispatch({
            type: EmojiTypes.RECEIVED_CUSTOM_EMOJIS,
            data,
        });

        return {data};
    };
}
