import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {ChannelCategory} from '@mattermost/types/channel_categories';
import type {ThunkConfig} from 'stores/redux_store';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import {getChannelChannelCategoriesForTeam} from '../selectors/get_channel_channel_categories_for_team';

import {updateSidebarCategoriesForTeamForUser} from '../api/update_sidebar_categories_for_team_for_user';

import {receivedChannelCategories} from './received_channel_categories';

type Payload = {
    category: ChannelCategory;
    channel: Channel;
};

/**
 * Add a channel to a given category without specifying its order.
 * The channel will be removed from its previous category (if any) on the given category's team
 * and it will be placed first in its new category.
 */
export const addChannelToChannelCategory = createAsyncThunk<boolean, Payload, ThunkConfig>(
    'sidebar/actions/addChannelToChannelCategory',
    async (payload, thunkAPI) => {
        const {category: newChannelCategoryForChannel, channel} = payload;

        const state = thunkAPI.getState();
        const dispatch = thunkAPI.dispatch;

        const currentUserId = getCurrentUserId(state);

        /**
         * The default sorting needs to behave like alphabetical sorting until the point that the user rearranges their
         * channels at which point, it becomes manual. Other than that, we never change the sorting method automatically.
         */
        const sorting = newChannelCategoryForChannel.sorting;

        const targetChannelCategoryChannelIds = newChannelCategoryForChannel.channel_ids.filter(
            (channelId) => channelId !== channel.id,
        );

        // Add the channel to the new category
        const categories: ChannelCategory[] = [
            {
                ...newChannelCategoryForChannel,
                sorting,

                // Put channel in the beginning
                channel_ids: [channel.id].concat(targetChannelCategoryChannelIds),
            },
        ];

        // And remove it from the old category
        const sourceCategories = getChannelChannelCategoriesForTeam(
            state,
            channel,
            newChannelCategoryForChannel.team_id,
        );

        if (sourceCategories.length) {
            sourceCategories.forEach((category) => {
                if (category.id === newChannelCategoryForChannel.id) {
                    return;
                }

                categories.push({
                    ...category,
                    channel_ids: category.channel_ids.filter((id) => id !== channel.id),
                });
            });
        }

        try {
            const updatedCategories = await dispatch(
                updateSidebarCategoriesForTeamForUser({
                    categories,
                    teamId: newChannelCategoryForChannel.team_id,
                    userId: currentUserId,
                }),
            ).unwrap();

            dispatch(receivedChannelCategories(updatedCategories as unknown as ChannelCategory[]));

            return true;
        } catch (error: any) {
            return false;
        }
    },
);
