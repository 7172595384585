import {useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useIntl} from 'react-intl';

import {useId} from '@react-aria/utils';

import {ModalDialog, ModalSize, nodeRef} from '@time-webkit/all/organisms/modal-dialog';
import {closeModal} from 'actions/views/modals';
import {ModalIdentifiers} from 'utils/constants';

import {getCurrentTeam} from 'mattermost-redux/selectors/entities/teams';

import {sendToStatist} from '@time-webkit/statist';

import {type Channel} from '@mattermost/types/channels';
import {type TeamInvitationRole} from '@mattermost/types/teams';
import {getConfig} from 'mattermost-redux/selectors/entities/general';

import {InviteMembersForm} from './invite_members_form';
import {getInvitableChannels, getAllowedGuestDomains, getAllowedTeamDomains} from './selectors';
import {LinkInviteMember} from './link-invite-member';
import {InviteMembersSummary} from './invite_members_summary';
import {inviteMembersContext} from './invite_members_context';

type Props = {
    role?: TeamInvitationRole;
    initialChannels?: Channel[];
}

const DEFAULT_ROLE: TeamInvitationRole = 'team_user';
const DEFAULT_INITIAL_CHANNELS: Channel[] = [];

export const InviteMembersModal = ({role = DEFAULT_ROLE, initialChannels = DEFAULT_INITIAL_CHANNELS}: Props) => {
    const intl = useIntl();
    const inviteMemberModalId = useId();
    const channels = useSelector(getInvitableChannels);
    const currentTeam = useSelector(getCurrentTeam);
    const allowedGuestDomains = useSelector(getAllowedGuestDomains);
    const allowedTeamDomains = useSelector(getAllowedTeamDomains);
    const config = useSelector(getConfig);
    const {success, sendingIssues, rateLimitIssues, result, reset} = useContext(inviteMembersContext);
    const emailsLimit = Number(config.EmailRateLimitMaxBurst) || undefined;

    const dispatch = useDispatch();

    const successModalTitle = useMemo(() => {
        let invitationsSent = result.length;
        if (sendingIssues.length > 0) {
            invitationsSent -= sendingIssues.length;
        } else if (rateLimitIssues.length > 0) {
            invitationsSent -= rateLimitIssues.length;
        }
        if (invitationsSent < result.length) {
            return intl.formatMessage({
                id: 'invite_members.invites_have_been_sent_with_issues',
                defaultMessage: 'Sent {count} out of {total} {total, plural, one {invitation} other {invitations}} to the {team}',
            }, {
                total: result.length,
                count: invitationsSent,
                team: currentTeam?.display_name,
            });
        }
        return intl.formatMessage({
            id: 'invite.members.invites_have_been_sent',
            defaultMessage: '{count, plural, one {Invitation} other {Invitations}} to the {team} {count, plural, one {has} other {have}} been sent',
        }, {
            count: invitationsSent,
            team: currentTeam?.display_name,
        });
    }, [sendingIssues.length, rateLimitIssues.length, intl, result.length, currentTeam?.display_name]);

    const handleClose = () => dispatch(closeModal(ModalIdentifiers.INVITE_MEMBERS));

    const handleInviteMoreButtonClick = () => {
        sendToStatist('team.invite.sendAgain', {
            teamid: currentTeam!.id,
        });
        reset();
    };

    const renderInviteMemberLink = () => {
        if (config.EnableTeamInvitations === 'true') {
            return <LinkInviteMember containerId={inviteMemberModalId} />;
        }

        return null;
    };

    if (!currentTeam) {
        return null;
    }

    if (!success) {
        return (
            <ModalDialog
                id={inviteMemberModalId}
                isOpen={true}
                size={ModalSize.MEDIUM}
                title={intl.formatMessage(
                    {
                        id: 'invite.members.invite_members',
                        defaultMessage: 'Invite to the {team}',
                    },
                    {
                        team: currentTeam.display_name,
                    },
                )}
                onClose={handleClose}
                nodeRef={nodeRef}
            >
                <InviteMembersForm
                    team={currentTeam}
                    role={role}
                    channels={initialChannels}
                    autocompleteChannels={channels}
                    allowedGuestDomains={allowedGuestDomains}
                    allowedTeamDomains={allowedTeamDomains}
                    emailsLimit={emailsLimit}
                    onCancel={handleClose}
                    renderInviteMemberLink={renderInviteMemberLink}
                />
            </ModalDialog>
        );
    }

    return (
        <ModalDialog
            isOpen={true}
            size={ModalSize.MEDIUM}
            title={successModalTitle}
            confirmText={intl.formatMessage({
                id: 'invite.members.ok',
                defaultMessage: 'Ok',
            })}
            cancelText={intl.formatMessage({
                id: 'invite.members.invite_more',
                defaultMessage: 'Invite more',
            })}
            onCancel={handleInviteMoreButtonClick}
            onConfirm={handleClose}
            onClose={handleClose}
            nodeRef={nodeRef}
            data-test-id='ResultsInvites'
        >
            <InviteMembersSummary />
        </ModalDialog>
    );
};
