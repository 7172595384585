import type {AxiosError} from 'axios';

import type {AsyncThunk} from '@reduxjs/toolkit';

import {sendPlatformNotification} from 'features/notifications/utils/send_platform_notification';

import {Client4} from 'mattermost-redux/client';
import {UserTypes} from 'mattermost-redux/action_types';

import {Client4Error} from 'mattermost-redux/types/client4';
import {Action, DispatchFunc, GenericAction, GetStateFunc} from 'mattermost-redux/types/actions';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import {localizeMessage} from 'mattermost-redux/utils/i18n_utils';
import {getConfig} from 'mattermost-redux/selectors/entities/general';

import {SIGNIN_ROUTE} from 'utils/route';

import {logError} from './errors';

type ActionType = string;
const HTTP_UNAUTHORIZED = 401;

const forceLogout = (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const state = getState();
    const siteName = getConfig(state).SiteName;

    sendPlatformNotification({
        title: siteName || '',
        body: localizeMessage(
            'login.session_expired',
            'Your session has expired. Please log in again.',
        ),
        requireInteraction: true,
        silent: false,
        onClick: () => {
            window.focus();
        },
    }).catch((err) => {
        dispatch(logError(err));
    });

    Client4.setToken('');
    dispatch({type: UserTypes.LOGOUT_SUCCESS, data: {}});
};

export function forceLogoutIfNecessary(err: Client4Error, dispatch: DispatchFunc, getState: GetStateFunc) {
    const {currentUserId} = getState().entities.users;

    if (
        'status_code' in err &&
        err.status_code === HTTP_UNAUTHORIZED &&
        err.url &&
        err.url.indexOf(SIGNIN_ROUTE) === -1 &&
        currentUserId
    ) {
        forceLogout(dispatch, getState);
    }
}

export const forceLogoutIfNecessaryThunk =
    (err: Client4Error | AxiosError) => (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const {currentUserId} = getState().entities.users;

        if (
            'status_code' in err &&
            err.status_code === HTTP_UNAUTHORIZED &&
            err.url &&
            err.url.indexOf(SIGNIN_ROUTE) === -1 &&
            currentUserId
        ) {
            forceLogout(dispatch, getState);
        }
    };

export const forceLogoutIfNecessaryThunkAxios =
    (err: AxiosError) => (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();

        const currentUserId = getCurrentUserId(state);

        if (currentUserId && err.status === HTTP_UNAUTHORIZED && !err.config?.url?.includes(SIGNIN_ROUTE)) {
            forceLogout(dispatch, getState);
        }
    };

function dispatcher(type: ActionType, data: any, dispatch: DispatchFunc) {
    if (type.indexOf('SUCCESS') === -1) {
        // we don't want to pass the data for the request types
        dispatch(requestSuccess(type, data));
    } else {
        dispatch(requestData(type));
    }
}

export function requestData(type: ActionType): GenericAction {
    return {
        type,
        data: null,
    };
}

export function requestSuccess(type: ActionType, data: any) {
    return {
        type,
        data,
    };
}

export function requestFailure(type: ActionType, error: Client4Error): any {
    return {
        type,
        error,
    };
}

type OnSuccessAction = Action | ActionType | AsyncThunk<any, any, any>;

/**
 * Returns an ActionFunc which calls a specfied (client) function and
 * dispatches the specifed actions on request, success or failure.
 *
 * @export
 * @param {Object} obj                                       an object for destructirung required properties
 * @param {() => Promise<mixed>} obj.clientFunc              clientFunc to execute
 * @param {ActionType} obj.onRequest                         ActionType to dispatch on request
 * @param {(ActionType | Array<ActionType>)} obj.onSuccess   ActionType to dispatch on success
 * @param {ActionType} obj.onFailure                         ActionType to dispatch on failure
 * @param {...Array<any>} obj.params
 */

export function bindClientFunc({
    clientFunc,
    onRequest,
    onSuccess,
    onFailure,
    params = [],
    pureError = false,
}: {
    clientFunc: (...args: any[]) => Promise<any>;
    onRequest?: ActionType;
    onSuccess?: OnSuccessAction | OnSuccessAction[];
    onFailure?: ActionType;
    params?: any[];
    pureError?: boolean;
}) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        if (onRequest) {
            dispatch(requestData(onRequest));
        }

        let data: any = null;
        try {
            data = await clientFunc(...params);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            if (onFailure) {
                dispatch(requestFailure(onFailure, error));
            }
            dispatch(logError(error));
            if (pureError && !('status_code' in error && error.status_code === 401)) {
                throw error;
            }
            return {error};
        }

        if (Array.isArray(onSuccess)) {
            await Promise.all(onSuccess.map(async (s) => {
                if (typeof s === 'string') {
                    dispatcher(s, data, dispatch);
                } else if (typeof s === 'function') {
                    await dispatch(s(data) as any);
                } else if (typeof s === 'object') {
                    dispatcher(s.type, {data, params}, dispatch);
                }
            }));
        } else if (onSuccess) {
            if (typeof onSuccess === 'string') {
                dispatcher(onSuccess, data, dispatch);
            } else if (typeof onSuccess === 'function') {
                await dispatch(onSuccess(data) as any);
            } else if (typeof onSuccess === 'object') {
                dispatcher(onSuccess.type, {data, params}, dispatch);
            }
        }

        return {data};
    };
}

// Debounce function based on underscores modified to use es6 and a cb

export function debounce(func: (...args: any) => unknown, wait: number, immediate?: boolean, cb?: () => unknown) {
    let timeout: NodeJS.Timeout | null;
    return function fx(...args: any[]) {
        const runLater = () => {
            timeout = null;
            if (!immediate) {
                Reflect.apply(func, null, args);
                if (cb) {
                    cb();
                }
            }
        };
        const callNow = immediate && !timeout;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(runLater, wait);
        if (callNow) {
            Reflect.apply(func, null, args);
            if (cb) {
                cb();
            }
        }
    };
}

export class FormattedError extends Error {
    intl: {
        id: string;
        defaultMessage: string;
        values: any;
    };

    constructor(id: string, defaultMessage: string, values: any = {}) {
        super(defaultMessage);
        this.intl = {
            id,
            defaultMessage,
            values,
        };
    }
}
