import {createSelector} from 'reselect';

import {getChannelsInCurrentTeam} from 'mattermost-redux/selectors/entities/channels';
import {getMyChannelPermissions} from 'mattermost-redux/selectors/entities/roles';
import Constants from 'utils/constants';
import {getCurrentTeam} from 'mattermost-redux/selectors/entities/teams';
import {Permissions} from 'mattermost-redux/constants';
import {PermissionsKeys} from '../../permissions_gates/types';

export const getInvitableChannels = createSelector(
    getCurrentTeam,
    getChannelsInCurrentTeam,
    getMyChannelPermissions,
    (team, channels, permissions) => channels.filter((channel) => {
        if (!team) {
            return [];
        }
        const getPermissions = (permissionKey: PermissionsKeys) => {
            return permissions.system.has(permissionKey) ||
              permissions.team[team.id]?.has(permissionKey) ||
              permissions.channel[channel.id]?.has(permissionKey);
        };
        if (channel.type === Constants.DM_CHANNEL || channel.type === Constants.GM_CHANNEL || channel.delete_at > 0) {
            return false;
        }
        if (channel.type === Constants.PRIVATE_CHANNEL) {
            return getPermissions(Permissions.MANAGE_PRIVATE_CHANNEL_MEMBERS);
        }
        return getPermissions(Permissions.MANAGE_PUBLIC_CHANNEL_MEMBERS);
    }));
