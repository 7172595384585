import {TypographySize, useTypography} from '../../hooks/typography';

import {LinkSize, LinkVariant} from './link';

export const useLinkTypography = (size: LinkSize, variant: LinkVariant) => {
    const [
        bodyMTypography,
        bodyLTypography,
    ] = useTypography([
        {size: variant === 'flat' ? TypographySize.BodyMTrue : TypographySize.BodyM},
        {size: variant === 'flat' ? TypographySize.BodyLTrue : TypographySize.BodyL},
    ]);
    switch (size) {
    case 'medium':
        return bodyMTypography;
    case 'large':
        return bodyLTypography;
    }
};
