import {useRef, useCallback, useState} from 'react';

import {reportMessageToSentry} from 'utils/sentry';
import {isDesktopApp} from 'utils/user_agent';

type CopyOptions = {
    successCopyTimeout?: number;

    /**
     * Обязательно передать этот параметр, если хук используется внутри модалки
     */
    appendId?: string;
    text: string;
    trackCallback?: () => void;
};

type CopyResponse = {
    copiedRecently: boolean;
    copyError: boolean;
    onClick: () => void;
};

const DEFAULT_COPY_TIMEOUT = 4000;

export default function useCopyText(options: CopyOptions): CopyResponse {
    const [copiedRecently, setCopiedRecently] = useState(false);
    const [copyError, setCopyError] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    let successCopyTimeout = DEFAULT_COPY_TIMEOUT;
    if (options.successCopyTimeout || options.successCopyTimeout === 0) {
        successCopyTimeout = options.successCopyTimeout;
    }

    const onClick = useCallback(async () => {
        if (options.trackCallback) {
            options.trackCallback();
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        const clipboard = navigator.clipboard;
        let hasPermissions = false;

        try {
            const permissions = await navigator.permissions.query({name: 'clipboard-write'});
            hasPermissions = permissions.state === 'granted';
        } catch (error) {
            reportMessageToSentry(`Can not obtain clipboard-write permissions due to: ${error}`);
        }

        if (!isDesktopApp() && hasPermissions) {
            try {
                await clipboard.writeText(options.text);
                setCopiedRecently(true);
                setCopyError(false);
            } catch (error) {
                setCopiedRecently(false);
                setCopyError(true);
                reportMessageToSentry(`Can not copy text to clipboard due to: ${error}`);
            }
        } else {
            const textField = document.createElement('input');

            textField.value = options.text;
            textField.style.position = 'fixed';
            textField.style.opacity = '0';

            if (options.appendId) {
                document.getElementById(options.appendId)?.append(textField);
            } else {
                document.body.append(textField);
            }

            textField.focus();
            textField.select();

            try {
                const success = document.execCommand('copy');
                setCopiedRecently(success);
                setCopyError(!success);
            } catch (err) {
                setCopiedRecently(false);
                setCopyError(true);
            } finally {
                textField.remove();
            }
        }

        timerRef.current = setTimeout(() => {
            setCopiedRecently(false);
            setCopyError(false);
        }, successCopyTimeout);
    }, [options, successCopyTimeout]);

    return {
        copiedRecently,
        copyError,
        onClick,
    };
}

