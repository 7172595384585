import {ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef} from 'react';

import classNames from 'classnames';

import styles from './dropdown-container.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean;
};

export const DropdownContainer = forwardRef(({
    className,
    children,
    ...props
}: PropsWithChildren<Props>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <div
            {...props}
            ref={ref}
            className={classNames(styles.root, className)}
        >
            {children}
        </div>
    );
});

DropdownContainer.displayName = 'Dropdown';
