import type {GlobalState} from 'types/store';

import {isAppFocused} from './is_app_focused';
import {isAppVisible} from './is_app_visible';

/**
 * Активно ли сейчас окно с приложением
 *
 * Используем связку visibilitychange и focus/blur
 * так как visibilitychange не срабатывает,
 * если приложение перекрыть другим приложением,
 * только если свернуть полностью приложение или
 * епсли мы в браузере то переключиться на другую вкладку
 */
export const isAppActive = (state: GlobalState) => isAppVisible(state) && isAppFocused(state);
