import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {Channel} from '@mattermost/types/channels';
import {ChannelReadOnlyActionType} from '../constants';
import {ChannelTypes, UserTypes} from 'mattermost-redux/action_types';

import type {GenericAction} from 'mattermost-redux/types/actions';

export type ReadOnlyChannelsState = Record<Channel['id'], boolean>;

const initialState: ReadOnlyChannelsState = {};

const removeChannel = (state: ReadOnlyChannelsState, action: GenericAction): ReadOnlyChannelsState => omit([action.data.id], state);

export const readOnlyChannelsSlice = createSlice({
    name: 'readOnlyChannels',
    initialState,
    reducers: {
        [ChannelReadOnlyActionType.ADD]: (state, action: PayloadAction<Channel['id']>) => ({
            ...state,
            [action.payload]: true,
        }),
        [ChannelReadOnlyActionType.REMOVE]: (state, action: PayloadAction<Channel['id']>) => omit([action.payload], state),
    },
    extraReducers: (builder) => {
        builder.addCase(UserTypes.RECEIVED_PROFILE_IN_CHANNEL, removeChannel);
        builder.addCase(ChannelTypes.RECEIVED_CHANNEL_DELETED, removeChannel);
    },
});
