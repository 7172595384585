import React from 'react';

import {TypographySize, useTypography} from '../../../../hooks/typography';
import {AlertOutlineIcon} from '../../../../icons';

import styles from './styles.module.css';

export const ModalError = (props: any) => {
    const textCls = useTypography({
        size: TypographySize.BodyLTrue,
    });

    return (
        <div className={styles.error}>
            <AlertOutlineIcon className={styles.icon} />
            <span className={textCls}>{props.children}</span>
        </div>
    );
};
